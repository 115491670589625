import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../../models/company.model';
import { Destination, SlotsBatch } from '../../../../../../models/slots-batch.model';
import { SlotService } from '../../../../../../services/slot.service';
import { Contract } from '../../../../contracts/models/contract.model';

interface Filters {
  order_by?: string,
  product_id?: number,
  visible_to?: number,
  operation_type?: string,
  applied?: boolean,
  settled?: boolean,
  sustainable?: boolean,
  'filters[reference]'?: string,
  'filters[seller.name]'?: string,
  'filters[buyer.name]'?: string
}

@Component({
  selector: 'ag-link-to-contract',
  templateUrl: './link-to-contract.component.html',
  styleUrls: ['./link-to-contract.component.scss']
})
export class LinkToContractComponent implements OnDestroy {

  @ViewChild('linkModal', { static: true }) public readonly linkModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public filters: Filters = {};

  @Output() readonly linked = new EventEmitter<Contract>();

  public hideColumns: string[] = ['actions', 'product', 'source', 'date', 'balance'];
  public processing: boolean;
  public batch: SlotsBatch;
  public availableContracts: Contract[];
  public contract: Contract;
  public refreshedTable: boolean = true;
  public destinations: Destination[];

  private type: 'compra' | 'venta' = 'compra';
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private slotService: SlotService
  ) { }

  get hideBuyerColumn(): string {
    return this.type !== 'venta' && !this.company.activity.broker ? 'buyer' : '';
  }

  public linkBatch(batch: SlotsBatch, type: 'compra' | 'venta'): void {
    if (type === 'venta') {
      this.type = 'venta'
    };

    this.availableContracts = undefined;
    this.contract = batch.contract;
    this.batch = batch;

    this.getContracts();
    this.openModal(this.linkModal, 'modal-xl modal-lg');
  }

  private getContracts(): void {
    /**
     * Get booked Contracts that meet all these conditions:
     * - Same Product as SlotsBatch
     * - Both SlotsBatch.company and SlotsBatch.allocator must have visibility
     * - If SlotsBatch.allocator is NOT a broker, it must be the buyer
     * - Evaluate delivey place/zone (?)
     *
     * It could be currently linked to a SlotsBatch. Contracts could have
     * multiple SlotsBatch.
     */
    this.processing = true;

    // this.applyFilter('order_by', '-date');
    this.applyFilter('product_id', this.batch.product.id);
    this.applyFilter('applied', false);
    this.applyFilter('settled', false);
    this.applyFilter('validity', 'on_date', true);
    if (this.batch.company) {
      this.applyFilter('visible_to', this.batch.company.id);
      this.applyFilter('company', 'contains:' + this.batch.company.name);
    }

    if (!this.batch.allocator?.activity.broker) {
      this.applyFilter('operation_type', 'compra');
    }

    if (this.type === 'venta') {
      if (!this.hideColumns.includes('seller')) this.hideColumns.push('seller');

      delete this.filters.operation_type;
    }

    if (this.batch.quality.sustainable) this.filters.sustainable = true;
  }

  public applyFilter(key: string, value: any, toggle: boolean = false): void {
    this.refreshedTable = false;

    if (toggle && (key in this.filters)) {
      delete this.filters[key];
    } else {
      if (key === 'company') {
        const filterKey = `filters[${this.type === 'compra' ? 'seller.name' : 'buyer.name'}]`;
        this.filters[filterKey] = value;

      } else {
        this.filters[key] = value;
      }
    }

    setTimeout(() => {
      this.refreshedTable = true;
    }, 100);
  }

  public tableLoaded(e): void {
    if (e.data.length) {
      this.availableContracts = e.data;
    }
    this.processing = false;
  }

  public selectedContract(e): void {
    this.contract = e;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c, ignoreBackdropClick: true });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    this.filters = {};

    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  public linkSelected(): void {
    if (this.batch.id) {
      this.processing = true;

      this.subscriptions.push(this.slotService.updateContract(this.company.id, this.batch, this.contract).subscribe(response => {
        this.closeModal(() => {
          this.linked.emit(this.contract);
        });
      }));
    } else {
      this.batch.contract = this.contract;
      if (!this.batch.company) {
        this.batch.company = this.contract.seller;
      }
      this.closeModal(() => {
        this.linked.emit(this.contract);
      });
    }
  }

  public unlinkBatch(batch: SlotsBatch): void {
    this.subscriptions.push(this.slotService.deleteContract(this.company.id, batch).subscribe(response => {
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
