import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account } from '../auth/models/account.model';
import { Role } from '../auth/models/role.model';
import { Invitation } from '../models/invitation.model';
import { TeamMember } from '../models/teamMember.model';

@Injectable()
export class TeamService {

  private rolesUrl: string = '/:apiBase/roles';
  private companyUrl: string = '/:apiBase/companies/:companyId';
  private membersUrl: string = this.companyUrl + '/team-members';
  private invitationsUrl: string = this.companyUrl + '/invitations';
  private membersCommercialUrl: string = this.membersUrl + '/commercial';
  private deleteInvitationsUrl: string = '/:apiBase/invitations/:invitationId';
  private accountsUrl: string = '/:apiBase/accounts';
  private accountByIdPath: string = this.accountsUrl + '/:accountId';

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number): Observable<TeamMember[]> {
    const url = this.membersUrl.replace(':companyId', companyId + '');

    return this.http.get<TeamMember[]>(url).pipe(map(members => {
      return plainToInstance(TeamMember, members);
    }));
  }

  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.rolesUrl);
  }

  public invite(companyId: number, invitation: Invitation): Observable<Invitation> {
    const url = this.invitationsUrl.replace(':companyId', companyId + '');

    return this.http.post<Invitation>(url, invitation);
  }

  public edit(member: TeamMember): Observable<Account> {
    const url = this.accountByIdPath.replace(':accountId', member.id + '');

    return this.http.put<Account>(url, member);
  }

  public delete(member: TeamMember): Observable<any> {
    let url = '';
    if (member.invitation) {
      url = this.deleteInvitationsUrl.replace(':invitationId', member.id + '');
    } else {
      url = this.accountByIdPath.replace(':accountId', member.id + '');
    }

    return this.http.delete<any>(url);
  }

  /** @deprecated */
  public getCommercials(companyId: number, withProducts: boolean): Observable<TeamMember[]> {
    let url = this.membersCommercialUrl.replace(':companyId', companyId + '');
    if (withProducts) {
      url += '?products';
    }

    return this.http.get<TeamMember[]>(url).pipe(map(members => {
      return plainToInstance(TeamMember, members);
    }));
  }
}
