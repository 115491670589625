
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { JSend } from '../../../../models/jsend.model';
import { PusherMessage } from '../../../../models/pusher-message.model';
import { PusherService } from '../../../../services/pusher.service';
import { FixationRequest, FixationRequestStatus } from '../models/fixation-request.model';

@Injectable({
  providedIn: 'root'
})
export class FixationRequestService {

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private apiBase = '/:apiBase'
  private baseUrl = this.apiBase + '/companies/:companyId';
  private fixationStatuses = this.apiBase + '/fixation-request-status'
  private fixationRequest = this.baseUrl + '/fixation-request';
  private requestById = this.fixationRequest + '/:requestId';
  private acceptRequest = this.requestById + '/accept';
  private rejectRequest = this.requestById + '/decline';

  /** Maps only those parameters that don't match in the API call. */
  // private readonly queryMap: Record<string, string>;

  public getAll(companyId: number): Observable<FixationRequest[]> {
    let url = this.fixationRequest.replace(':companyId', companyId + '');

    let stream = this.http.get<JSend<{ fixation_requests: FixationRequest[] }>>(url);
    return stream.pipe(map(response => {
      return plainToInstance(FixationRequest, response.data.fixation_requests);
    }));
  }

  public watch(companyId: number): Observable<FixationRequest[]> {
    return this.fixationRequestChannel(companyId).pipe(
      startWith({}),
      mergeMap(event => {
        return this.getAll(companyId);
      })
    );
  }

  public create(fixationReq: FixationRequest): Observable<any> {
    let url = this.fixationRequest
      .replace(':companyId', fixationReq.company.id + '');

    let body =
    {
      recipient_id: fixationReq.recipient.id,
      contract_reference: fixationReq.contract.reference,
      quantity: fixationReq.quantity,
      price: fixationReq.price,
      observations: fixationReq.observations
    }

    return this.http.post(url, body);
  }

  public update(companyId: number, request: FixationRequest): Observable<any> {
    let url = this.requestById
      .replace(':companyId', companyId + '')
      .replace(':requestId', request.id + '');

    let body =
    {
      quantity: request.quantity,
      price: request.price,
      observations: request.observations
    }

    return this.http.put(url, body);
  }

  public accept(companyId: number, requestId: number): Observable<any> {
    let url = this.acceptRequest
      .replace(':companyId', companyId + '')
      .replace(':requestId', requestId + '');

    return this.http.put(url, {});
  }

  public reject(companyId: number, requestId: number): Observable<any> {
    let url = this.rejectRequest
      .replace(':companyId', companyId + '')
      .replace(':requestId', requestId + '');

    return this.http.put(url, {});
  }

  public getById(companyId: number, requestId: number): Observable<FixationRequest> {
    let url = this.requestById
      .replace(':companyId', companyId + '')
      .replace(':requestId', requestId + '');

    let stream = this.http.get<JSend<{ fixation_request: FixationRequest }>>(url);
    return stream.pipe(map(response => {
      return plainToInstance(FixationRequest, response.data.fixation_request);
    }));
  }

  public getStatuses(): Observable<FixationRequestStatus[]> {
    let url = this.fixationStatuses;

    let stream = this.http.get<JSend<{ fixation_request_status: FixationRequestStatus[] }>>(url);
    return stream.pipe(map(response => {
      return plainToInstance(FixationRequestStatus, response.data.fixation_request_status);
    }));
  }

  public fixationRequestChannel(companyId: number): Observable<PusherMessage> {
    return this.pusherService.listen('company_' + companyId, 'fixation_request');
  }

  public fixationRequestPrivateChannel(userId: number): Observable<PusherMessage> {
    return this.pusherService.listen('private_' + userId, 'fixation_request');
  }
}
