import { Account } from '../auth/models/account.model';
import { CommercialZone } from '../company/models/commercial-zone.model';

export class TeamMember extends Account {
  get name(): string {
    return this.user ? this.user.name + ' ' + this.user.last_name : ' ';
  }

  get email(): string {
    return this.user ? this.user.email : this.invitation.email;
  }

  get active(): boolean {
    return Boolean(this.user);
  }

  readonly commercial_zones: CommercialZone[];

  settings: {
    viewRestrictedToCommercialZones?: boolean
  } = {};
}
