import { Type } from "class-transformer";

import { User } from "../../../../auth/models/user.model";
import { Company } from "../../../../models/company.model";
import { Assignment } from "../../../../ui/models/assignment.model";
import { Choice } from "./choices.model";
import { WorkFlowDataField, WorkflowDataValue } from "./work-flow-data-field.model";

class WorkFlowUnit {
  /** Agree's internal unique identifier. */
  readonly id: string;

  /**
   * Unique identifying term.
   * Allows UI mapping to specific legends.
   */
  readonly slug: string;
}

export class WorkflowRegistry {
  @Type(() => Date)
  created_at: Date;

  reason?: string;

  @Type(() => User)
  user: User;

  @Type(() => Company)
  company: Company;
}

export class WorkflowPossibility extends WorkFlowUnit {
  readonly class?: string;
  readonly icon?: string;
  readonly order: number;
  readonly move_to?: string; // WorkflowStep ID
  readonly move_to_slug: string; // WorkflowStep slug
  readonly validate?: boolean;
  readonly reason?: {
    required: boolean;
  };

  readonly action?: any;
  readonly assign_runtime?: boolean;
}

export const WorkFlowDataFieldSchema = {
  "type": "object",
  "properties": {
    "accept": {
      "type": "string"
    },
    "maxItems": {
      "type": "number"
    },
    "maxSize": {
      "type": "number"
    },
    "max": {
      "type": "number"
    },
    "maxlength": {
      "type": "number"
    },
    "min": {
      "type": "number"
    },
    "placeholder": {
      "type": "string"
    },
    "activities": {
      "type": "array",
      "uniqueItems": true,
      "items": {
        "type": "number"
      }
    },
    "step": {
      "type": "number"
    },
    "options": {
      "type": "array",
      "uniqueItems": true,
      "items": {
        "type": "string"
      }
    },
    "center": {
      "type": "object",
      "properties": {
        "lat": {
          "type": "number"
        },
        "lng": {
          "type": "number"
        }
      }
    },
    "zoom": {
      "type": "number"
    },
    "acceptedProducts": {
      "type": "array",
      "uniqueItems": true,
      "items": {
        "type": "number"
      }
    },
    "currencies": {
      "type": "array",
      "uniqueItems": true
    }
  }
};

/**
 * The Workflow progress in the UI is represented in groups.
 * The UI will render in each group its most recent history
 * [WorkflowStep](WorkflowStep).
 * 
 * Unique identifying term.
 * Allows UI mapping to specific legends.
 */
export class WorkflowGroup {
  name: string;
  order: number;
}

/**
 * Each Step of a [Workflow](Workflow) is determined by the
 * [group](WorkflowStep.group) and the required information of the assigned
 * [Participant](Participant).
 */
class WorkflowStep {
  /** Agree's internal unique identifier. */
  readonly id: string;

  readonly group?: WorkflowGroup;

  possibilities?: WorkflowPossibility[];

  /** Participant who is assigned the action in this step. */
  readonly assignments?: Assignment;

  /** Participants who have visibility of this step. */
  // readonly visibleTo: Participant[];

  /** Information to request from the assigned [Participant](Participant). */
  @Type(() => WorkFlowDataField)
  readonly form?: WorkFlowDataField[];

  /** HTML content. */
  help?: string;

  @Type(() => Date)
  readonly due_date: Date;

  /**
   * | ID | Description |
   * |---:|-------------|
   * |  0 | WAIT        |
   * |  1 | END         |
   * |  2 | START       |
   * |  3 | TRANSITION  |
   * |  4 | FORM        |
   * |  5 | REVIEW      |
   * |  6 | CHOICE      |
   */
  readonly type: number;

  /** Bindeable entities must have an entity() get method. */
  readonly entity?: 'envelope' | 'doa' | 'portfolio' | 'onboarding' | 'epyme';

  readonly choices?: Choice[];
  readonly due_days?: any;
  readonly visibilities?: any[];
  readonly slug: string;

  /**
   * Information submitted by the assigned [Participant](Participant).
   * 
   * If it does not exist, it means that the [Participant](Participant) has not
   * yet completed the information (current step).
   */
  data?: { [dataFieldKey: string]: WorkflowDataValue; };

  constructor(data: Partial<WorkflowStep> = {}) {
    Object.assign(this, data);
  }
}

export class WorkflowHistoryStep extends WorkflowStep {
  @Type(() => Date)
  readonly arrived_on: Date;

  /**
   * | ID | Description |
   * |---:|-------------|
   * |  0 | IN_PROGRESS |
   * |  1 | FINISHED    |
   * |  2 | COMPLETE    |
   * |  3 | FAILURE     |
   * |  4 | EXPIRED     |
   * |  5 | REJECTED    |
   */
  readonly status: number;

  @Type(() => WorkflowRegistry)
  readonly event?: WorkflowRegistry;

  constructor(data: Partial<WorkflowHistoryStep> = {}) {
    super(data);
    Object.assign(this, data);
  }
}

export class Workflow {
  @Type(() => Date)
  readonly created_at: Date;

  /** Agree's internal unique identifier. */
  readonly id: string;

  /** Workflow source template. */
  readonly steps: WorkflowStep[]; // WAIT | END | START | TRANSITION | FORM

  readonly withdrawers?: Assignment[];

  name: string;
  due_days: number;

  private _groups: WorkflowGroup[];

  active: boolean;

  /**
   * @returns An the Workflow ordered collection of unique
   * WorkflowGroups.
   */
  get groups(): WorkflowGroup[] {
    if (!this._groups) {
      let uniqueSlugs: string[] = [];
      this._groups = [];

      this.steps.forEach(step => {
        if (step.group) {
          const ukey = step.group.order + '_' + step.group.name;
          // The name and order combination must be unique
          if (!uniqueSlugs.includes(ukey)) {
            uniqueSlugs.push(ukey);
            this._groups.push(step.group);
          }
        }
      });

      // Sort by order
      this._groups.sort((a, b) => {
        return a.order - b.order;
      });

      return this._groups;
    }

    return this._groups;
  }

  public mapOrder(): void {
    let ordered: { [group_name: string]: number } = {};

    this._groups.forEach((group: WorkflowGroup, index: number) => {
      // re-order groups
      group.order = index;
      // store group index
      ordered[group.name] = index;
    });

    this.steps.forEach(step => {
      // assign new group index
      if (step.group) step.group.order = ordered[step.group.name];
    });
  }

  constructor(data: Partial<Workflow> = {}) {
    Object.assign(this, data);
  }
}

/** Instance of a [Workflow](Workflow). */
export class WorkflowInstance {
  @Type(() => Workflow)
  readonly workflow: Workflow;

  @Type(() => WorkflowHistoryStep)
  readonly history: WorkflowHistoryStep[];

  /** Current [WorkflowStep](WorkflowStep). */
  @Type(() => WorkflowHistoryStep)
  readonly current_step?: WorkflowHistoryStep;

  data?: { [dataFieldKey: string]: WorkflowDataValue; };

  /**
   * | ID | Description |
   * |---:|-------------|
   * |  0 | IN_PROGRESS |
   * |  1 | FINISHED    |
   * |  2 | COMPLETE    |
   * |  3 | FAILURE     |
   * |  4 | EXPIRED     |
   * |  6 | WITHDRAW    |
   */
  readonly status: number;

  @Type(() => WorkflowRegistry)
  readonly withdrawn?: WorkflowRegistry;

  get current(): WorkflowHistoryStep {
    return this.current_step || this.history[0];
  }
}