<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'LINKER.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <!-- <div class="loading" *ngIf="!contract">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div> -->
    <p class="text-muted" [innerHtml]="'LINKER.DESCRIPTION'|translate"></p>
    <h5>{{ (select === 'negotiation' ? 'LINKER.SELECTED_CONTRACT' : 'LINKER.SELECTED_TRADE')|translate }}</h5>
    <div class="row" *ngIf="product"><!-- Actors -->
      <div class="col-sm-4">
        <table-detail [header]="'GLOBAL.PRODUCT'|translate">
          <h4>{{ product.name }}</h4>
        </table-detail>
      </div>
      <div class="col-sm-4">
        <company-table
        [header]="'ORDER_SUMMARY.SELLER'|translate"
        header-class="sell"
        [activity]="false"
        [companies]="[seller]"></company-table>
      </div>
      <div class="col-sm-4">
        <company-table
        [header]="'ORDER_SUMMARY.BUYER'|translate"
        header-class="buy"
        [activity]="false"
        [companies]="[buyer]"></company-table>
      </div>
    </div>

    <div *ngIf="select === 'negotiation'" class="row">
      <div class="col-xs-12">
        <ng-container *ngTemplateOutlet="contractsTable; context:{
          contracts: [contract]
        }"></ng-container>

        <div class="row">
          <connector [linked]="negotiation"></connector>
        </div>

        <ng-container *ngIf="negotiation">
          <h5>{{ 'LINKER.SELECTED_TRADE'|translate }}
            <!-- &nbsp;<small>(<a [class.disabled]="processing" (click)="negotiation = undefined">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>)</small> -->
            <button type="button"
            class="btn btn-link btn-xs" (click)="negotiation = undefined"
            [disabled]="processing">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</button>
          </h5>
          <ng-container *ngTemplateOutlet="negotiationsTable;context:{
            negotiations: [negotiation]
          }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!negotiation">
          <h5>{{ 'LINKER.TRADES'|translate }}</h5>
          <ng-container *ngIf="availableNegotiations; else loading">
            <ng-container *ngIf="availableNegotiations.length; else noNegotiations">
              <p class="text-muted" [innerHtml]="'LINKER.SELECT_TRADE'|translate"></p>
              <ng-container *ngTemplateOutlet="negotiationsTable;context:{
                negotiations: availableNegotiations
              }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div *ngIf="select === 'contract'" class="row">
      <div class="col-xs-12">
        <ng-container *ngTemplateOutlet="negotiationsTable;context:{
          negotiations: [negotiation]
        }"></ng-container>

        <div class="row">
          <connector [linked]="contract"></connector>
        </div>

        <ng-container *ngIf="contract">
          <h5>{{ 'LINKER.SELECTED_CONTRACT'|translate }}
            <!-- &nbsp;<small>(<a [class.disabled]="processing" (click)="contract = undefined">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>)</small> -->
            <button type="button"
            class="btn btn-link btn-xs" (click)="contract = undefined"
            [disabled]="processing">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</button>
          </h5>
          <ng-container *ngTemplateOutlet="contractsTable; context:{
            contracts: [contract]
          }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!contract">
          <h5>{{ 'LINKER.CONTRACTS'|translate }}</h5>
          <ng-container *ngIf="availableContracts; else loading">
            <ng-container *ngIf="availableContracts.length; else noContract">
              <p class="text-muted" [innerHtml]="'LINKER.SELECT_CONTRACT'|translate"></p>
              <ng-container *ngTemplateOutlet="contractsTable; context:{
                contracts: availableContracts
              }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button"
    (click)="linkSelected()" class="btn btn-agree" [disabled]="processing || (!contract || !negotiation)">{{ 'LINKER.LINK'|translate }}</button>
  </div>
</ng-template>

<ng-template #contractsTable let-contracts="contracts">
  <div class="horizontal-scroller">
    <table *ngIf="contracts?.length > 0" class="table table-condensed" sortable defaultSortBy="-date" #tableSortable="tableSortable">
      <thead>
        <tr class="tr-align-center">
          <th class="sap" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
          <th class="sap" sortBy="reference">{{ 'GLOBAL.CONTRACT'|translate }}</th>
          <th sortBy="delivery.locations[0].name">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="dlb drb" sortBy="quantity.value">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="contract.delivery.date_from">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="price.value">
            {{ 'GLOBAL.PRICE'|translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1">/{{ company.market.quantity_units[0].abbrev }}</ng-container>
          </th>
          <!-- <ng-container *ngIf="!environment.production">
            <th class="dlb" *ngIf="environment.modules.unloads">{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}</th>
            <th class="text-right drb" *ngIf="environment.modules.invoices">{{ 'COMPANY_TRADED_VIEW.LIQUIDATED'|translate }}</th>
          </ng-container> -->
        </tr>
      </thead>
      <tbody>
        <tr class="tr-align-center"
        [class.clickable]="select === 'contract' && !contract"
        [class.selected]="contract?.id === c.id"
        (click)="select === 'contract' && !contract ? contract = c : null"
        *ngFor="let c of contracts|orderBy: tableSortable.sortBy;">
          <td class="sap micro text-muted">
            <date-md [date]="c.date"></date-md>
          </td>
          <td class="small sap"><samp>{{ c.reference }}</samp></td>
          <td class="small">
            {{ c.delivery.locations |locationString }}
          </td>
          <td class="text-right dlb drb">
            <quantity-control [quantity]="c.quantity" [compact]="true"></quantity-control>
          </td>
          <td class="sap">
            <date-range
            [from]="c.delivery.date_from"
            [to]="c.delivery.date_to"></date-range>
          </td>
          <td class="sap">
            <div class="price-box booked-bg">
              <div class="type"
                [class.buy]="c.buyer.id === company.id"
                [class.sell]="c.seller.id === company.id">{{
                  (c.buyer.id === company.id ? 'COMPANY_TRADED_VIEW.BOUGHT' : (c.seller.id === company.id ? 'COMPANY_TRADED_VIEW.SOLD' : ''))|translate
              }}</div>
              <div class="value">
                <price-display [price]="c.price" [market]="company.market" [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
          <!-- <ng-container *ngIf="!environment.production">
            <td class="text-right dlb" *ngIf="environment.modules.unloads">
              {{ (c.quantity.value * 0.7)|number:undefined:currentLang }}
              <span class="currency">{{ c.quantity.unit.abbrev }}.</span>
              &nbsp;<progress-circle [progress]="0.7" size="13"></progress-circle>
            </td>
            <td class="text-right drb" *ngIf="environment.modules.invoices">
              <price-display [price]="{
                type: 'flat',
                unit: {
                  name: 'AR$'
                },
                value: 12345678
              }"></price-display>
            </td>  
          </ng-container> -->
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #negotiationsTable let-negotiations="negotiations">
  <div class="horizontal-scroller">
    <table *ngIf="negotiations?.length > 0" class="table table-condensed" sortable defaultSortBy="-booking_date" #tableSortable="tableSortable">
      <thead>
        <tr class="tr-align-center">
          <th class="sap" sortBy="booking_date">{{ 'GLOBAL.DATE'|translate }}</th>
          <th class="sap" sortBy="id"
          *ngIf="company.market.configuration.location.enabled">{{ 'GLOBAL.TRADE'|translate }}</th>
          <th sortBy="delivery.locations[0].name">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="sap" sortBy="data_external.reference.id">
            <abbr title tooltip="{{ 'GLOBAL.EXTERNAL_REFERENCE'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.EXTERNAL_REFERENCE_ABBR'|translate }}</abbr>
          </th>
          <th class="dlb drb" sortBy="quantity.value">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="contract.delivery.date_from">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="price.value">
            {{ 'GLOBAL.PRICE'|translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1">/{{ company.market.quantity_units[0].abbrev }}</ng-container>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="tr-align-center"
        [class.clickable]="select === 'negotiation' && !negotiation"
        [class.selected]="negotiation?.id === n.id"
        (click)="select === 'negotiation' && !negotiation ? negotiation = n : null"
        *ngFor="let n of negotiations|orderBy: tableSortable.sortBy;">
          <td class="sap micro">
            <ag-timestamp [date]="n.booking_date"></ag-timestamp>
          </td>
          <td class="small sap"><samp>#{{ n.id }}</samp></td>
          <td class="small"
          *ngIf="company.market.configuration.location.enabled">
            <span class="clamp-2">{{ n.proposal.business_detail.delivery.locations |locationString }}</span>
          </td>
          <td class="sap small">
            <samp *ngIf="n.data_external?.reference?.id">{{ n.data_external.reference.id }}</samp>
          </td>
          <td class="text-right dlb drb">
            <quantity-control [quantity]="n.proposal.business_detail.quantity" [compact]="true"></quantity-control>
          </td>
          <td class="sap">
            <date-range
            [from]="n.proposal.business_detail.delivery.date_from"
            [to]="n.proposal.business_detail.delivery.date_to"></date-range>
          </td>
          <td class="sap">
            <div class="price-box booked-bg">
              <div class="type"
              [class.buy]="n.buyers_id.includes(company.id)"
              [class.sell]="n.sellers_id.includes(company.id)">{{
                (n.buyers_id.includes(company.id) ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
              }}</div>
              <div class="value">
                <price-display [price]="n.proposal.business_detail.price" [market]="company.market" [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noContract>
  <div class="text-danger" [innerHtml]="'LINKER.NO_CONTRACTS'|translate"></div>
</ng-template>

<ng-template #noNegotiations>
  <div class="text-danger" [innerHtml]="'LINKER.NO_TRADES'|translate"></div>
</ng-template>