import { Type } from "class-transformer";

import { Order } from "../company/modules/commercial/models/order.model";
import { Invoice } from "../company/modules/imported-data/models/invoice.model";
import { Barter } from "./barter.model";
import { Company } from "./company.model";
import { Price } from "./price.model";

/**
 * A Barter Proposal is a container of [[Barter|Barters]] and it has the
 * reference to the [[BarterProposal.invoices|invoices]] that the farmer
 * ([[BarterProposal.customer|customer]]) has to pay.
 *
 * From this all related [[Barter|Barters]] are derived, each of which may be
 * managed by a different [[Company]]. When one of the [[Barter|Barters]] is
 * confirmed, the others will be closed automatically.
 *
 * ### Related UI components:
 * - [[ViewBarterComponent]]
 * - [[CreateBarterComponent]]
 * - [[MyBartersComponent]]
 */
export class BarterProposal {
  /** Agree's internal unique identifier. */
  readonly id: number;

  /** Input supplier [[Company]]. */
  supplier: Company;
  /** Invoiced [[Company]] (usually, a farmer). */
  customer: Company;
  /** Input supplier's Distributor [[Company]]. */
  distributor?: Company;
  /** Owner/Creator of the Barter Proposal. */
  readonly company: Company;
  /**
   * [[Order|Orders]] used as a template for all Barters created from this
   * Proposal.
   */
  @Type(() => Order)
  orders: Order[];

  /**
   * ### Possible values
   * | id | name   |
   * |---:|--------|
   * |  1 | Open   |
   * |  2 | Closed |
   */
  status: {
    id: number;
    name: string;
  }

  @Type(() => BarterProposalInvoice)
  invoices: BarterProposalInvoice[];

  // @Type(() => Date)
  created_at?: Date;

  @Type(() => Date)
  updated_at: Date;

  @Type(() => Barter)
  barters: Barter[];

  scope?: "offline";

  get invoicesBalance(): Price {
    let price = new Price({
      value: 0
    });

    if (this.invoices && this.invoices.length > 0) {
      const unit = this.invoices[0].amount.unit;
      let total = 0;
      let sameUnit = true;

      this.invoices.forEach(bpInvoice => {
        if (bpInvoice.amount.unit && unit.id === bpInvoice.amount.unit.id) {
          total += bpInvoice.amount.value;
        } else {
          sameUnit = false;
        }
      });

      if (sameUnit) {
        price.unit = unit;
        // Rounds to 2 decimal places
        // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
        price.value = +(Math.round(Number(total + "e+2")) + "e-2");
      }
    }

    return price;
  }

  constructor() {
    this.orders = [];
    this.barters = [];
  }
}

export class BarterProposalInvoice {
  @Type(() => Invoice)
  invoice: Invoice;

  total?: boolean;

  @Type(() => Price)
  amount?: Price;
}
