import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Contract } from '../../../contracts/models/contract.model';
import { ContractService } from '../../../contracts/services/contract.service';

/**
 * ContractMirrorLinker is a component intended to assist the [[User]] to link and/or
 * unlink [[Contract|Contracts]] with another [[Contract|Contracts]].
 *
 * ## Usage
 * ``` html
 * <contract-mirror-linker #contractMirrorLinker
 * [company]="company"></contract-mirror-linker>
 * ```
 *
 * ### Related UI components:
 * - [[ContractsTableComponent]]
 */
@Component({
  selector: 'contract-mirror-linker',
  templateUrl: './contract-mirror-linker.component.html',
  styleUrls: ['./contract-mirror-linker.component.scss']
})
export class ContractMirrorLinkerComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  /**
   * [[Company]] that makes the link.
   */
  @Input() public company: Company;

  @Output() public readonly onLinked: EventEmitter<Contract> = new EventEmitter();

  public availableMirrorContracts: Contract[];
  public mirrorContracts: Contract[];
  public selectedContract: Contract;
  /**
   * [[Contract]] to link.
   * @ignore */
  public contract: Contract;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private modalService: BsModalService,
    private contractService: ContractService
  ) { }

  private reset(): void {
    this.modalRef = undefined;

    this.selectedContract = undefined;

    this.availableMirrorContracts = undefined;
    this.mirrorContracts = undefined;
    this.processing = false;
  }

  /**
   * Opens the modal with the link wizard.
   */
  public link(contract: Contract): void {
    this.contract = contract;
    this.getContracts();

    this.openModal(this.modalTemplate, 'modal-lg');
  }

  /**
   * Links contract to contract.
   */
  public linkSelected(): void {
    this.processing = true;
    this.subscriptions.push(this.contractService.bind(this.company.id, this.contract.id, this.selectedContract.id).subscribe(contract => {
      this.closeModal();
      this.processing = false;
      this.onLinked.emit(contract);
    }));
  }

  private getContracts(): void {
    this.processing = true;

    this.subscriptions.push(this.contractService.getMirrorContracts(this.company, this.contract).subscribe(contracts => {
      this.availableMirrorContracts = contracts.body;
      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.reset();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
