import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

import { Account } from '../../../../../auth/models/account.model';
import { User } from '../../../../../auth/models/user.model';
import { Company, companyCan, companyIs } from '../../../../../models/company.model';
import { Product } from '../../../../../models/product.model';
import { Slot } from '../../../../../models/slot.model';
import { CPEActors, Destination, SlotsBatch } from '../../../../../models/slots-batch.model';
import { Zone } from '../../../../../models/zone.model';
import { CompanyService } from '../../../../../services/company.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { DestinationsService } from '../../../../../services/destinations.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { LocationService } from '../../../../../services/location.service';
import { SlotService } from '../../../../../services/slot.service';
import { FormReuseService } from '../../../../../ui/services/form-reuse.service';
import { SlotsRequest } from '../../../../models/slots-request.model';
import { Contract } from '../../models/contract.model';

@Component({
  selector: 'ag-contracts-slots-modals',
  templateUrl: './contracts-slots-modals.component.html',
  styleUrls: ['./contracts-slots-modals.component.scss']
})
export class ContractsSlotsModals implements OnDestroy {

  @ViewChild('assignToContractModal', { static: true }) private readonly assignToContractModal: TemplateRef<any>;
  @ViewChild('assignSlotsModal', { static: true }) private readonly assignSlotsModal: TemplateRef<any>;
  @ViewChild('acceptSlotsModal', { static: true }) private readonly acceptSlotsModal: TemplateRef<any>;
  @ViewChild('rejectModal', { static: true }) private readonly rejectModal: TemplateRef<any>;
  @ViewChild('requestSlotsModal', { static: true }) private readonly requestSlotsModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() private user: User;
  @Input() public contract: Contract
  @Input() public details: boolean = true;

  private modalRef: BsModalRef[];
  private subscriptions: Subscription[] = [];

  public selectedBatch: SlotsBatch;
  public availableBatches: SlotsBatch[];
  public reasons: string;
  public slotRequest: SlotsRequest;
  public slotsToAssignSelectedQty: number;
  public availableCounterparts: Company[];
  public processing: boolean = false;
  public deliveryZones: Zone[];
  public destinations: Destination[];
  public deliveryZoneSelected = null;
  public destinationSelected = null;

  /**
    * Enables a more simpler UX modal to accept a [[SlotsBatch|batch]].
    */
  public simplerAccept: boolean;
  /** Amount of accepted [[Slots]]. */
  public acceptedSlots: number = 0;
  public assignModel: {
    noMoreCompanies: boolean,
    assignedToMyCompany: boolean,
    assigned: SlotsBatch[],
    available: Slot[],
    observations?: string,
    reference: number,
    parent_id: number,
    actors?: any
  };

  constructor(
    private destinationsService: DestinationsService,
    private slotService: SlotService,
    private modalService: BsModalService,
    private formReuseService: FormReuseService,
    public companyService: CompanyService,
    private dataDogLoggerService: DataDogLoggerService,
    private locationService: LocationService,
    private intercomService: IntercomService
  ) { }

  /**
   * Modal displaying methods
   * 
   */
  public assignBatchToContract(contract: Contract, slotRequest?: SlotsRequest): void {
    if (slotRequest) {
      this.slotRequest = slotRequest;
    }

    this.contract = contract;
    this.availableBatches = undefined;
    this.assignModel = undefined;

    let filters = {
      product_id: contract.product.id,
      validity: 'on_date'
    };

    this.subscriptions.push(
      this.destinationsService.get(this.company.id).subscribe(destinations => {
        this.destinations = destinations;
      })
    )

    this.subscriptions.push(this.locationService.getZones().subscribe(deliveryZones => {
      this.deliveryZones = deliveryZones;
    }))

    this.subscriptions.push(this.slotService.watchBatches(this.company.id, filters).subscribe(response => {
      this.availableBatches = response.body.filter(sb => {
        return sb.slots_unassigned > 0 && sb.company.id === this.company.id;
      });
    }));

    this.openModal(this.assignToContractModal, 'modal-lg');
  }

  public openAcceptSlotsModal(batch: SlotsBatch, forceSimpler: boolean = false): void {
    this.simplerAccept = forceSimpler || companyIs(this.company, 'FARMER');
    this.acceptedSlots = batch.unassigned.length;
    this.execModal(this.acceptSlotsModal, batch);
  }

  /**
   * Accepts and assigns the entire [[SlotsBatch]] to the specified
   * [[Company]].
   */
  public takeBatch(batch: SlotsBatch): void {
    this.processing = true;

    this.subscriptions.push(this.slotService.take(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], batch.id).subscribe(response => {
      this.doneModalProcessing();
    }));
  }

  public rejectBatch(batch: SlotsBatch): void {
    this.execModal(this.rejectModal, batch);
  }
  /**
     * Opens the modal to create one or more child [[SlotsBatch|Batch]] for the
     * specified batch.
     */
  public createBatch(parentBatch: SlotsBatch): void {
    this.assignModel = {
      noMoreCompanies: undefined,
      assignedToMyCompany: false, // TODO: Check if there is a batch assigned to my copmany
      assigned: [this.newSlotsBatch(instanceToInstance(parentBatch.unassigned), parentBatch.product, parentBatch.quality)],
      available: [],
      observations: '',
      reference: parentBatch.reference,
      parent_id: parentBatch.id
      // actors: instanceToInstance(parentBatch.actors), // Clone batch Actors
    };

    if (companyIs(this.company, 'FARMER')) this.assignToMyCompany(); // Farmers

    this.execModal(this.assignSlotsModal, parentBatch);
  }
  /**
   * Opens the modal to create one or more requests [[SlotsBatch|Batch]] for the
   * specified batch.
   */
  public requestBatchToContract(contract: Contract): void {

    this.contract = contract;
    this.availableBatches = undefined;

    // let filters = {
    //   product_id: contract.product.id,
    //   validity: 'on_date'
    // };

    // if (negotiation.proposal.business_detail.sustainable) filters['sustainable'] = true;

    // this.subscriptions.push(this.slotService.getBatches(this.company.id, filters).subscribe(response => {
    //   this.slotsSolicitadosBatches = response.body.filter(sb => {
    //     return sb.slots_unassigned > 0 && sb.company.id === this.company.id;
    //   });
    // }));

    this.openModal(this.requestSlotsModal, 'modal-lg');
  }

  public addBatch(): void {
    this.assignModel.assigned.push(this.newSlotsBatch(instanceToInstance(this.assignModel.available), this.selectedBatch.product, this.selectedBatch.quality));
    this.assignModel.available = [];
  }

  public removeBatch(batch_index: number): void {
    let sb: SlotsBatch = this.assignModel.assigned[batch_index],
      removedBatchSlots = sb.unassigned;

    if (sb.company.id === this.company.id) {
      this.assignModel.assignedToMyCompany = false;
    }

    this.assignModel.assigned.splice(batch_index, 1);
    this.assignModel.available = this.assignModel.available.concat(removedBatchSlots);
  }

  public isDisabledQuantity(index: number): boolean {
    const optionValue = index + 1;
    if (this.slotRequest) {
      return this.getSlotsQuantity() < optionValue;
    }
    return false;
  }

  public isSelectedQuantity(index: number): boolean {
    const slotsUnassigned = this.assignModel.assigned[0].slots_unassigned;
    const optionValue = index + 1;
    if (this.slotRequest?.quantity < slotsUnassigned) {
      // Set as max selection the quantity requested in slotRequest
      return this.slotRequest.quantity - this.slotRequest.assignedQuantity === optionValue;
    }

    if (this.assignModel.assigned[0].slots_unassigned === (index + 1)) {
      this.slotsToAssignSelectedQty = index + 1
      return true;
    }
  }

  public contractAssignableSlots(slots: Slot[], contract: Contract): Slot[] {
    if (!contract) {
      return slots;
    }

    return slots.filter((slots, index) => index < contract.assignableSlots)
  }

  public refreshAvailableBatches($event): void {
    const filters = {
      product_id: this.contract.product.id,
      validity: 'on_date',
    };

    if (this.destinationSelected) {
      filters['destination_id'] = this.destinationSelected.id;
    }

    if (this.deliveryZoneSelected) {
      filters['zone_id'] = this.deliveryZoneSelected.id;
    }

    this.subscriptions.push(this.slotService.watchBatches(this.company.id, filters).subscribe(response => {
      this.availableBatches = response.body.filter(sb => {
        return sb.slots_unassigned > 0 && sb.company.id === this.company.id;
      });
    }));
  }

  public getSlotsQuantity(): number {
    if (this.slotRequest) {
      return this.slotRequest.quantity - this.slotRequest.assignedQuantity;
    }
    return this.selectedBatch.unassigned.length
  }

  /** Fully or partially accepts the received [[SlotsBatch|Batch]]. */
  public acceptSlots(): void {
    this.processing = true;
    const status = {
      id: 2
    };

    if (this.acceptedSlots === this.selectedBatch.slots.length) {
      this.subscriptions.push(this.updateBatch(this.selectedBatch.id, status).subscribe(response => this.doneModalProcessing()));
    } else {
      let rejectedBatch = new SlotsBatch();

      rejectedBatch.status = {
        id: 3,
        reason: this.reasons
      }
      rejectedBatch.allocator = this.selectedBatch.allocator;
      rejectedBatch.company = this.selectedBatch.company;
      // rejectedBatch.negotiation = this.selectedBatch.negotiation;
      rejectedBatch.contract = this.selectedBatch.contract;
      rejectedBatch.parent_id = this.selectedBatch.parent_id;
      rejectedBatch.reference = this.selectedBatch.reference;
      rejectedBatch.date = this.selectedBatch.date;
      rejectedBatch.destination = this.selectedBatch.destination;
      rejectedBatch.product = this.selectedBatch.product;
      rejectedBatch.recipient = this.selectedBatch.recipient;

      let rejectedSlots = [];
      for (let index = this.acceptedSlots; index < this.selectedBatch.slots.length; index++) {
        rejectedSlots.push(this.selectedBatch.slots[index]);
      }

      rejectedBatch.unassigned = rejectedSlots;

      this.subscriptions.push(this.updateBatch(this.selectedBatch.id, status, rejectedBatch).subscribe(response => this.doneModalProcessing()));
    }
  }

  private updateBatch(batch_id: number, status: any, batch?: SlotsBatch): Observable<any> {
    return this.slotService.update(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], batch_id, status, batch);
  }

  /** Rejects the entire [[SlotsBatch|Batch]]. */
  public rejectSlots(): void {
    this.processing = true;

    this.subscriptions.push(this.updateBatch(this.selectedBatch.id, {
      id: 3,
      reason: this.reasons
    }).subscribe(response => this.doneModalProcessing()));
  }

  public changeAssignedQuantity(batch_index: number, e): void {
    const sb: SlotsBatch = this.assignModel.assigned[batch_index];
    const value = parseInt(e.target.value);

    this.slotsToAssignSelectedQty = value

    if (value < sb.unassigned.length) {
      // Remove slots
      const removedSlots = sb.unassigned.splice(-(sb.unassigned.length - value));
      this.assignModel.available = this.assignModel.available.concat(removedSlots);
    } else if (value > sb.unassigned.length) {
      // Add slots
      const slotsToAdd = this.assignModel.available.splice(0, (value - sb.unassigned.length));
      sb.unassigned = sb.unassigned.concat(slotsToAdd);
    }
  }

  public setBatchToContract(parentBatch: SlotsBatch): void {
    const sb = this.newSlotsBatch(instanceToInstance(parentBatch.unassigned), parentBatch.product, parentBatch.quality);
    sb.contract = this.contract;

    this.availableCounterparts = [this.contract.seller];

    // If there is only one counterparty, it is automatically defined.
    if (this.availableCounterparts.length === 1) sb.company = this.availableCounterparts[0];

    this.assignModel = {
      noMoreCompanies: undefined,
      assignedToMyCompany: false, // TODO: Check if there is a batch assigned to my copmany
      assigned: [sb],
      available: [],
      observations: `Contrato <b>#${this.contract.reference}</b><br>`,
      reference: parentBatch.reference,
      parent_id: parentBatch.id
      // actors: instanceToInstance(parentBatch.actors), // Clone batch Actors
    };

    this.reasons = undefined;
    this.selectedBatch = parentBatch;
    this.setQuantityFromSlotRequest(0, this.slotRequest);
  }

  private setQuantityFromSlotRequest(batch_index: number, slotRequest: SlotsRequest): void {
    if (slotRequest) {
      const avaiblesSlots = slotRequest.quantity - slotRequest.assignedQuantity;
      const sb: SlotsBatch = this.assignModel.assigned[batch_index];
      if (avaiblesSlots < sb.unassigned.length) {
        // Remove slots
        const removedSlots = sb.unassigned.splice(-(sb.unassigned.length - avaiblesSlots));
        this.assignModel.available = this.assignModel.available.concat(removedSlots);
      } else if (avaiblesSlots > sb.unassigned.length) {
        // Add slots
        const slotsToAdd = this.assignModel.available.splice(0, (avaiblesSlots - sb.unassigned.length));
        sb.unassigned = sb.unassigned.concat(slotsToAdd);
      }
    }
  }

  private newSlotsBatch(unassigned: Slot[], product?: Product, quality?: any): SlotsBatch {
    const nb = new SlotsBatch();

    nb.allocator = this.company;
    nb.unassigned = unassigned;
    if (quality) nb.quality = quality;

    if (product) nb.product = product;

    return nb;
  }

  public assignToMyCompany(): void {
    this.assignModel.assigned[this.assignModel.assigned.length - 1].company = this.company;
    this.assignModel.assignedToMyCompany = true;
  }

  /**
   * Save the data submitted to be reused.
   */
  private saveForm(callback?: Function): void {
    this.subscriptions.push(this.formReuseService.save(this.company.id, 'cpe-actors', this.selectedBatch.cpe.actors).subscribe({
      next: response => {
        if (callback) callback();
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
        if (callback) callback();
      }
    }));
  }

  private doneModalProcessing(): void {
    this.closeModal();
    this.processing = false;
  }

  private _syngentaEntregador: Company;
  private syngentaEntregador(actors: CPEActors, entityObj: any): void {
    const normalize = (str: string): string => {
      return str.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    if ((!actors.representante_entregador || !actors.representante_recibidor) &&
      normalize(this.company.name).includes('SYNGENTA') &&
      entityObj.delivery?.locations?.length &&
      !normalize(entityObj.delivery.locations[0].location?.name).includes('NECOCHEA')) {
      const assign = () => {
        actors.representante_entregador = actors.representante_entregador || this._syngentaEntregador;
        actors.representante_recibidor = actors.representante_recibidor || this._syngentaEntregador;
      };

      if (this._syngentaEntregador) assign();
      else {
        this.subscriptions.push(this.companyService.getCompaniesByFiscalValue(this.company.id, this.company.market.country_id, '30707386076').subscribe(companies => {
          const firstCompany = companies[0];

          if (firstCompany?.id) {
            // Only if a Company is found, cache is updated
            this._syngentaEntregador = firstCompany;
            assign();
          }
        }));
      }
    }
  }

  public linkedListener(entityObj: any): void {
    // Assign actors from contract
    if (entityObj) {
      const { actors } = this.selectedBatch.cpe;

      if (entityObj.brokers) {
        // Set corredor_venta_primaria if there is none
        if (!actors.corredor_venta_primaria) actors.corredor_venta_primaria = entityObj.brokers.pop();

        if (entityObj.brokers.length > 0 &&
          actors.corredor_venta_primaria.id != entityObj.brokers[0].id) {
          // Add the rest to observations
          const seller = entityObj.seller?.name || 'Rte. Comercial Venta Primaria';
          const buyer = entityObj.buyer?.name || 'Rte. Comercial Venta Secundaria';
          this.assignModel.observations += `Interviene como corredor <b>${entityObj.brokers[0].name}</b> entre <i>${seller}</i> y <i>${buyer}</i>.`;
          actors.observaciones = [...(actors.observaciones || []), ...entityObj.brokers];
        }
      }

      if (entityObj.seller) {
        if (companyCan(entityObj.seller).produce) {
          // Set remitente_comercial_productor if there is none
          actors.remitente_comercial_productor = actors.remitente_comercial_productor || entityObj.seller;
        } else {
          // Set remitente_comercial_venta_primaria if there is none
          actors.remitente_comercial_venta_primaria = actors.remitente_comercial_venta_primaria || entityObj.seller;
        }
      }

      if (entityObj.buyer && !actors.remitente_comercial_venta_secundaria) {
        // Set remitente_comercial_venta_secundaria if there is none
        actors.remitente_comercial_venta_secundaria = entityObj.buyer;
      }

      // Entregador para Syngenta
      this.syngentaEntregador(actors, entityObj);
    }
  }

  private filterAccount(account: Account): Account {
    if (account.company_id === this.company.id && account.user_id === this.user.id) {
      return account;
    }
  }
  public createAndAssign(): void {
    this.processing = true;

    this.assignModel.assigned.forEach((sb: SlotsBatch) => {
      if (this.assignModel.observations && this.assignModel.observations !== '') {
        sb.observations = this.assignModel.observations;
      }
      sb.reference = this.assignModel.reference;
      sb.parent_id = this.assignModel.parent_id;
      // sb.actors = this.assignModel.actors;
      sb.date = this.selectedBatch.date;
      sb.destination = this.selectedBatch.destination;
      sb.product = this.selectedBatch.product;
      sb.recipient = this.selectedBatch.recipient;
      sb.status = { id: 1 };

      if (this.selectedBatch.cpe) {
        sb.cpe = this.selectedBatch.cpe;
      }
    });

    this.subscriptions.push(this.slotService.create(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], this.assignModel.assigned, this.slotRequest).subscribe(response => {
      this.intercomService.track('slots-batch-created');
      // Save form reuse
      this.saveForm(() => this.doneModalProcessing());
    }));
  }
  public assignableCompaniesLoaded(e: any): void {
    // TODO: Filter Companies that have already been assigned a batch
    if (this.assignModel.noMoreCompanies === undefined) this.assignModel.noMoreCompanies = (e.results && e.results.length === 0);
  }

  /**
   * Modal display methods
   *  
   */
  private execModal(template: TemplateRef<any>, batch: SlotsBatch = undefined): void {
    this.reasons = undefined;
    this.selectedBatch = batch;
    this.openModal(template, 'modal-lg');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    const newModal = this.modalService.show(template, { class: c });

    this.modalRef = [...(this.modalRef || []), newModal];
    newModal.onHide.subscribe(() => {
      // Removes last modal reference
      this.modalRef.pop();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    const mr = this.modalRef[this.modalRef.length - 1];

    if (mr) {
      mr.hide();
      if (onHide) mr.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.modalRef?.forEach(modal => modal.hide());

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
