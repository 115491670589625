import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { Price } from '../../../../../models/price.model';
import { Quantity } from '../../../../../models/quantity.model';
import { Unit } from '../../../../../models/unit.model';
import { ModalManager } from '../../../../../utilities/modal-manager';
import { OrderService } from '../../../commercial/services/order.service';
import { Contract } from '../../../contracts/models/contract.model';
import { FixationRequest } from '../../models/fixation-request.model';
import { FixationRequestService } from '../../services/fixation-request.service';

/**
 * This component show an input [[Price|price]].
 *
 * ## Usage
 * ``` html
 * <price-input
 *    [(price)]="priceModel"
 *    [currencies]="currencies"
 *    placeholder="0.00">
 * </price-input>
 * ```
 */
@Component({
  selector: 'request-fixation-modal',
  templateUrl: './request-fixation-modal.component.html',
  styleUrls: ['./request-fixation-modal.component.scss']
})
export class RequestFixationModalComponent extends ModalManager implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() public contract: Contract;
  /** [[Market]] supported [[Currency|Currencies]]. */
  @Input() public currencies: Currency[] = [];

  public fixationReq: FixationRequest;
  public counterparts: Company[];
  public processing: boolean = false;

  /**
   * Checks if a recipient is selected in the fixation request.
   *
   * @returns {boolean} True if a recipient is selected, otherwise false.
   */
  get recipientIsSelected(): boolean {
    return !!this.fixationReq.recipient;
  }

  constructor(
    public modalService: BsModalService,
    public fixationRequestService: FixationRequestService,
    public orderService: OrderService
  ) {
    super(modalService);
  }

  ngOnInit(): void {
    this.initializeFixationRequest();
    this.counterparts = this.getCounterParts();
  }

  private initializeFixationRequest(): void {
    this.fixationReq = new FixationRequest();

    this.fixationReq.recipient = this.getCounterpart();
    this.fixationReq.quantity = new Quantity({ value: null });
    this.fixationReq.quantity.unit = this.company.market.quantity_units[0] ?? new Unit();

    this.fixationReq.price = new Price({ unit: this.currencies[0], type: 'flat', value: null });

    this.fixationReq.contract = this.contract;
    this.fixationReq.company = this.company;
  }

  public getCounterpart(): Company {
    if (this.contract.buyer.id === this.company.id) {
      return this.contract.seller;
    }
    return this.contract.buyer;
  }

  public getCounterParts(): Company[] {
    const counterpart = this.getCounterpart();
    const counterparts = [counterpart].concat(this.contract.brokers || [])
    return counterparts.filter(counterpart => counterpart.id !== this.company.id);
  }

  public submitForm(): void {
    this.processing = true;
    this.subscriptions.push(
      this.fixationRequestService.create(this.fixationReq).subscribe(response => {
        this.processing = false;
        this.closeModal()
      })
    );
  }

  public show(): void {
    this.openModal(this.modalTemplate, 'modal-lg');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
