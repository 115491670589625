import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { environment } from '../../../../../../environments/environment';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { removeString } from '../../../../../utilities/array';
import { TableFilters } from '../../../../../utilities/table-filters';
import { Contract } from '../../models/contract.model';
import { ContractService } from '../../services/contract.service';

/**
 * ### Related UI components:
 * - [[ContractLinkerComponent]]
 * - [[ContractModalComponent]]
 */
@Component({
  selector: 'contracts-table',
  templateUrl: './contracts-table.component.html',
  styleUrls: ['./contracts-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ContractsTableComponent),
    multi: true
  }]
})
export class ContractsTableComponent extends TableFilters implements OnInit, OnDestroy, ControlValueAccessor {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = []; //['slots'];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;
  /** Allows you to select a contract, related UI: [[SlotLinker > LinkToContract]] */
  @Input() public selectable: boolean;
  @Input() public contractSelected: Contract;
  @Input() public defaultSortBy: string = '';

  @Output('load') readonly onLoad = new EventEmitter();
  @Output('onSelectedContract') public readonly selectedContractEmitter = new EventEmitter();

  public contracts: Contract[];
  /** The language currently used. */
  public currentLang: string;
  /** Flag used to indicate if the component is exporting information. */
  public exporting: boolean;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public predefinedFilters: boolean;

  private pusherSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private contractService: ContractService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    const { configuration } = this.company.market,
      { modules } = environment,
      notAvailable = (slug) => !configuration.imported_data.collections.includes(slug) || !modules[slug];

    if (notAvailable('invoices')) this.hideColumns.push('balance');
    if (notAvailable('unloads')) this.hideColumns.push('unloads');

    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('contracts')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  public hiddenColumnsCount(columns: string[]): number {
    let count = 0;
    columns.forEach(key => {
      if (this.hideColumns.includes(key)) count++;
    });
    return count;
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    if (!this.predefinedFilters) {
      if (this.route.parent.snapshot.queryParams['product_id']) this.hideColumns.push('product');
      else this.hideColumns = removeString(this.hideColumns, 'product');
    }

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.contractService.watch(this.company.id, this.filters).subscribe(response => {
      if (!response) return;

      this.dataLoaded(response.body, parsePagination(response.headers));
    });
  }

  /**
 * Unlinks contract to contract.
 */
  public unlink(contract: Contract, contractToUnbind: Contract): void {
    this.subscriptions.push(this.contractService.unbind(this.company.id, contract.id, contractToUnbind.id).subscribe(contract => {
    }));
  }

  /** Export current view to Excel format for download. */
  public exportToXLSX(): void {
    this.exporting = true;

    this.subscriptions.push(this.contractService.exportToXLS(this.company.id, this.filters).subscribe(response => {
      const blob = new Blob([response], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const objectUrl = window.URL.createObjectURL(blob),
        dateSuffix = (new Date()).toISOString().split('T')[0];

      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = objectUrl;
      a.download = 'Contracts - ' + dateSuffix + '.xlsx';
      a.click();

      window.URL.revokeObjectURL(objectUrl);
      this.exporting = false;
    }));
  }

  private dataLoaded(data: Contract[], pagination: Pagination): void {
    this.contracts = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.contracts,
      pagination: pagination
    });
  }

  public selectedContract(contract): void {
    this.selectedContractEmitter.emit(contract)
  }

  // ngModel
  private _value: Contract[] = [];
  public get selected(): Contract[] { return this._value; }
  public set selected(v: Contract[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
