import { Location } from './location.model';
import { Zone } from './zone.model';

export class GeoSelection {

  constructor(
    public zone: Zone = null,
    public location:Location = null
  ) { }

  sortableName:string;
}
