import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { MarketService } from '../../../../../services/market.service';
import { Contract } from '../../../contracts/models/contract.model';
import { ContractService } from '../../../contracts/services/contract.service';
import { Fixation } from '../../models/fixation.model';

/**
 * ### Related UI components:
 * - [[ContractsTableComponent]]
 * - [[ContractLinkerComponent]]
 */
@Component({
  selector: 'contract-modal',
  templateUrl: './contract-modal.component.html',
  styleUrls: ['./contract-modal.component.scss']
})
export class ContractModalComponent implements OnInit, OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  @Input() public company: Company;

  public contract: Contract;
  /** The language currently used. */
  public currentLang: string;
  public liquidacionesData;
  public applicationsData;
  public isImporter: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public trucksData;

  private loadedContracts: any = {};
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private contractService: ContractService,
    private currentDate: CurrentDateService,
    private modalService: BsModalService,
    private marketService: MarketService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  public show(contract: Contract | number): void {
    if (this.company) {
      this.contract = undefined;
      this.isImporter = this.company.hasModule('importer');

      // Supports Contract objects or ids
      if (typeof contract === 'number') this.loadContract(contract);
      else this.dataLoaded(contract);
      this.openModal(this.modalTemplate, 'modal-lg');
    }
  }

  private loadContract(id: number): void {
    if (this.loadedContracts[id]) this.dataLoaded(this.loadedContracts[id]); // Checks if its already loaded
    else {
      this.subscriptions.push(this.contractService.getById(this.company.id, id).subscribe(contract => {
        this.dataLoaded(contract);
      }));
    }
  }

  private dataLoaded(contract: Contract): void {
    this.loadedContracts[contract.id] = contract;
    this.contract = contract;
  }

  // External data
  public trucksLoaded(e: Event): void {
    this.trucksData = e;
  }

  public liquidacionesLoaded(e: Event): void {
    this.liquidacionesData = e;
  }

  public applicationsLoaded(e: Event): void {
    this.applicationsData = e;
  }

  // Fixations
  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[];
  public newFixation: Fixation;
  public showFixationForm: boolean;
  private fixationForm: NgForm;

  public toggleFixation(form?: NgForm): void {
    this.showFixationForm = !this.showFixationForm;

    // Unable to use ViewChild, so passing the form reference as a parameter
    if (form) this.fixationForm = form;

    if (this.showFixationForm) {
      this.defaultFixation();
    }
  }
  private loadCurrencies(): void {
    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
      this.currencies = currencies;
      this.defaultFixation();
    }));
  }

  private defaultFixation(): void {
    if (this.fixationForm) this.fixationForm.resetForm();
    if (this.currencies) {
      this.newFixation = new Fixation();

      this.newFixation.date = this.currentDate.get();

      // Default volume unit
      this.newFixation.volume.unit = this.company.market.quantity_units[0];

      // Default price unit
      this.newFixation.price.unit = this.currencies[0];
      this.newFixation.price.quantity_unit = this.company.market.quantity_units[0];
      this.newFixation.slate_price.unit = this.currencies[0];
      this.newFixation.slate_price.quantity_unit = this.company.market.quantity_units[0];
      this.newFixation.exchange_rate.unit = this.currencies.find(currency => currency.slug === 'ars');

    } else this.loadCurrencies();
  }

  public fixationFormSubmit(): void {
    this.processing = true;

    const stream = this.contractService.addFixation(this.company.id, this.contract.id, this.newFixation);

    this.subscriptions.push(stream.subscribe(response => {
      this.contract.fixations.push(response);
      this.contract.reset();
      this.showFixationForm = false;
      this.processing = false;
      this.closeModal();
      this.openModal(this.modalTemplate, 'modal-lg');
    }));
  }

  public deleteFixation(fixation: Fixation): void {
    this.processing = true;

    const stream = this.contractService.deleteFixation(this.company.id, this.contract.id, fixation.id);

    this.subscriptions.push(stream.subscribe(response => {
      this.contract.fixations = this.contract.fixations.filter(f => f.id !== fixation.id);
      this.contract.reset();
      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.trucksData = undefined;
      this.liquidacionesData = undefined;
      this.processing = false;
      this.showFixationForm = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
