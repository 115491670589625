import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AFIPLiquidacion } from '../../../../../models/afipliquidacion.model';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { FilePreviewComponent } from '../../../../../ui/components/file-preview/file-preview.component';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { removeString } from '../../../../../utilities/array';
import { TableFilters } from '../../../../../utilities/table-filters';
import { AfipService } from '../../services/afip.service';

@Component({
  selector: 'ag-liquidaciones-table',
  templateUrl: './liquidaciones-table.component.html',
  styleUrls: ['./liquidaciones-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LiquidacionesTableComponent),
    multi: true
  }]
})
export class LiquidacionesTableComponent extends TableFilters implements OnInit, ControlValueAccessor, OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output('load') readonly onLoad = new EventEmitter();

  public liquidaciones: AFIPLiquidacion[];
  /** Flag used to indicate if the component is loading information. */
  public loading = true;
  public predefinedFilters: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;
  public isImporter: boolean;

  private pusherSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private afipService: AfipService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;
    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('liquidaciones')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }

    this.isImporter = this.company.hasModule('importer');
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    if (!this.predefinedFilters) {
      if (this.route.parent.snapshot.queryParams['product_id']) this.hideColumns.push('product');
      else this.hideColumns = removeString(this.hideColumns, 'product');
    }

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.afipService.watch(this.company.id, this.filters).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    });
  }

  private dataLoaded(data: AFIPLiquidacion[], pagination: Pagination): void {
    this.liquidaciones = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.liquidaciones,
      pagination: pagination
    });
  }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(liquidacion: AFIPLiquidacion): void {
    this.filePreviewer.preview(this.afipService.getFile(this.company.id, liquidacion.id), liquidacion.coe + '.pdf');
  }

  // ngModel
  private _value: AFIPLiquidacion[] = [];
  public get selected(): AFIPLiquidacion[] { return this._value }
  public set selected(v: AFIPLiquidacion[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
