import { Type } from "class-transformer";

import { SeedingZone } from "../../models/seeding-zone.model";

export class ProductionPlan {
  /** Agree's internal unique identifier. */
  readonly id?: string;

  @Type(() => Date)
  readonly createdAt?: Date;

  companyId?: number;

  name?: string;

  seedingZones: SeedingZone[];

  sharedWith?: number[];

  constructor(data: Partial<ProductionPlan> = {}) {
    Object.assign(this, data);
  }
}
