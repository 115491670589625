import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';

import { JSend } from '../../../../models/jsend.model';
import { multiSort } from '../../../../utilities/array';
import { buildFilters } from '../../../../utilities/filters';
import { FintechForm } from '../models/fintech-form.model';

@Injectable({
  providedIn: 'root'
})
export class FintechFormsService {

  private baseUrl: string = '/:apiBase/fintech';
  private formsUrl: string = this.baseUrl + '/forms';
  private formsById: string = this.formsUrl + '/:formId';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient
  ) { }

  /** SuperAdmin */
  public getAll(filters?: any, paginated: boolean = true): Observable<{ body: FintechForm[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.formsUrl;
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<FintechForm[]>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') {
        response.body.data.forEach(form => {
          form.spec = multiSort(form.spec, 'label', 'type', 'order');
        });
        return { body: plainToInstance(FintechForm, response.body.data), headers: response.headers };
      } else throw new Error(String(response.body.data));
    }));
  }

  /** SuperAdmin */
  public get(id: string): Observable<FintechForm> {
    const url = this.formsById
      .replace(":formId", id);

    const stream = this.http.get<JSend<FintechForm>>(url);

    return stream.pipe(map(response => {
      if (response.status === 'success') {
        response.data.spec = multiSort(response.data.spec, '-label', 'type', 'order');
        return plainToInstance(FintechForm, response.data);
      } else throw new Error(String(response.data));
    }));
  }

  /** SuperAdmin */
  public create(workflow: FintechForm): Observable<FintechForm> {
    const url = this.formsUrl;

    const stream = this.http.post<JSend<FintechForm>>(url, workflow);

    return stream.pipe(map(response => {
      return plainToInstance(FintechForm, response.data);
    }));
  }
}
