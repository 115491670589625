import { AIExtractorProviders } from "../modules/fintech/models/work-flow-data-field.model";

class Textract_Extraction {
  page: number;
  alias: string;
  answer: any;
  confidence: number;
}

class Document_AI_Extraction {
  label: string;
  answer: any;
}

class FileExtractionResult {
  key: string;
  value: any;
  /** Raw data from extractor. */
  extractor: Textract_Extraction | Document_AI_Extraction;
}

class FileExtraction {
  params?: {
    prompts?: any
  };
  provider: AIExtractorProviders;
  results: FileExtractionResult[];
  /** Agree drive file IDs. */
  source: string;
}

export class FileExtract {
  /** Agree's internal unique identifier. */
  readonly id: string;

  status?: 'COMPLETED' | 'ERROR' | 'IN_PROGRESS' | 'PENDING';

  extracted?: FileExtraction[];

  public get sources(): string[] {
    let _sources = [];

    this.extracted?.forEach(extration => {
      if (!_sources.includes(extration.source)) _sources.push(extration.source);
    });

    return _sources;
  }

  public resultsFrom(sourceId: string): any[] {
    let results;

    this.extracted?.forEach(extraction => {
      if (extraction.source == sourceId) {
        results = [...(results || []), ...extraction.results];
      }
    });

    return results;
  }
}
