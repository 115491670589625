import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Account } from '../../../../../auth/models/account.model';
import { BarterProposal } from '../../../../../models/barter-proposal.model';
import { Company, companyCan } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { BarterService } from '../../../../../services/barter.service';
import { CompanyService } from '../../../../../services/company.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { MarketService } from '../../../../../services/market.service';
import { Invoice } from '../../models/invoice.model';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'ag-invoices-modals',
  templateUrl: './invoices-modals.component.html',
  styleUrls: ['./invoices-modals.component.scss']
})
export class InvoicesModalsComponent implements OnInit, OnDestroy {

  @ViewChild('createInvoice', { static: true }) private readonly createInvoice: TemplateRef<any>;
  @ViewChild('invoiceModal', { static: true }) private readonly invoiceModal: TemplateRef<any>;

  @Input() public company: Company;

  @Output() readonly created = new EventEmitter<Invoice>();

  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[];
  public errorMessage: string;
  public invoice: Invoice;
  public invoiceNumberMask: string = '0000-00000000';
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public barter_proposals: BarterProposal[];
  public bartersEnabled: boolean;
  public canCreateBarter: boolean;

  private account: Account;
  private companyCan: { [action: string]: boolean };
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];
  private pusherSubscription: Subscription;

  constructor(
    public companyService: CompanyService,
    private marketService: MarketService,
    private modalService: BsModalService,
    private invoiceService: InvoiceService,
    private barterService: BarterService,
    private router: Router,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      this.account = account;
    }));
    this.companyCan = companyCan(this.company);
  }

  public open(data: Partial<Invoice> = {}): void {
    this.errorMessage = undefined;
    this.invoice = new Invoice(data);
    this.invoice.company = this.company;

    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(response => {
      if (response) {
        this.currencies = response;
        this.invoice.unit = this.currencies[0];
      }
    }));
    this.openModal(this.createInvoice);
  }

  public create(): void {
    this.errorMessage = undefined;
    this.processing = true;
    this.subscriptions.push(this.invoiceService.create(this.company.id, this.invoice).subscribe({
      next: response => {
        this.created.emit(response);
        this.closeModal();
      },
      error: error => {
        this.errorMessage = error.error.info.message;
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public show(invoice: Invoice | number): void {
    if (invoice) {
      // Supports Invoice objects or ids
      if (typeof invoice === 'number') this.load(invoice);
      else this.loaded(invoice);

      this.openModal(this.invoiceModal, 'modal-lg');
    }
  }

  private load(invoiceId: number): void {
    this.invoice = undefined;
    this.processing = true;
    this.subscriptions.push(this.invoiceService.getInvoice(this.company.id, invoiceId).subscribe(invoice => {
      this.loaded(invoice);
    }));
  }

  private loaded(invoice: Invoice): void {
    this.invoice = invoice;
    this.getBarters();
    this.processing = false;
  }

  private getBarters(): void {
    this.bartersEnabled = this.company.hasModule('barters') &&
      (this.company.hasModule('market') || this.company.hasModule('my-network'));

    this.canCreateBarter = this.account.is.commercial &&
      this.companyCan.createBarter; // Grain elevatorss, Brokers, Distributors or Suppliers only

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
    if (this.bartersEnabled && this.invoice.reference) {
      this.pusherSubscription = this.barterService.watchProposals(this.company.id, {
        'filters[invoice.reference]': 'is:' + this.invoice.reference
      }, false).subscribe(response => {
        this.barter_proposals = response.body;
      });
    }
  }

  /**
   * Navigates to the specified [[Barter.id|Barter]].
   */
  public goToBarter(barterId: number): void {
    if (this.company && barterId) {
      this.closeModal();
      this.router.navigate(['company', this.company.id, 'barters', barterId]);
    }
  }

  public calculateBalance(): void {
    if (this.invoice.net && this.invoice.taxes) this.invoice.balance = this.invoice.net + this.invoice.taxes;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
      class: c
    });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
      this.barter_proposals = undefined;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
