<button type="button" class="btn btn-agree" [class.hidden]="hidden" (click)="show()" *ngIf="type === 'multiple' || !value" [disabled]="disabled">
  <i class="material-symbols-rounded hidden-xs hidden-sm">add</i> {{ 'LOCATION_PICKER.ADD_LOCATION_BUTTON'|translate }}
</button>

<ul *ngIf="type !== 'multiple' && value" class="locations" [class.hidden]="hidden">
  <li class="location">
    <span class="location-text" *ngIf="!value.location && value.zone">{{ value.zone.name }}</span>
    <span class="location-text" *ngIf="value.location">
      {{ value.location.name }}
      <ng-container *ngIf="value.location.levels">
        {{ value.location.levels[2]? '(' + value.location.levels[2].name + ')' : '' }} - {{ value.location.levels[1].name }}
      </ng-container>
    </span>
    <span class="location-remove" [class.disabled]="disabled" (click)="remove(item)">&times;</span>
  </li>
</ul>

<ul *ngIf="type === 'multiple' && value?.length > 0" class="locations" [class.hidden]="hidden">
  <li class="location" *ngFor="let item of value">
    <span class="location-text" *ngIf="!item.location">{{ item.zone.name }}</span>
    <span class="location-text" *ngIf="item.location">
      {{ item.location.name }} {{ item.location.levels[2]? '(' + item.location.levels[2].name + ')' : '' }} - {{ item.location.levels[1].name }}
    </span>
    <span class="location-remove" [class.disabled]="disabled" (click)="remove(item)">&times;</span>
  </li>
</ul>

<!-- modal -->
<ng-template #locationPickerModal>
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ title? title: 'LOCATION_PICKER.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p>
      <span *ngIf="hasZones" [innerHtml]="'LOCATION_PICKER.BODY_0'|translate"></span>
      {{ 'LOCATION_PICKER.BODY_1'|translate }}
    </p>
    <form id="location-form" #locationForm="ngForm">
      <tabset *ngIf="hasZones">
        <tab heading="Zonas" (selectTab)="setType('zone')">
          <div class="form-group">
            <!-- <label>Zona</label> -->
            <select class="form-control" name="zone" [(ngModel)]="selection.zone" [required]="selectionType === 'zone' && hasZones" #zone="ngModel">
              <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'LOCATION_PICKER.SELECT_ZONE'|translate }}</option>
              <option *ngFor="let zone of namedZones" [ngValue]="zone">{{ zone.name }}</option>
            </select>
            <p class="help-block small" *ngIf="selection.zone?.locations">{{ 'LOCATION_PICKER.INCLUDE_LOCATIONS'|translate }} <span>{{ selection.zone.locations|listFormatter }}</span></p>
          </div>
        </tab>
        <tab heading="Localidades" (selectTab)="setType('location')">
          <div class="form-group">
            <ng-template #locationTemplate let-item="item">
              {{ item.name }} {{ item.levels[2]? '(' + item.levels[2].name + ')' : '' }} - {{ item.levels[1].name }}
            </ng-template>
            <!-- <label>Localidad</label> -->
            <search-input
            name="location"
            [(ngModel)]="selection.location"
            [search]="locationService.locationSolver(market.id)"
            type="object"
            groupBy="levels.1.name"
            [itemTemplate]="locationTemplate"
            placeholder="{{ 'LOCATION_PICKER.PLACEHOLDER'|translate }}"
            #localidad="ngModel"
            [required]="selectionType === 'location' && hasZones"
            #searchInput="searchInput"></search-input>
          </div>
        </tab>
      </tabset>
      <div class="form-group" [hidden]="hasZones">
          <ng-template #locationTemplate let-item="item">
            {{ item.name }} {{ item.levels[2]? '(' + item.levels[2].name + ')' : '' }} - {{ item.levels[1].name }}
          </ng-template>
          <!-- <label>Localidad</label> -->
          <search-input
          name="location"
          [(ngModel)]="selection.location"
          [search]="locationService.locationSolver(market.id)"
          type="object"
          groupBy="levels.1.name"
          [itemTemplate]="locationTemplate"
          placeholder="{{ 'LOCATION_PICKER.PLACEHOLDER'|translate }}"
          #localidad="ngModel"
          [required]="!hasZones"
          #searchInput="searchInput"></search-input>
        </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button" (click)="locationForm.valid && submit()" class="btn btn-agree" [disabled]="locationForm.invalid" >{{ 'LOCATION_PICKER.CONFIRM_BUTTON'|translate }}</button>
  </div>
</ng-template>