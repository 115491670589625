/**
 * Accounts related to a system entity.
 */
export class Assignee {
  /** Agree's internal unique identifier. */
  id: string;
  userId: number;
  accountId: number;
  email: string;
  name: string;
  /**
   * ## Possible values
   * | id | slug     |
   * |---:|----------|
   * |  1 | creator  |
   * |  2 | operator |
   * |  3 | watcher  |
   */
  roleId: number;

  constructor(data: Partial<Assignee> = {}) {
    Object.assign(this, data);
  }
}