import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { JSONPath } from 'jsonpath-plus';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { FintechAdditional, FintechApplication } from '../../models/fintech-application.model';

@Component({
  selector: 'ag-application-additionals',
  templateUrl: './application-additionals.component.html',
  styleUrls: ['./application-additionals.component.scss']
})
export class ApplicationAdditionalsComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public application: FintechApplication;

  public additionals: FintechAdditional[];
  public total: number;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService
  ) { }

  public show(): void {
    if (this.application?.approved && this.application.additionals?.length > 0) {
      this.update();
      this.openModal(this.modal);
    }
  }

  private update() {
    this.additionals = instanceToInstance(this.application.additionals);

    this.additionals.forEach(additional => {
      if (additional.value == undefined && additional.function) {
        for (const key in additional.function.arguments) {
          const arg = additional.function.arguments[key];
          if (typeof arg === 'string' && arg.startsWith('$')) {
            additional.function.arguments[key] = JSONPath({ path: arg, json: this.application })[0];
          }
        }

        const newFunc = new Function(...Object.keys(additional.function.arguments), additional.function.body);
        const value = newFunc(...Object.values(additional.function.arguments));

        additional.value = isNaN(value) ? undefined : value;
      }
    });

    this.total = this.application.approved.value + this.additionals.reduce((accumulator, currentValue) => accumulator + (currentValue.value || 0), 0);
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}
