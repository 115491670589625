<ag-file-preview #filePreviewer></ag-file-preview>

<ag-volume-calculator-generic #volumeCalculator
[amount]="application.approved?.value"
[currency]="application.approved?.unit"
[rate]="application.product.rate"
[tax-rate]="application.product.tax_rate"
[company]="company"></ag-volume-calculator-generic>

<table class="app-data">
  <tbody>
    <ng-container *ngFor="let dataGroup of data">
      <tr *ngIf="dataGroup.member?.slug">
        <td colspan="3" class="member"><span class="material-symbols-rounded">subdirectory_arrow_right</span> {{ dataGroup.member.slug }}</td>
      </tr>

      <ng-container *ngTemplateOutlet="fileRow; context: {
        files: dataGroup.files
      }"></ng-container>
  
      <ng-container *ngFor="let field of dataGroup.fields|keyvalue: originalOrder">
        <ng-container *ngTemplateOutlet="fieldRow; context: {
          field: field.value
        }"></ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-template #fileRow let-files="files">
  <ng-container *ngIf="files?.length">
    <tr *ngFor="let file of files; let fileIndex = index">
      <th *ngIf="fileIndex === 0" [attr.rowspan]="files.length">
        {{ 'WORKFLOW.FIELD.FILES'|translate }}
      </th>
      <td>
        <div class="flex-center-distributed">
          <div class="grow">
            <a *ngFor="let fileId of file.value; let i = index"
            [class.disabled]="processing"
            (click)="preview(fileId)">
              <ng-container *ngIf="i === 0">
                {{ file.label || ('WORKFLOW.FIELD.' + file.slug)|translate }}
                <span *ngIf="file.slug_suffix">{{ file.slug_suffix }}</span>
              </ng-container>
              {{ file.value.length > 1 ? (file.slug_suffix ? ' ' + file.slug_suffix : ' [' + (i+1) + ']') : '' }}
            </a>
          </div>
          <a *ngIf="file.slug === 'fwd_contract' && application.approved"
          class="small hidden-print"
          (click)="volumeCalculator.show()"
          title="{{ 'VOLUME.CALCULATE'|translate }}">
            <span class="material-symbols-rounded">calculate</span>
          </a>
        </div>
      </td>
      <td class="sap micro">
        <on-off-switch
        *ngIf="!readonly && company.id === application.product.funder.company.id && application.status === 0; else showLock;"
        [disabled]="processing"
        (ngModelChange)="update()"
        [(ngModel)]="file.readonly"></on-off-switch>
        <ng-template #showLock>
          <span *ngIf="file.readonly"
          class="material-symbols-rounded text-danger">lock</span>
        </ng-template>
      </td>
    </tr>
  </ng-container>
</ng-template>

<ng-template #fieldRow let-field="field">
  <tr *ngIf="field &&
  field.type !== 'COMPANY_FILE'">
    <th>
      {{ field.label || ('WORKFLOW.FIELD.' + field.slug)|translate }}
      <span *ngIf="field.slug_suffix">{{ field.slug_suffix }}</span>
    </th>
    <td>
      <ng-container [ngSwitch]="field.type">

        <samp *ngSwitchCase="'NUMBER'"
        class="small">{{ field.value|number }}</samp>

        <div *ngSwitchCase="'DATE_RANGE'"
        class="small">
          <date-range
          [simple]="true"
          [from]="field.value[0]"
          [to]="field.value[1]"></date-range>
        </div>

        <span *ngSwitchCase="'BOOLEAN'"
        class="material-symbols-rounded"
        [class.text-success]="field.value"
        [class.text-danger]="!field.value">{{ field.value ? 'done' : 'close' }}</span>

        <company-info *ngSwitchCase="'COMPANY'"
        [company]="field.value" [activity]="false"
        [file-manager]="true"></company-info>

        <ng-container *ngSwitchCase="'COMPANIES'">
          <company-info *ngFor="let c of field.value"
          [company]="c" [activity]="false"
          [file-manager]="true"></company-info>
        </ng-container>

        <ng-container *ngSwitchCase="'SIGNERS'">
          <div *ngFor="let signer of field.value" class="flex-center-distributed">
            <div class="grow" title="{{ signer.email }}">
              <b class="truncate" [class.highlighted-text]="user?.email === signer.email">{{ signer.name }} {{ signer.last_name }}</b>
              <div class="text-muted micro" *ngIf="signer.fiscal_id">
                <samp>{{ signer.fiscal_id.mask ? (signer.fiscal_id.value| mask: signer.fiscal_id.mask) : signer.fiscal_id.value }}</samp>
              </div>
              <div class="truncate small" *ngIf="signer.email">{{ signer.email }}</div>
            </div>
            <button (click)="getSignerFile(signer)"
            type="button"
            *ngIf="signer.id && signer.file"
            [disabled]="processing"
            class="btn btn-link btn-sm hidden-print"
            title="{{ 'SIGNATURES.SIGNER_AUTHORIZATION'|translate }}">
              <i class="material-symbols-rounded">save_alt</i>
              <!-- <span class="hidden-xs">&nbsp;{{ 'SIGNATURES.SIGNER_AUTHORIZATION_VIEW'|translate }}</span> -->
            </button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'APPROVERS'">
          <div *ngFor="let approver of field.value"
          title="{{ approver.user.email }}">
            <b class="truncate" [class.highlighted-text]="user?.email === approver.user.email">{{ approver.user.name }} {{ approver.user.last_name }}</b>
            <div class="text-muted micro" *ngIf="approver.user.email">{{ approver.user.email }}</div>
          </div>
        </ng-container>

        <price *ngSwitchCase="'PRICE'"
        [unit]="field.value.unit.name"
        [value]="field.value.value"></price>   

        <span *ngSwitchCase="'SELECT'">{{ field.value.label || field.value  }}</span>

        <span *ngSwitchDefault
        class="small">
          <date-md *ngIf="isDate(field.value); else generic"
          [date]="field.value" [style]="true"></date-md>
          <ng-template #generic>{{ field.value }}</ng-template>
        </span>
      </ng-container>
    </td>
    <td class="sap micro">
      <on-off-switch
      *ngIf="!readonly && company.id === application.product.funder.company.id && application.status === 0; else showLock;"
      [disabled]="processing"
      (ngModelChange)="update()"
      [(ngModel)]="field.readonly"></on-off-switch>
      <ng-template #showLock>
        <span *ngIf="field.readonly"
        class="material-symbols-rounded text-danger">lock</span>
      </ng-template>
    </td>
  </tr>
</ng-template>