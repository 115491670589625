import { Type } from "class-transformer";

import { Company } from "../../models/company.model";
import { SlotsBatch } from "../../models/slots-batch.model";
import { Negotiation } from "../modules/commercial/models/negotiation.model";
import { Contract } from "../modules/contracts/models/contract.model";

export enum Status {
  UNKNOWN = 0,
  PENDING = 1,
  EXPIRED = 2,
  ASSIGNED = 3,
  ASSIGNED_PARTIALLY = 4,
  RETIRED = 5,
  REJECTED = 6
};

const StatusLocaleNames = Object.freeze({
  [Status.UNKNOWN]: 'ERROR',
  [Status.PENDING]: 'SLOTS.STATUS.PENDING',
  [Status.EXPIRED]: 'SLOTS.STATUS.EXPIRED',
  [Status.ASSIGNED]: 'SLOTS.STATUS.ASSIGNED',
  [Status.ASSIGNED_PARTIALLY]: 'SLOTS.STATUS.ASSIGNED_PARTIALLY',
  [Status.RETIRED]: 'SLOTS.STATUS.WITHDRAWN',
  [Status.REJECTED]: 'SLOTS.STATUS.REJECTED',
});

export class RequestStatus {
  public id: Status;
  public name: string;
  public description: string;

  public get locale_name(): string {
    return StatusLocaleNames[this.id] ?? StatusLocaleNames[Status.UNKNOWN];
  }

  constructor(data: Partial<RequestStatus> = {}) {
    Object.assign(this, data);
  }
}

export class SlotsRequest {
  constructor(data: Partial<SlotsRequest> = {}) {
    Object.assign(this, data);
  }

  readonly id?: number;
  quantity: number;

  /**
   * If there is a related [[Negotiation]] to this Batch.
   */
  @Type(() => Negotiation)
  negotiation: Negotiation;

  @Type(() => Contract)
  contract: Contract;

  @Type(() => Date)
  date: Date;

  @Type(() => Date)
  readonly created_at?: Date;

  /**
   * [[Company]] that request the slots.
   */
  @Type(() => Company)
  readonly company?: Company;

  /**
   * [[Company]] that will receive the slots.
   */
  @Type(() => Company)
  recipient: Company;

  /**
   * Additional instructions or comments defined by the
   * [[SlotsBatch.allocator|Allocator]].
   */
  observations?: string;

  /**
   * Slots assigned for this Request
   */
  @Type(() => SlotsBatch)
  readonly batches?: SlotsBatch[];

  @Type(() => RequestStatus)
  public status: RequestStatus;

  /**
   * Quantity of slots assigned for this [[SlotsRequest]] .
   * @returns The total number of slots assigned in the [[SlotsRequest]].
   */
  get assignedQuantity(): number {
    const batchesFiltered = this.batches.filter(batch => batch.status.id === 1 || batch.status.id === 2);

    let total = 0;
    batchesFiltered.forEach(batch => {
      total += batch.slots_total
    });

    return total;
  }

  /**
   * Percentage of slots assigned for this Request.
   */
  get assignedPercentage(): number {
    if (this.quantity === 0 || this.assignedQuantity === 0) return 0;
    const percentage = (this.assignedQuantity / this.quantity);
    return percentage;
  }

  public get expired(): boolean {
    const today = new Date();
    if(this.status?.id === Status.EXPIRED || this.date.getTime() < today.getTime()){
      this.status = new RequestStatus({
        id: Status.EXPIRED,
        name: 'expired'
      });

      return  this.status?.id === Status.EXPIRED || this.date.getTime() < today.getTime();
    }
  }
}
