import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Place } from '../../modules/imported-data/models/carta-porte-electronica.model';

/**
 * PlaceInfo is a component that displays [[Place]] information as ORIGIN/DESTINATION
 *
 * ## Usage
 * ``` html
 * <place-info
 * [placeInfo]="place"></place-info>
 * ```
 */
@Component({
  selector: 'place-info',
  templateUrl: './place-info.component.html',
  styleUrls: ['./place-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceInfoComponent {

  /**
   * The [[Place]] info to be displayed.
   */
  @Input() public placeInfo: Place;

  constructor() { }

}
