import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { ProductionPlan } from '../../models/production-plan.model';
import { ProductionPlansService } from '../../services/production-plans.service';

@Component({
  selector: 'ag-production-plan-importer',
  templateUrl: './production-plan-importer.component.html',
  styleUrls: ['./production-plan-importer.component.scss']
})
export class ProductionPlanImporterComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;
  @Input('fiscal-ids') public fiscalIds: string[];

  @Output() readonly imported = new EventEmitter<ProductionPlan>();

  public fetched: {
    fiscalId: string;
    productionPlan: ProductionPlan;
  }[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public source: number;
  public sources = [
    { id: 1, name: "Sancor Seguros" }
  ];

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private watchSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private productionPlansService: ProductionPlansService,
    private modalService: BsModalService
  ) { }

  public open(): void {
    this.source = this.sources[0].id;
    this.openModal(this.modal);
  }

  public import(): void {
    this.processing = true;

    this.subscriptions.push(this.productionPlansService.import(this.company.id, this.fiscalIds, this.source).subscribe(response => {
      this.fetched = response;
      this.processing = false;
    }));
  }

  public apply(): void {
    if (this.fetched) {
      this.imported.emit(this.fetched[0].productionPlan);
      this.killSubs();
    }
  }

  private killSubs(): void {
    this.closeModal();

    if (this.watchSub) this.watchSub.unsubscribe();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.fetched = undefined;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();
    this.killSubs();
  }
}
