import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../../models/company.model';
import { SlotsBatch } from '../../../../../../models/slots-batch.model';
import { SlotService } from '../../../../../../services/slot.service';
import { Negotiation } from '../../../../commercial/models/negotiation.model';
import { OrderService } from '../../../../commercial/services/order.service';

@Component({
  selector: 'ag-link-to-negotiation',
  templateUrl: './link-to-negotiation.component.html',
  styleUrls: ['./link-to-negotiation.component.scss']
})
export class LinkToNegotiationComponent implements OnDestroy {

  @ViewChild('linkModal', { static: true }) public readonly linkModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public filters: {
    order_by: string,
    product_id: number,
    visible_to: number,
    operation_type?: string,
    'filters[applied]'?: boolean,
    'filters[settled]'?: boolean,
    sustainable?: boolean
  };

  @Output() readonly linked = new EventEmitter<Negotiation>();

  public processing: boolean;
  public batch: SlotsBatch;
  public availableNegotiations: Negotiation[];
  public negotiation: Negotiation;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private orderService: OrderService,
    private slotService: SlotService
  ) { }

  public linkBatch(batch: SlotsBatch): void {
    this.availableNegotiations = undefined;
    this.negotiation = batch.negotiation;
    this.batch = batch;
    this.getNegotiations();
    this.openModal(this.linkModal, 'modal-xl modal-lg');
  }

  private getNegotiations(): void {
    /**
     * Get booked Negotiations that meet all these conditions:
     * - Same Product as SlotsBatch
     * - Both SlotsBatch.company and SlotsBatch.allocator must have visibility
     * - If SlotsBatch.allocator is NOT a broker, it must be the buyer
     * - Evaluate delivey place/zone (?)
     *
     * It could be currently linked to a SlotsBatch. Negotiations could have
     * multiple SlotsBatch.
     */
    this.processing = true;

    this.filters = {
      order_by: "-booking_date",
      product_id: this.batch.product.id,
      visible_to: this.batch.company.id,
      'filters[applied]': false,
      'filters[settled]': false
    };

    if (!this.batch.allocator.activity.broker) {
      this.filters.operation_type = 'compra';
    }

    if (this.batch.quality.sustainable) this.filters.sustainable = true;

    this.subscriptions.push(this.orderService.getCompanyNegotiations(this.company.id, this.filters).subscribe(response => {
      this.availableNegotiations = response.body;
      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  public linkSelected(): void {
    if (this.batch.id) {
      this.processing = true;

      this.subscriptions.push(this.slotService.updateNegotiation(this.company.id, this.batch, this.negotiation).subscribe(response => {
        this.closeModal(() => {
          this.linked.emit(this.negotiation);
        });
      }));
    } else {
      this.batch.negotiation = this.negotiation;
      this.closeModal(() => {
        this.linked.emit(this.negotiation);
      });
    }
  }

  public unlinkBatch(batch: SlotsBatch): void {
    this.subscriptions.push(this.slotService.deleteNegotiation(this.company.id, batch).subscribe(response => {
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
