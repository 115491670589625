import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Company } from '../../../models/company.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { MetaDataField, MetadataService } from '../../../services/metadata.service';

@Component({
  selector: 'ag-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit, OnDestroy {

  @Input() private company: Company;
  /**
   * Entity type. Eg: negotiation, invoice, etc.
   * 
   * Either this attribute or [[external_id|external-id]] is required.
   */
  @Input() public entity: string;
  /**
   * Entity unique identifier.
   * 
   * This attribute in combination with the [[entity|entity type]] is the
   * unique reference to the virtual file folder.
   * 
   * This attribute is required.
   */
  @Input('entity-id') public entity_id: string;
  @Input() public readonly: boolean;

  public metadata: { [key: string]: MetaDataField };
  public metadata_temp: { [key: string]: MetaDataField };
  public view_mode: string = 'NORMAL';
  public importantOnly: boolean = true;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public editable: boolean;
  public UUID: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private metadataService: MetadataService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.UUID = 'md-' + uuidv4();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.processing = true;
    this.subscriptions.push(this.metadataService.watch(this.company.id, this.entity, this.entity_id).subscribe({
      next: response => {
        this.dataLoaded(response);
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
        this.processing = false;
      }
    }));
  }

  private dataLoaded(data: { [key: string]: MetaDataField }): void {
    this.metadata = data;
    this.metadata_temp = instanceToInstance(data);
    this.processing = false;
  }

  public save(data: { [key: string]: MetaDataField } = this.metadata_temp): void {
    if (!this.processing) {
      this.processing = true;
      this.subscriptions.push(this.metadataService.post(this.company.id, this.entity, this.entity_id, data).subscribe(response => {
        this.editable = false;
        this.dataLoaded(response);
      }));
    }
  }

  public orderOrder(a: KeyValue<number, MetaDataField>, b: KeyValue<number, MetaDataField>): number {
    return a.value.order > b.value.order ? 1 : (b.value.order > a.value.order ? -1 : 0);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}