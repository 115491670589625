import { Type } from "class-transformer";

import { Company } from "../../../../models/company.model";
import { Currency } from "../../../../models/currency.model";

/**
 * A Funder is a financial institution or provider that offers one or more
 * [[LendProduct|credit products]] through Agree.
 * 
 * Defines its own requirements for an [[LendApplication|application]] and is
 * associated with a [[Company]].
 */
export class Funder {
  /** Agree's internal unique identifier. */
  readonly id: string;

  @Type(() => Company)
  company: Company;

  /** Inherited from the [[Company]]. */
  market_id: number;

  name: string;
  description: string;

  /** Funder status. */
  active: boolean;

  /** Sets the displaying order of the Funder with respect to the others. */
  order: number;

  max_credit_line?: number;

  /** If true is able to see others funders products. */
  market_visibility: boolean;

  currency: Currency;

  constructor(data: Partial<Funder> = {}) {
    this.max_credit_line = 100000000;
    
    Object.assign(this, data);
  }
}