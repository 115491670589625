import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company } from '../../../../../models/company.model';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { CompanyService } from '../../../../../services/company.service';
import { TableFilters } from '../../../../../utilities/table-filters';
import { SlotsRequest, Status } from '../../../../models/slots-request.model';
import { SlotRequestService } from '../../../../services/slot-request.service';

@Component({
  selector: 'ag-slots-requests-table',
  templateUrl: './slots-requests-table.component.html',
  styleUrls: ['./slots-requests-table.component.scss']
})
export class SlotsRequestsTableComponent extends TableFilters implements OnInit, OnDestroy {

  @Input() public company: Company;
  @Input() public compact: boolean = false;
  @Input() public negotiationId?: number;
  @Input() public contractId?: number;
  @Input() public preLoadedSlotsRequests?: SlotsRequest[];

  @Output('load') readonly onLoad = new EventEmitter();

  public processing: boolean;
  public readonlyUser: boolean;
  /**
   * Current [[User]]
  */
  public user: User;
  public requests: SlotsRequest[];

  /**
   * Indicates if the UI should refresh itself or should show a legend to the
   * User to do it manually.
  */
  private autoRefresh: boolean = true;

  constructor(
    private slotRequestService: SlotRequestService,
    private loginService: LoginService,
    private companyService: CompanyService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
  }

  public isRecipient(item: SlotsRequest): boolean {
    return item.recipient.id === this.company.id;
  }

  public canRetire(slotRequest: SlotsRequest): boolean {
    return !slotRequest.expired && !this.isRecipient(slotRequest) && slotRequest.status?.id === Status.PENDING;
  }

  public canAccept(slotRequest: SlotsRequest): boolean {
    return slotRequest.contract && !slotRequest.expired && this.isRecipient(slotRequest) &&
      (slotRequest.status?.id === Status.PENDING || slotRequest.status?.id === Status.ASSIGNED_PARTIALLY);
  }

  public canReject(slotRequest: SlotsRequest): boolean {
    return !slotRequest.expired && this.isRecipient(slotRequest) && slotRequest.status?.id === Status.PENDING
  }

  public canDelete(slotRequest: SlotsRequest): boolean {
    return slotRequest.company.id === this.company.id && slotRequest.status.id === Status.PENDING;
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.subscribeToPusherChannel()
    this.getAccountPermissions();
  }

  private subscribeToPusherChannel(): void {
    this.subscriptions.push(this.slotRequestService.slotRequestChannel(this.company.id).subscribe(response => {
      if (this.autoRefresh) this.loadData();
    }));
    this.subscriptions.push(this.slotRequestService.slotRequestPrivateChannel(this.user.id).subscribe(response => {
      this.loadData();
    }));

    if (this.filters) {
      this.loadData();
    } else {
      // Filters based on URL
      // First, remove "page" query param
      this.router.navigate([], { queryParams: { page: null }, queryParamsHandling: 'merge' }).then(() => {
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      });
    }
  }

  private getCurrentUser(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  private getAccountPermissions(): void {
    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        // Account changed
        this.readonlyUser = !account.is.logistics && !account.is.commercial && !account.is.commercial_with_users;
      }
    }));
  }

  private loadData(): void {
    if (this.processing) return;
    this.processing = true;

    if (this.negotiationId) {
      this.fetchRequestsByNegotiation(this.negotiationId);
      return;
    }

    if (this.contractId) {
      this.fetchRequestsByContract(this.contractId);
      return;
    }

    const queryParams = this.route.parent.snapshot.queryParams
    this.subscriptions.push(this.slotRequestService.get(this.company.id, queryParams).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));

  }

  public fetchRequestsByNegotiation(negotiationId: number): void {
    this.subscriptions.push(this.slotRequestService.getByNegotiation(this.company.id, negotiationId, true).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  public fetchRequestsByContract(contractId: number): void {
    this.subscriptions.push(this.slotRequestService.getByContract(this.company.id, contractId, true).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  public reject(observations: string, slotRequest: SlotsRequest): void {
    this.subscriptions.push(
      this.slotRequestService.reject(this.company.id, slotRequest.id, observations).subscribe(() => { })
    );
  }

  public retire(observations: string, slotRequest: SlotsRequest): void {
    this.subscriptions.push(
      this.slotRequestService.retire(this.company.id, slotRequest.id, observations).subscribe(() => { })
    );
  }

  public delete(observations: string, slotRequest: SlotsRequest): void {
    this.subscriptions.push(
      this.slotRequestService.delete(this.company.id, slotRequest.id, observations).subscribe(() => { })
    );
  }

  public getStatusClass(statusId: number): string {
    const statusClassMap = {
      [Status.UNKNOWN]: 'text-danger',
      [Status.PENDING]: 'text-warning',
      [Status.EXPIRED]: 'text-danger',
      [Status.ASSIGNED]: 'text-success',
      [Status.ASSIGNED_PARTIALLY]: 'text-warning',
      [Status.RETIRED]: 'text-danger',
      [Status.REJECTED]: 'text-danger'
    };

    return statusClassMap[statusId] ?? '';
  }

  private dataLoaded(data: SlotsRequest[], pagination?: Pagination): void {
    this.requests = data;
    this.processing = false;

    this.onLoad.emit({
      data: this.requests,
      pagination: pagination
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
