import { Company } from "../../../../models/company.model";
import { Type } from "class-transformer";
import { Quantity } from "../../../../models/quantity.model";
import { InvoiceItem } from "./invoice-item.model";
import { Contract } from "../../contracts/models/contract.model";
import { Product } from "../../../../models/product.model";
import { Label } from "../../../../models/label.model";
import { Currency } from "../../../../models/currency.model";

/**
 * @deprecated Use instead InvoiceV2
 * 
 * Represents a real life invoice, remittance, proforma, etc. It is exclusively
 * a data imported from an external system.
 */
export class Invoice {
  /** Agree's internal unique identifier. */
  readonly id: number;

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  /** Date of issue. */
  @Type(() => Date)
  date: Date;

  /** Due date. */
  @Type(() => Date)
  due_date?: Date;

  /**
   * Only if there is some type of intermediary linked to the Invoice, it can
   * be a Broker or a Distributor.
   */
  broker?: Company;
  /** Invoice issuer. */
  company: Company;
  /** Receiver of the Invoice. */
  recipient: Company;

  /** Invoice currency. */
  unit: Currency;
  /** Only if the Invoice for a volume of a specific [[Product]]. */
  quantity: Quantity;
  /** List of [[InvoiceItem|concepts/items]]. */
  items: InvoiceItem[];

  /** Total balance of the Invoice in the specified [[Invoice.unit|unit]]. */
  balance: number;
  /**
   * Total Net Invoice ([[Invoice.balance|Balance]] minus
   * [[Invoice.taxes|taxes]]).
   */
  net: number;
  /** VAT percentage (ex: 10.5). */
  tax: number;
  /** Invoice amount subject to tax. */
  taxed: number;
  /** Taxes. */
  taxes: number;
  /**
   * Invoice number (ex: 3310-04385016).
   * It must be unique per issuing [[Invoice.company|Company]].
   */
  reference: string;

  /**
   * Free description of the payment method used (or to be used) to settle the
   * Invoice.
   */
  payment_method?: string;
  /** Crop campaign period sow/harvest in "yy/yy" format (ex: 20/21). */
  crop?: string;
  /** Optional observations. */
  observations?: string;

  /** Invoice type. */
  concept?: {
    id?: number;
    type?: string;
  };

  /**
   * Settled amount of the Invoice [[Invoice.balance|balance]] in the specified
   * [[Invoice.unit|unit]].
   */
  settled: number;

  private _contract_reference: string;
  private _product: string;
  private _contracts: Contract[];
  private _products: Product[];

  private mapItems(): void {
    let included_ids = [];

    this._products = [];
    this._contracts = [];

    this.items.forEach(item => {
      if (item.contract && !included_ids.includes(item.contract.id)) {
        // Add distinct only
        included_ids.push(item.contract.id);
        this._products.push(item.contract.product);
        this._contracts.push(item.contract);
      }
    });

    this._products.sort((a, b) => {
      return a.name.localeCompare(b.name);
    }); // Sorted by name
    this._contracts.sort((a, b) => {
      return a.reference.localeCompare(b.reference);
    }); // Sorted by reference

    this._contract_reference = this._contracts.length ? this._contracts[0].reference : '';
    this._product = this._products.length ? this._products[0].name : '';
  }

  /** [[Contract|Contracts]] linked to this Invoice */
  get contracts(): Contract[] {
    if (!this._contracts) this.mapItems(); // Perform only once
    return this._contracts;
  }

  /** [[Product|Products]] linked to this Invoice */
  get products(): Product[] {
    if (!this._products) this.mapItems(); // Perform only once
    return this._products;
  }

  /**
   * First [[Contract]] [[Contract.reference|reference]] (ordered
   * alphabetically) for ordering uses.
   */
  get contract_reference(): string {
    if (!this._contract_reference) this.mapItems(); // Perform only once
    return this._contract_reference; // Returns the first reference for sorting purposes
  }

  /**
   * First [[Product]] [[Product.name|name]] (ordered alphabetically) for
   * ordering uses.
   */
  get product_name(): string {
    if (!this._product) this.mapItems(); // Perform only once
    return this._product; // Returns the first product name for sorting purposes
  }

  get isSettled(): boolean {
    return this.settled != undefined && this.balance <= this.settled;
  }

  /** For labeleable entities. */
  get entity(): string { return 'invoice'; }
  readonly labels?: Label[];

  constructor(data: Partial<Invoice> = {}) {
    this.concept = {};

    Object.assign(this, data);
  }
}

export class InvoiceCoverage {
  /** Agree's internal unique identifier. */
  readonly id: number;

  amount: number;

  concept: string;

  constructor(data: Partial<InvoiceCoverage> = {}) {
    Object.assign(this, data);
  }
}
