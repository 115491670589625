import { Directive, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import smoothscroll from 'smoothscroll-polyfill';

@Directive({
  selector: '[validateFormStep]',
  exportAs: 'validateFormStep'
})
export class ValidateFormStepDirective implements OnChanges {

  @HostBinding('class.completed')
  @Input() public validateFormStep: boolean;
  @Input() private nextStep: ValidateFormStepDirective;
  @Input() private preventScroll: boolean;

  /** Triggered on step completed. */
  @Output() readonly completedStep = new EventEmitter();

  constructor(
    private elementRef: ElementRef
  ) {
    smoothscroll.polyfill();
  }

  private doFocusScroll(): void {
    const element = this.elementRef;

    requestAnimationFrame(function (): void {
      if (element.nativeElement.offsetParent) {
        element.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validateFormStep.currentValue && !changes.validateFormStep.firstChange) {
      setTimeout(() => {
        this.completedStep.emit();
        if (!this.preventScroll && this.nextStep) this.nextStep.doFocusScroll();
      });
    }
  }
}
