import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

import { Account } from '../../../../../auth/models/account.model';
import { User } from '../../../../../auth/models/user.model';
import { Company, companyIs } from '../../../../../models/company.model';
import { Product } from '../../../../../models/product.model';
import { Slot } from '../../../../../models/slot.model';
import { SlotsBatch } from '../../../../../models/slots-batch.model';
import { CompanyService } from '../../../../../services/company.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { SlotService } from '../../../../../services/slot.service';
import { FormReuseService } from '../../../../../ui/services/form-reuse.service';
import { SlotsRequest } from '../../../../models/slots-request.model';
import { Negotiation } from '../../../commercial/models/negotiation.model';

/**
 * [[Slot|Slots]] related modals.
 *
 * ## Usage
 * ``` html
 * <slots-modals #slotsModals
 * [details]="false"
 * [company]="company"
 * [user]="user"></slots-modals>
 * ```
 *
 * Then, to open a modal use:
 * ``` html
 * <button (click)="slotsModals.createBatch(batch)">Open modal</button>
 * ```
 *
 * ### Related UI components:
 * - [[SlotsComponent]]
 * - [[ViewBatchComponent]]
 */
@Component({
  selector: 'slots-modals',
  templateUrl: './slots-modals.component.html',
  styleUrls: ['./slots-modals.component.scss']
})
export class SlotsModalsComponent implements OnDestroy {

  @ViewChild('assignSlotsModal', { static: true }) private readonly assignSlotsModal: TemplateRef<any>;
  @ViewChild('rejectModal', { static: true }) private readonly rejectModal: TemplateRef<any>;
  @ViewChild('acceptSlotsModal', { static: true }) private readonly acceptSlotsModal: TemplateRef<any>;
  @ViewChild('assignToTradeModal', { static: true }) private readonly assignToTradeModal: TemplateRef<any>;
  @ViewChild('requestSlotsModal', { static: true }) private readonly requestSlotsModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() private user: User;
  @Input() public details: boolean = true;

  /** Amount of accepted [[Slots]]. */
  public acceptedSlots: number = 0;
  /** Assign modal data. */
  public assignModel: {
    noMoreCompanies: boolean,
    assignedToMyCompany: boolean,
    assigned: SlotsBatch[],
    available: Slot[],
    observations?: string,
    reference: number,
    parent_id: number,
    actors?: any
  };
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** Reasons for rejection. */
  public reasons: string;
  /** @ignore */
  public selectedBatch: SlotsBatch;
  public slotsToAssignSelectedQty: number;
  /**
   * Enables a more simpler UX modal to accept a [[SlotsBatch|batch]].
   */
  public simplerAccept: boolean;

  private modalRef: BsModalRef[];
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private modalService: BsModalService,
    private slotService: SlotService,
    private formReuseService: FormReuseService,
    /** @ignore */
    public companyService: CompanyService,
    private intercomService: IntercomService
  ) { }

  /** Opens the modal to reject a [[SlotsBatch|Batch]]. */
  public rejectBatch(batch: SlotsBatch): void {
    this.execModal(this.rejectModal, batch);
  }

  /** Rejects the entire [[SlotsBatch|Batch]]. */
  public rejectSlots(): void {
    this.processing = true;

    this.subscriptions.push(this.updateBatch(this.selectedBatch.id, {
      id: 3,
      reason: this.reasons
    }).subscribe(response => this.doneModalProcessing()));
  }

  /**
   * Opens the modal to create one or more child [[SlotsBatch|Batch]] for the
   * specified batch.
   */
  public createBatch(parentBatch: SlotsBatch): void {
    this.assignModel = {
      noMoreCompanies: undefined,
      assignedToMyCompany: false, // TODO: Check if there is a batch assigned to my copmany
      assigned: [this.newSlotsBatch(instanceToInstance(parentBatch.unassigned), parentBatch.product, parentBatch.quality)],
      available: [],
      observations: '',
      reference: parentBatch.reference,
      parent_id: parentBatch.id
      // actors: instanceToInstance(parentBatch.actors), // Clone batch Actors
    };

    if (companyIs(this.company, 'FARMER')) this.assignToMyCompany(); // Farmers

    this.execModal(this.assignSlotsModal, parentBatch);
  }

  public selectedNegotiation: Negotiation;
  public availableBatches: SlotsBatch[];
  public availableCounterparts: Company[];
  public slotRequest: SlotsRequest;
  /**
   * Opens the modal to create one or more child [[SlotsBatch|Batch]] for the
   * specified batch.
   */
  public assignBatchToNegotiation(negotiation: Negotiation, slotRequest?: SlotsRequest): void {
    if (slotRequest) {
      this.setSlotRequest(slotRequest);
    }

    this.selectedNegotiation = negotiation;
    this.availableBatches = undefined;
    this.assignModel = undefined;

    const filters = {
      product_id: negotiation.order.product.id,
      validity: 'on_date'
    };

    if (negotiation.proposal.business_detail.sustainable) filters['sustainable'] = true;

    this.subscriptions.push(this.slotService.watchBatches(this.company.id, filters).subscribe(response => {
      this.availableBatches = response.body.filter(sb => {
        return sb.slots_unassigned > 0 && sb.company.id === this.company.id;
      });
    }));

    this.openModal(this.assignToTradeModal, 'modal-lg');
  }

  public setSlotRequest(slotRequest: SlotsRequest): void {
    this.slotRequest = slotRequest;
  }

  public setBatchToNegotiation(parentBatch: SlotsBatch): void {
    const sb = this.newSlotsBatch(instanceToInstance(parentBatch.unassigned), parentBatch.product, parentBatch.quality);
    sb.negotiation = this.selectedNegotiation;
    this.availableCounterparts = [this.selectedNegotiation.seller].concat(this.selectedNegotiation.seller_represented || []);

    // If there is only one counterparty, it is automatically defined.
    if (this.availableCounterparts.length === 1) sb.company = this.availableCounterparts[0];

    this.assignModel = {
      noMoreCompanies: undefined,
      assignedToMyCompany: false, // TODO: Check if there is a batch assigned to my copmany
      assigned: [sb],
      available: [],
      observations: '',
      reference: parentBatch.reference,
      parent_id: parentBatch.id
      // actors: instanceToInstance(parentBatch.actors), // Clone batch Actors
    };

    this.reasons = undefined;
    this.selectedBatch = parentBatch;
    this.setQuantityFromSlotRequest(0, this.slotRequest);
  }

  /**
   * Cambia la cantidad asignada de un lote específico basado en la entrada del usuario.
   * @param batch_index - El índice del lote en el array de lotes asignados.
   * @param e - El evento que contiene el nuevo valor de cantidad asignada.
   */
  public changeAssignedQuantity(batch_index: number, e: Event): void {
    // Obtiene el lote específico del array de lotes asignados
    const sb: SlotsBatch = this.assignModel.assigned[batch_index];

    // Convierte el valor del evento a un entero
    const value = parseInt((e.target as HTMLInputElement).value);

    // Actualiza la cantidad seleccionada de slots a asignar
    this.slotsToAssignSelectedQty = value;

    // Verifica si el valor es menor que la cantidad de slots no asignados actualmente en el lote
    if (value < sb.unassigned.length) {
      // Elimina slots si el nuevo valor es menor
      const removedSlots = sb.unassigned.splice(-(sb.unassigned.length - value));
      // Añade los slots eliminados al array de slots disponibles
      this.assignModel.available = this.assignModel.available.concat(removedSlots);
    } else if (value > sb.unassigned.length) {
      // Añade slots si el nuevo valor es mayor
      const slotsToAdd = this.assignModel.available.splice(0, (value - sb.unassigned.length));
      // Añade los slots al array de slots no asignados del lote
      sb.unassigned = sb.unassigned.concat(slotsToAdd);
    }
  }

  /**
   * Ajusta la cantidad de slots no asignados en un lote específico basado en una solicitud de slots.
   * @param batch_index - El índice del lote en el array de lotes asignados.
   * @param slotRequest - La solicitud de slots que contiene la cantidad total y la cantidad asignada.
   */
  private setQuantityFromSlotRequest(batch_index: number, slotRequest: SlotsRequest): void {
    if (!slotRequest) return;

    // Calcula la cantidad de slots disponibles a partir de la solicitud
    const availableSlots = slotRequest.quantity - slotRequest.assignedQuantity;

    // Obtiene el lote específico del array de lotes asignados
    const sb: SlotsBatch = this.assignModel.assigned[batch_index];

    // Verifica si la cantidad de slots disponibles es menor que los slots no asignados actualmente en el lote
    if (availableSlots < sb.unassigned.length) {
      // Elimina slots si los slots disponibles son menores
      const removedSlots = sb.unassigned.splice(-(sb.unassigned.length - availableSlots));
      // Añade los slots eliminados al array de slots disponibles
      this.assignModel.available = this.assignModel.available.concat(removedSlots);
    } else if (availableSlots > sb.unassigned.length) {
      // Añade slots si los slots disponibles son mayores
      const slotsToAdd = this.assignModel.available.splice(0, (availableSlots - sb.unassigned.length));
      // Añade los slots al array de slots no asignados del lote
      sb.unassigned = sb.unassigned.concat(slotsToAdd);
    }
  }


  public removeBatch(batch_index: number): void {
    const sb: SlotsBatch = this.assignModel.assigned[batch_index],
      removedBatchSlots = sb.unassigned;

    if (sb.company.id === this.company.id) {
      this.assignModel.assignedToMyCompany = false;
    }

    this.assignModel.assigned.splice(batch_index, 1);
    this.assignModel.available = this.assignModel.available.concat(removedBatchSlots);
  }

  public assignToMyCompany(): void {
    this.assignModel.assigned[this.assignModel.assigned.length - 1].company = this.company;
    this.assignModel.assignedToMyCompany = true;
  }

  public addBatch(): void {
    this.assignModel.assigned.push(this.newSlotsBatch(instanceToInstance(this.assignModel.available), this.selectedBatch.product, this.selectedBatch.quality));
    this.assignModel.available = [];
  }

  /**
   * Creates all the [[SlotsBatch|Batches]] define in this.assignModel.assigned
   * @ignore
   */
  public createAndAssign(): void {
    this.processing = true;

    this.assignModel.assigned.forEach((sb: SlotsBatch) => {
      if (this.assignModel.observations && this.assignModel.observations !== '') {
        sb.observations = this.assignModel.observations;
      }
      sb.reference = this.assignModel.reference;
      sb.parent_id = this.assignModel.parent_id;
      // sb.actors = this.assignModel.actors;
      sb.date = this.selectedBatch.date;
      sb.destination = this.selectedBatch.destination;
      sb.product = this.selectedBatch.product;
      sb.recipient = this.selectedBatch.recipient;

      if (this.selectedBatch.cpe) {
        sb.cpe = this.selectedBatch.cpe;
      }
    });

    this.subscriptions.push(this.slotService.create(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], this.assignModel.assigned, this.slotRequest).subscribe(response => {
      this.intercomService.track('slots-batch-created');
      // Save form reuse
      this.saveForm(() => this.doneModalProcessing());
    }));
  }

  /**
   * Save the data submitted to be reused.
   */
  private saveForm(callback?: Function): void {
    this.subscriptions.push(this.formReuseService.save(this.company.id, 'cpe-actors', this.selectedBatch.cpe.actors).subscribe(response => {
      if (callback) callback();
    }));
  }

  private filterAccount(account: Account): Account {
    if (account.company_id === this.company.id && account.user_id === this.user.id) {
      return account;
    }
  }

  /**
   * Assign selectize listener.
   *
   * Evaluates if there are still Companies to assign.
   * @ignore
   */
  public assignableCompaniesLoaded(e: any): void {
    // TODO: Filter Companies that have already been assigned a batch
    if (this.assignModel.noMoreCompanies === undefined) this.assignModel.noMoreCompanies = (e.results && e.results.length === 0);
  }

  public openAcceptSlotsModal(batch: SlotsBatch, forceSimpler: boolean = false): void {
    this.simplerAccept = forceSimpler || companyIs(this.company, 'FARMER'); // Farmers
    this.acceptedSlots = batch.unassigned.length;
    this.execModal(this.acceptSlotsModal, batch);
  }

  /**
   * Accepts and assigns the entire [[SlotsBatch]] to the specified
   * [[Company]].
   */
  public takeBatch(batch: SlotsBatch): void {
    this.processing = true;

    this.subscriptions.push(this.slotService.take(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], batch.id).subscribe(response => {
      this.doneModalProcessing();
    }));
  }

  /** Fully or partially accepts the received [[SlotsBatch|Batch]]. */
  public acceptSlots(): void {
    this.processing = true;

    const status = { id: 2 };

    if (this.acceptedSlots === this.selectedBatch.slots.length) {
      this.subscriptions.push(this.updateBatch(this.selectedBatch.id, status).subscribe(response => this.doneModalProcessing()));
    } else {
      const rejectedBatch = new SlotsBatch();

      rejectedBatch.status = {
        id: 3,
        reason: this.reasons
      }
      rejectedBatch.allocator = this.selectedBatch.allocator;
      rejectedBatch.company = this.selectedBatch.company;
      rejectedBatch.negotiation = this.selectedBatch.negotiation;
      rejectedBatch.parent_id = this.selectedBatch.parent_id;
      rejectedBatch.reference = this.selectedBatch.reference;
      rejectedBatch.date = this.selectedBatch.date;
      rejectedBatch.destination = this.selectedBatch.destination;
      rejectedBatch.product = this.selectedBatch.product;
      rejectedBatch.recipient = this.selectedBatch.recipient;

      // Asignar los slots rechazados al nuevo lote
      const rejectedSlots = this.selectedBatch.slots.slice(this.acceptedSlots);
      rejectedBatch.unassigned = rejectedSlots;

      this.subscriptions.push(this.updateBatch(this.selectedBatch.id, status, rejectedBatch).subscribe(response => this.doneModalProcessing()));
    }
  }

  private updateBatch(batch_id: number, status: any, batch?: SlotsBatch): Observable<any> {
    return this.slotService.update(this.company.id, this.user.accounts.filter(this.filterAccount, this)[0], batch_id, status, batch);
  }

  private execModal(template: TemplateRef<any>, batch: SlotsBatch = undefined): void {
    this.reasons = undefined;
    this.selectedBatch = batch;
    this.openModal(template, 'modal-lg');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    const newModal = this.modalService.show(template, { class: c });

    this.modalRef = [...(this.modalRef || []), newModal];
    newModal.onHide.subscribe(() => {
      // Removes last modal reference
      this.modalRef.pop();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    const mr = this.modalRef[this.modalRef.length - 1];

    if (mr) {
      mr.hide();
      if (onHide) mr.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  private doneModalProcessing(): void {
    this.closeModal();
    this.processing = false;
  }

  private newSlotsBatch(unassigned: Slot[], product?: Product, quality?: any): SlotsBatch {
    const nb = new SlotsBatch();

    nb.allocator = this.company;
    nb.unassigned = unassigned;
    if (quality) nb.quality = quality;

    if (product) nb.product = product;

    return nb;
  }

  /**
   * If the slotRequest.quantity is less than the slotsUnassigned, then return true if the
   * slotRequest.quantity is equal to the optionValue, otherwise return true if the slotsUnassigned is
   * equal to the index + 1.
   * @param {number} index - number - the index of the option in the select element
   * @returns The return value is a boolean.
   */
  public isSelectedQuantity(index: number): boolean {
    const slotsUnassigned = this.assignModel.assigned[0].slots_unassigned;
    const optionValue = index + 1;
    if (this.slotRequest?.quantity < slotsUnassigned) {
      // Set as max selection the quantity requested in slotRequest
      return this.slotRequest.quantity - this.slotRequest.assignedQuantity === optionValue;
    }

    if (this.assignModel.assigned[0].slots_unassigned === (index + 1)) {
      this.slotsToAssignSelectedQty = index + 1
      return true;
    }
  }

  public isDisabledQuantity(index: number): boolean {
    const optionValue = index + 1;
    if (this.slotRequest)
      return this.getSlotsQuantity() < optionValue;
    return false;
  }

  public getSlotsQuantity(): number {
    if (this.slotRequest) {
      return this.slotRequest.quantity - this.slotRequest.assignedQuantity;
    }
    return this.selectedBatch.unassigned.length
  }

  /**
   * Opens the modal to create one or more requests [[SlotsBatch|Batch]] for the
   * specified batch.
   */
  public requestBatchToNegotiation(negotiation: Negotiation): void {

    this.selectedNegotiation = negotiation;
    this.availableBatches = undefined;

    const filters = {
      product_id: negotiation.order.product.id,
      validity: 'on_date'
    };

    if (negotiation.proposal.business_detail.sustainable) filters['sustainable'] = true;

    this.openModal(this.requestSlotsModal, 'modal-lg');
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.modalRef?.forEach(modal => modal.hide());

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
