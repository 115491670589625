import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Counterpart } from '../../../models/counterpart.model';
import { Pagination, parsePagination } from '../../../models/pagination.model';
import { FilterSetComponent } from '../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../utilities/table-filters';
import { CounterpartsService } from '../../services/counterparts.service';

@Component({
  selector: 'ag-counterparties-table',
  templateUrl: './counterparties-table.component.html',
  styleUrls: ['./counterparties-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CounterpartiesTableComponent),
    multi: true
  }]
})
export class CounterpartiesTableComponent extends TableFilters implements OnInit, OnDestroy, ControlValueAccessor {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output('load') onLoad = new EventEmitter();

  private pusherSubscription: Subscription;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public predefinedFilters: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public counterparts: Counterpart[];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private counterpartsService: CounterpartsService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    if (this.filters) {
      this.predefinedFilters = true;
      this.loadData();
    } else {
      // Filters based on URL
      this.onFiltersChange = this.loadData;
      this.setupFilters();
    }
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.counterpartsService.watch(this.company.id, this.filters).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    });
  }

  private dataLoaded(data: Counterpart[], pagination: Pagination): void {
    this.counterparts = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.counterparts,
      pagination: pagination
    });
  }

  public addToMyNetwork(counterpart: Counterpart): void {
    this.processing = true;
    this.subscriptions.push(this.counterpartsService.add(this.company.id, counterpart.id).subscribe(response => {
      this.processing = false;
      counterpart.network_relation = response;
    }));
  }

  public unblock(counterpart: Counterpart): void {
    this.processing = true;
    this.subscriptions.push(this.counterpartsService.unblock(this.company.id, counterpart.id).subscribe(response => {
      counterpart.approved = true;
      this.processing = false;
    }));
  }

  public block(counterpart: Counterpart): void {
    this.processing = true;
    this.subscriptions.push(this.counterpartsService.block(this.company.id, counterpart.id).subscribe(response => {
      counterpart.approved = false;
      this.processing = false;
    }));
  }

  public removeFromMyNetwork(counterpart: Counterpart): void {
    this.processing = true;
    this.subscriptions.push(this.counterpartsService.remove(this.company.id, counterpart.network_relation.id).subscribe(response => {
      this.processing = false;
      counterpart.network_relation = null;
    }));
  }

  public acceptNetworkRequest(counterpart: Counterpart): void {
    this.processing = true;
    this.subscriptions.push(this.counterpartsService.accept(this.company.id, counterpart.network_relation.id).subscribe(response => {
      this.processing = false;
      counterpart.network_relation = response;
    }));
  }

  // ngModel
  private _value: Counterpart[] = [];
  public get selected(): Counterpart[] { return this._value }
  public set selected(v: Counterpart[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
