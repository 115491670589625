<ng-container *ngIf="enabled">
  <button (click)="show()" type="button" class="btn btn-link btn-sm"
  [disabled]="processing"
  [class.not-editable]="!editable" [popover]="assignees?.length ? assigneeDetails : null" triggers="mouseenter:mouseleave focus" placement="right"
  containerClass="popFadeDelay width-800"
  container="body">
    <i class="material-symbols-rounded">people</i>
  </button>
  
  <button type="button" class="btn btn-link btn-sm"
  *ngIf="assignees && editable && !currentUserIncluded"
  (onConfirm)="addMe()"
  [confirm-options]="{
    title: 'ASSIGNEES_MANAGER.ADD_ME'|translate,
    body: 'ASSIGNEES_MANAGER.ADD_ME_BODY'|translate
  }"
  title="{{ 'ASSIGNEES_MANAGER.ADD_ME'|translate }}"
  [disabled]="processing">
    <i class="material-symbols-rounded">person_add</i>
  </button>
</ng-container>

<ng-template #assigneeDetails>
  <div class="container-fluid">
    <div class="row" *ngIf="assigneesByRole[1] && assigneesByRole[1][0]">
      <div class="col-xs-12">
        <p class="small">
          {{ 'ASSIGNEES_MANAGER.CREATOR'|translate }}:
          <b [class.highlighted-text]="user?.id === assigneesByRole[1][0].userId">{{ assigneesByRole[1][0].name }}</b>
          <small class="text-muted"> &#8212; {{ assigneesByRole[1][0].email }}</small>
        </p>
      </div>
    </div>
    <div class="row">
      <ng-container *ngTemplateOutlet="assigneeTable; context: {
        title: 'ASSIGNEES_MANAGER.RESPONSIBLE',
        assignees: assigneesByRole[2]}"></ng-container>
      <ng-container *ngTemplateOutlet="assigneeTable; context: {
        title: 'ASSIGNEES_MANAGER.WATCHERS',
        assignees: assigneesByRole[3]}"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #assigneeTable let-title="title" let-assignees="assignees">
  <div class="col-md-6"
  *ngIf="assignees">
    <table class="table table-detail table-condensed">
      <thead>
        <tr>
          <th>{{ title|translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let assignee of assignees">
          <td>
            <b [class.highlighted-text]="user?.id === assignee.userId">{{ assignee.name }}</b>
            <div class="small text-muted">{{ assignee.email }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #assigneeManager>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()"
    [disabled]="processing">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'ASSIGNEES_MANAGER.MODAL.TITLE'|translate }}</h4>
  </div>
  
  <div class="modal-body">
    <div class="loading" *ngIf="processing">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
    <ng-container *ngIf="!processing">
      <div class="form-group">
        <label class="control-label">{{ 'ASSIGNEES_MANAGER.MODAL.RESPONSIBLE'|translate}}</label>
        <span class="help-block">{{ 'ASSIGNEES_MANAGER.MODAL.RESPONSIBLE_HELP'|translate }}</span>
        <selectize name="operators" placeholder="{{ 'ASSIGNEES_MANAGER.MODAL.PLACEHOLDER'|translate }}" [minLength]="1"
        valueField="accountId"
        (onItemAdd)="onChange($event, 2, true)"
        (onItemRemove)="onChange($event)"
        [options]="availableAssignees"
        type="assignee" [(ngModel)]="assigneesByRole[2]">
        </selectize>
      </div>
      <div class="form-group">
        <label class="control-label">{{ 'ASSIGNEES_MANAGER.MODAL.WATCHERS'|translate}}</label>
        <span class="help-block">{{ 'ASSIGNEES_MANAGER.MODAL.WATCHERS_HELP'|translate }}</span>
        <selectize name="watchers" placeholder="{{ 'ASSIGNEES_MANAGER.MODAL.PLACEHOLDER'|translate }}"
        valueField="accountId"
        (onItemAdd)="onChange($event, 3, true)"
        (onItemRemove)="onChange($event)"
        [options]="availableAssignees"
        type="assignee" [(ngModel)]="assigneesByRole[3]">
        </selectize>
      </div>
    </ng-container>
  </div>
</ng-template>