import { Type } from "class-transformer";
import { Company } from "../../../../models/company.model";
import { Currency } from "../../../../models/currency.model";
import { Price } from "../../../../models/price.model";
import { Product } from "../../../../models/product.model";
import { Quantity } from "../../../../models/quantity.model";

export class InvoiceV2 {
  readonly uuid: string;
  readonly id: string;
  invoice_lines: InvoiceLine[];
  reference: string;
  total: number;
  currency: Currency;
  category: null;

  @Type(() => Company)
  company: Company;

  @Type(() => Company)
  recipient: Company;

  @Type(() => Company)
  importer: Company;

  conversion_rate: any;
  conversion_current_id: any;
  budget: any;
  file_id: string;
  type: InvoiceType;

  @Type(() => Date)
  created_at: Date;

  @Type(() => Date)
  due_date: Date;

  @Type(() => Date)
  issue_date: Date;

  private _products: Product[];

  get products(): Product[] {
    this._products = this.invoice_lines
      .map(invoice => invoice.product)
      .sort((a, b) => a.name.localeCompare(b.name));

    return this._products;
  }
}

export class InvoiceLine {
  readonly uuid: string;
  product: Product;
  invoice_uuid: string;
  quantity_id: number;
  quantity: Quantity;
  currency: Currency;
  description: string;
  unit_price: Price;
  total_amount: number;
}

export class InvoiceType {
  id: number;
  name: string
}

export interface InvoiceConcept {
  id: number;
  type: number;
}