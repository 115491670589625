<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FINTECH.FIELDS_TABLE.IMPORT_PRODUCTION_PLAN'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div [collapse]="fetched != undefined" [isAnimated]="true">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label" for="source">{{ 'FINTECH.FIELDS_TABLE.IMPORT_SOURCES'|translate }}</label>
            <select class="form-control input-lg"
            name="source" id="source"
            [(ngModel)]="source"
            required
            [disabled]="processing">
              <option *ngFor="let s of sources" [ngValue]="s.id">{{ s.name }}</option>
            </select>
          </div>
        </div>
      </div>
      
      <div class="row" *ngIf="!processing; else loading">
        <div class="col-sm-offset-8 col-sm-4">
          <button type="button" (click)="import()" class="btn btn-agree btn-block" [disabled]="processing">
            <span class="material-symbols-rounded">input</span> {{ 'FINTECH.FIELDS_TABLE.IMPORT'|translate }}
          </button>
        </div>
      </div>
    </div>

    <div [collapse]="fetched == undefined" [isAnimated]="true">
      <ng-container *ngIf="fetched?.length > 0; else noData">
        <div class="row">
          <div class="col-xs-12">
            <p class="text-center text-muted">{{ 'FINTECH.FIELDS_TABLE.FILE_WARNING'|translate }}</p>
            <table class="table no-margin">
              <tbody>
                <tr class="tr-align-center">
                  <th>{{ 'FINTECH.FIELDS_TABLE.FIELDS'|translate }}</th>
                  <td class="bigger">{{ fetched[0].productionPlan.seedingZones.length }}</td>
                </tr>
                <tr class="tr-align-center">
                  <th>{{ 'FINTECH.FIELDS_TABLE.HECTARES'|translate }}</th>
                  <td class="bigger">{{ fetched[0].productionPlan.seedingZones|pluck:'field_size'|sum|number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-4 col-sm-4">
            <button type="button" (click)="fetched = undefined" class="btn btn-link btn-block" [disabled]="processing">
              {{ 'GLOBAL.RETRY'|translate }}
            </button>
          </div>
          <div class="col-sm-4">
            <button type="button" (click)="apply()" class="btn btn-agree btn-block" [disabled]="processing">
              {{ 'VOLUME.APPLY'|translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'GLOBAL.NO_DATA'|translate }}
    <button type="button" (click)="fetched = undefined" class="btn btn-link btn-block" [disabled]="processing">
      {{ 'GLOBAL.RETRY'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>