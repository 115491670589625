import { Type } from "class-transformer";

import { Currency } from "../../../../models/currency.model";
import { JSONObject } from "../../../../models/jsonobject.model";
import { Participant } from "../../../../ui/models/assignment.model";
import { Funder } from "./funder.model";
import { WorkFlowDataField } from "./work-flow-data-field.model";

export class FintechProduct {
  /** Agree's internal unique identifier. */
  id: string;

  @Type(() => Date)
  readonly created_at: Date;

  @Type(() => Date)
  readonly updated_at: Date;

  @Type(() => Funder)
  funder?: Funder;

  name: string;

  description: string[];

  information?: string;

  /** Minimum amount to request in a line of credit (consider all currencies). */
  amount_min: number = 1;
  /** Maximum amount to request in a line of credit (consider all currencies). */
  amount_max: number = 999999999999;
  /** Optional legend, displayed below the line of credit amount input. */
  amount_help?: string;
  /** Optional legend, displayed as label of the line of credit amount input. */
  amount_label?: string;

  risk_min?: number;
  risk_max?: number;

  /**
   * | Value   | Description                                                    |
   * |---------|----------------------------------------------------------------|
   * | SCORING | Execute the scoring template, qualification subject to result. |
   * | MANUAL  | Submit all qualifications for manual review.                   |
   * | AUTO    | The qualification is automatically approved.                   |
   */
  readonly approval: 'SCORING' | 'MANUAL' | 'AUTO';

  @Type(() => WorkFlowDataField)
  readonly fields: WorkFlowDataField[];

  /** Supported currencies for credit application. */
  currencies: Currency[];

  /** Interest rate. */
  rate?: number;
  /** Taxes rate. */
  tax_rate?: number;

  external_id?: string;

  rate_help?: string;
  /** Optional legend, displayed as label of the tax rate. */
  rate_label?: string;

  line_group?: string;

  requires_amount?: boolean;

  /** Requires authorization from the Applicant or Representative of the Company. */
  requires_authorization?: boolean;

  /** Requires CIEC key from the Applicant or Representative of the Company. */
  requires_ciec?: boolean;

  /** Requires NIP from the Applicant or Representative of the Company. */
  requires_nip?: boolean;

  /** Requires a Supplier. */
  requires_supplier?: boolean;

  properties?: JSONObject;

  active: boolean;

  restricted?: 'PERSON' | 'COMPANY';

  flow_id?: string;
  template_id?: string;
  template_credit_id?: string;

  /** Allows to create child applications. */
  enable_related?: boolean;

  visibilities?: {
    participant: Participant
  }[];

  public get group(): string {
    return this.line_group ? this.funder.id + '_' + this.line_group.trim().replace(/[^a-zA-Z0-9]/g, '') : undefined;
  }

  constructor(data: Partial<FintechProduct> = {}) {
    Object.assign(this, {
      // Default values
      active: true,
      approval: 'MANUAL',
      description: [''],
      fields: [],
      flow_id: null,
      requires_amount: true,
      requires_authorization: false,
      requires_ciec: false,
      requires_nip: false,
      requires_supplier: true,
      restricted: null,
      risk_max: 100,
      risk_min: 70,
      template_credit_id: null,
      template_id: null,
      // Partial
      ...data
    });
  }
}

class ProductList {
  black?: (string | number)[];
  white?: (string | number)[];
}

export class FintechProductLists {
  tenant?: ProductList;
  activity?: ProductList;
  fiscal_id?: ProductList;
  supplier?: ProductList;
  manager?: ProductList;
}