import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { TableFilters } from '../../../../../utilities/table-filters';
import { ContractService } from '../../../contracts/services/contract.service';
import { ContractApplication } from '../../models/contract-application.model';

@Component({
  selector: 'applications-table',
  templateUrl: './applications-table.component.html',
  styleUrls: ['./applications-table.component.scss'],
})
export class ApplicationsTableComponent extends TableFilters implements OnInit, OnDestroy {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;

  @Output('load') readonly onLoad = new EventEmitter();

  public applications: ContractApplication[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public predefinedFilters: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  constructor(
    private contractService: ContractService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    const { configuration } = this.company.market;
    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('contract_applications')) {
      if (this.filters) {
        this.loadData();
      } else {
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  private loadData(): void {
    this.loading = true;
    this.subscriptions.push(this.contractService.watchAplicaciones(this.company.id, this.filters).subscribe(response => {
      if (!response) return;

      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  private dataLoaded(data: ContractApplication[], pagination: Pagination): void {
    this.applications = data;
    this.loading = false;

    this.onLoad.emit({
      data: this.applications,
      pagination: pagination
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
