import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../../../models/jsend.model';
import { buildFilters } from '../../../../utilities/filters';
import { FintechProduct, FintechProductLists } from '../models/fintech-product.model';
import { Funder } from '../models/funder.model';
import { WorkFlowDataField } from '../models/work-flow-data-field.model';

/** http://api-funders.dev.agree.ag/docs/ */
@Injectable({
  providedIn: 'root'
})
export class FundersService {

  private baseUrl: string = '/:apiBase/fintech';
  private companyUrl: string = this.baseUrl + '/companies/:companyId';
  private fundersUrl: string = this.companyUrl + '/funders';
  private funderById: string = this.fundersUrl + '/:funderId';
  private funderProducts: string = this.funderById + '/products';
  private productsById: string = this.funderProducts + '/:productId';
  private adminFunders: string = this.baseUrl + '/funders';
  private adminFunderById: string = this.adminFunders + '/:funderId';
  private adminProducts: string = this.baseUrl + '/products';
  private adminProductById: string = this.adminProducts + '/:productId';
  private adminProductLists: string = this.adminProductById + '/lists';
  private adminFields: string = this.adminProducts + '/fields';
  private adminFieldsById: string = this.adminFields + '/:fieldId';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient
  ) { }

  /** SuperAdmin */
  public get(filters?: any, paginated: boolean = true): Observable<{ body: Funder[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    const url = buildFilters(this.adminFunders, filters, this.queryMap);

    const stream = this.http.get<JSend<Funder[]>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') return { body: plainToInstance(Funder, response.body.data), headers: response.headers };
      else throw new Error(String(response.body.data));
    }));
  }

  /** SuperAdmin */
  public getAllProducts(filters?: any, paginated: boolean = true): Observable<{ body: FintechProduct[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    const url = buildFilters(this.adminProducts, filters, this.queryMap);

    const stream = this.http.get<JSend<FintechProduct[]>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') return { body: plainToInstance(FintechProduct, response.body.data), headers: response.headers };
      else throw new Error(String(response.body.data));
    }));
  }

  /** SuperAdmin */
  public create(funder: Funder): Observable<Funder> {
    const url = this.adminFunders;
    const stream = this.http.post<JSend<Funder>>(url, funder);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(Funder, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /** SuperAdmin */
  public edit(funder: Funder): Observable<Funder> {
    const url = this.adminFunderById
      .replace(":funderId", funder.id);
    const stream = this.http.put<JSend<Funder>>(url, funder);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(Funder, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /** SuperAdmin */
  public editProductAdmin(product: FintechProduct): Observable<FintechProduct> {
    const url = this.adminProductById
      .replace(":productId", product.id);
    const stream = this.http.put<JSend<FintechProduct>>(url, product);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FintechProduct, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /** SuperAdmin */
  public createProductAdmin(product: FintechProduct): Observable<FintechProduct> {
    const url = this.adminProducts;

    const stream = this.http.post<JSend<FintechProduct>>(url, product);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FintechProduct, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /** Funders only */
  public getCompany(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: Funder[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.fundersUrl
      .replace(":companyId", companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<Funder[]>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') return { body: plainToInstance(Funder, response.body.data), headers: response.headers };
      else throw new Error(String(response.body.data));
    }));
  }

  /** Funders only */
  public getProducts(companyId: number, funderId: string, filters?: any, paginated: boolean = true): Observable<{ body: FintechProduct[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.funderProducts
      .replace(":companyId", companyId + '')
      .replace(":funderId", funderId);

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<FintechProduct[]>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      if (response.body.status === 'success') return { body: plainToInstance(FintechProduct, response.body.data), headers: response.headers };
      else throw new Error(String(response.body.data));
    }));
  }

  /** Funders only */
  public editProduct(companyId: number, funderId: string, product: FintechProduct): Observable<FintechProduct> {
    const url = this.productsById
      .replace(":companyId", companyId + '')
      .replace(":funderId", funderId)
      .replace(":productId", product.id);

    const stream = this.http.put<JSend<FintechProduct>>(url, product);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FintechProduct, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /** SuperAdmin */
  public deleteProduct(product: FintechProduct): Observable<any> {
    const url = this.adminProductById
      .replace(":productId", product.id);

    const stream = this.http.delete<JSend>(url);

    return stream;
  }

  /** SuperAdmin */
  public getProductList(product: FintechProduct): Observable<FintechProductLists> {
    const url = this.adminProductLists
      .replace(":productId", product.id);

    const stream = this.http.get<JSend<FintechProductLists>>(url);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FintechProductLists, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /** SuperAdmin */
  public editProductList(product: FintechProduct, lists: FintechProductLists): Observable<FintechProductLists> {
    const url = this.adminProductLists
      .replace(":productId", product.id);

    const stream = this.http.put<JSend<FintechProductLists>>(url, lists);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(FintechProductLists, response.data);
      else throw new Error(String(response.data));
    }));
  }

  public getFields(): Observable<WorkFlowDataField[]> {
    const url = this.adminFields;

    const stream = this.http.get<JSend<WorkFlowDataField[]>>(url);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(WorkFlowDataField, response.data);
      else throw new Error(String(response.data));
    }));
  }

  public createField(field: WorkFlowDataField): Observable<WorkFlowDataField> {
    const url = this.adminFields;

    const stream = this.http.post<WorkFlowDataField>(url, field);

    return stream.pipe(map(response => {
      return plainToInstance(WorkFlowDataField, response);
    }));
  }

  public editField(field: WorkFlowDataField): Observable<WorkFlowDataField> {
    const url = this.adminFieldsById
      .replace(":fieldId", field.id);

    const stream = this.http.put<WorkFlowDataField>(url, field);

    return stream.pipe(map(response => {
      return plainToInstance(WorkFlowDataField, response);
    }));
  }

  /** SuperAdmin */
  public deleteField(field: WorkFlowDataField): Observable<any> {
    const url = this.adminFieldsById
      .replace(":fieldId", field.id);

    const stream = this.http.delete<JSend>(url);

    return stream;
  }
}
