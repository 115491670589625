import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { AFIPLiquidacion } from '../../../../models/afipliquidacion.model';
import { JSend } from '../../../../models/jsend.model';
import { PusherService } from '../../../../services/pusher.service';
import { buildFilters } from '../../../../utilities/filters';

@Injectable({
  providedIn: 'root'
})
export class AfipService {

  private baseUrl: string = '/:apiBase/companies/:companyId/afip';
  private liquidacionesUrl: string = this.baseUrl + '/liquidaciones';
  private liquidacionUrl: string = this.liquidacionesUrl + '/:liquidacionId';
  private liquidacionFileUrl: string = this.liquidacionUrl + '/file';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product_id]',
    'commercial_zone': 'filters[commercial_zone]'
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: AFIPLiquidacion[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.liquidacionesUrl.replace(':companyId', companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<{
      liquidaciones: AFIPLiquidacion[]
    }>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(AFIPLiquidacion, response.body.data.liquidaciones), headers: response.headers };
    }));
  }

  public watch(companyId?: number, filters?: any, paginated: boolean = true): Observable<{ body: AFIPLiquidacion[], headers: HttpHeaders }> {
    return this.pusherService.listen('company_' + companyId, 'liquidaciones').pipe(
      startWith({}),
      mergeMap(event => {
        return this.get(companyId, filters, paginated);
      })
    );
  }

  public getFile(companyId: number, liquidacionId: string): Observable<any> {
    const url = this.liquidacionFileUrl
      .replace(":companyId", companyId + '')
      .replace(":liquidacionId", liquidacionId);

    return this.http.get<JSend<any>>(url).pipe(map(response => {
      return response.data.file;
    }));
  }
}
