<ag-add-contract-modal #contractModal (save)="done.emit()" [company]="company"></ag-add-contract-modal>

<ag-contracts-slots-modals #slotsModals [company]="company" [user]="user"></ag-contracts-slots-modals>

<request-fixation-modal #fixationRequestModal [company]="company" [contract]="contract"
  [currencies]="currencies"></request-fixation-modal>

<div class="btn-group" dropdown [container]="container" placement="{{ right ? 'bottom right' : 'bottom left'}}">
  <button dropdownToggle type="button" tooltip="{{'GLOBAL.ACTIONS'|translate}}"
    class="btn btn-link btn-xs dropdown-toggle">
    <i class="material-symbols-rounded">more_vert</i>
  </button>
  <ul *dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="right" role="menu">
    <li role="menuitem"><a class="dropdown-item" (click)="contractModal.open(contract)">{{ 'GLOBAL.EDIT'|translate
        }}</a></li>
    <li *ngIf="!isFarmer && company?.hasModule('slots')" role="menuitem">
      <a class="dropdown-item" [class.disabled]="!(buyer || broker)"
        (click)="slotsModals.assignBatchToContract(contract)">{{'NEGOTIATION_ACTIONS.ASSIGN_SLOTS'|translate }}</a>
    </li>
    <li *ngIf="(contract.seller.id === company.id || contract.getBroker(company.id)) && company.hasModule('slots')" role="menuitem"
      [class.disabled]="!(seller || broker)">
      <a class="dropdown-item" (click)="slotsModals.requestBatchToContract(contract)">Solicitar Cupos</a>
    </li>
    <li role="menuitem" [class.disabled]="contract.negotiation"><a class="dropdown-item" (click)="toTrade()">{{
        'CONTRACTS.GENERATE_TRADE'|translate }}</a></li>
    <li *ngIf="contract?.price?.type === 'to_be_fixed'" role="menuitem">
      <a class="dropdown-item" (click)="fixationRequestModal.show()">Solicitar Fijacion</a>
    </li>
    <li role="separator" class="divider"></li>
    <li role="menuitem"><a class="dropdown-item" (onConfirm)="delete()" [confirm-options]="{
        action: 'GLOBAL.YES_DELETE'|translate
      }">{{ 'TEMPLATES_VIEW.DELETE'|translate }}</a></li>
  </ul>
</div>

<ng-template #toTradeModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'CONTRACTS.GENERATE_TRADE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-muted">{{ 'CONTRACTS.CONFIRM_PARTICIPANTS'|translate }}</p>
    <form id="trade-form" #tradeForm="ngForm" (ngSubmit)="tradeForm.valid && submit()">
      <div class="row">
        <div class="col-md-6">
          <!-- SELECT:Seller -->
          <div class="form-group" hasError>
            <label class="control-label">{{ 'ORDER_SUMMARY.SELLER'|translate }}</label>
            <selectize *ngIf="!actors.seller" required [maxItems]="1" [options]="availableActors.sellers"
              [(ngModel)]="actors.seller" name="seller" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="actors.seller" class="editable-pill">
              <button [disabled]="processing" type="button" class="close" (click)="actors.seller = undefined"><span
                  aria-hidden="true">&times;</span></button>
              <company-info [company]="actors.seller"></company-info>
            </div>
            <div class="help-block" *ngIf="!actors.seller &&
            contract.seller.fiscal_id.value === company.fiscal_id.value">
              <a class="small" (click)="actors.seller = company" [class.disabled]="processing">{{
                'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- SELECT:Broker -->
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'ORDER_SUMMARY.BROKER_SELLER'|translate }}
              <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <selectize *ngIf="!actors.broker_seller" [class.hidden]="noBrokers" [maxItems]="1" [searchURL]="companyService.getCompanies(company.id, {
                all: true,
                brokers: true,
                excluded_ids: [actors.buyer?.id, actors.seller?.id, actors.broker_buyer?.id]
              })" [(ngModel)]="actors.broker_seller" name="broker" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              (load)="brokersLoaded($event)" [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="actors.broker_seller" class="editable-pill">
              <button [disabled]="processing" type="button" class="close"
                (click)="actors.broker_seller = undefined"><span aria-hidden="true">&times;</span></button>
              <company-info [company]="actors.broker_seller"></company-info>
            </div>
            <alert *ngIf="noBrokers" type="warning"><span
                [innerHTML]="'IMPORTER.NO_COMPANIES'|translate: {companyId: company.id}"></span></alert>
            <div class="help-block" *ngIf="company.activity.broker &&
            actors.broker_seller?.id !== company.id &&
            ![actors.buyer?.id, actors.seller?.id, actors.broker_buyer?.id].includes(company.id)">
              <a class="small" (click)="actors.broker_seller = company" [class.disabled]="processing">{{
                'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <!-- SELECT:Buyer -->
          <div class="form-group" hasError>
            <label class="control-label">{{ 'ORDER_SUMMARY.BUYER'|translate }}</label>
            <selectize *ngIf="!actors.buyer" required [maxItems]="1" [options]="availableActors.buyers"
              [(ngModel)]="actors.buyer" name="buyer" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="actors.buyer" class="editable-pill">
              <button [disabled]="processing" type="button" class="close" (click)="actors.buyer = undefined"><span
                  aria-hidden="true">&times;</span></button>
              <company-info [company]="actors.buyer"></company-info>
            </div>
            <div class="help-block" *ngIf="!actors.buyer &&
            contract.buyer.fiscal_id.value === company.fiscal_id.value">
              <a class="small" (click)="actors.buyer = company" [class.disabled]="processing">{{
                'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- SELECT:Broker -->
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'ORDER_SUMMARY.BROKER_BUYER'|translate }}
              <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <selectize *ngIf="!actors.broker_buyer" [class.hidden]="noBrokers" [maxItems]="1" [searchURL]="companyService.getCompanies(company.id, {
                all: true,
                brokers: true,
                excluded_ids: [actors.buyer?.id, actors.seller?.id, actors.broker_seller?.id]
              })" [(ngModel)]="actors.broker_buyer" name="broker" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              (load)="brokersLoaded($event)" [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="actors.broker_buyer" class="editable-pill">
              <button [disabled]="processing" type="button" class="close"
                (click)="actors.broker_buyer = undefined"><span aria-hidden="true">&times;</span></button>
              <company-info [company]="actors.broker_buyer"></company-info>
            </div>
            <alert *ngIf="noBrokers" type="warning"><span
                [innerHTML]="'IMPORTER.NO_COMPANIES'|translate: {companyId: company.id}"></span></alert>
            <div class="help-block" *ngIf="company.activity.broker &&
            actors.broker_buyer?.id !== company.id &&
            ![actors.buyer?.id, actors.seller?.id, actors.broker_seller?.id].includes(company.id)">
              <a class="small" (click)="actors.broker_buyer = company" [class.disabled]="processing">{{
                'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </form>
    <alert type="danger" *ngIf="companyNotFound" [dismissible]="true">
      <span [innerHtml]="'CONTRACTS.COMPANY_NOT_FOUND'|translate"></span>
    </alert>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="trade-form" class="btn btn-agree" [disabled]="processing">
      {{ 'GLOBAL.GENERATE'|translate }}
    </button>
  </div>
</ng-template>