<ng-template #loadingTemplate>
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
</ng-template>

<ng-template #slotsDetailModal>
    <!-- modal -->
    <div class="modal-header">
        <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">
            {{ 'SLOTS.DETAILS_MODAL.TITLE'|translate }}
            <button type="button" class="btn btn-link" *ngIf="selectedBatch?.id"
                [routerLink]="['/company', company.id, 'slots', selectedBatch.id]" (click)="closeModal()">{{
                'SLOTS.BATCH_DETAILS'|translate }}</button>
            <button type="button" class="btn btn-link hidden-xs" [useExistingCss]="true" printSectionId="print-modal"
                ngxPrint><span class="material-symbols-rounded">print</span></button>
        </h4>
    </div>
    <div class="modal-body" id="print-modal">
        <div class="row">
            <div class="col-xs-12">
                <ng-container *ngIf="selectedBatch; else loadingTemplate">
                    <batch-details [company]="company" [batch]="selectedBatch"></batch-details>
                    <ag-batch-slots-table [readonly]="readonly" [company]="company" [batch]="selectedBatch"
                        [disabled]="processing" [slots]="selectedSlots"
                        (onWithdrawal)="closeModal()"></ag-batch-slots-table>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>