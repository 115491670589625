import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Product } from '../../../../../models/product.model';
import { Negotiation } from '../../../commercial/models/negotiation.model';
import { OrderService } from '../../../commercial/services/order.service';
import { Contract } from '../../models/contract.model';
import { ContractService } from '../../services/contract.service';

/**
 * ContractLinker is a component intended to assist the [[User]] to link and/or
 * unlink [[Contract|Contracts]] with [[Negotiation|Negotiations]].
 *
 * ## Usage
 * ``` html
 * <contract-linker #contractLinker
 * [company]="company"></contract-linker>
 * ```
 *
 * ### Related UI components:
 * - [[ClosedNegotiationsTableComponent]]
 * - [[ContractsTableComponent]]
 */
@Component({
  selector: 'contract-linker',
  templateUrl: './contract-linker.component.html',
  styleUrls: ['./contract-linker.component.scss']
})
export class ContractLinkerComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  /**
   * [[Company]] that makes the link.
   */
  @Input() public company: Company;

  /** @ignore */
  public availableContracts: Contract[];
  /** @ignore */
  public availableNegotiations: Negotiation[];
  /**
   * Buyer [[Comopany]].
   * @ignore */
  public buyer: Company;
  /**
   * [[Contract]] to link.
   * @ignore */
  public contract: Contract;
  /**
   * [[Negotiation]] to link.
   * @ignore */
  public negotiation: Negotiation;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** @ignore */
  public product: Product;
  /**
   * Specifies which object the user should select.
   *
   * Possible values: `contract` or `negotiation`.
   * @ignore */
  public select: string;
  /**
   * Seller [[Comopany]].
   * @ignore */
  public seller: Company;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private modalService: BsModalService,
    private contractService: ContractService,
    private orderService: OrderService
  ) { }

  private reset(): void {
    this.modalRef = undefined;
    this.product = undefined;
    this.buyer = undefined;
    this.seller = undefined;
    this.contract = undefined;
    this.negotiation = undefined;
    this.select = undefined;

    this.availableContracts = undefined;
    this.availableNegotiations = undefined;
    this.processing = false;
  }

  /**
   * Opens the modal with the link wizard.
   */
  public link(
    product: Product,
    counterparts: {
      buyer: Company,
      seller: Company
    },
    documents: {
      contract?: Contract
      negotiation?: Negotiation
    }
  ) {
    this.product = product;

    this.buyer = counterparts.buyer;
    this.seller = counterparts.seller;

    this.contract = documents.contract;
    this.negotiation = documents.negotiation;

    if (!this.contract) this.getContracts();
    if (!this.negotiation) this.getNegotiations();

    this.openModal(this.modalTemplate, 'modal-lg');
  }

  /**
   * Links negotiation to contract.
   */
  public linkSelected(): void {
    this.processing = true;

    this.subscriptions.push(this.contractService.link(this.company.id, this.contract, this.negotiation.id).subscribe(contract => {
      this.closeModal();
    }));
  }

  /**
   * Unlinks negotiation from specified object.
   */
  public unlink(contract: Contract): void {
    this.processing = true;

    this.subscriptions.push(this.contractService.link(this.company.id, contract).subscribe(contract => {
      this.processing = false;
    }));
  }

  private getContracts(): void {
    this.select = 'contract';
    this.processing = true;

    this.subscriptions.push(this.orderService.relatedContracts(this.company.id, this.negotiation).subscribe(negotiations => {
      this.availableContracts = negotiations;
      this.processing = false;
    }));
  }

  private getNegotiations(): void {
    this.select = 'negotiation';
    this.processing = true;

    this.subscriptions.push(this.contractService.related(this.company.id, this.contract).subscribe(negotiations => {
      this.availableNegotiations = negotiations;
      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.reset();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
