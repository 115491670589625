 <div class="row" *ngIf="order.product && proposal?.product_detail?.quality[key]?.quality_specifications">
    <div class="col-xs-12">
      <div class="quality-info">
        <p class="text-muted">{{ 'GLOBAL.SPECIFICATIONS'|translate }}</p>
        <ul>
          <li *ngFor="let pair of proposal?.product_detail?.quality[key].quality_specifications | pairs | orderBy:'1.order'">
            {{ pair[1].name ? pair[1].name : pair[0] }}
            <ul *ngIf="pair[1].children">
              <li *ngFor="let subpair of pair[1].children | pairs | orderBy:'1.order' ">
                {{ subpair[1].name ? subpair[1].name : subpair[0] }}: {{ subpair[1].value}}
              </li>
            </ul>
            <span *ngIf="pair[1].value">: {{ pair[1].value }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
