import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { User } from '../../../auth/models/user.model';
import { Company } from '../../../models/company.model';
import { EnvelopeSigner } from '../../../models/envelope.model';
import { SignersService } from '../../../services/signers.service';
import { FilePreviewComponent } from '../../../ui/components/file-preview/file-preview.component';

@Component({
  selector: 'ag-team-signers',
  templateUrl: './team-signers.component.html',
  styleUrls: ['./team-signers.component.css']
})
export class TeamSignersComponent implements OnInit, OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() public company: Company;
  @Input() public user?: User;
  @Input() public account?: Account;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public signers: EnvelopeSigner[];

  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private signersService: SignersService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.processing = true;
    this.subscriptions.push(this.signersService.get(this.company.id).subscribe(signers => {
      this.signers = signers;
      this.processing = false;
    }));
  }

  /** Removes the specified [[EnvelopeSigner|Signer]]. */
  public delete(signer: EnvelopeSigner): void {
    if (!this.processing) {
      this.processing = true;
      this.subscriptions.push(this.signersService.delete(this.company.id, signer.id).subscribe(response => {
        this.loadData();
      }));
    }
  }

  public refresh(): void {
    if (!this.processing) this.loadData();
  }

  /** Opens the [[EnvelopeSigner|signer's]] power document. */
  public getSignerFile(signer: EnvelopeSigner): void {
    if (signer && signer.file) {
      this.processing = true;
      const observable = this.signersService.getFile(this.company.id, signer.id, signer.file);

      this.subscriptions.push(observable.subscribe(response => {
        this.processing = false;
        this.filePreviewer.preview(observable, response.name, undefined, response.url);
      }));
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
