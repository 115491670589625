import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { FintechFormsService } from '../../../admin/modules/fintech/services/forms.service';
import { Company } from '../../../models/company.model';
import { Currency } from '../../../models/currency.model';
import { Price } from '../../../models/price.model';
import { MarketService } from '../../../services/market.service';
import { FileExtract } from '../../models/file-extract.model';
import { WorkFlowDataField, WorkflowDataValue } from '../../modules/fintech/models/work-flow-data-field.model';
import { FileExtractService } from '../../services/file-extract.service';
import { FileManagerService } from '../../services/file-manager.service';

@Component({
  selector: 'ag-file-extract',
  templateUrl: './file-extract.component.html',
  styleUrls: ['./file-extract.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileExtractComponent),
    multi: true
  }]
})
export class FileExtractComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public label: string;
  @Input('file-type') public fileType: string;
  @Input('file-label') public fileLabel: string;
  @Input('form-id') private formId: string;
  @Input() public placeholder: string;
  /** [[Company]] IDs with which you want to share the files. */
  @Input() public shared: number[];
  /** Maximum number of selectable files. */
  @Input('max-files') public maxFiles: number = 1;
  /**
   * https://agreemarket.atlassian.net/wiki/spaces/DEV/pages/90112001/Web+application+UI#Archivos
   */
  @Input('max-size') public maxSize: number = 5242880 * 50;
  /**
   * Specify what file types the user can attach.
   *
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  @Input() public accept: string = "";
  @Input() private set disabled(d: boolean) {
    this._disabled = d;
  };
  public get disabled(): boolean {
    return Boolean(this._disabled || this.processing || this.extracting);
  }

  public active: boolean;
  public control: FormControl;
  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[];
  /** The language currently used. */
  public currentLang: string;
  public extracting: boolean;
  public fileUrl: string;
  public fieldResults: { [fieldKy: string]: any[] };
  public formData: { [dataFieldKey: string]: WorkflowDataValue; };
  public paramsData: { [paramKey: string]: { [dataFieldKey: string]: WorkflowDataValue; } };
  public form: WorkFlowDataField[];
  public params: { [key: string]: WorkFlowDataField[] };
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;
  public readonlyField: { [key: string]: boolean };
  public source: string[];
  public UUID: string;

  private fieldValues: { [fieldKy: string]: any[] };
  /** Files extracted */
  private sources: string[];
  private _disabled: boolean;
  private modalRef: BsModalRef;
  private subscriptions: Subscription[] = [];
  private watchSub: Subscription;

  /** @ignore */
  constructor(
    private fileManagerService: FileManagerService,
    private fileExtractService: FileExtractService,
    private fintechFormsService: FintechFormsService,
    private modalService: BsModalService,
    private marketService: MarketService,
    private translateService: TranslateService
  ) {
    this.UUID = 'fe-' + uuidv4();
    this.source = [];
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  public open(): void {
    if (!this.value) this.value = new FileExtractData();
    this.openModal(this.modal, 'modal-xl');

    if (!this.form) {
      this.processing = true;

      this.subscriptions.push(this.fintechFormsService.get(this.formId).subscribe(form => {
        this.formLoaded(form.spec);
        this.parseParams(form.params);

        this.processing = false;
      }));
    }
  }

  private formLoaded(form: WorkFlowDataField[]): void {
    this.form = form;
    this.formData = this.formData || {};
    this.setupFormFields(this.form, this.formData);

    this.readonlyField = {};

    const valueData = this.value.data;
    const extractions = this.value.extractions;

    if (valueData) {
      // Fill form with value data
      for (let i = 0, keys = Object.keys(valueData); i < keys.length; i++) {
        const key = keys[i];
        this.formData[key].value = valueData[key].value;
      }
    }

    if (extractions) {
      // Parse extractions from value
      extractions.forEach(extraction => {
        this.sources = [...(this.sources || []), ...extraction.extracted.map(e => e.source)];
        this.extracted(extraction);
      });

      // Preview first file
      if (!this.fileUrl && this.sources.length) this.previewFile(this.sources[0]);
    }
  }

  private parseParams(params: { [key: string]: WorkFlowDataField[] }): void {
    if (params) {
      for (let i = 0, keys = Object.keys(params); i < keys.length; i++) {
        const key = keys[i];
        this.paramsData = { ...(this.paramsData || {}), [key]: {} };
        this.setupFormFields(params[key], this.paramsData[key]);
        this.params = { ...(this.params || {}), ...{ [key]: params[key] } };
      }
    };
  }

  private setupFormFields(form: WorkFlowDataField[], formData: { [dataFieldKey: string]: WorkflowDataValue; }): void {
    instanceToInstance(form).forEach((dataField: WorkFlowDataField) => {
      let value = dataField.attr?.value;

      if (value !== undefined) {
        switch (dataField.type) {
          case 'DATE':
            value = new Date(value);
            break;
          case 'DATE_RANGE':
            value = value.map(date => new Date(date));
            break;
        }
      } else if (dataField.type == 'BOOLEAN') {
        value = false;
      }

      const field = new WorkflowDataValue({
        slug: dataField.slug,
        type: dataField.type,
        value
      });

      if (dataField.slug_suffix) field.slug_suffix = dataField.slug_suffix;
      if (dataField.label) field.label = dataField.label;

      // formData = { ...(formData || {}), [dataField.key]: field };
      formData[dataField.key] = field;

      if (dataField.type === 'PRICE') {
        this.loadCurrencies(() => {
          if (!formData[dataField.key].value) {
            const unit = dataField.attr?.currencies ? dataField.attr.currencies[0] : this.currencies[0];
            formData[dataField.key].value = new Price({ unit });
          }
        });
      }
    });
  }

  public extract(): void {
    // console.time('Extractor');

    const toBeRead: string[] = this.source.filter(fileId => !this.sources?.includes(fileId));

    if (toBeRead.length > 0) {
      let params = {};

      if (this.paramsData) {
        for (const key in this.paramsData) {
          const element = this.paramsData[key];
          params = { ...params, ...{ [key]: {} } };
          for (const paramKey in element) {
            params[key] = { ...params[key], ...{ [paramKey]: element[paramKey].value } };
          }
        }
      }

      this.extracting = true;

      // console.timeLog('Extractor', '\tPOST');
      this.subscriptions.push(this.fileExtractService.extract(this.company.id, toBeRead, this.formId, params).subscribe(response => {
        // console.timeLog('Extractor', '\tPOST response', response);
        this.sources = [...(this.sources || []), ...toBeRead];

        // console.timeLog('Extractor', '\tWATCH');
        this.watchSub = this.fileExtractService.watch(this.company.id, response.id).subscribe(request => {
          // console.timeLog('Extractor', '\tWATCH response', request.status);
          if (request.status == 'COMPLETED') {
            this.watchSub.unsubscribe();
            // Add response from Extractor
            // console.timeLog('Extractor', '\tPARSE');
            this.extracted(request);
            // console.timeLog('Extractor', '\tPARSE Completed');

            this.source = [];
            this.extracting = false;

            // Preview first file
            // console.timeLog('Extractor', '\tPREVIEW');
            if (!this.fileUrl) this.previewFile(this.sources[0]);
            // console.timeEnd('Extractor');
          }
        });
      }));
    }
  }

  private extracted(extracted: FileExtract): void {
    if (!extracted.extracted) return;

    this.fieldValues = this.fieldValues || {};
    this.fieldResults = this.fieldResults || {};

    extracted.extracted.forEach(({ results }) => {
      // Iterate files
      results?.forEach(result => {
        // Iterate data
        const formField = this.form.find(field => field.key == result.key);

        if (formField) {
          // Form field exists
          const parsedResult = this.parsefieldValue(result.value, formField);

          this.fieldValues[result.key] = this.fieldValues[result.key] || [];
          this.fieldResults[result.key] = this.fieldResults[result.key] || [];

          if (!this.fieldValues[result.key].includes(parsedResult)) {
            this.fieldValues[result.key].push(parsedResult)
            this.fieldResults[result.key].push({ ...result, value: parsedResult });
          }
        }
      });
    });

    for (let i = 0, keys = Object.keys(this.fieldResults); i < keys.length; i++) {
      const key = keys[i];
      const results = this.fieldResults[key];
      // Sort by confidence index
      results.sort((a, b) => b.extractor.confidence - a.extractor.confidence);

      if (!this.readonlyField[key] && this.formData[key] && !this.formData[key].value) {
        // Set field value if do not exists with the more confident result
        this.formData[key].value = results[0].value;
      }
    }

    this.value.extractions = [...(this.value.extractions || []), extracted];
  }

  /** Maneja la lógica de conversión basada en el tipo de campo */
  private parsefieldValue(value: any, formField: WorkFlowDataField): any {
    switch (formField.type) {
      case 'DATE':
        return new Date(value);
      case 'PRICE':
        const parsedResult = new Price(value);
        if (!value.unit) {
          parsedResult.unit = formField.attr?.currencies?.[0] || this.currencies[0];
        }
        return parsedResult;
      default:
        return value;
    }
  }

  public submit(): void {
    this.setValue = { ...this.value, ...{ data: this.formData } };
    this.closeModal();
  }

  private set setValue(value: FileExtractData) {
    this.value = value;
    this.propagateChange(this.value);
  }

  private loadCurrencies(callBack: Function): void {
    if (!this.currencies) {
      this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
        this.currencies = currencies;
        callBack();
      }));
    } else callBack();
  }

  public previewFile(fileId: string): void {
    this.fileUrl = undefined;

    this.subscriptions.push(this.fileManagerService.getFile(this.company.id, fileId).subscribe(response => {
      this.fileUrl = response.url;
    }))
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.active = true;
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalRef.onHide.subscribe((reason: string) => {
      this.active = false;
      this.killSubs();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  private killSubs(): void {
    if (this.watchSub) this.watchSub.unsubscribe();

    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // ngModel
  public value: FileExtractData;
  private propagateChange = (_: any) => { };

  writeValue(value: FileExtractData) {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.killSubs();
  }
}

class FileExtractData {
  extractions?: FileExtract[];

  /** User declared data. */
  data?: { [dataFieldKey: string]: WorkflowDataValue; };
}