import { Directive, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

import { SubscriptionManager } from "./subscription-manager";

@Directive()
export class ModalManager extends SubscriptionManager {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(public modalService: BsModalService) {
    super();
  }

  protected error: boolean;

  /** Generic Modal trigger. */
  protected openModal(template: TemplateRef<any>, c: string = '', backdropEnable: boolean = true): void {
    this.modalRef = this.modalService.show(template, { class: c, ignoreBackdropClick: !backdropEnable });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.modalRef = null;
      this.error = false;
    });
  }

  /** Closes the most recent opened modal. */
  protected closeModal(onHide: Function = null) {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
