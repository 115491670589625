<button type="button"
class="btn btn-block btn-default btn-lg flex-center-distributed"
[class.active]="active"
[disabled]="disabled"
(click)="open()">
  <span
  class="material-symbols-rounded invisible small">check</span>
  <div class="grow text-center">
    <i class="material-symbols-rounded faded">move_down</i>&nbsp;
    {{ 'FILE_EXTRACT.EXTRACT'|translate }}...
  </div>
  <span [class.invisible]="!value?.data"
  class="material-symbols-rounded text-success small">check</span>
</button>

<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="disabled" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ label }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-5 col-md-6">
        <ng-template #formFields>
          <div class="text-left m-b1">
            <p class="faded">{{ 'FILE_EXTRACT.FIELDS'|translate }}</p>
            <ng-container *ngFor="let field of form; let fieldIndex = index;">
              <div *ngIf="fieldIndex < 10" class="truncate"><span class="material-symbols-rounded faded">arrow_right</span> {{ field.label || ('WORKFLOW.FIELD.' + field.slug)|translate }}</div>
              <div *ngIf="fieldIndex == 10"><span class="material-symbols-rounded faded">arrow_right</span> {{ 'FILE_EXTRACT.AND_MORE'|translate }}</div>
            </ng-container>
          </div>
        </ng-template>
        <p>
          {{ 'FILE_EXTRACT.DESCRIPTION'|translate }}
          <span class="material-symbols-rounded text-info"
          *ngIf="form"
          [tooltip]="formFields">info</span>
        </p>
        <p class="text-muted">{{ 'FILE_EXTRACT.DISCLAIMER'|translate }}</p>
      </div>
      <div class="col-sm-7 col-md-6" *ngIf="source">
        <form #payloadForm="ngForm" (ngSubmit)="payloadForm.valid && extract()">
          <!-- Params -->
          <div class="flex-row" *ngIf="params">
            <ng-container *ngFor="let param of params|keyvalue">
              <ng-container *ngFor="let field of param.value; let paramIndex = index;">
                <ng-container [ngSwitch]="field.type">
                  <!-- <div *ngIf="paramIndex > 0 &&
                    field.type !== form[paramIndex - 1].type"
                  class="flex-break"></div> -->
        
                  <!-- <div *ngSwitchCase="'TEXTAREA'"
                  class="flex-col-6">
                    <div class="form-group" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <textarea class="form-control" rows="3"
                      [disabled]="disabled"
                      autocomplete="off"
                      maxlength="{{ field.attr?.maxlength || 512 }}"
                      placeholder="{{ field.attr?.placeholder || '' }}"
                      name="fe-{{ field.key }}"
                      [attr.list]="'dl-' + field.key"
                      [required]="field.required"
                      [(ngModel)]="formData[field.key].value"></textarea>
                      <ng-container *ngTemplateOutlet="fieldOptions; context: { key: field.key }"></ng-container>
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div> -->
    
                  <div *ngSwitchCase="'NUMBER'"
                  class="flex-col-6">
                    <div class="form-group" hasError hasErrorOn="always">
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field, disableRO: true }"></ng-container>
                      <input type="number" class="form-control"
                      [disabled]="disabled"
                      autocomplete="off"
                      placeholder="{{ field.attr?.placeholder || '' }}"
                      name="param-{{ param.key }}-{{ field.key }}"
                      [required]="field.required"
                      [min]="field.attr?.min"
                      [max]="field.attr?.max"
                      [step]="field.attr?.step"
                      [(ngModel)]="paramsData[param.key][field.key].value">
                      <input type="range"
                      [disabled]="disabled"
                      name="param-{{ param.key }}-{{ field.key }}-range"
                      *ngIf="field.attr?.min != undefined && field.attr?.max != undefined"
                      [attr.min]="field.attr.min"
                      [attr.max]="field.attr.max"
                      [attr.step]="field.attr?.step"
                      [(ngModel)]="paramsData[param.key][field.key].value">
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'TEXT'"
                  class="flex-col-6">
                    <div class="form-group" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field, disableRO: true }"></ng-container>
                      <input type="text" class="form-control"
                      [disabled]="disabled"
                      autocomplete="off"
                      placeholder="{{ field.attr?.placeholder || '' }}"
                      name="param-{{ param.key }}-{{ field.key }}"
                      [required]="field.required"
                      maxlength="{{ field.attr?.maxlength || 64 }}"
                      [(ngModel)]="paramsData[param.key][field.key].value">
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <!-- <div *ngSwitchCase="'DATE'"
                  class="flex-col-6">
                    <div class="form-group" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="material-symbols-rounded">today</i>
                        </div>
                        <input type="text" class="form-control"
                        [disabled]="disabled || readonlyField[field.key]"
                        readonly
                        placeholder="{{ field.attr?.placeholder || ('GLOBAL.DATE_PLACEHOLDER'|translate) }}"
                        name="fe-{{ field.key }}"
                        [attr.list]="'dl-' + field.key"
                        [required]="field.required"
                        bsDatepicker
                        container="#{{ UUID }}"
                        [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                        [(ngModel)]="formData[field.key].value">
                      </div>
                      <error-list></error-list>
                      <ng-container *ngTemplateOutlet="fieldOptions; context: { key: field.key }"></ng-container>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'BOOLEAN'"
                  class="flex-col-6">
                    <div class="form-group">
                      <div class="checkbox flex-center-distributed">
                        <div>
                          <input type="checkbox" class="ios"
                          id="chk-{{ field.key }}"
                          [disabled]="disabled || readonlyField[field.key]"
                          name="fe-{{ field.key }}"
                          [(ngModel)]="formData[field.key].value">
                        </div>
                        <label for="chk-{{ field.key }}" class="grow">{{ field.label || ('WORKFLOW.FIELD.' + field.slug)|translate }}</label>
                      </div>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'PRICE'"
                  class="flex-col-6">
                    <div class="form-group" *ngIf="currencies" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <price-form
                      [disabled]="disabled || readonlyField[field.key]"
                      placeholder="0.00"
                      prefix="fe-"
                      [required]="field.required"
                      [min]="field.attr?.min"
                      [max]="field.attr?.max"
                      [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
                      [price]="formData[field.key].value"
                      [currencies]="field.attr?.currencies || currencies" [showQuantityUnit]="false"
                      ></price-form>
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div> -->
    
                  <div *ngSwitchDefault class="flex-col-6">
                    <div class="form-group">
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <pre class="micro">{{ field.type|json }}</pre>
                    </div>
                  </div>
        
                </ng-container>
              </ng-container>
            </ng-container>
          </div>

          <div class="form-group">
            <label class="control-label">{{ (fileLabel || 'IMPORTER.SOURCE_FILE')|translate }}</label>
            <ag-file-manager
            [shared]="shared"
            [(ngModel)]="source"
            [disabled]="disabled"
            name="file_{{ i }}"
            [required]="true"
            [max-files]="maxFiles"
            [max-size]="maxSize"
            [accept]="accept"
            [company]="company"
            autocomplete="off"
            [label]="fileLabel"
            [type]="fileType"></ag-file-manager>
          </div>        
          <div class="bubble bubble-top">
            <!-- <p>Define uno o varios archivos para extraer la información.</p> -->
            <button type="submit"
            (click)="extract()"
            [disabled]="disabled || !payloadForm.valid"
            class="btn btn-default btn-block bt-lg">
              <ng-container *ngIf="!extracting; else extractingMsg">
                <span class="material-symbols-rounded faded">auto_fix_high</span>
                {{ 'FILE_EXTRACT.EXTRACT'|translate }}
              </ng-container>
            </button>
          </div>
        </form>
      </div>
    </div>

    <form *ngIf="formData && value.extractions" id="extract-form" #extractForm="ngForm" (ngSubmit)="extractForm.valid && submit()">
      <div class="row">
        <connector [linked]="true"
        on="move_down"></connector>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div id="{{ UUID }}"
          *ngIf="form">
            <div class="flex-row">
              <ng-container *ngFor="let field of form; let fieldIndex = index;">
                <ng-container [ngSwitch]="field.type">
                  <!-- <div *ngIf="fieldIndex > 0 &&
                    field.type !== form[fieldIndex - 1].type"
                  class="flex-break"></div> -->
        
                  <div *ngSwitchCase="'TEXTAREA'"
                  class="flex-col-6">
                    <div class="form-group" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <textarea class="form-control" rows="3"
                      [disabled]="disabled"
                      autocomplete="off"
                      maxlength="{{ field.attr?.maxlength || 512 }}"
                      placeholder="{{ field.attr?.placeholder || '' }}"
                      name="fe-{{ field.key }}"
                      [attr.list]="'dl-' + field.key"
                      [required]="field.required"
                      [(ngModel)]="formData[field.key].value"></textarea>
                      <ng-container *ngTemplateOutlet="fieldOptions; context: { key: field.key }"></ng-container>
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'NUMBER'"
                  class="flex-col-6">
                    <div class="form-group" hasError hasErrorOn="always">
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <input type="text" class="form-control"
                      [readonly]="readonlyField[field.key]"
                      [disabled]="disabled"
                      autocomplete="off"
                      placeholder="{{ field.attr?.placeholder || '' }}"
                      name="fe-{{ field.key }}"
                      [attr.list]="'dl-' + field.key"
                      [required]="field.required"
                      [min]="field.attr?.min"
                      [max]="field.attr?.max"
                      [step]="field.attr?.step"
                      mask="separator.2"
                      [(ngModel)]="formData[field.key].value">
                      <input type="range"
                      [disabled]="disabled"
                      name="fe-{{ field.key }}-range"
                      *ngIf="field.attr?.min != undefined && field.attr?.max != undefined"
                      [attr.min]="field.attr.min"
                      [attr.max]="field.attr.max"
                      [attr.step]="field.attr?.step"
                      [(ngModel)]="formData[field.key].value">
                      <ng-container *ngTemplateOutlet="fieldOptions; context: { key: field.key }"></ng-container>
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'TEXT'"
                  class="flex-col-6">
                    <div class="form-group" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <input type="text" class="form-control"
                      [readonly]="readonlyField[field.key]"
                      [disabled]="disabled"
                      autocomplete="off"
                      placeholder="{{ field.attr?.placeholder || '' }}"
                      name="fe-{{ field.key }}"
                      [attr.list]="'dl-' + field.key"
                      [required]="field.required"
                      maxlength="{{ field.attr?.maxlength || 64 }}"
                      [(ngModel)]="formData[field.key].value">
                      <error-list></error-list>
                      <ng-container *ngTemplateOutlet="fieldOptions; context: { key: field.key }"></ng-container>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'DATE'"
                  class="flex-col-6">
                    <div class="form-group" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <div class="input-group">
                        <div class="input-group-addon">
                          <i class="material-symbols-rounded">today</i>
                        </div>
                        <input type="text" class="form-control"
                        [disabled]="disabled || readonlyField[field.key]"
                        readonly
                        placeholder="{{ field.attr?.placeholder || ('GLOBAL.DATE_PLACEHOLDER'|translate) }}"
                        name="fe-{{ field.key }}"
                        [attr.list]="'dl-' + field.key"
                        [required]="field.required"
                        bsDatepicker
                        container="#{{ UUID }}"
                        [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                        [(ngModel)]="formData[field.key].value">
                      </div>
                      <error-list></error-list>
                      <ng-container *ngTemplateOutlet="fieldOptions; context: { key: field.key }"></ng-container>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'BOOLEAN'"
                  class="flex-col-6">
                    <div class="form-group">
                      <div class="checkbox flex-center-distributed">
                        <div>
                          <input type="checkbox" class="ios"
                          id="chk-{{ field.key }}"
                          [disabled]="disabled || readonlyField[field.key]"
                          name="fe-{{ field.key }}"
                          [(ngModel)]="formData[field.key].value">
                        </div>
                        <label for="chk-{{ field.key }}" class="grow">{{ field.label || ('WORKFLOW.FIELD.' + field.slug)|translate }}</label>
                      </div>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchCase="'PRICE'"
                  class="flex-col-6">
                    <div class="form-group" *ngIf="currencies" hasError>
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <price-form
                      [disabled]="disabled || readonlyField[field.key]"
                      placeholder="0.00"
                      prefix="fe-"
                      [required]="field.required"
                      [min]="field.attr?.min"
                      [max]="field.attr?.max"
                      [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
                      [price]="formData[field.key].value"
                      [currencies]="field.attr?.currencies || currencies" [showQuantityUnit]="false"
                      ></price-form>
                      <error-list></error-list>
                      <span class="help-block small" *ngIf="field.help" [innerHtml]="field.help"></span>
                    </div>
                  </div>
    
                  <div *ngSwitchDefault class="flex-col-6">
                    <div class="form-group">
                      <ng-container *ngTemplateOutlet="inputLabel; context: { field: field }"></ng-container>
                      <pre class="micro">{{ field.type|json }}</pre>
                    </div>
                  </div>
        
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-md-6 sticky">
          <tabset>
            <!-- <ng-container *ngFor="let extraction of value.extractions; let extractionIndex = index">
            </ng-container> -->
            <tab *ngFor="let file of sources; let fileIndex = index"
            heading="{{ (fileLabel || 'IMPORTER.SOURCE_FILE')|translate }}{{ sources.lenght > 1 ? ' [' + (fileIndex + 1) + ']' : '' }}"
            [disabled]="disabled"
            (selectTab)="previewFile(file)">
              <ngx-doc-viewer *ngIf="fileUrl; else loadingIndicator" [url]="fileUrl" viewer="google"></ngx-doc-viewer>
            </tab>
          </tabset>
        </div>
      </div>
    </form>

    <!-- <pre>{{value|json}}</pre> -->
  </div>
  <div class="modal-footer" *ngIf="formData && value.extractions">
    <!-- <button type="button" (click)="closeModal()" [disabled]="disabled" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button> -->
    <button type="submit" form="extract-form" class="btn btn-agree" [disabled]="disabled">
      {{ 'VOLUME.APPLY'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #inputLabel let-field="field" let-disableRO="disableRO">
  <label class="control-label flex-center-distributed">
    <span class="grow">
      {{ field.label || ('WORKFLOW.FIELD.' + field.slug)|translate }}
      <span *ngIf="field.slug_suffix">{{ field.slug_suffix }}</span>
      <small class="text-muted" *ngIf="!field.required"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
    </span>
    <on-off-switch
    *ngIf="!disableRO"
    [disabled]="disabled"
    class="micro"
    [ngModelOptions]="{ standalone: true }"
    [(ngModel)]="readonlyField[field.key]"></on-off-switch>
  </label>
</ng-template>

<ng-template #extractingMsg>
  <div>
    <div class="extracting">
      <spinner></spinner>
      <span>{{ 'FILE_EXTRACT.EXTRACTING'|translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #fieldOptions let-key="key">
  <ng-container *ngIf="!readonlyField[key] && fieldResults && fieldResults[key]">
    <div class="suggestions hidden-print"
    *ngIf="fieldResults[key].length > 1">
      <span class="text-muted no-select"
      tooltip="El extractor encontró más de un valor posible.">
        <span class="material-symbols-rounded">auto_awesome</span>
        {{ 'FILE_EXTRACT.SUGGESTIONS'|translate }}
      </span>
      <button
      (click)="formData[key].value = item.value"
      type="button"
      [class.hidden]="item.value == formData[key].value"
      class="btn btn-default btn-xs"
      [disabled]="disabled || readonlyField[key]"
      title="{{ item.extractor?.confidence ? 'Nivel de confianza: ' + (item.extractor.confidence|number:'1.0-2':currentLang) : '' }}"
      *ngFor="let item of fieldResults[key]">
        <ng-container *ngTemplateOutlet="showValue; context: {
          value: item.value
        }"></ng-container>
      </button>
    </div>
    <datalist
    *ngIf="fieldResults && fieldResults[key]"
    id="dl-{{ key }}">
      <option *ngFor="let item of fieldResults[key]" [value]="item.value"></option>
    </datalist>
  </ng-container>
</ng-template>

<ng-template #showValue
let-value="value">
  <samp *ngIf="value | isNumber"
  [class.text-danger]="value < 0"
  [class.text-muted]="value === 0">{{ value | number:'1.0-2':currentLang }}</samp>
  <span *ngIf="value | isString">{{ value }}</span>
  <!-- <span *ngIf="value === true || value === false"
  class="material-symbols-rounded"
  [class.text-success]="value"
  [class.text-danger]="!value">{{ value ? 'done' : 'close' }}</span> -->
</ng-template>

<ng-template #loadingIndicator>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>