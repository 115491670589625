import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from "class-transformer";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyLocation } from '../models/company-location.model';
import { GeoSelection } from '../models/geo-selection.model';
import { Location } from '../models/location.model';
import { Zone } from '../models/zone.model';

@Injectable()
export class CompanyLocationService {

  private readonly baseUrl = '/:apiBase/companies/:companyId';
  private readonly companyLocationsUrl = this.baseUrl + '/locations';
  private readonly deleteLocationsUrl = this.companyLocationsUrl + '/:locationId';

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number): Observable<CompanyLocation[]> {
    const url = this.companyLocationsUrl.replace(":companyId", companyId + '');
    const stream = this.http.get<CompanyLocation[]>(url);

    return stream.pipe(map(locations => {
      return plainToInstance(CompanyLocation, locations);
    }));
  }

  public getCompanyLocations(companyId: number): Observable<GeoSelection[]> {
    return this.get(companyId).pipe(map(location => {

      const location_data = location.map(function (x) {

        const geoSelection = new GeoSelection();

        if (x.location != null) {
          geoSelection.location = new Location();

          geoSelection.location.id = x.location.id as number;

          geoSelection.location.name = x.location.name;
          geoSelection.sortableName = x.location.name;

          geoSelection.location.levels = [];

          geoSelection.location.levels.push(new Location());
          geoSelection.location.levels.push(new Location());
          geoSelection.location.levels.push(new Location());

          geoSelection.location.levels[1].id = x.provincia.id as number;
          geoSelection.location.levels[1].name = x.provincia.name;

          geoSelection.location.levels[2].id = x.partido.id as number;
          geoSelection.location.levels[2].name = x.partido.name;
        }
        else {
          geoSelection.zone = new Zone();

          geoSelection.zone.id = x.zone.id as number;

          geoSelection.zone.name = x.zone.name;
          geoSelection.sortableName = x.zone.name;
        }

        return geoSelection;
      });

      location_data.sort((a, b) => {
        return a.sortableName.localeCompare(b.sortableName);
      });

      return location_data;

    }));
  }

  public delete(companyId: number, location): Observable<any> {
    const url = this.deleteLocationsUrl.replace(":companyId", companyId + '').replace(":locationId", location.id + '');

    return this.http.delete<any>(url);
  }

  public create(companyId: number, zone: Zone, location: Location): Observable<any> {
    const url = this.companyLocationsUrl.replace(":companyId", companyId + '');

    return this.http.post<any>(url, {
      id_zone: zone ? zone.id : null,
      id_location: location ? location.id : null
    });
  }
}
