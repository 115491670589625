import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

import { PusherMessage } from "../../models/pusher-message.model";
import { PusherService } from "../../services/pusher.service";
import { buildFilters } from "../../utilities/filters";
import { SlotsRequest } from "./../models/slots-request.model";

@Injectable({
  providedIn: 'root'
})
export class SlotRequestService {

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private baseUrl = '/:apiBase/companies/:companyId';
  private slotRequest = this.baseUrl + '/slots-request';
  private slotRequestReject = this.slotRequest + '/:slotRequestId/reject';
  private slotRequestRetire = this.slotRequest + '/:slotRequestId/retire';
  private slotRequestById = this.slotRequest + '/:slotRequestId';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product][id]',
    'validity': 'filters[validity]',
    'negotiation_id': 'filters[negotiation][reference]',
    'recipient_name': 'filters[recipient_name]',
    'assigned_to': 'filters[assigned_to]',
    'sustainable': 'filters[sustainable]'
  };

  public create(companyId: number, data: SlotsRequest[]): Observable<any> {
    const url = this.slotRequest
      .replace(':companyId', companyId + '');

    return this.http.post(url, data);
  }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: SlotsRequest[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.slotRequest.replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<SlotsRequest[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      return { body: plainToInstance(SlotsRequest, response.body), headers: response.headers };
    }));

  }

  public reject(companyId: number, slotsRequestId: number, observations: string = ""): Observable<SlotsRequest> {
    const url = this.slotRequestReject
      .replace(':companyId', String(companyId))
      .replace(':slotRequestId', String(slotsRequestId))

    return this.http.patch<SlotsRequest>(url, { observations });
  }

  public retire(companyId: number, slotsRequestId: number, observations?: string): Observable<SlotsRequest> {
    const url = this.slotRequestRetire
      .replace(':companyId', String(companyId))
      .replace(':slotRequestId', String(slotsRequestId))

    return this.http.patch<SlotsRequest>(url, { observations });
  }

  public delete(companyId: number, slotsRequestId: number, observations?: string): Observable<any> {
    const url = this.slotRequestById
      .replace(':companyId', String(companyId))
      .replace(':slotRequestId', String(slotsRequestId))

    return this.http.delete(url, { body: observations });
  }

  public getAll(companyId: number): Observable<{ body: SlotsRequest[], headers: HttpHeaders }> {
    const url = this.slotRequest
      .replace(':companyId', companyId + '');

    const stream = this.http.get<SlotsRequest[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(SlotsRequest, response.body), headers: response.headers };
    }));
  }

  public getByNegotiation(companyId: number, negotiation_id: number, on_date?: boolean): Observable<{ body: SlotsRequest[], headers: HttpHeaders }> {
    let url = this.slotRequest
      .replace(':companyId', companyId + '');

    const filters = {
      negotiation_id: negotiation_id,
    };

    if (on_date) {
      filters['validity'] = 'on_date';
    }

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<SlotsRequest[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(SlotsRequest, response.body), headers: response.headers };
    }));
  }

  public getByContract(companyId: number, contract_id: number, on_date?: boolean): Observable<{ body: SlotsRequest[], headers: HttpHeaders }> {
    let url = this.slotRequest
      .replace(':companyId', companyId + '');

    const filters = {
      'filters[contract][id]': contract_id,
    };

    if (on_date) {
      filters['validity'] = 'on_date';
    }

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<SlotsRequest[]>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(SlotsRequest, response.body), headers: response.headers };
    }));
  }

  public slotRequestChannel(companyId: number): Observable<PusherMessage> {
    return this.pusherService.listen('company_' + companyId, 'slot_request', 2000);
  }
  public slotRequestPrivateChannel(userId: number): Observable<PusherMessage> {
    return this.pusherService.listen('private_' + userId, 'slot_request', 2000);
  }
}