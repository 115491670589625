import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { NgPipesModule, PairsPipe } from 'ngx-pipes';
import { QuillModule } from 'ngx-quill';

import { ValidateFormStepDirective } from '../../../directives/validate-form-step.directive';
import { ListNamesPipe } from '../../../pipes/list-names.pipe';
import { UiModule } from '../../../ui/ui.module';
import { AssigneeManagerComponent } from '../../components/assignee-manager/assignee-manager.component';
import { CompanyNotesComponent } from '../../components/company-notes/company-notes.component';
import { CounterpartiesTableComponent } from '../../components/counterparties-table/counterparties-table.component';
import { CustomStatusMenuComponent } from '../../components/custom-status-menu/custom-status-menu.component';
import { FieldDataSelectorComponent } from '../../components/field-data-selector/field-data-selector.component';
import { FileExtractComponent } from '../../components/file-extract/file-extract.component';
import { FileManagerComponent } from '../../components/file-manager/file-manager.component';
import { LocationPickerComponent } from '../../components/location-picker/location-picker.component';
import { MetadataComponent } from '../../components/metadata/metadata.component';
import { NegotiableGroupComponent } from '../../components/negotiable-group/negotiable-group.component';
import { OrderSummaryComponent } from '../../components/order-summary/order-summary.component';
import { PlaceInfoComponent } from '../../components/place-info/place-info.component';
import { ProductQualityInfoComponent } from '../../components/product-quality-info/product-quality-info.component';
import { ProductionPlanImporterComponent } from '../../components/production-plan-importer/production-plan-importer.component';
import { QualityInputComponent } from '../../components/quality-input/quality-input.component';
import { RepublishMassivelyComponent } from '../../components/republish-massively/republish-massively.component';
import { TeamSignersComponent } from '../../components/team-signers/team-signers.component';
import { TemplateNamePickerComponent } from '../../components/template-name-picker/template-name-picker.component';
import { OrderTypePipe } from '../../pipes/order-type.pipe';
import { QualityPipe } from '../../pipes/quality.pipe';
import { CreateBarterComponent } from '../barters/components/create-barter/create-barter.component';
import { CreateOrderModalComponent } from '../commercial/components/create-order-modal/create-order-modal.component';
import { CreateOrderComponent } from '../commercial/components/create-order/create-order.component';
import { PreviewOrderComponent } from '../commercial/components/preview-order/preview-order.component';
import { ProposalDetailComponent } from '../commercial/components/proposal-detail/proposal-detail.component';
import { AddContractModalComponent } from '../contracts/components/add-contract-modal/add-contract-modal.component';
import { ContractActionsComponent } from '../contracts/components/contract-actions/contract-actions.component';
import { ContractLinkerComponent } from '../contracts/components/contract-linker/contract-linker.component';
import { ContractsSlotsModals } from '../contracts/components/contracts-slots-modals/contracts-slots-modals.component';
import { ContractsTableComponent } from '../contracts/components/contracts-table/contracts-table.component';
import { ApplicationAdditionalsComponent } from '../fintech/components/application-additionals/application-additionals.component';
import { FintechApplicationDataComponent } from '../fintech/components/application-data/application-data.component';
import { FintechApplicationProductComponent } from '../fintech/components/application-product/application-product.component';
import { FintechApplicationSummaryComponent } from '../fintech/components/application-summary/application-summary.component';
import { ApplicationsTableComponent } from '../imported-data/components/applications-table/applications-table.component';
import { ContractMirrorLinkerComponent } from '../imported-data/components/contract-mirror-linker/contract-mirror-linker.component';
import { ContractModalComponent } from '../imported-data/components/contract-modal/contract-modal.component';
import { CpeModalComponent } from '../imported-data/components/cpe-modal/cpe-modal.component';
import { InvoicesModalsComponent } from '../imported-data/components/invoices-modals/invoices-modals.component';
import { LiquidacionesTableComponent } from '../imported-data/components/liquidaciones-table/liquidaciones-table.component';
import { RequestFixationModalComponent } from '../imported-data/components/request-fixation-modal/request-fixation-modal.component';
import { TrucksTableComponent } from '../imported-data/components/trucks-table/trucks-table.component';
import { WaybillModalComponent } from '../imported-data/components/waybill-modal/waybill-modal.component';
import { BatchSlotsTableComponent } from '../slots/components/batch-slots-table/batch-slots-table.component';
import { SlotHistoryComponent } from '../slots/components/slot-history/slot-history.component';
import { SlotsBatchContractRequestFormComponent } from '../slots/components/slots-batch-contract-request-form/slots-batch-contract-request-form.component';
import { SlotsBatchRequestFormComponent } from '../slots/components/slots-batch-request-form/slots-batch-request-form.component';
import { LinkToContractComponent } from '../slots/components/slots-linker/link-to-contract/link-to-contract.component';
import { LinkToNegotiationComponent } from '../slots/components/slots-linker/link-to-negotiation/link-to-negotiation.component';
import { SlotsLinkerComponent } from '../slots/components/slots-linker/slots-linker.component';
import { SlotsModalsComponent } from '../slots/components/slots-modals/slots-modals.component';
import { SlotsRequestsTableComponent } from '../slots/components/slots-requests-table/slots-requests-table.component';
import { StopStatusComponent } from '../slots/components/stop-status/stop-status.component';
import { ViewBatchModalComponent } from '../slots/components/view-batch-modal/view-batch-modal.component';
import { FunderLineModalComponent } from '../fintech/components/funder-line-modal/funder-line-modal.component';

@NgModule({
  declarations: [
    ListNamesPipe,
    CreateBarterComponent,
    CreateOrderComponent,
    CreateOrderModalComponent,
    InvoicesModalsComponent,
    ValidateFormStepDirective,
    LocationPickerComponent,
    ProductQualityInfoComponent,
    ProposalDetailComponent,
    QualityInputComponent,
    NegotiableGroupComponent,
    WaybillModalComponent,
    CpeModalComponent,
    SlotsModalsComponent,
    SlotsLinkerComponent,
    ContractModalComponent,
    OrderTypePipe,
    PreviewOrderComponent,
    TemplateNamePickerComponent,
    CounterpartiesTableComponent,
    QualityPipe,
    OrderSummaryComponent,
    RepublishMassivelyComponent,
    SlotsBatchRequestFormComponent,
    TeamSignersComponent,
    FieldDataSelectorComponent,
    FileManagerComponent,
    FileExtractComponent,
    MetadataComponent,
    ApplicationsTableComponent,
    LiquidacionesTableComponent,
    AssigneeManagerComponent,
    PlaceInfoComponent,
    CustomStatusMenuComponent,
    FintechApplicationSummaryComponent,
    FunderLineModalComponent,
    FintechApplicationDataComponent,
    FintechApplicationProductComponent,
    ContractLinkerComponent,
    ContractMirrorLinkerComponent,
    ContractActionsComponent,
    ContractsTableComponent,
    RequestFixationModalComponent,
    AddContractModalComponent,
    ContractsSlotsModals,
    LinkToContractComponent,
    LinkToNegotiationComponent,
    TrucksTableComponent,
    ApplicationAdditionalsComponent,
    SlotsBatchContractRequestFormComponent,
    ProductionPlanImporterComponent,
    SlotsRequestsTableComponent,
    BatchSlotsTableComponent,
    SlotHistoryComponent,
    ViewBatchModalComponent,
    CompanyNotesComponent,
    StopStatusComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          // ['blockquote', 'code-block'],

          // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction

          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean']                                         // remove formatting button
        ]
      }
    }),
    UiModule,
    NgPipesModule,
    RouterModule,
    ClipboardModule
  ],
  exports: [
    CreateOrderComponent,
    CreateOrderModalComponent,
    CreateBarterComponent,
    InvoicesModalsComponent,
    ValidateFormStepDirective,
    LocationPickerComponent,
    ProductQualityInfoComponent,
    ProposalDetailComponent,
    QualityInputComponent,
    NegotiableGroupComponent,
    WaybillModalComponent,
    CpeModalComponent,
    SlotsModalsComponent,
    SlotsLinkerComponent,
    ContractModalComponent,
    LiquidacionesTableComponent,
    ListNamesPipe,
    OrderTypePipe,
    PreviewOrderComponent,
    TemplateNamePickerComponent,
    CounterpartiesTableComponent,
    QualityPipe,
    OrderSummaryComponent,
    RepublishMassivelyComponent,
    SlotsBatchRequestFormComponent,
    TeamSignersComponent,
    FieldDataSelectorComponent,
    FileManagerComponent,
    FileExtractComponent,
    MetadataComponent,
    AssigneeManagerComponent,
    PlaceInfoComponent,
    CustomStatusMenuComponent,
    FintechApplicationSummaryComponent,
    FunderLineModalComponent,
    FintechApplicationDataComponent,
    FintechApplicationProductComponent,
    ContractLinkerComponent,
    ContractMirrorLinkerComponent,
    ContractActionsComponent,
    ContractsTableComponent,
    RequestFixationModalComponent,
    AddContractModalComponent,
    ApplicationsTableComponent,
    ContractsSlotsModals,
    LinkToContractComponent,
    LinkToNegotiationComponent,
    TrucksTableComponent,
    SlotsBatchContractRequestFormComponent,
    ProductionPlanImporterComponent,
    SlotsRequestsTableComponent,
    BatchSlotsTableComponent,
    SlotHistoryComponent,
    ViewBatchModalComponent,
    CompanyNotesComponent,
    StopStatusComponent
  ],
  providers: [
    PairsPipe,
    ListNamesPipe
  ]
})
export class SharedModule { }
