import { JSONPath } from "jsonpath-plus";

import { Currency } from "../../../../models/currency.model";
import { FintechAdditional, FintechApplication } from "./fintech-application.model";

type DataType = 'PRODUCTION_PLAN' | 'ADDITIONAL_ACTIVITIES' |
  'COMPANY_FILE' | 'COMPANY' | 'APPROVERS' | 'FISCAL_ID' |
  'TEXT' | 'TEXTAREA' | 'BOOLEAN' | 'DATE' | 'DATE_RANGE' | 'NUMBER' |
  'PRICE' | 'COMMITTEE' | 'SIGNERS' | 'SELECT' |
  'FORM_GROUP' | 'FILE_EXTRACT';

export type AIExtractorProviders = 'TEXTRACT' | 'DOCUMENT_AI';

export class DataColumn {
  uuid: string;
  slug: string;
  key: string;
  type: DataType;
  order: number;
  /**
   * The way some attributes impact an input depends on the input type,
   * impacting different input types in different ways.
   */
  attr?: WorkFlowDataFieldAttr;

  constructor(data: Partial<DataColumn> = {}) {
    Object.assign(this, data);
  }
}
export class WorkflowDataValue {
  slug: string;
  slug_suffix?: string;
  label?: string;
  type?: DataType;

  readonly?: boolean;
  hidden?: boolean;

  value: any;
  /**
   * For WorkflowDataValue.type === 'FORM_GROUP' only.
   * Will be grouped by slug.
   */
  member?: { slug: string; value: any };

  constructor(data: Partial<WorkflowDataValue> = {}) {
    Object.assign(this, data);
  }
}

class WorkFlowDataFieldAttr {
  /**
   * Hint for expected file type in file upload controls.
   * Types: 'COMPANY_FILE'
   */
  accept?: string;
  /**
   * Maximum number of selectable items.
   * Types: 'COMPANY_FILE', 'COMPANY'
   */
  maxItems?: number;
  /**
   * Maximum file size in bytes.
   * Types: 'COMPANY_FILE'
   */
  maxSize?: number;
  /**
   * Types: 'COMPANY_FILE'
   */
  autocomplete?: "on" | "off";
  /**
  * Maximum value.
  * Types: 'PRICE'
  */
  max?: number;
  /**
   * Maximum length (number of characters) of value.
   * Types: 'TEXT', 'TEXTAREA'
   */
  maxlength?: number;
  /**
   * Minimum value.
   * Types: 'PRICE'
   */
  min?: number;
  /**
   * Text slug hat appears in the form control when it has no value set.
   * Types: Almost all
   */
  placeholder?: string;
  /**
   * Initial value.
   * Types: Almost all
   */
  value?: any;
  /**
   * Included activities.
   * Types: 'COMPANY'
   */
  activities?: number[];
  /**
   * The granularity that the value must adhere to.
   * Types: 'NUMBER'
   */
  step?: number;
  /**
   * Signers from this company (default: applicant).
   * Supports ID and JSON Path of application.
   * Types: 'SIGNERS'
   */
  companyId?: number | string;

  /**
   * Types: 'SELECT'
   */
  options?: (string | { label: string; value: any })[];

  hide?: string[];

  fileMode?: 'AUTO' | 'ONLY' | 'NO';

  /**
   * Types: 'PRODUCTION_PLAN'
   */
  center?: { lat: number; lng: number; };
  zoom?: number;
  acceptedProducts?: number[];
  extras?: any[];

  currencies?: Currency[];

  /**
   * JSONPath to the array object. Actual object available at
   * WorkFlowDataField.iterable().
   */
  iterablePath?: string;

  disableAmount?: boolean;
}

class AIParamsTextract {
  Text: string;
  Alias: string;
  Pages: any[];
}

class AIParamsDocumentAI {
  label: string;
}

class AIPrompts {
  params: (AIParamsTextract | AIParamsDocumentAI)[];
  provider: AIExtractorProviders;
}

export class WorkFlowDataField {
  /** Agree's internal unique identifier. */
  readonly id?: string;

  /**
   * Allows UI mapping to specific legends.
   */
  readonly slug: string;

  readonly label?: string;

  readonly slug_suffix?: string;

  /**
   * Unique identifying term.
   * Or key template literal form this.type === 'FORM_GROUP'.
   */
  readonly key: string;

  readonly type: DataType;

  /** A value is required or must be check for the form to be submittable. */
  required?: boolean = false;

  /** Optional field help block. */
  readonly help?: string;

  readonly if?: FintechAdditional;

  readonly restricted?: 'PERSON' | 'COMPANY';

  /**
   * The way some attributes impact an input depends on the input type,
   * impacting different input types in different ways.
   */
  attr?: WorkFlowDataFieldAttr;

  order?: number;

  prompts?: AIPrompts[];

  visibilities?: any[];

  private _iterable: any[];

  public iterable(application: FintechApplication): any[] {
    if (this.attr?.iterablePath && !this._iterable) this._iterable = JSONPath({ path: this.attr.iterablePath, json: application });
    return this._iterable;
  }

  constructor(data: Partial<WorkFlowDataField> = {}) {
    Object.assign(this, data);
  }
}