<contract-modal #contractModal [company]="company"></contract-modal>

<contract-linker #contractLinker [company]="company"></contract-linker>

<contract-mirror-linker
  #contractMirrorLinker
  [company]="company"
></contract-mirror-linker>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table
        class="table table-condensed"
        sortable
        #tableSortable="tableSortable"
        defaultSortBy="{{ defaultSortBy }}"
        [variableInUrl]="!predefinedFilters"
        AgMultiselection
        [(ngModel)]="selected"
      >
        <thead>
          <tr class="tr-align-center">
            <th
              class="sap micro"
              *ngIf="enableSelection && contracts?.length > 0"
            >
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              {{ "GLOBAL.DATE" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="date"
                [dateRange]="true"
                alignment="left"
                [filters]="filters"
                key="filters[date]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th sortBy="reference" *ngIf="!hideColumns.includes('reference')">
              {{ "GLOBAL.CONTRACT" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[reference]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th class="sap">{{ "GLOBAL.MIRROR_CONTRACT" | translate }}</th>
            <th sortBy="product.name" *ngIf="!hideColumns.includes('product')">
              {{ "GLOBAL.PRODUCT" | translate }}
            </th>
            <th sortBy="seller.name" *ngIf="!hideColumns.includes('seller')">
              {{ "ORDER_SUMMARY.SELLER" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[seller.name]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th sortBy="buyer.name" *ngIf="!hideColumns.includes('buyer')">
              {{ "ORDER_SUMMARY.BUYER" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[buyer.name]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th sortBy="broker.name" *ngIf="!hideColumns.includes('brokers')">
              {{ "BARTERS.BROKERS" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[broker.name]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
            sortBy="delivery.locations.0.name"
            *ngIf="!hideColumns.includes('delivery.locations')">
              <abbr title
              tooltip="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}"
              container="body"
              [adaptivePosition]="false">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</abbr>
              <!-- {{ "GLOBAL.DELIVERY_PLACES" | translate }} -->
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[delivery.locations.0.name]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
            class="sap dlb drb"
            sortBy="quantity.value"
            *ngIf="!hideColumns.includes('quantity')">
              <abbr title
              tooltip="{{ 'GLOBAL.QUANTITY' | translate }}"
              container="body"
              [adaptivePosition]="false">{{ "GLOBAL.QUANTITY_ABBR" | translate }}</abbr>
              <column-filter
                *ngIf="!predefinedFilters"
                type="number"
                alignment="left"
                [filters]="filters"
                key="filters[quantity.value]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
              class="sap text-center"
              sortBy="delivery.date_from"
              *ngIf="!hideColumns.includes('contract.delivery')"
            >
              {{ "GLOBAL.PERIOD" | translate }}
            </th>
            <th
              class="sap"
              sortBy="price.value"
              *ngIf="!hideColumns.includes('price')"
            >
              {{ "GLOBAL.PRICE" | translate }}
              <ng-container *ngIf="company.market.quantity_units.length === 1"
                >/{{ company.market.quantity_units[0].abbrev }}</ng-container
              >
              <column-filter
                *ngIf="!predefinedFilters"
                type="number"
                [filters]="filters"
                key="filters[price.value]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
              class="sap text-center"
              *ngIf="!hideColumns.includes('slots')"
            >
              {{ "COMPANY.SLOTS" | translate }}
            </th>
            <th
              class="sap text-center"
              *ngIf="!hideColumns.includes('fixations_data')"
            >
              {{ "CONTRACTS.FIXED" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="boolean"
                [filters]="filters"
                key="filters[fixed]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <!-- <th class="sap text-center" *ngIf="!hideColumns.includes('fixations_data.percentage')">{{ 'CONTRACTS.FIXED'|translate }}</th> -->
            <th
              class="sap text-center dlb"
              *ngIf="!hideColumns.includes('unloads')"
            >
              <abbr
                tooltip="{{ 'COMPANY_TRADED_VIEW.UNLOADED' | translate }}"
                container="body"
                [adaptivePosition]="false"
                ><span class="material-symbols-rounded">local_shipping</span></abbr
              >
              <column-filter
                *ngIf="!predefinedFilters"
                type="boolean"
                [filters]="filters"
                key="filters[applied]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
              class="sap text-center dlb"
              *ngIf="!hideColumns.includes('balance')"
            >
              <abbr
                tooltip="{{ 'COMPANY_TRADED_VIEW.LIQUIDATED' | translate }}"
                container="body"
                [adaptivePosition]="false"
                ><span class="material-symbols-rounded">check</span></abbr
              >
              <column-filter
                *ngIf="!predefinedFilters"
                type="boolean"
                [filters]="filters"
                key="filters[liquidated]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
              *ngIf="!hideColumns.includes('actions')"
              class="sap hidden-xs"
            ></th>
            <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr
                tooltip="{{ 'GLOBAL.SOURCE' | translate }}"
                container="body"
                [adaptivePosition]="false"
              >
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let contract of contracts | orderBy : tableSortable.sortBy; let contractIndex = index"
          >
            <tr
            [class.clickable]="selectable && !contractSelected"
            [class.selected]="selectable && contractSelected?.id === contract.id"
            (click)="selectable && selectedContract(contract)"
            class="tr-align-center first-row">
              <td class="sap micro" rowspan="2" *ngIf="enableSelection">
                <input
                [AgCheckbox]="contract"
                type="checkbox"
                name="chk-{{ contract.id }}"
                class="show-onhover no-margin">
              </td>
              <td
                class="sap no-vertical-padding"
                *ngIf="!hideColumns.includes('date')"
              >
                <ag-timestamp
                  [date]="contract.date"
                  [showTime]="false"
                ></ag-timestamp>
              </td>
              <td
                class="small no-vertical-padding"
                *ngIf="!hideColumns.includes('reference')"
              >
                <samp
                  [tooltip]="contract.warnings?.length ? warnTemplate : null"
                  [adaptivePosition]="false"
                >
                  <a *ngIf="!selectable"
                    [routerLink]="
                      '/company/' + company.id + '/contracts/' + contract.id
                    "
                    >{{ contract.reference }}</a
                  >
                  <span *ngIf="selectable">
                    {{contract.reference}}
                  </span>
                  <span
                    *ngIf="contract.warnings?.length"
                    class="differences material-symbols-rounded text-warning no-select hidden-xs"
                    >warning</span
                  >
                  <ng-template #warnTemplate>
                    <div class="text-left">
                      <p class="small">
                        {{ "IMPORTER.WARNING.TITLE" | translate }}
                      </p>
                      <span
                        *ngFor="let d of contract.warnings; let last = last"
                      >
                        {{ "IMPORTER.WARNING." + d | translate }}
                        <ng-container *ngIf="!last"> ,</ng-container>
                      </span>
                    </div>
                  </ng-template>
                </samp>
              </td>

              <!-- CONTRACT MIRROR LINKER START -->
              <td
                class="small sap text-left"
                rowspan="2"
                *ngIf="!hideColumns.includes('mirror_contract')"
              >
                <ng-container *ngIf="contract.linked_contracts.length > 0">
                  <div *ngFor="let c of contract.linked_contracts">
                    <samp
                      ><a clickStop (click)="contractModal.show(c.id)"
                        >#{{ c.reference }}</a
                      ></samp
                    >
                    <button
                      type="button"
                      class="show-onhover btn btn-link btn-xs"
                      title="{{ 'LINKER.UNLINK' | translate }}"
                      (onConfirm)="unlink(contract, c)"
                    >
                      <i class="material-symbols-rounded">link_off</i>
                    </button>
                  </div>
                </ng-container>
                <samp *ngIf="contract.contract_type.id === 2" class="text-muted"
                  >Espejo</samp
                >

                <button
                type="button"
                  *ngIf="
                    contract.linked_contracts.length === 0 &&
                    contract.contract_type.id !== 2
                  "
                  class="show-onhover btn btn-link btn-xs"
                  title="{{ 'LINKER.TITLE' | translate }}"
                  (click)="contractMirrorLinker.link(contract)"
                >
                  <i class="material-symbols-rounded">link</i>
                </button>
              </td>

              <!-- CONTRACT MIRROR LINKER ENDs-->
              <td
                class="no-vertical-padding"
                *ngIf="!hideColumns.includes('product')"
              >
                <ng-container *ngIf="contract.product?.name; else none">{{
                  contract.product.name
                }}</ng-container>
              </td>

              <td
                class="actor"
                *ngIf="!hideColumns.includes('seller')"
                rowspan="2"
              >
                <company-info
                  [company]="contract.seller"
                  [activity]="false"
                ></company-info>
              </td>
              <td
                class="actor"
                *ngIf="!hideColumns.includes('buyer')"
                rowspan="2"
              >
                <company-info
                  [company]="contract.buyer"
                  [activity]="false"
                ></company-info>
              </td>
              <td
                class="actor"
                *ngIf="!hideColumns.includes('brokers')"
                rowspan="2"
              >
                <company-info
                  *ngFor="let broker of contract.brokers"
                  [company]="broker"
                  [activity]="false"
                >
                </company-info>
              </td>
              <td
                class="small"
                rowspan="2"
                *ngIf="!hideColumns.includes('delivery.locations')"
              >
                {{ contract.delivery.locations | locationString }}
              </td>
              <td
                class="sap text-right dlb drb"
                rowspan="2"
                *ngIf="!hideColumns.includes('quantity')"
              >
                <quantity-control
                  [compact]="true"
                  [quantity]="contract.quantity"
                ></quantity-control>
              </td>
              <td
                class="sap"
                rowspan="2"
                *ngIf="!hideColumns.includes('contract.delivery')"
              >
                <date-range
                  [from]="contract.delivery.date_from"
                  [to]="contract.delivery.date_to"
                ></date-range>
              </td>
              <td
                class="sap"
                rowspan="2"
                *ngIf="!hideColumns.includes('price')"
              >
                <div class="price-box booked-bg">
                  <div
                    class="type"
                    [class.buy]="contract.buyer.id === company.id"
                    [class.sell]="contract.seller.id === company.id"
                  >
                    {{
                      (contract.buyer.id === company.id
                        ? "COMPANY_TRADED_VIEW.BOUGHT"
                        : contract.seller.id === company.id
                        ? "COMPANY_TRADED_VIEW.SOLD"
                        : ""
                      ) | translate
                    }}
                  </div>
                  <div class="value">
                    <price-display
                      [price]="contract.price"
                      [market]="company.market"
                      [withoutYear]="true"
                    >
                    </price-display>
                  </div>
                </div>
              </td>
              <th class="sap" rowspan="2" class="sap text-center" *ngIf="!hideColumns.includes('slots')">
                <ag-slots-details
                [batches]="contract.batches"></ag-slots-details>
              </th>
              <td
                class="sap"
                rowspan="2"
                *ngIf="!hideColumns.includes('fixations_data')"
              >
                <div class="flex-center-distributed fixation-data">
                  <div *ngIf="contract.fixations_data?.period.to">
                    <div class="micro text-muted">
                      {{ "CONTRACTS.FIXATION_DEADLINE" | translate }}
                    </div>
                    <date-md
                      class="small"
                      [date]="contract.fixations_data.period.to"
                      [style]="true"
                    ></date-md>
                  </div>
                  <progress-circle
                    *ngIf="contract.price.type === 'to_be_fixed'"
                    label="{{ 'CONTRACTS.FIXED' | translate }}"
                    [progress]="contract.fixations_data?.percentage"
                  ></progress-circle>
                </div>
              </td>
              <!-- <td class="sap text-center" *ngIf="!hideColumns.includes('fixations_data.percentage')">
              </td> -->
              <td
                class="sap text-center dlb"
                rowspan="2"
                *ngIf="!hideColumns.includes('unloads')"
              >
                <!-- <progress-circle label="{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}"
                  [progress]="contract.summary.applied_volume ? contract.summary.applied_volume.value/contract.quantity.value : 0">
                </progress-circle> -->
                <progress-circle
                label="{{ 'COMPANY_TRADED_VIEW.UNLOADED' | translate }}"
                [value]="contract.applied.value"
                [unit]="contract.quantity.unit.abbrev"
                [progress]="contract.applied.value ? contract.applied.value / contract.quantity.value : 0"></progress-circle>
              </td>
              <td
                *ngIf="!hideColumns.includes('balance')"
                rowspan="2"
                class="sap text-center dlb"
              >
                <progress-circle
                  label="{{ 'COMPANY_TRADED_VIEW.LIQUIDATED' | translate }}"
                  [progress]="
                    contract.summary.liquidated_volume / contract.quantity.value
                  "
                ></progress-circle>
              </td>
              <td
                *ngIf="!hideColumns.includes('actions')"
                class="sap hidden-xs"
                rowspan="2"
              >
                <ag-contract-actions
                  [company]="company"
                  [contract]="contract"
                  [right]="true"
                ></ag-contract-actions>
              </td>
              <td
                *ngIf="!hideColumns.includes('source')"
                rowspan="2"
                class="sap"
              >
                <span
                  *ngIf="contract.import_channel_id; let source"
                  class="micro text-muted"
                  >{{ "IMPORTER.SOURCE." + source | translate }}</span
                >
              </td>
            </tr>
            <tr 
            class="tr-align-center second-row">
              <td
                [attr.colspan]="
                  4 -
                  hiddenColumnsCount([
                    'date',
                    'reference',
                    'negotiation',
                    'product'
                  ])
                "
                *ngIf="
                  !hideColumns.includes('labels') && contract.labels?.length
                "
                class="td-empty no-vertical-padding"
              >
                <span
                  *ngFor="let label of contract.labels"
                  class="ag-label"
                  [style.backgroundColor]="label.color"
                  >{{ label.name }}</span
                >
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <!-- No data -->
    <div class="no-data" *ngIf="contracts && contracts.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ "CONTRACTS.NO_DATA" | translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
