import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Account } from '../../../../../auth/models/account.model';
import { User } from '../../../../../auth/models/user.model';
import { Company } from '../../../../../models/company.model';
import { CompanyService } from '../../../../../services/company.service';
import { FintechApplication } from '../../models/fintech-application.model';
import { Qualification } from '../../models/qualification.model';

@Component({
  selector: 'ag-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss']
})
export class FintechApplicationSummaryComponent implements OnInit, OnDestroy {

  @Input() public application: FintechApplication;
  @Input() public company: Company;
  @Input() public user: User;
  @Input() public disabled: boolean;

  public account: Account;
  /** The language currently used. */
  public currentLang: string;
  public qualification: Qualification;

  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) this.account = account;
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
