<contract-modal #contractModal
[company]="company"></contract-modal>

<waybill-modal #waybillModal
[company]="company"></waybill-modal>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table table-condensed table-hover" sortable #tableSortable="tableSortable" [variableInUrl]="!predefinedFilters"
      AgMultiselection [(ngModel)]="selected">
        <thead>
          <tr class="tr-align-center">
            <th class="sap micro"
            *ngIf="enableSelection &&
            trucks?.length > 0">
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              {{ 'GLOBAL.ISSUED'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="date"
              alignment="left"
              [filters]="filters"
              key="filters[date]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="sap" sortBy="waybill.waybill_number" *ngIf="!hideColumns.includes('waybill.waybill_number')">
              {{ 'TRUCKS.WAYBILL'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[waybill.waybill_number]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="sap" sortBy="contract_reference" *ngIf="!hideColumns.includes('contract_reference')">
              {{ 'GLOBAL.CONTRACT'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[contracts.*.reference]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="waybill.product.name" *ngIf="!hideColumns.includes('waybill.product')">
              {{ 'GLOBAL.PRODUCT'|translate }}
              <!-- <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[waybill.product.name]"
              (onChange)="changeFilter($event)"></column-filter> -->
            </th>
            <th sortBy="waybill.actors.holder.name" *ngIf="!hideColumns.includes('waybill.actors.holder')">
              {{ 'TRUCKS.ACTORS.HOLDER'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[waybill.actors.holder.name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th sortBy="waybill.actors.destination.name" *ngIf="!hideColumns.includes('waybill.actors.destination')">
              {{ 'GLOBAL.RECEIVER'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[waybill.actors.destination.name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="dlb" sortBy="waybill.origin.location.name" *ngIf="!hideColumns.includes('waybill.origin.location')">
              {{ 'GLOBAL.ORIGIN'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              alignment="left"
              [filters]="filters"
              key="filters[waybill.origin.location.name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th *ngIf="!(hideColumns.includes('waybill.origin.location') || hideColumns.includes('waybill.destination.location'))"></th>
            <th class="drb" sortBy="waybill.destination.location.name" *ngIf="!hideColumns.includes('waybill.destination.location')">
              {{ 'TRUCKS.DESTINATION'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="string"
              [filters]="filters"
              key="filters[waybill.destination.location.name]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="sap" sortBy="waybill.destination.date" *ngIf="!hideColumns.includes('waybill.destination.date')">
              {{ 'TRUCKS.UNLOADED'|translate }}
              <column-filter
              *ngIf="!predefinedFilters"
              type="date"
              [filters]="filters"
              key="filters[waybill.destination.date]"
              (onChange)="changeFilter($event)"></column-filter>
            </th>
            <th class="text-right" sortBy="quantity_applied.value" *ngIf="!hideColumns.includes('quantity_applied')">{{ 'TRUCKS.APPLIED'|translate }}</th>
            <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr tooltip="{{ 'GLOBAL.SOURCE'|translate }}"
              container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-align-center" *ngFor="let truck of trucks|orderBy: tableSortable.sortBy;">
            <td class="sap micro"
            *ngIf="enableSelection">
              <input
              [AgCheckbox]="truck" type="checkbox"
              class="show-onhover no-margin">
            </td>
            <td class="sap" *ngIf="!hideColumns.includes('date')">
              <ag-timestamp [date]="truck.date" [showTime]="false"></ag-timestamp>
            </td>
            <td class="sap small" *ngIf="!hideColumns.includes('waybill.waybill_number')">
              <samp
              [tooltip]="truck.warnings?.length ? warnTemplate : null"
              [adaptivePosition]="false">
                <a (click)="waybillModal.show(truck.waybill)">{{ truck.waybill?.waybill_number }}</a>
                <span *ngIf="truck.warnings?.length"
                class="differences material-symbols-rounded text-warning no-select hidden-xs">warning</span>
                <ng-template #warnTemplate>
                  <div class="text-left">
                    <p class="small">{{ 'IMPORTER.WARNING.TITLE'|translate }}</p>
                    <span *ngFor="let d of truck.warnings; let last = last">
                      {{ 'IMPORTER.WARNING.'+d|translate }}
                      <ng-container *ngIf="!last"> ,</ng-container>
                    </span>
                  </div>
                </ng-template>
              </samp>
            </td>
            <td class="sap dlb small"  *ngIf="!hideColumns.includes('contract_reference')">
              <div *ngFor="let contract of truck.contracts">
                <samp><a (click)="contractModal.show(contract.id)">{{ contract.reference }}</a></samp>
              </div>
            </td>
            <td *ngIf="!hideColumns.includes('waybill.product')">{{ truck.waybill?.product?.name }}</td>
            <td *ngIf="!hideColumns.includes('waybill.actors.holder')"><company-info [company]="truck.waybill?.actors.holder" [activity]="false"></company-info></td>
            <td *ngIf="!hideColumns.includes('waybill.actors.destination')"><company-info [company]="truck.waybill?.actors.destination" [activity]="false"></company-info></td>
            <td class="dlb small" *ngIf="!hideColumns.includes('waybill.origin.location')">{{ truck.waybill?.origin.location.name }}</td>
            <td class="sap micro" *ngIf="!(hideColumns.includes('waybill.origin.location') || hideColumns.includes('waybill.destination.location'))"><i class="material-symbols-rounded">arrow_right_alt</i></td>
            <td class="drb small" *ngIf="!hideColumns.includes('waybill.destination.location')">{{ truck.waybill?.destination.location.name }}</td>
            <td class="sap micro text-muted" *ngIf="!hideColumns.includes('waybill.destination.date')"><date-md [date]="truck.waybill?.destination.date"></date-md></td>
            <td class="sap text-right" [tooltip]="weightTemplate" placement="left" container="body" *ngIf="!hideColumns.includes('quantity_applied')">
              {{ truck.quantity_applied.value|number:'1.0-3':currentLang }} <small class="text-muted">{{ truck.quantity_applied.unit.abbrev }}</small>
            </td>
            <ng-template #weightTemplate>
              <div class="text-right">
                <div [innerHtml]="'TRUCKS.NET_KG'|translate:{quantity: (truck.waybill?.destination.weight.net|number:'1.0-0':currentLang)}"></div>
                <div [innerHtml]="'TRUCKS.GROSS_KG'|translate:{quantity: (truck.waybill?.destination.weight.gross|number:'1.0-0':currentLang)}"></div>
                <div [innerHtml]="'TRUCKS.TARE_KG'|translate:{quantity: (truck.waybill?.destination.weight.tare|number:'1.0-0':currentLang)}"></div>
              </div>
            </ng-template>
            <td *ngIf="!hideColumns.includes('source')" class="sap">
              <span *ngIf="truck.import_channel_id; let source"
              class="micro text-muted">{{ 'IMPORTER.SOURCE.'+source|translate }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="no-data" *ngIf="trucks && trucks.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'TRUCKS.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>