import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { User } from '../../../../../auth/models/user.model';
import { Company } from '../../../../../models/company.model';
import { EnvelopeSigner } from '../../../../../models/envelope.model';
import { SignersService } from '../../../../../services/signers.service';
import { FilePreviewComponent } from '../../../../../ui/components/file-preview/file-preview.component';
import { FileManagerService } from '../../../../services/file-manager.service';
import { FintechApplication } from '../../models/fintech-application.model';
import { WorkflowDataValue } from '../../models/work-flow-data-field.model';
import { FintechApplicationsService } from '../../services/fintech-applications.service';

@Component({
  selector: 'ag-application-data',
  templateUrl: './application-data.component.html',
  styleUrls: ['./application-data.component.scss']
})
export class FintechApplicationDataComponent implements OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() set application(a: FintechApplication) {
    this._application = a;
    this.parse();
  };
  get application(): FintechApplication {
    return this._application;
  }

  @Input() public company: Company;
  @Input() public user: User;
  @Input() public readonly: boolean;

  public data: {
    member?: { slug: string; value: any; };
    files?: WorkflowDataValue[];
    fields?: { [dataFieldKey: string]: WorkflowDataValue; };
  }[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private _application: FintechApplication;
  private subscriptions: Subscription[] = [];

  constructor(
    private fileManagerService: FileManagerService,
    private fintechApplicationsService: FintechApplicationsService,
    private signersService: SignersService
  ) { }

  private filterObject(obj: { [dataFieldKey: string]: WorkflowDataValue; }, filterFn): { [dataFieldKey: string]: WorkflowDataValue; } {
    const entries = Object.entries(obj).filter(filterFn);
    // return Object.fromEntries(c.filter(fn))
    const newObj = {};
    for (const [key, value] of entries) {
      newObj[key] = value;
    }
    return newObj;
  }

  private parse(): void {
    this.data = [];

    this.data.push({
      files: Object.values(this.filterObject(this.application.data, (entry) => {
        return entry[1] && !entry[1].hidden && entry[1].value !== null && entry[1].type === 'COMPANY_FILE' && !entry[1].member;
      })),
      fields: this.filterObject(this.application.data, (entry) => {
        return entry[1] && !entry[1].hidden && entry[1].value !== null && entry[1].type !== 'COMPANY_FILE' && !entry[1].member;
      })
    });

    const mappedMemberSlugs = [];
    const groupedData = [];

    for (const key in this.application.data) {
      const element = this.application.data[key];

      if (element && element.member && !mappedMemberSlugs.includes(element.member.slug)) {
        const member = element.member;
        mappedMemberSlugs.push(member.slug);

        // Group data by member slug
        groupedData.push({
          files: Object.values(this.filterObject(this.application.data, (entry) => {
            return entry[1].type === 'COMPANY_FILE' && entry[1].member?.slug === member.slug;
          })),
          fields: this.filterObject(this.application.data, (entry) => {
            return entry[1].type !== 'COMPANY_FILE' && entry[1].member?.slug === member.slug;
          }),
          member: member
        });
      }
    }

    this.data = [...this.data, ...groupedData.sort((a, b) => a.member.slug.localeCompare(b.member.slug))]; // Sort grouped data by slug
  }

  public isDate(date: Date): boolean {
    return typeof date?.getMonth === 'function';
  }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(fileId: string, name?: string): void {
    this.processing = true;
    const observable = this.fileManagerService.getFile(this.company.id, fileId);

    this.subscriptions.push(observable.subscribe(response => {
      this.filePreviewer.preview(observable, response.filename, response.size, response.url);
      this.processing = false;
    }));
  }

  public update(): void {
    setTimeout(() => {
      // Hack to reflect model change
      this.processing = true;
      this.subscriptions.push(this.fintechApplicationsService.update(this.company.id, this.application.id, this.application.data).subscribe(response => {
        this.processing = false;
      }));
    });
  }

  /** Opens the [[EnvelopeSigner|signer's]] power document. */
  public getSignerFile(signer: EnvelopeSigner): void {
    if (signer && signer.file) {
      this.processing = true;
      const observable = this.signersService.getFile(this.application.applicant.id, signer.id, signer.file);

      this.subscriptions.push(observable.subscribe(response => {
        this.processing = false;
        this.filePreviewer.preview(observable, response.name, undefined, response.url);
      }));
    }
  }

  /**
   * Compare function for
   * [[https://angular.io/api/common/KeyValuePipe|keyvalue]] pipe to preserve
   * original array order.
   */
  public originalOrder(): number {
    return 0;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
