<ag-application-product #appProduct
[application]="application"
[company]="company"></ag-application-product>

<div class="row">
  <div class="col-sm-6">
    <p class="small">
      {{ 'FINTECH.REQUESTED_BY'|translate }} <b [class.highlighted-text]="user?.id === application.user.id"
        title="{{ application.user.email }}">{{ application.user.name }} {{ application.user.last_name}}</b>
      &nbsp;<date-md class="text-muted small" [date]="application.created_at"></date-md>
      <br>
        {{ 'FINTECH.MANAGED_BY'|translate }} <b [class.highlighted-text]="company?.id === application.company.id">{{ application.company.name }}</b>
      <!-- <ng-container *ngIf="application.expired"><br>
        {{ 'FINTECH.STATUS.EXPIRED'|translate }}: <date-md [date]="application.expired"></date-md>
      </ng-container> -->
    </p>
  </div>
  <div class="col-sm-6" *ngIf="application.product">
    <p class="small">
      {{ 'GLOBAL.PRODUCT'|translate }}: 
      <a 
      [class.disabled]="disabled"
      title="{{ 'GLOBAL.EDIT'|translate }}"
      *ngIf="account?.is.financial &&
      company.id === application.product.funder.company.id &&
      application.status === 0; else productName"
      (click)="appProduct.edit()"><ng-container *ngTemplateOutlet="productName"></ng-container>&nbsp;<i class="material-symbols-rounded hidden-print">edit</i></a>
      <ng-template #productName>
        <b>{{ application.product.line_group || application.product.name }}</b>
        <ng-container *ngIf="application.product.line_group">&nbsp;{{ application.product.name }}</ng-container>
      </ng-template>
      <br>
      {{ 'FINTECH.FUNDER'|translate }}: <b [class.highlighted-text]="company?.id === application.product.funder.company.id">{{ application.product.funder.name }}</b>
    </p>
  </div>
</div>
<div class="row" *ngIf="application.withdrawn">
  <div class="col-xs-12 small">
    <p>
      <span class="text-danger">{{ 'FINTECH.WITHDRAWN_BY'|translate }}</span>&nbsp;
      <b [class.highlighted-text]="user?.id === application.withdrawn.user.id"
      title="{{ application.withdrawn.user.email }}">{{ application.withdrawn.user.name }} {{ application.withdrawn.user.last_name}}</b>
      &nbsp;<date-md class="text-muted small" [date]="application.withdrawn.created_at"></date-md>
      <span *ngIf="application.withdrawn.reason"
      class="material-symbols-rounded text-danger hidden-xs"
      popover="{{ application.withdrawn.reason }}"
      triggers="mouseenter:mouseleave">feedback</span>
    </p>
    <p class="visible-xs text-danger" *ngIf="application.withdrawn.reason">{{ application.withdrawn.reason }}</p>
  </div>
</div>
<div class="row">
  <div class="col-sm-6"
  [class.col-md-3]="application.supplier">
    <table-detail [header]="'FINTECH.APPLICANT'|translate">
      <company-info [company]="application.applicant"
      [activity]="false"
      [commercial-zones]="true"
      [file-manager]="true"></company-info>
    </table-detail>
  </div>
  <div class="col-md-3 col-sm-6" *ngIf="application.supplier">
    <table-detail [header]="'GLOBAL.SUPPLIER'|translate">
      <company-info [company]="application.supplier"
      [activity]="false"></company-info>
    </table-detail>
  </div>
  <div class="col-md-6 col-xs-12">
    <div class="row">
      <div class="col-xs-6">
        <table-detail [header]="(application.product.amount_label || 'BARTERS.AMOUNT')|translate"
        classes="no-margin">
          <div class="flex-center-distributed">
            <ng-container *ngIf="application.status == 0 && application.additionals?.length">
              <ag-application-additionals #appAdditionals
              [application]="application"></ag-application-additionals>
              
              <a 
              [class.disabled]="disabled"
              class="hidden-print"
              title="{{ 'FINTECH.ADDITIONALS.TITLE'|translate }}"
              (click)="appAdditionals.show()"><i class="material-symbols-rounded">calculate</i></a>
            </ng-container>
            <div class="text-right grow">
              <a *ngIf="account?.is.financial &&
              company.id === application.product.funder.company.id &&
              application.status === 0; else approvedAmount"
              [class.disabled]="disabled"
              title="{{ 'GLOBAL.EDIT'|translate }}"
              (click)="appProduct.edit()"><ng-container *ngTemplateOutlet="approvedAmount"></ng-container> <i class="material-symbols-rounded hidden-print">edit</i></a>
              <ng-template #approvedAmount>
                <price *ngIf="application.approved; else approvedPending"
                [unit]="application.approved.unit.name" [value]="application.approved.value"></price>
              </ng-template>
              <div class="text-muted micro"
              *ngIf="application.requested && application.requested.value !== application.approved.value">
                {{ 'FINTECH.REQUESTED'|translate }}:
                <price [unit]="application.requested.unit.name" [value]="application.requested.value"></price>
              </div>
            </div>
          </div>
        </table-detail>
      </div>
      <div class="col-xs-6" *ngIf="application.product?.rate || application.product?.tax_rate">
        <table-detail [header]="'FINTECH.CONDITIONS'|translate"
        classes="no-margin">
          <a *ngIf="account?.is.financial &&
          company.id === application.product.funder.company.id &&
          application.status === 0"
          class="pull-right hidden-print"
          [class.disabled]="disabled"
          title="{{ 'GLOBAL.EDIT'|translate }}"
          (click)="appProduct.edit()"><i class="material-symbols-rounded">edit</i></a>
          <div *ngIf="application.product.rate">
            {{ (application.product.rate_label || 'FINTECH.INTEREST_RATE')|translate }}: {{ application.product.rate|number:'1.2-2':currentLang }}<span class="currency">%</span>
            <sup *ngIf="application.product.rate_help" class="text-muted">[*]</sup>
          </div>
          <div class="text-muted micro"
          *ngIf="application.product.tax_rate">{{ 'FINTECH.VAT'|translate }}:
            {{ application.product.tax_rate|number:'1.0-2':currentLang }}<span class="currency">%</span></div>
        </table-detail>
      </div>
    </div>
    <div class="row" *ngIf="application.product.rate_help">
      <div class="col-xs-12">
        <p class="text-muted micro">[*]: {{ application.product.rate_help }}</p>
      </div>
    </div>
  </div>
</div>

<ng-template #approvedPending>
  <span class="text-muted">{{ 'FINTECH.AMOUNT_PENDING'|translate }}</span>
</ng-template>