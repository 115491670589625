<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 *ngIf="contract" class="modal-title">{{ 'GLOBAL.CONTRACT'|translate }}: <samp>{{ contract.reference }}</samp>
    </h4>
  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="!contract">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <ng-container *ngIf="contract">
      <div class="row">
        <!-- Date -->
        <div class="col-sm-9">
          <h3>
            <ng-container *ngIf="contract.product?.name; else none">{{ contract.product.name }}</ng-container>
            <ag-contract-actions [company]="company" [contract]="contract" container=".modal-body"
              (done)="closeModal()"></ag-contract-actions>
          </h3>
        </div>
        <div class="col-sm-3">
          <table-detail [header]="'GLOBAL.DATE'|translate" [right]="true">
            <date-md [date]="contract.date"></date-md>
          </table-detail>
        </div>
      </div>
      <div class="row">
        <!-- Actors -->
        <div class="col-sm-4">
          <company-table [header]="'ORDER_SUMMARY.SELLER'|translate" header-class="sell" [activity]="false"
            [companies]="[contract.seller]"></company-table>
        </div>
        <div class="col-sm-4">
          <company-table [header]="'ORDER_SUMMARY.BUYER'|translate" header-class="buy" [activity]="false"
            [companies]="[contract.buyer]"></company-table>
        </div>
        <div class="col-sm-4">
          <company-table [header]="'BARTERS.BROKERS'|translate" [activity]="false" [companies]="contract.brokers">
          </company-table>
        </div>
      </div>
      <div class="row">
        <!-- Delivery -->
        <div class="col-sm-4">
          <table-detail [header]="'GLOBAL.DELIVERY_PLACES'|translate">
            <span *ngIf="!contract.delivery.locations || contract.delivery.locations?.length === 0">-</span>
            {{ contract.delivery.locations |locationString }}
            <span class="small help-block" *ngIf="contract.delivery.locations &&
              (contract.delivery.locations.length > 1 || contract.delivery.locations[0]?.zone)">
              {{ 'PROPOSAL.DELIVERY_LOCATIONS.BUYER_OPTION'|translate }}
            </span>
          </table-detail>
        </div>
        <div class="col-sm-4">
          <table-detail [header]="company?.market.configuration.order.business_detail.delivery.range.label">
            <ng-container *ngIf="contract.delivery.date_from && contract.delivery.date_to; else none">
              <date-range [simple]="true" [from]="contract.delivery.date_from" [to]="contract.delivery.date_to">
              </date-range>
            </ng-container>
          </table-detail>
        </div>
        <div class="col-sm-4">
          <table class="table table-detail table-condensed">
            <thead>
              <tr>
                <th>
                  {{ 'GLOBAL.PRICE'|translate }}
                  <ng-container *ngIf="company.market.quantity_units.length === 1">/{{
                    company.market.quantity_units[0].abbrev }}</ng-container>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="price-box booked-bg">
                    <div class="type" [class.buy]="contract.buyer.id === company.id"
                      [class.sell]="contract.seller.id === company.id">{{
                      (contract.buyer.id === company.id ? 'COMPANY_TRADED_VIEW.BOUGHT' : (contract.seller.id ==
                      company.id ? 'COMPANY_TRADED_VIEW.SOLD' : ''))|translate
                      }}</div>
                    <div class="value">
                      <price-display [price]="contract.price" [market]="company.market" [withoutYear]="true">
                      </price-display>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <!-- Quantity -->
        <div class="col-sm-4">
          <table-detail [header]="'GLOBAL.QUANTITY'|translate">
            <quantity-control [quantity]="contract.quantity"></quantity-control>
          </table-detail>
        </div>
        <div class="col-sm-4">
          <table-detail [header]="'GLOBAL.ORIGIN'|translate">
            <span *ngIf="contract.origin?.name; else none">{{ contract.origin.name }}</span>
          </table-detail>
        </div>
        <!-- <div class="col-sm-4">
          <table-detail [header]="'CONTRACTS.TOLERANCE'|translate">
            {{ contract.tolerance ? contract.tolerance : 'PROPOSAL.QUALITY.PLACEHOLDER'|translate }}
          </table-detail>
        </div> -->
      </div>
      <div class="row">
        <div class="col-sm-4">
          <table-detail [header]="'CONTRACTS.PARTIAL_PAYMENT'|translate">
            <span *ngIf="contract.partial_payment; else none">{{ contract.partial_payment }}<span
                class="text-muted">%</span></span>
          </table-detail>
        </div>
      </div>

      <ng-container *ngIf="contract.price.type === 'to_be_fixed'">
        <div class="row">
          <!-- Fixing conditions -->
          <div class="col-xs-12">
            <div class="horizontal-scroller">
              <table-detail [header]="'GLOBAL.FIXING_CONDITIONS'|translate">
                <p>
                  <ng-container *ngIf="contract.price.value; else none">{{ contract.price.value }}</ng-container>
                </p>
                <table class="nested-table" *ngIf="contract.fixing_conditions">
                  <thead>
                    <tr>
                      <th>#</th>
                      <!-- <th *ngIf="contract.fixing_conditions.length > 1">{{ 'GLOBAL.QUANTITY'|translate }}</th> -->
                      <th>{{ 'CONTRACTS.SLATE'|translate }}</th>
                      <th>{{ 'GLOBAL.FIXING_PERIOD'|translate }}</th>
                      <th *ngIf="contract.fixing_conditions.length > 1" class="sap text-center">{{
                        'GLOBAL.DELIVERY_PERIOD'|translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fc of contract.fixing_conditions">
                      <td class="sap small">
                        <samp>{{ fc.order }}</samp>
                      </td>
                      <!-- <td *ngIf="contract.fixing_conditions.length > 1" class="text-right sap">
                        <quantity-control [quantity]="fc.quantity"></quantity-control>
                      </td> -->
                      <td>
                        <b title="{{ fc.slate }}" class="ellipsis">{{ fc.slate }}</b>
                        <div *ngIf="fc.bonus" class="small">(Bonif./Reb.: {{ fc.bonus }})</div>
                      </td>
                      <td class="sap">
                        <ng-container *ngIf="fc.from && fc.to; else none">
                          <date-range [simple]="true" [from]="fc.from" [to]="fc.to"></date-range>
                        </ng-container>
                        <div *ngIf="fc.limit_hour" class="small text-muted">(Hasta: {{ fc.limit_hour }} hs.)</div>
                      </td>
                      <td *ngIf="contract.fixing_conditions.length > 1" class="sap text-center">
                        <date-range [from]="fc.delivery.date_from" [to]="fc.delivery.date_to"></date-range>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </table-detail>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="ag-header">
              <div class="ag-header-title">
                <h4>
                  {{ 'CONTRACTS.FIXATIONS'|translate }}
                  <button type="button" class="btn btn-link btn-xs" *ngIf="isImporter"
                    (click)="toggleFixation(fixationForm)" [disabled]="showFixationForm || processing"><i
                      class="material-symbols-rounded">add</i></button>
                </h4>
              </div>
              <div class="ag-header-actions">
                <div class="row-summary text-right"
                  *ngIf="contract.fixations && contract.fixations_data && contract.summary.fixed_volume">
                  <div *ngIf="contract.summary.fixed_volume">
                    <b>{{ 'CONTRACTS.FIXED'|translate }}:</b> {{
                    contract.summary.fixed_volume.value|number:'1.0-3':currentLang }}
                    <small class="text-muted">{{ contract.summary.fixed_volume.unit.abbrev }}</small>&nbsp;
                    <small [class.text-danger]="contract.quantity.value > contract.summary.fixed_volume.value">({{
                      ((contract.summary.fixed_volume.value * 100) / contract.quantity.value)|number:'1.0-2':currentLang
                      }}%)</small>
                  </div>
                  <div class="small" *ngIf="contract.fixations_data.average_price">
                    {{ 'CONTRACTS.WEIGHTED_AVERAGE'|translate }}: <price-display
                      [price]="contract.fixations_data.average_price"></price-display>
                    <span *ngIf="contract.fixations_data.average_price.quantity_unit.abbrev" class="currency">/{{
                      contract.fixations_data.average_price.quantity_unit.abbrev }}</span>
                    <!-- <span class="text-muted" [innerHtml]="'CONTRACTS.FIXATIONS_NUMBER'|translate:{quantity: contract.fixations.length}"></span>   -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form #fixationForm="ngForm" (ngSubmit)="fixationForm.valid && fixationFormSubmit()">
          <div [collapse]="!showFixationForm" [isAnimated]="true">
            <spinner *ngIf="!newFixation" message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
            <ng-container *ngIf="newFixation">
              <div class="row">
                <!-- Date -->
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.DATE'|translate }}</label>
                    <div class="input-group">
                      <div class="input-group-addon">
                        <i class="material-symbols-rounded">date_range</i>
                      </div>
                      <input type="text" name="date" class="form-control" bsDatepicker
                        placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" container=".modal" required
                        [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                        [(ngModel)]="newFixation.date" [disabled]="processing" readonly>
                    </div>
                    <error-list></error-list>
                  </div>
                </div>

                <!-- Slate -->
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'CONTRACTS.SLATE'|translate }}</label>
                    <input type="text" name="slate" class="form-control" required [(ngModel)]="newFixation.slate"
                      [disabled]="processing" maxlength="48">
                    <error-list></error-list>
                  </div>
                </div>

                <!-- Volume -->
                <div class="col-sm-4">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'FUTURES.VOLUME'|translate }}</label>
                    <div class="input-group">
                      <input type="number" placeholder="0" step="1" class="form-control" name="quantity"
                        [disabled]="processing" [(ngModel)]="newFixation.volume.value" required [min]="1"
                        [max]="9999999">
                      <div dropdown agSelect name="quantityUnit" class="input-group-btn"
                        [(ngModel)]="newFixation.volume.unit" [isDisabled]="processing"
                        [class.single-unit]="company.market.quantity_units.length <= 1">
                        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{ newFixation.volume?.unit.name_plural || newFixation.volume?.unit.abbrev ||
                          newFixation.volume?.unit.name }}
                          <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                          <li *ngFor="let u of company.market.quantity_units" [agOption]="u"><a>{{ u.name_plural }}</a>
                          </li>
                        </ul>
                      </div>
                    </div><!-- /btn-group -->
                    <error-list></error-list>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- Price -->
                <div class="col-sm-6 col-md-4">
                  <div ngModelGroup="priceGroup" #price="ngModelGroup" hasError>
                    <div class="form-group">
                      <label class="control-label">{{ 'GLOBAL.PRICE'|translate }}</label>
                      <price-form [price]="newFixation.price" [currencies]="currencies" [min]="0.01"
                        [units]="company.market.quantity_units" [disabled]="processing" placeholder="0.00"></price-form>
                    </div>
                  </div>
                </div>

                <!-- Slate Price -->
                <div class="col-sm-6 col-md-4">
                  <div ngModelGroup="slatePriceGroup" #slateprice="ngModelGroup" hasError>
                    <div class="form-group">
                      <label class="control-label">
                        {{ 'CONTRACTS.SLATE_PRICE'|translate }}
                        <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                      </label>
                      <price-form prefix="slate_" [price]="newFixation.slate_price" [currencies]="currencies"
                        [min]="0.01" [units]="company.market.quantity_units" [disabled]="processing"
                        [required]="false" help-block="{{ 'CONTRACTS.SLATE_PRICE_HELP'|translate }}" placeholder="0.00">
                      </price-form>
                    </div>
                  </div>
                </div>

                <!-- Exchange rate -->
                <div class="col-sm-6 col-md-4">
                  <div ngModelGroup="exchangePriceGroup" #slateprice="ngModelGroup" hasError>
                    <div class="form-group">
                      <label class="control-label">
                        {{ 'FUTURES.EXCHANGE_RATE'|translate }}
                        <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                      </label>
                      <price-form prefix="exchange_" [price]="newFixation.exchange_rate" [showQuantityUnit]="false"
                        [currencies]="[newFixation.exchange_rate.unit]" [min]="0.01" [disabled]="processing"
                        [required]="false" placeholder="0.00"></price-form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- Observations -->
                <div class="col-xs-12">
                  <div class="form-group" hasError>
                    <label class="control-label">
                      {{ 'GLOBAL.OBSERVATIONS'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <textarea class="form-control" name="observations" [disabled]="processing"
                      [(ngModel)]="newFixation.observations" maxlength="1024" rows="4"></textarea>
                    <!-- <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span> -->
                    <error-list></error-list>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 text-right">
                  <button (click)="toggleFixation()" [disabled]="processing" type="button" class="btn btn-link">{{
                    'GLOBAL.CANCEL'|translate}}</button>
                  <button [disabled]="processing" type="submit" class="btn btn-agree">Agregar fijación</button>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
        <div class="row">
          <div class="col-xs-12">
            <div class="horizontal-scroller" *ngIf="contract.fixations && contract.fixations.length; else noFixations">
              <table class="table table-condensed table-hover" sortable #tableSortable="tableSortable">
                <thead>
                  <tr class="tr-align-center">
                    <th class="sap hidden-xs" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
                    <th class="sap" sortBy="slate">{{ 'CONTRACTS.SLATE'|translate }}</th>
                    <th class="sap hidden-xs hidden-sm" sortBy="volume.value">
                      <abbr title="{{ 'GLOBAL.QUANTITY'|translate }}">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                    </th>
                    <th class="hidden-xs hidden-sm"></th>
                    <th class="sap hidden-xs hidden-sm" sortBy="price.value">{{ 'GLOBAL.PRICE'|translate }}<span
                        *ngIf="contract.fixations[0].volume?.unit.abbrev">/{{ contract.fixations[0].volume.unit.abbrev
                        }}</span></th>
                    <th class="sap hidden-xs" sortBy="exchange_rate.value">{{ 'FUTURES.EXCHANGE_RATE'|translate }}</th>
                    <th sortBy="observations">
                      <abbr title="{{ 'GLOBAL.OBSERVATIONS'|translate }}">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate
                        }}</abbr>
                    </th>
                    <th>{{ 'CONTRACTS.INVOICING'|translate }}</th>
                    <th class="sap" *ngIf="isImporter"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tr-align-center"
                    *ngFor="let fixation of contract.fixations|orderBy: tableSortable.sortBy;">
                    <td class="sap micro text-muted hidden-xs">
                      <date-md [date]="fixation.date"></date-md>
                    </td>
                    <td>
                      <div class="visible-xs micro text-muted">
                        <date-md [date]="fixation.date"></date-md>
                      </div>
                      <b class="ellipsis" title="{{ fixation.slate }}">{{ fixation.slate }}</b>
                      <div class="small visible-xs vixible-sm">
                        <quantity-control [quantity]="fixation.volume"></quantity-control>
                        <span class="text-muted">&nbsp;&#9659;&nbsp;</span>
                        <price-display [price]="fixation.price"></price-display>
                      </div>
                    </td>
                    <td class="sap text-right hidden-xs hidden-sm">
                      <quantity-control [quantity]="fixation.volume"></quantity-control>
                    </td>
                    <td class="sap text-muted hidden-xs hidden-sm">&#9659;</td>
                    <td class="sap text-right hidden-xs hidden-sm">
                      <price-display [price]="fixation.price"></price-display>
                    </td>
                    <td class="sap text-right text-muted small hidden-xs">
                      <price-display *ngIf="fixation.exchange_rate; else none" [price]="fixation.exchange_rate">
                      </price-display>
                    </td>
                    <td class="small"><span *ngIf="fixation.observations; else none">{{ fixation.observations }}</span>
                    </td>
                    <td class="sap small">
                      <ng-container *ngIf="fixation.invoicing?.type; else none">
                        <span *ngIf="fixation.invoicing.type === 1"
                          [innerHtml]="'CONTRACTS.BUSINESS_DAYS'|translate:{days: fixation.invoicing.days}"></span>
                        <span *ngIf="fixation.invoicing.type === 2"
                          [innerHtml]="'CONTRACTS.CALENDAR_DAYS'|translate:{days: fixation.invoicing.days}"></span>
                        <date-md *ngIf="fixation.invoicing.type === 3" [date]="fixation.date"></date-md>
                      </ng-container>
                    </td>
                    <td class="sap" *ngIf="isImporter">
                      <a title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}" [class.disabled]="processing"
                        (onConfirm)="deleteFixation(fixation)" [confirm-options]="{
                        title: 'FIXATIONS.DELETE'|translate,
                        action: 'GLOBAL.YES_DELETE'|translate
                      }"><i class="material-symbols-rounded">delete</i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #noFixations>
              <div class="text-muted text-center">
                <p>{{ 'CONTRACTS.NO_FIXATION_DATA'|translate }}</p>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <!-- Unloads -->
      <div class="row" [class.hidden]="!trucksData || trucksData.data.length === 0">
        <div class="col-xs-12">
          <div class="ag-header">
            <div class="ag-header-title">
              <h4>{{ 'TRUCKS.TITLE'|translate }}</h4>
            </div>
            <div class="ag-header-actions" *ngIf="trucksData && trucksData.data.length > 0">
              <div class="row-summary text-right" [tooltip]="weightTemplate" placement="left" container="body">
                <div><b>{{ 'TRUCKS.APPLIED'|translate }}:</b> {{
                  trucksData.weight.applied.value|number:'1.0-3':currentLang }} <small class="text-muted">{{
                    trucksData.weight.applied.unit.abbrev }}</small></div>
                <div class="text-muted small"
                  [innerHtml]="'TRUCKS.UNLOADS_NUMBER'|translate:{quantity: trucksData.data.length}"></div>
              </div>
              <ng-template #weightTemplate>
                <div class="text-right">
                  <div
                    [innerHtml]="'TRUCKS.NET_KG'|translate:{quantity: (trucksData.weight.net|number:'1.0-0':currentLang)}">
                  </div>
                  <div
                    [innerHtml]="'TRUCKS.GROSS_KG'|translate:{quantity: (trucksData.weight.gross|number:'1.0-0':currentLang)}">
                  </div>
                  <div
                    [innerHtml]="'TRUCKS.TARE_KG'|translate:{quantity: (trucksData.weight.tare|number:'1.0-0':currentLang)}">
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <trucks-table [company]="company" [filters]="{
            'filters[contracts.*.id]': 'is:'+contract.id
          }" [hideColumns]="['date', 'contract_reference', 'waybill.product', 'source']" (load)="trucksLoaded($event)">
          </trucks-table>
        </div>
      </div>
      <!-- Liquidaciones -->
      <div class="row" [class.hidden]="!liquidacionesData || liquidacionesData.data.length === 0">
        <div class="col-xs-12">
          <div class="ag-header">
            <div class="ag-header-title">
              <h4>{{ 'LIQUIDACIONES.TITLE'|translate }}</h4>
            </div>
            <div class="ag-header-actions" *ngIf="liquidacionesData && liquidacionesData.data.length > 0">
              <div class="row-summary text-right">
                <b>{{ 'INVOICES.BALANCE'|translate }}:</b>&nbsp;<price unit="ARS"
                  [value]="liquidacionesData.data|pluck:'total'|sum"></price>
              </div>
            </div>
          </div>
          <ag-liquidaciones-table [company]="company" [filters]="{
            'filters[contract.id]': 'is:' + contract.id
          }" [hideColumns]="['contract_reference', 'product_name', 'buyer', 'seller', 'broker', 'source']"
            (load)="liquidacionesLoaded($event)"></ag-liquidaciones-table>
        </div>
      </div>

      <!-- Applications -->
      <div class="row" [class.hidden]="!applicationsData || applicationsData.data.length === 0">
        <div class="col-xs-12">
          <div class="ag-header">
            <div class="ag-header-title">
              <h4>{{ 'CONTRACTS.APPLICATIONS'|translate }}</h4>
            </div>

          </div>
          <applications-table [company]="company" [filters]="{
            'filters[contract.reference]': 'is:' + contract.reference
          }" (load)="applicationsLoaded($event)">
          </applications-table>
        </div>
      </div>

      <div *ngIf="company" class="row">
        <div class="col-xs-12">
          <ag-related-envelopes entity="contract" [entity-id]="contract.id" [disabled]="processing" [company]="company">
          </ag-related-envelopes>
        </div>
      </div>
    </ng-container>

  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
