import { DatePipe, Location } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable, Subscription, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../../../../environments/environment';
import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { DeliveryTypeGroup } from '../../../../../models/delivery-type-group.model';
import { DeliveryType } from '../../../../../models/delivery-type.model';
import { GeoSelection } from '../../../../../models/geo-selection.model';
import { GroupBy } from '../../../../../models/group-by.model';
import { PaymentCondition } from '../../../../../models/payment-condition.model';
import { Product } from '../../../../../models/product.model';
import { CompanyLocationService } from '../../../../../services/company-location.service';
import { CompanyService } from '../../../../../services/company.service';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { HubSpotService } from '../../../../../services/hub-spot.service';
import { IntercomService } from '../../../../../services/intercom.service';
import { LocationService } from '../../../../../services/location.service';
import { MarketService } from '../../../../../services/market.service';
import { ConfirmComponent } from '../../../../../ui/components/confirm/confirm.component';
import { defaultOrderExpiration, startOfDay } from '../../../../../utilities/date';
import { genCropList } from '../../../../../utilities/setProp';
import { ContractClausesFormComponent } from '../../../imported-data/components/contract-clauses-form/contract-clauses-form.component';
import { OrderCreation } from '../../models/order.model';
import { MemoryStorageService } from '../../services/memory-storage.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit, OnDestroy {

  @ViewChild('form', { static: true }) private readonly form: NgForm;
  @ViewChild('editCancel', { static: true }) private readonly editCancel: ConfirmComponent;
  @ViewChild('contract') private readonly contract: ContractClausesFormComponent;

  private allQuantityTypes: any[] = [];
  private auction_durations: Array<any> = [
    { value: 5, disabled: false },
    { value: 10, disabled: false },
    { value: 30, disabled: false },
    { value: 60, disabled: false },
    { value: 90, disabled: false },
    { value: 120, disabled: false },
    { value: 150, disabled: false },
    { value: 180, disabled: false },
    { value: 210, disabled: false },
    { value: 240, disabled: false }
  ];
  private canceled: boolean = false;
  private defaultPaymentConditions: PaymentCondition[] = [];
  private GroupedDeliveryTypes: GroupBy<DeliveryTypeGroup, DeliveryType>[] = [];
  private hasContract: boolean;
  private loading: Array<any> = [];
  private subscriptions: Subscription[] = [];

  public auctionStep: number = 0.1;
  public auction_allowed_durations: Array<any>;
  public auction_date: Date;
  public auction_duration: number;
  public auction_extend_time: boolean;
  public auction_hour_from: string;
  public previous_contract: any;
  public availableLanguages: any[] = environment.languages;
  public brokers: Company[] = [];
  public chambers: Location[] = [];
  public company: Company;
  public counterparts: Company[] = [];
  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[] = [];
  /** The language currently used. */
  public currentLang: string;
  public deletedFile = new EventEmitter();
  public delivery_type_label: string;
  public delivery_type_placeholder: string;
  public delivery_types: GroupBy<DeliveryTypeGroup, DeliveryType>[] = [];
  public editMode: boolean = false;
  public expirationForTBF: boolean = false; // Default expiration for Price to be fixed
  public fatalError: string;
  public fetchingBuyers: boolean = false;
  public fetchingLocations: boolean = false;
  public forceQuantity: boolean = false;
  /** List of crop years. */
  public cropList: string[];
  public isAuction: boolean = false;
  public isPreOrder: boolean = false;
  public isQualityCompleted: boolean = false;
  public locations: GeoSelection[] = [];
  public maxExpirationDate: Date;
  public maxPrice: number = null;
  public mergeMode: boolean = false;
  public minAuctionDate: Date;
  public minExpirationDate: Date;
  public minPaymentDate: Date;
  public minPrice: number = null;
  public minQuantity: number = null;
  public mode: 'create' | 'edit' | 'republish' = 'create';
  public order: OrderCreation = new OrderCreation();
  public paymentConditions: PaymentCondition[] = [];
  public previewOn: boolean = false;
  public products: Product[] = [];
  public quantityTypes: any[] = [];
  public quantityUnits: any[] = [];
  public reloadSelectize = new EventEmitter();
  public sending: boolean = false;
  public scopes: { [scope: string]: boolean } = {
    market: false,
    network: false,
    private: false,
    offline: false
  };
  public scopeSelector: boolean;
  public today: Date;

  /** @ignore */
  constructor(
    private companyLocationService: CompanyLocationService,
    private componentComm: ComponentCommService,
    private currentDate: CurrentDateService,
    private memoryStorageService: MemoryStorageService,
    private intercomService: IntercomService,
    private localeService: BsLocaleService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private marketService: MarketService,
    public hubSpotService: HubSpotService,
    /** @ignore */
    public translateService: TranslateService,
    /** @ignore */
    public companyService: CompanyService,
    /** @ignore */
    public locationService: LocationService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.localeService.use(this.translateService.currentLang);
    this.cropList = genCropList();
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.mode = this.route.snapshot.data && this.route.snapshot.data.mode;
    this.isAuction = this.route.snapshot.data && this.route.snapshot.data.auction;

    this.setTimeProps();

    this.setDefaultAuctionValues();

    this.setLoadingKey('company', 1);
    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (this.company.activation_level.id === 1) {
        // Not fully activated
        this.router.navigateByUrl('/companies');
      }

      this.scopeSelectorCheck();
      this.setLoadingKey('company');
      this.initOrder();
    }));
  }

  setTimeProps(): void {
    this.today = this.currentDate.get();
    this.minAuctionDate = new Date(this.today.getTime());
    this.minExpirationDate = new Date(this.today.getTime() + 24 * 60 * 60 * 1000);
    this.minPaymentDate = new Date(this.today.getTime() + 24 * 60 * 60 * 1000);
    this.maxExpirationDate = new Date(this.minExpirationDate.getTime() + 180 * 24 * 60 * 60 * 1000);
  }

  private setDefaultAuctionValues(): void {
    if (this.isAuction && !this.order.auction) {
      this.order.auction = {
        step: Math.ceil(this.auctionStep),
        date_from: null,
        date_to: null,
        extend_time: 0,
        company_enrolled: false,
        enrolled_companies: []
      }

      this.auction_allowed_durations = this.auction_durations;
    }
  }

  private scopeSelectorCheck(): void {
    let config = this.company.market.configuration,
      activeScopes = 0;

    // Enabled scope
    this.scopes.market = this.company.hasModule('market');
    this.scopes.network = this.company.hasModule('my-network');
    this.scopes.private = config.order.scopes.private.enabled;
    this.scopes.offline = config.order.scopes.offline && config.order.scopes.offline.enabled;

    for (let scope of Object.values(this.scopes)) {
      if (scope) activeScopes++;
    }

    if (activeScopes > 1) this.scopeSelector = true;

    this.forcedScope();
  }

  private forcedScope(): void {
    if (!this.scopeSelector) {
      // force de only active one
      if (this.scopes.market) this.order.scope = 'abierto';
      if (this.scopes.network) this.order.scope = 'red';
      if (this.scopes.private) this.order.scope = 'privado';
    }
  }

  public qualityChanged(): void {
    for (const key in this.order.product_detail.quality) {
      if (this.order.product_detail.quality.hasOwnProperty(key)) {
        const element = this.order.product_detail.quality[key];
        if (!element) {
          this.isQualityCompleted = false;
          return;
        }
      }
    }

    this.isQualityCompleted = true;
  }

  public isLoading(): boolean {
    let sum = 0;
    let values = Object.values(this.loading);
    if (values.length > 0) {
      sum = values.reduce(function (a, b) { return a + b; });
    }
    return sum === 0 ? false : true;
  }

  private setLoadingKey(key: string, val: number = 0): void {
    this.loading[key] = val;
  }

  private initOrder(): void {
    this.hasContract = this.company.market.configuration.order.contract.type === 'contract_clauses';
    this.order.language = this.translateService.currentLang;
    this.checkOrderType();

    if (this.memoryStorageService.data.order) {
      this.mergeMode = true;
      this.order = this.memoryStorageService.data.order;
      this.transform(this.order.negotiability);

      if (this.order.operation_type === 'compra') {
        this.maxPrice = this.order.business_detail.price.value;
      } else {
        this.minPrice = this.order.business_detail.price.value;
      }
      this.minQuantity = this.order.business_detail.quantity.value;
      this.getCounterparts(false);
      this.checkLocations();
      this.memoryStorageService.data = {};
      this.isPreOrder = true;
      this.qualityChanged();
    }

    // Edit mode
    if (this.mode === 'edit') {
      if (this.route.snapshot.params.orderId) {
        this.editMode = true;

        this.subscriptions.push(this.route.data.subscribe((data: {
          mode: string,
          order: OrderCreation
        }) => {
          this.order = data.order;
          this.traslateDeliveryRange();
          this.transform(this.order.negotiability);

          // TODO: No debería hacer falta esto, el request debería haber
          // fallado y no se debería llegar nunca hasta acá.
          if (this.order.company.id !== this.company.id) {
            this.router.navigateByUrl('/company/' + this.company.id + '/working-orders');
          }
          this.checkScope();
          this.checkCompany();
          this.checkOperationType();
          this.checkQuantityType();
          this.getCounterparts(false);
          this.checkLocations();
          if (this.isAuction) {
            this.checkAuctionData();
          }

          this.resetContract();
          this.qualityChanged();
        }));
      }
    } else if (this.mode === 'republish') {
      this.subscriptions.push(this.route.data.subscribe((data: {
        mode: string,
        order: OrderCreation
      }) => {
        this.order = data.order;
        this.traslateDeliveryRange();
        this.checkOrderType();
        this.transform(this.order.negotiability);

        this.checkDate();

        this.order.id = null;
        this.order.order_status = null;
        this.order.negotiations = [];

        this.checkScope();
        this.checkCompany();
        this.checkOperationType();
        this.checkQuantityType();
        this.getCounterparts(false);
        this.checkLocations();

        if (this.isAuction) {
          this.checkAuctionData();
        }

        if (this.order.contract_detail) {
          this.resetContract();
        }

        this.qualityChanged();
        this.checkQuantityUnitsInProduct();
      }));
    } else if (this.mode === 'create') {
      this.order.broker_company = null;
      this.checkCompany();
      this.checkPrice();
      this.checkOperationType();
      this.checkQuantityType();
      this.checkLocations();
      this.minPrice = 0.01;
      this.minQuantity = 1;

      if (this.isAuction) {
        // Default values
        // Default start round next X minutes
        let nextMinutes = 30,
          defStart = new Date(this.currentDate.get().getTime() + (nextMinutes * 60 * 1000));

        // Round time to the nearest quarter hour
        let minutes = defStart.getMinutes(), hours = defStart.getHours(),
          m = (((minutes + 7.5) / 15 | 0) * 15) % 60,
          h = ((((minutes / 105) + .5) | 0) + hours) % 24;

        this.setAuctionDate(defStart); // Default date
        this.auction_hour_from = (h < 10 ? "0" : "") + h + ":" + (m < 10 ? "0" : "") + m; // Default hour
      }
    }

    // Default auction_extend_time value
    if (this.order.auction) this.auction_extend_time = this.order.auction.extend_time > 0;

    // Check Order product
    if (this.mode !== 'create' && !this.order.product.id) {
      // FATAL ERROR: Product do not exists

      this.fatalError = '<h2>ERROR: No Product ID</h2><p>Report to <a href="mailto:info@agree.ag">info@agree.ag</a></p><hr>\
      <p><code>this.order.product</code></p>\
      <p><samp class="small">' + JSON.stringify(this.order.product) + '</samp></p>';
    } else {
      // Automatically set the expiration date
      this.order.expiration_datetime = defaultOrderExpiration(this.company, this.order, this.expirationForTBF, this.currentDate);

      if (this.order.media.length > 0) {
        this.order.media.forEach((media, index) => {
          this.order.media[index].name = media.uri.split("/").pop();
        });
      }

      this.subscriptions.push(this.companyService.getBrokers(this.company.id).subscribe(brokers => {
        this.brokers = brokers.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
      }));
      this.subscriptions.push(this.marketService.watchProducts().subscribe(products => {
        if (products) {
          this.products = products.sort((a, b) => a.name.localeCompare(b.name)); // Sorts alphabetically
          this.checkQuantityUnitsInProduct();
        }
      }));

      this.subscriptions.push(this.orderService.getDeliveryTypes(this.company.market.id).subscribe(delivery_types => {
        this.GroupedDeliveryTypes = delivery_types;
        this.filterDeliveryTypes();

        this.delivery_type_label = this.company.market.configuration.order.business_detail.delivery.delivery_type.label;
        this.delivery_type_placeholder = this.company.market.configuration.order.business_detail.delivery.delivery_type.placeholder;
        if (!this.delivery_type_label) {
          this.delivery_type_label = this.translateService.instant('GLOBAL.DELIVERY_TYPE');
        }
        if (!this.delivery_type_placeholder) {
          this.delivery_type_placeholder = this.translateService.instant('ORDER_FORM.DELIVERY_TYPE.PLACEHOLDER');
        }
      }));

      this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
        if (currencies) {
          this.currencies = currencies;

          // Default para cada mercado (hay que mandar la que se desee en la primer posicion)
          if (!this.order.business_detail.price.value) {
            this.order.business_detail.price.unit = this.currencies[0];
          }
        }
      }));

      if (typeof this.order.product === 'undefined' || typeof this.order.product.attributes.payment_conditions === 'undefined') {
        this.subscriptions.push(this.orderService.getPaymentConditions().subscribe(paymentConditions => {
          this.paymentConditions = paymentConditions;
          this.defaultPaymentConditions = paymentConditions;
        }));
      } else {
        this.paymentConditions = this.order.product.attributes.payment_conditions;
        this.order.payment_detail.payment_condition = this.paymentConditions[0];
      }

      this.subscriptions.push(this.orderService.getQuantityTypes().subscribe(quantityTypes => {
        this.quantityTypes = quantityTypes;
        this.allQuantityTypes = quantityTypes;

        // Default
        if (!this.order.business_detail.quantity.type) {
          this.order.business_detail.quantity.type = quantityTypes[0];
        }
      }));

      if (this.company.market.configuration.order.arbitration_chamber.enabled) {
        this.subscriptions.push(this.orderService.getExtraAttributes<Location>('camara_arbitral').subscribe(chambers => {
          this.chambers = chambers;
        }));
      }

      this.componentComm.emit({ name: 'app-title', title: (this.isAuction ? 'AUCTION_FORM.HEADING' : 'ORDER_FORM.HEADING') });
    }

    // Only if it has Contract
    if (this.hasContract) this.subscriptions.push(this.form.valueChanges.subscribe((change) => {
      if (this.contract?.refresh) this.contract.refresh();
    }));
  }

  public traslatedRange: boolean;

  /** Verify that the date range is not earlier than today. */
  private traslateDeliveryRange(): void {
    const { range } = this.order.business_detail.delivery;
    const today_start = startOfDay(this.today);

    if (range[0].getTime() < today_start.getTime()) {
      // Move the date to today, respecting the range of days.
      const daysDifference = Math.ceil((range[1].getTime() - range[0].getTime()) / (1000 * 3600 * 24));
      this.setDeliveryRange(daysDifference);
      this.traslatedRange = true;
    }
  }

  public deliveryRangeChange(): void {
    this.traslatedRange = false;
    this.order.expiration_datetime = defaultOrderExpiration(this.company, this.order, this.expirationForTBF, this.currentDate);
  }

  private checkScope(): void {
    if (this.order.scope === 'abierto' && !this.scopes.market) this.order.scope = 'red';
    if (this.order.scope === 'red' && !this.scopes.network) this.order.scope = null;
    if (this.order.scope === 'offline' && !this.scopes.offline) this.order.scope = null;

    this.forcedScope();
  }

  private checkQuantityUnitsInProduct(): void {
    // es un workaround que sirve para validar que los productos de las ordenes viejas que se republican, tengan quantity_units
    if (this.order.product && !this.order.product.quantity_units && this.products.length > 0) {
      let filtered = this.products.filter(product => {
        return this.order.product.id === product.id;
      });
      if (filtered) {
        this.order.product.quantity_units = filtered[0].quantity_units; // filtered debera tener siempre un unico valor porque filtra por id
      }
    }
  }

  private afterDelete(file_id: string): void {
    let temp = [];
    this.order.media.forEach(media => {
      if (media.id !== file_id) {
        temp.push(media);
      }
    });
    this.order.media = temp;
  }

  public deleteImage(event): void {
    let file_id = event.file.id;

    if (this.mode === 'republish') {
      this.afterDelete(file_id);
      setTimeout(() => {
        this.deletedFile.emit();
      });
    } else {
      this.subscriptions.push(this.orderService.deleteMedia(this.company.id, this.order.id, file_id).subscribe(response => {
        this.afterDelete(file_id);
        this.deletedFile.emit();
      }));
    }
  }

  private filterDeliveryTypes(): void {
    if (this.order.operation_type) {
      let results = [];
      let groups = [];
      this.GroupedDeliveryTypes.forEach(group => {
        group.values.forEach(delivery_type => {
          if (delivery_type.operation_types.indexOf(this.order.operation_type) !== -1) {
            if (!results[group.key.id]) results[group.key.id] = [];
            groups[group.key.id] = group.key.name;
            results[group.key.id].push(delivery_type);
          }
        });
      });

      this.delivery_types = [];
      results.forEach((result, key) => {
        this.delivery_types.push({
          key: {
            id: key,
            name: groups[key]
          },
          values: result
        });
      });

    } else {
      this.delivery_types = [];
    }

    if (this.mode === 'create' || (this.mode === 'republish' && !this.order.business_detail.delivery.delivery_type)) {
      this.order.business_detail.delivery.delivery_type = undefined;

      if (this.delivery_types.length === 1 && this.delivery_types[0].values.length === 1) {
        this.order.business_detail.delivery.delivery_type = this.delivery_types[0].values[0];
      }

      let actives = [];
      this.GroupedDeliveryTypes.forEach(group => {
        group.values.forEach(delivery_type => {
          if (delivery_type.active) actives.push(delivery_type);
        });
      });

      if (actives.length === 1) {
        this.order.business_detail.delivery.delivery_type = actives[0];
      }

    }
  }

  private getLocations(): void {
    this.fetchingLocations = true;
    this.subscriptions.push(this.companyLocationService.getCompanyLocations(this.company.id).subscribe(locations => {
      this.fetchingLocations = false;
      this.locations = locations;

      if ((this.mode === 'republish' || this.mode === 'edit') && this.order.business_detail.delivery.locations) {

        let aux = [];

        this.order.business_detail.delivery.locations.forEach(element => {

          let result;

          if (element.location) {
            result = this.locations.find(location => location.location?.id === element.location.id);
          } else if (element.zone) {
            result = this.locations.find(location => location.zone?.id === element.zone.id);
          }

          if (result) {
            aux.push(result);
          }
        });

        this.order.business_detail.delivery.locations = aux;
      }
    }));
  }

  private transform(x: any): void {
    for (let i in x) {
      if (x[i] !== null && typeof x[i] === 'object') {
        if (Array.isArray(x[i])) {
          x[i] = { ...x[i] };
        } else {
          this.transform(x[i]);
        }
      }
    }
  }

  private checkAuctionData(): void {
    let datePipe = new DatePipe('es-AR');
    let date_from = new Date(this.order.auction.date_from);
    let date_to = new Date(this.order.auction.date_to);

    this.setAuctionDate(date_from);
    this.auction_hour_from = datePipe.transform(date_from, 'HH:mm');
    this.auction_duration = (date_to.getTime() - date_from.getTime()) / 1000 / 60;
  }

  private setAuctionDate(date: Date): void {
    this.auction_date = date;
  }

  public isAuctionStepValid(): boolean {
    let decimalStr = this.auctionStep.toString().split(".")[1],
      decimalsCount = decimalStr ? decimalStr.length : 0, // Count number of decimals in a step
      decimalRnd = Math.pow(10, decimalsCount),
      remainder = ((this.order.auction.step * decimalRnd) % (this.auctionStep * decimalRnd)); // Check if the step is multiple

    return remainder === 0;
  }

  private checkDate(): void {
    if (this.order.payment_detail.date < new Date()) {
      this.order.payment_detail.date = null;
    }
  }

  private checkLocations(): void {
    if (this.order.operation_type === 'compra') {
      this.getLocations();
    }
  }

  public changeLocations(locations): void {
    this.order.business_detail.delivery.locations = locations;
    this.getCounterparts();

    if (this.order.operation_type === 'venta') {
      this.reloadSelectize.emit();
    }
  }

  public changeOperationType(value: 'compra' | 'venta'): void {
    this.order.operation_type = value;

    this.checkQuantityType();

    if (this.company.market.configuration.location.enabled) {
      this.order.business_detail.delivery.locations = [];
      this.cleanPrivateCompanies();

      this.order.product = undefined;
      this.getCounterparts();
      this.checkLocations();
    }

    this.filterDeliveryTypes();
    this.reloadSelectize.emit();
  }

  public changeDeliveryType(delivery_type: DeliveryType): void {
    this.order.business_detail.delivery.delivery_type = delivery_type;
    this.order.business_detail.delivery.locations = [];
    this.checkQuantityType();
  }

  public changeScope(value: string): void {
    this.order.business_detail.delivery.locations = [];
    this.cleanPrivateCompanies();
  }

  public changeBroker(broker: Company): void {
    this.order.broker_company = broker;
    // We force the scope for pre-orders to validate the step
    this.order.scope = broker ? 'abierto' : undefined;

    this.checkQuantityType();
    this.checkOrderType();
  }

  private resetContract(): void {
    // Check if Order has contract clauses
    if (this.hasContract) {
      // Reset values
      this.previous_contract = undefined;
      this.order.contract_detail = undefined;

      if (this.order.product &&
        this.order.product.contract_clauses) {
        // Required for conditions eval
        // eval(groupOfClauses.clauses[j].conditions)

        const cloneObj = (obj) => { return JSON.parse(JSON.stringify(obj)) };
        let temp_contract = cloneObj(this.order.product.contract_clauses);

        // Removes clauses from the original contract based on coditions
        temp_contract.forEach((groupOfClauses, i) => {
          groupOfClauses.clauses.forEach((clause, j) => {
            // TODO: Needs refactor, avoid using eval
            if (clause.conditions) {
              try {
                if (!eval(clause.conditions))
                  Object(temp_contract)[i].clauses.splice(j, 1);
              } catch (error) {
                console.warn('clause.conditions', j, clause.conditions, error);
              }
            }
          });
        });

        // We consider the product original contract after conditions as the previous contract
        this.previous_contract = cloneObj(temp_contract);
        // Reset order contract
        this.order.contract_detail = temp_contract;
      }
    }
  }

  public changeProduct(product: Product): void {
    this.order.product = product;
    this.order.product_detail.quality = {};

    this.resetContract();

    if (product.attributes.payment_conditions) {
      this.paymentConditions = product.attributes.payment_conditions;
      this.order.payment_detail.payment_condition = this.paymentConditions[0];
    } else {
      this.paymentConditions = this.defaultPaymentConditions;
    }

    this.order.business_detail.loading_rate.metric = undefined;
    this.order.business_detail.loading_rate.value = undefined;

    if (product.attributes.loading_rates && product.attributes.loading_rates.metrics) {
      let metric = product.attributes.loading_rates.metrics.filter(metric => {
        return metric.slug === 'satafshex';
      })[0];
      this.order.business_detail.loading_rate.metric = metric;
    }

    if (product.attributes.loading_rates && product.attributes.loading_rates.values && product.attributes.loading_rates.values.length === 1) {
      this.order.business_detail.loading_rate.value = product.attributes.loading_rates.values[0];
    }

    this.order.product_detail.quality = this.prepareQualityData(this.order.product.attributes.quality);

    if (!product.attributes.ports) {
      this.order.business_detail.port = undefined;
      this.order.business_detail.berth = undefined;
    }

    // Default
    this.order.business_detail.quantity.unit = product.quantity_units[0];
    this.order.business_detail.price.quantity_unit = product.quantity_units[0];

    this.qualityChanged();
  }

  public changePaymentCondition(payment_condition: PaymentCondition): void {
    this.order.payment_detail.date = null;

    if (this.order.payment_detail.other) {
      this.order.payment_detail.other = undefined;
    }

    this.order.payment_detail.payment_condition = payment_condition;

    this.resetContract();
  }

  public changePort(port): void {
    this.order.business_detail.berth = undefined;
    this.resetContract();
  }

  public changeBerth(berth): void {
    this.order.business_detail.other_berth = undefined;
    this.order.business_detail.berth = berth;
    this.resetContract();
  }

  public changeLoadingRateMetric(metric): void {
    this.order.business_detail.loading_rate.metric = metric;
    if (metric.slug !== 'other') {
      this.order.business_detail.loading_rate.other = undefined;
    }
    this.resetContract();
  }

  private prepareQualityData(quality: any): void {
    let result: any = {};
    for (let i in quality) {
      let attribute = quality[i];
      if (attribute.element !== "textarea") {
        if (attribute.empty) {
          result[i] = attribute.empty;
        } else {
          result[i] = attribute.values[0];
        }
      } else {
        result[i] = attribute.value;
      }

      if (attribute.order) {
        result[i].order = attribute.order;
      }
    }
    return result;
  }

  private checkCompany(): void {
    if (!this.order.company) {
      this.order.company = this.company;
    }
  }

  private checkPrice(): void {
    if (!this.order.business_detail.price.type) {
      this.order.business_detail.price.type = 'flat';
      delete this.order.business_detail.price.month;
      delete this.order.business_detail.price.year;
    }
  }

  private checkOperationType(): void {
    if (!this.company.activity.buyer || !this.company.activity.seller) {
      // This company either buys or sells, not both
      this.order.operation_type = this.company.activity.buyer ? 'compra' : 'venta';
    }
  }

  private checkOrderType(): void {
    if (this.order.broker_company) {
      this.order.type = this.isAuction ? { id: 5, name: '' } : { id: 2, name: '' };
    } else {
      this.order.type = this.isAuction ? { id: 4, name: '' } : { id: 1, name: '' };
    }
  }

  public companyHasNoLocations(): boolean {
    return this.order.operation_type === 'compra' && this.company.market.configuration.location.enabled && !this.locations.length && !this.fetchingLocations;
  }

  public changeAuctionDuration(): void {
    if (!this.company || !this.isAuction || !this.auction_date) return;

    this.auction_date.setSeconds(0, 0); // Reset seconds and ms to zero

    let auction_date_and_hour = new Date(this.auction_date.getTime()), // Clone a date
      operationalTo = new Date(this.auction_date.getTime()), // Clone a date
      hours_and_minutes = this.auction_hour_from.split(':');

    // Combine start date and hour
    auction_date_and_hour.setHours(parseInt(hours_and_minutes[0] ? hours_and_minutes[0] : '0'), parseInt(hours_and_minutes[1] ? hours_and_minutes[1] : '0'));
    this.order.auction.date_from = auction_date_and_hour;

    // Starting date in milliseconds
    let fromInMs = this.order.auction.date_from.getTime();

    // Sets market operational time
    operationalTo.setHours(parseInt(this.company.market.operational_range.to), 0);

    // este es mi stack de duraciones que voy a mostrar en el combo del template
    this.auction_allowed_durations = [];
    // recorro el array de duraciones posibles, y voy comparando a ver si me da para habilitar o deshabilitar la cantidad de minutos
    this.auction_durations.forEach(duration => {
      let durationInMs = duration.value * 60 * 1000,
        disabled = false;

      if (fromInMs + durationInMs > operationalTo.getTime()) disabled = true;
      if (this.auction_duration === duration.value && disabled) delete this.auction_duration;

      this.auction_allowed_durations.push({
        value: duration.value,
        disabled: disabled
      });
    });

    // si tengo el horario desde y la duración, hago el calculo para obtener la fecha/hora hasta (que es el que va a terminar viajando a la API)
    if (this.auction_hour_from && this.auction_duration) {
      this.order.auction.date_to = new Date(fromInMs + (this.auction_duration * 60 * 1000));
    } else {
      this.order.auction.date_to = null;
    }
  }

  public changeAuctionExtendTime(): void {
    if (!this.company || !this.isAuction || !this.auction_date) return;

    this.order.auction.extend_time = this.auction_extend_time ? 60 : 0;
  }

  private checkClauses(): void {
    if (this.hasContract && this.mode !== 'republish' && !this.isPreOrder) {
      const equal = require('deep-equal');
      this.order.has_contract_change = !(equal(this.previous_contract, this.order.contract_detail));
    }
  }

  private checkQuantityType(): void {
    if (
      this.order.operation_type !== 'compra' ||
      this.order.broker_company ||
      // this.company.activity.broker ||
      !this.company.market.configuration.order.quantity_types.enabled
    ) {
      this.forceQuantity = true;
      this.quantityTypes = this.allQuantityTypes.filter(element => {
        return element.id === 1;
      });
      this.order.business_detail.quantity.type = this.quantityTypes[0];
    } else {
      this.quantityTypes = this.allQuantityTypes;
      this.forceQuantity = false;
    }
  }

  private getCounterparts(reset: boolean = true): void {
    if (reset) {
      this.order.business_detail.authorized_buyers = [];
    }

    if (this.order.operation_type === 'venta' && this.order.business_detail.delivery.locations.length) {
      this.setLoadingKey('getCounterparts', 1);

      let locations = [];
      let zones = [];

      if (this.order.business_detail.delivery.delivery_type.slug !== 'fca') {
        locations = this.order.business_detail.delivery.locations.filter(loc => {
          return !!loc.location;
        }).map(loc => {
          return loc.location.id;
        });
        zones = this.order.business_detail.delivery.locations.filter(loc => {
          return !loc.location && loc.zone;
        }).map(loc => {
          return loc.zone.id;
        });
      }

      this.fetchingBuyers = true;
      this.subscriptions.push(this.companyService.getCounterparts(this.company.id, locations, zones, this.order.scope === 'red' ? 1 : 0).subscribe({
        next: counterparts => {
          this.fetchingBuyers = false;
          this.counterparts = counterparts;

          if (reset) {
            this.order.business_detail.authorized_buyers = [];
            this.counterparts.forEach(company => {
              this.order.business_detail.authorized_buyers.push(company);
            });
          } else {
            let counterparts_ids = this.counterparts.map(({ id }) => id);
            this.order.business_detail.authorized_buyers = this.order.business_detail.authorized_buyers.filter(value => {
              return counterparts_ids.indexOf(value.id) !== -1;
            });
          }

          this.setLoadingKey('getCounterparts');
        },
        error: error => {
          this.fetchingBuyers = false;
          this.setLoadingKey('getCounterparts');
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  public getId(a): number {
    return a.id;
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  public getGeoSelectionKey(a): string {
    if (!a.location)
      return "Zone " + a.zone.id;
    else
      return "Location " + a.location.id;
  }

  /**
   * Last form validation and switch to preview mode.
   */
  public showPreview(): void {
    if (this.isAuction) {
      // Force to check if the auction start is not in the past
      this.form.controls.auction_hour.updateValueAndValidity();
    }

    if (this.form.valid) {
      this.checkClauses();
      this.previewOn = true;
      this.componentComm.emit({ name: 'app-scroll' });
    }
  }

  /** Back to edit mode. */
  public hidePreview(): void {
    this.previewOn = false;
    this.componentComm.emit({ name: 'app-scroll' });
  }

  public create(): void {
    this.form.control.setErrors({
      "remote": false
    });

    this.sending = true;

    // en caso que sea precio "to_be_fixed" o "consignment",
    // limpio el objeto order.business_detail.price para que no viaje hacia la API con atributos propios de precio flat o premium.
    if (this.order.business_detail.price.type === 'to_be_fixed' ||
      this.order.business_detail.price.type === 'consignment') {
      delete this.order.business_detail.price.unit;
      delete this.order.business_detail.price.quantity_unit;
    }

    this.subscriptions.push(this.orderService.create(this.order, this.company.id).subscribe({
      next: order => {
        this.intercomService.track('order-created', {
          order_id: order.id,
          company_id: this.company.id,
          company_name: this.company.name,
          product_name: this.order.product.name
        });
        this.sending = false;

        if (this.order.type && (this.order.type.id === 3 || this.order.type.id === 6)) {
          // Pre-order or Pre-auction
          this.router.navigateByUrl('/company/' + this.company.id + '/templates');
        } else if (this.order.broker_company) {
          this.router.navigateByUrl('/company/' + this.company.id + '/preorders');
        } else {
          this.router.navigate(['/company', this.company.id, 'my-operations'], {
            queryParams: {
              status_group_orders: 1,
              order_by: '-updated_at'
            }
          });
        }
      },
      error: error => {
        this.sending = false;
        this.hidePreview();
        this.form.control.setErrors({
          "remote": true
        });
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public save(): void {
    this.form.control.setErrors({
      "remote": false
    });

    this.order.on_hold = 0;

    this.sending = true;

    this.subscriptions.push(this.orderService.save(this.order, this.company.id).subscribe({
      next: response => {
        this.canceled = true;
        this.sending = false;

        if (this.order.broker_company) {
          this.router.navigateByUrl('/company/' + this.company.id + '/preorders');
        } else {
          this.router.navigate(['/company', this.company.id, 'my-operations'], {
            queryParams: {
              status_group_orders: 1,
              order_by: '-updated_at'
            }
          });
        }
      },
      error: error => {
        this.sending = false;
        this.hidePreview();
        this.form.control.setErrors({
          "remote": true
        });
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public cancel(): void {
    /*
    // Este script sirve para detectar cuales son los campos del formulario que estan con error o son invalidos
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors = this.form.form.get(key);
      if (controlErrors.invalid) {
        // key
      }
    });
    */

    this.sending = true;
    this.canceled = true;
    if (this.editMode) {
      this.subscriptions.push(this.orderService.onHold(this.order, this.company.id, 2).subscribe(response => {
        this.router.navigateByUrl('/company/' + this.company.id + '/order/' + this.order.id);
        // TODO: on error?
      }));
    } else {
      this.router.navigateByUrl('/company/' + this.company.id + '/working-orders');
    }
  }

  canLeave(): Observable<boolean> | boolean {
    if (this.canceled) {
      return true;
    } else {
      return this.editCancel.askConfirmation().pipe(mergeMap(confirmed => {
        if (confirmed) {
          this.sending = true;
          return this.orderService.onHold(this.order, this.company.id, 2).pipe(mergeMap(response => {
            return of(true);
          }));
        } else {
          return of(false);
        }
      }));
    }
  }

  public cleanPrivateCompanies(): void {
    this.order.private_companies = [];
    this.order.represented_counterpart = [];
  }

  public onChangePrice(event): void {
    let bPrice = this.order.business_detail.price;

    if (bPrice.type === 'flat') {
      // Check if there is a Currency unit set
      if (!bPrice.unit || typeof bPrice.unit.id === 'undefined') bPrice.unit = this.currencies[0];

      let oProduct = this.order.product;

      if (oProduct.quantity_units) {
        bPrice.quantity_unit = bPrice.quantity_unit || oProduct.quantity_units[0];
      }
    }

    this.order.expiration_datetime = defaultOrderExpiration(this.company, this.order, this.expirationForTBF, this.currentDate);
    this.resetContract();
  }

  public setDeliveryRange(days: number = 45): void {
    // Sets delivery range, from today to 'days' days after
    // Default value 45
    const today_start = startOfDay(this.today);

    this.order.business_detail.delivery.range = [
      today_start,
      new Date(today_start.getTime() + (days * 24 * 60 * 60 * 1000))
    ];

    this.deliveryRangeChange();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
