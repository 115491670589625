<cpe-modal #cpeModal [company]="company"></cpe-modal>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table table-condensed" sortable #tableSortable="tableSortable">
        <thead>
          <tr class="tr-align-center">
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              <span class="hidden-xs">{{ 'GLOBAL.DATE'|translate }}</span>
              <i class="material-symbols-rounded visible-xs">date_range</i>
            </th>
            <th class="sap"></th>
            <th>C.T.G.</th>
            <th class="sap text-right" sortBy="quantity.value" *ngIf="!hideColumns.includes('quantity')">{{
              'TRUCKS.APPLIED'|translate }}</th>
            <th class="sap text-center" *ngIf="!hideColumns.includes('source')">
              <abbr tooltip="{{ 'GLOBAL.SOURCE'|translate }}" container="body" [adaptivePosition]="false">
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let application of applications|orderBy: tableSortable.sortBy;">
            <tr class="tr-align-center">
              <td class="sap small" *ngIf="!hideColumns.includes('date')">
                <date-md *ngIf="application.date; else none" [date]="application.date" [showTime]="false" timezone="UTC"></date-md>
              </td>
              <td class="sap">
                <span *ngIf="application.warnings?.length"
                  [tooltip]="application.warnings?.length ? warnTemplate : null" [adaptivePosition]="false"
                  class="differences material-symbols-rounded text-warning no-select hidden-xs">warning</span>
                <ng-template #warnTemplate>
                  <div class="text-left">
                    <p class="small">{{ 'IMPORTER.WARNING.TITLE'|translate }}</p>
                    <span *ngFor="let d of application.warnings; let last = last">
                      {{ 'IMPORTER.WARNING.'+d|translate }}
                      <ng-container *ngIf="!last"> ,</ng-container>
                    </span>
                  </div>
                </ng-template>
              </td>
              <td *ngIf="!hideColumns.includes('ctg')">
                <a (click)="cpeModal.show(application.numero_ctg)">{{application.numero_ctg}}</a>
              </td>
              <td class="sap text-right" *ngIf="!hideColumns.includes('quantity')">
                {{ application.quantity.value|number:'1.0-3':currentLang }} <small class="text-muted">{{
                  application.quantity.unit.abbrev }}</small>
              </td>
              <td class="sap" *ngIf="!hideColumns.includes('source')" class="sap">
                <span *ngIf="application.import_channel_id; let source" class="micro text-muted">{{
                  'IMPORTER.SOURCE.'+source|translate }}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="no-data" *ngIf="applications && applications.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'GLOBAL.NO_DATA'|translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
