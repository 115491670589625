<div class="row">
  <div class="col-sm-6">
    <div class="assignedVolume">
      <span [innerHtml]="'SLOTS.REQUEST_SLOT_MODAL.MAX_SLOTS'|translate: {quantity: maxCuposToRequest}">
      </span>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="assignedVolume">
      <span style="margin-right: 10px;">{{ "SLOTS.REQUEST_SLOT_MODAL.APPLIED_VOLUME"|translate }}:</span>
      <quantity-control [quantity]="appliedVolume"></quantity-control>
    </div>
  </div>

</div>

<div class="negotiation-detail">

  <table>
    <tbody>
      <tr class="tr-align-center">
        <td class="smtd hidden-xs" style="padding-right: 15px;">
          <ag-timestamp [date]="negotiation.booking_date"></ag-timestamp>
        </td>
        <td class="selectize">
          <ng-container *ngIf="!selectedRecipient && buyers.length > 1; else showCompany">
            <selectize
              [(ngModel)]="selectedRecipient"
              [maxItems]="1"
              [options]="buyers"
              placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}">
            </selectize>
          </ng-container>

          <ng-template #showCompany>
            <div [class.editable-pill]="buyers.length > 1">
              <button *ngIf="buyers.length > 1"
                type="button" class="close" [disabled]="processing"
                (click)="selectedRecipient = undefined"><span
                  aria-hidden="true">&times;</span></button>
              <company-info [company]="selectedRecipient" [activity]="false"></company-info>
            </div>
          </ng-template>

        </td>
        <td class="product">
          <h4>{{ negotiation.order?.product?.name }}</h4>
        </td>

        <td class="text-right">
          <quantity-control [quantity]="negotiation.proposal.business_detail.quantity"></quantity-control>
        </td>
        <td class="smtd">
          <date-range [from]="negotiation.proposal.business_detail.delivery.date_from"
            [to]="negotiation.proposal.business_detail.delivery.date_to"></date-range>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<form id="requestSlotsForm" #requestSlotsForm="ngForm">
  <div class="row">
    <div class="col-xs-12">
      <table class="table table-condensed">
        <thead>
          <tr>
            <th>{{ 'SLOTS.ASSIGN_MODAL.QUANTITY'|translate }}</th>
            <th>{{ 'GLOBAL.DATE'|translate }}</th>
            <th class="sap"></th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let slot of formArray.controls; index as i">
            <tr [formGroup]="slot" class="tr-align-center">
              <td>
                <div class="form-group" hasError>
                  <select class="form-control selector" formControlName="cuposQty" [selectedIndex]="0">
                    <option [disabled]="qty > this.slotsAvailable" *ngFor="let qty of arrayCantCupos">{{ qty }}</option>
                  </select>
                </div>
              </td>
              <td>
                <div hasError>
                  <div class="input-group">
                    <div class="input-group-addon">
                      <i class="material-symbols-rounded">date_range</i>
                    </div>
                    <input type="text" formControlName="date" bsDatepicker class="form-control"
                      placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" required
                      [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                      [disabled]="processing" [minDate]="today" readonly>
                  </div>
                  <error-list></error-list>
                </div>
              </td>
              <td class="sap">
                <a class="small" title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
                (click)="removeCupo(i)"
                [class.disabled]="processing || formArray.length === 1">
                  <i class="material-symbols-rounded">delete</i>
                </a>
                <!-- <button
                type="button" class="close" [disabled]="processing || formArray.length === 1" (click)="removeCupo(i)">
                  <span aria-hidden="true">&times;</span>
                </button> -->
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="text-right" style="margin-top:10px; margin-bottom: 10px;">
    <button type="button"
    [disabled]="slotsAvailable === 0 || processing || someControlIsEmpty" class="btn btn-default"
    (click)="addCupo()">
      {{
      'SLOTS.REQUEST_SLOT_MODAL.ENTER_ANOTHER'|translate }}</button>
  </div>
  <div class="row" style="margin-top:20px">
    <div class="col-xs-12">
      <div class="form-group">
        <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
          <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
        </label>

        <textarea class="form-control" name="observations" [disabled]="processing" [(ngModel)]="observations"
          maxlength="1024" rows="4"></textarea>
        <p class="help-block small">{{ 'SLOTS.ASSIGN_MODAL.OBSERVATIONS_HELP'|translate }}</p>
      </div>
    </div>
  </div>

  <alert type="warning" class="text-left" *ngIf="negotiation?.slot_request.length">
    <p
      [innerHtml]="'SLOTS.REQUEST_SLOT_MODAL.EXISTING_REQUEST_MESSAGE'|translate: {quantity: negotiation.slot_request.length, total: totalRequestedSlotsInNegotiation}">
    </p>
  </alert>

  <div class="text-right">
    <alert type="danger" class="text-left" *ngIf="errorMessage">
      <span [innerHTML]="errorMessage"></span>
    </alert>
    <button type="button"
    class="btn btn-agree" [disabled]="processing || totalSlotsRequest === 0 || !this.selectedRecipient" (click)="submit()">{{
      'SLOTS.REQUEST_SLOT_MODAL.TITLE'|translate }}</button>
  </div>
</form>
