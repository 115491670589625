<ag-file-preview #filePreviewer></ag-file-preview>

<input type="file"
#fileInput
(change)="parseFiles($event.target.files)"
[disabled]="disabled"
[multiple]="true"
[accept]="accept" />

<div class="row" #defaultDrop>
  <div class="col-lg-5"
  [class.hidden]="!options.map">
    <p *ngIf="!readonly" class="bubble bubble-bottom visible-xs visible-sm visible-md">
      <span [innerHtml]="'FINTECH.FIELDS_TABLE.FILE_HELP'|translate"></span>
      <ng-container *ngIf="options.map">&nbsp;<span [innerHtml]="'FINTECH.FIELDS_TABLE.MAP_HELP'|translate"></span></ng-container>
    </p>
    <div class="map-container m-b1 rounded">
      <div #mapElement></div>
    </div>

    <ng-container *ngTemplateOutlet="showSources"></ng-container>
  </div>
  <div
  [class.col-lg-7]="options.map"
  [class.col-xs-12]="!options.map">
    <p *ngIf="isDragging && options.hover; else showForm"
    class="no-data">
      <i class="material-symbols-rounded">cloud_upload</i>
      {{ 'MESSENGER.DROP_HERE'|translate }}
    </p>
  </div>
</div>

<ng-template #showForm>
  <ng-container *ngIf="!options.map">
    <ng-container *ngTemplateOutlet="showSources"></ng-container>
  </ng-container>

  <div *ngIf="!readonly &&
  (options.max_files > sourceFiles.length || fileMode == 'NO')"
  (click)="(options.max_files > sourceFiles.length && fileMode != 'NO' && !disabled) ? fileInput.click() : null"
  class="bubble hidden-xs hidden-sm hidden-md"
  [class.clickable]="options.max_files > sourceFiles.length && fileMode != 'NO'"
  [class.is-dragging]="isDragging"
  [class.bubble-left]="options.map">
    <div
    [class.m-b1]="fileMode != 'NO'">
      <span [innerHtml]="(fileMode == 'ONLY' ? 'FINTECH.FIELDS_TABLE.FILE_HELP' : 'FINTECH.FIELDS_TABLE.MANUAL_HELP')|translate"></span>
      <ng-container *ngIf="options.map">&nbsp;<span [innerHtml]="'FINTECH.FIELDS_TABLE.MAP_HELP'|translate"></span></ng-container>
    </div>
    <label class="btn btn-block btn-default"
    *ngIf="fileMode != 'NO'"
    [class.disabled]="disabled">
      <i class="material-symbols-rounded">cloud_upload</i>&nbsp;
      {{ 'FILE_INPUT.UPLOAD_FILE'|translate }}
    </label>
  </div>

  <p *ngIf="options.import && (!value || value.length === 0)" class="small text-center">
    <ag-production-plan-importer #productionPlanImporter
    [company]="company"
    [fiscal-ids]="[fiscalId]"
    (imported)="setPP($event)"></ag-production-plan-importer>
    <a (click)="productionPlanImporter.open()"><span class="material-symbols-rounded">input</span> {{ 'FINTECH.FIELDS_TABLE.IMPORT_LINK'|translate }}</a>
  </p>

  <p *ngIf="previous?.length > 0" class="small text-info text-center">
    <span class="material-symbols-rounded">auto_fix_high</span>&nbsp;
    <span *ngIf="selectedPreviousIndex != undefined; else selectPrevious" [innerHtml]="'FINTECH.APPLY.PREVIOUS_CROPS_LOADED'|translate: { date: (previous[selectedPreviousIndex].createdAt|date: ('GLOBAL.DATE_FORMAT.MD'|translate)) }"></span>
    <ng-template #selectPrevious>{{ 'FINTECH.APPLY.PREVIOUS_CROPS'|translate }}</ng-template>
    <span dropdown agSelect
    name="previous_index" class="btn-group"
    [(ngModel)]="selectedPreviousIndex"
    (ngModelChange)="setPrevious()"
    [isDisabled]="disabled">
      <a dropdownToggle>
        <span class="material-symbols-rounded">expand_more</span>
      </a>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <ng-container *ngFor="let p of previous; let i = index">
          <li [agOption]="i"
          *ngIf="i !== selectedPreviousIndex">
            <a class="dropdown-item"
            [innerHtml]="'FINTECH.APPLY.PREVIOUS_CROPS_OPTION'|translate: { fields: previous[i].seedingZones.length, date: previous[i].createdAt|date: ('GLOBAL.DATE_FORMAT.MD'|translate) }"></a>
          </li>
        </ng-container>
      </ul>
    </span>
    <a *ngIf="selectedPreviousIndex != undefined"
    title="{{ 'GLOBAL.CLEAR'|translate }}"
    [class.disabled]="disabled"
    (click)="unsetPrevious()">
      <span class="material-symbols-rounded">backspace</span>
    </a> 
  </p>

  <ng-container *ngIf="options.table">
    <tabset *ngIf="sourceDiff && sourceParsed; else valueTable">
      <tab heading="{{ 'FINTECH.FIELDS_TABLE.TABS.SUBMITTED'|translate }}"
      (selectTab)="render()">
        <p [innerHtml]="'FINTECH.FIELDS_TABLE.TABS.SUBMITTED_HELP'|translate"></p>
        <ng-container *ngIf="value?.length; else noData">
          <ng-container *ngTemplateOutlet="fieldsTable; context: {
            fields: value
          }"></ng-container>
        </ng-container>
      </tab>
      <tab heading="{{ 'FINTECH.FIELDS_TABLE.TABS.DIFF'|translate }}"
      (selectTab)="render(sourceDiff)">
        <p [innerHtml]="'FINTECH.FIELDS_TABLE.TABS.DIFF_HELP'|translate"></p>
        <ng-container *ngIf="sourceDiff?.length; else noData">
          <ng-container *ngTemplateOutlet="fieldsTable; context: {
            fields: sourceDiff
          }"></ng-container>
        </ng-container>
      </tab>
      <tab heading="{{ 'FINTECH.FIELDS_TABLE.TABS.SOURCE'|translate }}"
      (selectTab)="render(sourceParsed)">
        <p [innerHtml]="'FINTECH.FIELDS_TABLE.TABS.SOURCE_HELP'|translate"></p>
        <ng-container *ngIf="sourceParsed?.length; else noData">
          <ng-container *ngTemplateOutlet="fieldsTable; context: {
            fields: sourceParsed
          }"></ng-container>
        </ng-container>
      </tab>
    </tabset>
  </ng-container>

  <ng-container *ngIf="!readonly && options.table">
    <p class="text-right" *ngIf="fileMode != 'ONLY' || sourceFiles.length > 0">
      <a class="btn btn-default"
      [class.disabled]="disabled"
      (click)="addField()">
      <i class="material-symbols-rounded">add</i>
      {{ 'FINTECH.FIELDS_TABLE.ADD'|translate }}</a>
    </p>
    <p class="help-block small" *ngIf="help" [innerHtml]="help"></p>
    <p class="text-muted" *ngIf="options.affidavit" [innerHtml]="'FINTECH.AFFIDAVIT'|translate"></p>
  </ng-container>
</ng-template>

<ng-template #valueTable>
  <ng-container *ngTemplateOutlet="fieldsTable; context: {
    fields: value
  }"></ng-container>
</ng-template>

<ng-template #fieldsTable let-fields="fields" let-diff="diff">
  <ng-container *ngIf="fields?.length">
    <div class="horizontal-scroller">
      <table class="table table-hover table-condensed">
        <thead>
          <tr class="tr-align-center">
            <th class="sap"
            *ngIf="!hide?.includes('order')"></th>
            <th *ngIf="!hide?.includes('latitude')">{{ 'FINTECH.FIELDS_TABLE.LATITUDE'|translate }}</th>
            <th *ngIf="!hide?.includes('longitude')">{{ 'FINTECH.FIELDS_TABLE.LONGITUDE'|translate }}</th>
            <th *ngIf="!hide?.includes('type')">{{ 'GLOBAL.TYPE'|translate }}</th>
            <th *ngIf="!hide?.includes('crop')">{{ 'FINTECH.FIELDS_TABLE.CROP'|translate }}</th>
            <th *ngIf="!readonly"></th>
            <th *ngIf="!hide?.includes('hectares')">{{ 'FINTECH.FIELDS_TABLE.HECTARES'|translate }}</th>
            <ng-container *ngFor="let extraField of extras">
              <th>{{ ('FINTECH.FIELDS_TABLE.'+extraField.key)|translate }}</th>
            </ng-container>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- Field row -->
          <tr class="tr-align-center" *ngFor="let field of fields; let i = index"
          (click)="centerOn(field.geo_zone)"
          [class.success]="field.diff?.new"
          [class.danger]="field.diff?.deleted">
            <td class="sap text-center text-muted micro"
            *ngIf="!hide?.includes('order')">{{ i + 1 }}</td>
            <ng-container *ngIf="readonly">
              <td class="sap small text-right"
              *ngIf="!hide?.includes('latitude')"
              [class.warning]="field.diff?.edited['geo_zone']"
              [tooltip]="field.diff?.edited['geo_zone'] ? field.diff?.edited['geo_zone'].latitude : null">{{ field.geo_zone.latitude|number: '1.3-3': currentLang }}</td>
              <td class="sap small text-right"
              *ngIf="!hide?.includes('longitude')"
              [class.warning]="field.diff?.edited['geo_zone']"
              [tooltip]="field.diff?.edited['geo_zone'] ? field.diff?.edited['geo_zone'].longitude : null">{{ field.geo_zone.longitude|number: '1.3-3': currentLang }}</td>
              <td *ngIf="!hide?.includes('type')"
              [class.warning]="field.diff?.edited['field_type']"
              [tooltip]="field.diff?.edited['field_type'] ? (('FINTECH.FIELDS_TABLE.TYPES.' + field.diff?.edited['field_type'])|translate) : null">{{ ('FINTECH.FIELDS_TABLE.TYPES.' + field.field_type)|translate }}</td>
              <td *ngIf="!hide?.includes('crop')"
              [class.warning]="field.diff?.edited['seeding_type']"
              [tooltip]="field.diff?.edited['seeding_type'] ? field.diff?.edited['seeding_type'].name : null">{{ field.seeding_type?.name || '&#8212;' }}</td>
              <td class="sap text-right"
              *ngIf="!hide?.includes('hectares')"
              [class.warning]="field.diff?.edited['field_size']"
              [tooltip]="field.diff?.edited['field_size'] ? field.diff?.edited['field_size'] : null">{{ field.field_size|number: '1.0-1': currentLang }} <span class="currency">ha.</span></td>
              <ng-container *ngFor="let extraField of extras">
                <td class="small">{{ field.extras ? (field.extras[extraField.key].label || field.extras[extraField.key]) || '&#8212;' : '&#8212;' }}</td>
              </ng-container>
              <td class="sap micro text-muted">{{ field.zone?.description }}</td>
            </ng-container>
            <ng-container *ngIf="!readonly">
              <td class="sap" hasError hasErrorOn="dirty"
              *ngIf="!hide?.includes('latitude')"><input type="number"
                placeholder="0.0000" step="0.0001"
                min="-90" max="90"
                class="form-control input-sm coordinates"
                [disabled]="disabled"
                required
                (ngModelChange)="updateMarkers()"
                (keyup)="updateMarkers()"
                name="{{ 'latitude_' + i }}"
                [(ngModel)]="field.geo_zone.latitude"></td>
              <td class="sap" hasError hasErrorOn="dirty"
              *ngIf="!hide?.includes('longitude')"><input type="number"
                placeholder="0.0000" step="0.0001"
                min="-180" max="180"
                class="form-control input-sm coordinates"
                [disabled]="disabled"
                required
                (ngModelChange)="updateMarkers()"
                (keyup)="updateMarkers()"
                name="{{ 'longitude_' + i }}"
                [(ngModel)]="field.geo_zone.longitude"></td>
              <td *ngIf="!hide?.includes('type')">
                <select class="form-control input-sm"
                name="{{ 'field_type_' + i }}"
                [disabled]="disabled"
                [(ngModel)]="field.field_type" required>
                  <option *ngFor="let type of fieldTypes" [ngValue]="type">{{ ('FINTECH.FIELDS_TABLE.TYPES.' + type)|translate }}</option>
                </select>
              </td>
              <td *ngIf="!hide?.includes('crop')">
                <select class="form-control input-sm"
                name="{{ 'crop_' + i }}"
                [disabled]="disabled"
                [(ngModel)]="field.seeding_type"
                [compareWith]="compareId"
                required>
                  <option *ngFor="let seeding_type of products" [ngValue]="seeding_type">{{ seeding_type.name }}</option>
                </select>
              </td>
              <td class="sap small">
                <a [class.disabled]="disabled"
                (click)="addField(undefined, undefined, field)"
                title="{{ 'FINTECH.FIELDS_TABLE.ADD_CROP'|translate }}"><i class="material-symbols-rounded">add</i></a>
              </td>
              <td class="sap" hasError hasErrorOn="dirty"
              *ngIf="!hide?.includes('hectares')">
                <input type="number" class="form-control input-sm"
                name="{{ 'field_size_' + i }}"
                min="1" max="999999" step="any" placeholder="0"
                [(ngModel)]="field.field_size"
                (ngModelChange)="updateMarkers()"
                (keyup)="updateMarkers()"
                required
                [disabled]="disabled">        
              </td>
              <ng-container *ngFor="let extraField of extras">
                <td [ngSwitch]="extraField.type" hasError hasErrorOn="dirty">
                  <select *ngSwitchCase="'select'"
                  class="form-control input-sm"
                  name="{{ 'field_extra_' + extraField.key + '_' + i }}"
                  [disabled]="disabled"
                  [(ngModel)]="field.extras[extraField.key]"
                  [compareWith]="compareExtras"
                  required>
                    <option *ngFor="let option of extraField.options" [ngValue]="option">{{ option.label || option }}</option>
                  </select>
                  <input *ngSwitchDefault
                  type="text" class="form-control input-sm"
                  name="{{ 'field_extra_' + extraField.key + '_' + i }}"
                  [(ngModel)]="field.extras[extraField.key]"
                  required
                  [disabled]="disabled">
                </td>
              </ng-container>
              <td class="sap small">
                <a [class.disabled]="disabled"
                (click)="removeField(i)"
                title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"><i class="material-symbols-rounded">delete</i></a>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  
    <table *ngIf="readonly && !hide?.includes('hectares')"
    class="table table-condensed table-hover">
      <thead>
        <tr>
          <th></th>
          <th class="text-right">{{ 'FINTECH.FIELDS_TABLE.HECTARES'|translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngIf="fields|pluck: 'field_size'|sum; let total">
        <ng-container *ngFor="let type of (fields|pluck: 'field_type'|unique); let last = last">
          <ng-container *ngTemplateOutlet="fieldRow; context: {
            label: ('FINTECH.FIELDS_TABLE.TYPES.' + type)|translate,
            partial: fields|filterBy: ['field_type']: type|pluck: 'field_size'|sum,
            total: total,
            last: last
          }"></ng-container>
        </ng-container>
        <ng-container *ngFor="let type of (fields|pluck: 'seeding_type.name'|unique); let last = last">
          <ng-container *ngTemplateOutlet="fieldRow; context: {
            label: type,
            partial: fields|filterBy: ['seeding_type.name']: type|pluck: 'field_size'|sum,
            total: total,
            last: last
          }"></ng-container>
        </ng-container>
        <ng-container *ngFor="let type of (fields|pluck: 'zone.description'|unique); let last = last">
          <ng-container *ngTemplateOutlet="fieldRow; context: {
            label: type,
            partial: fields|filterBy: ['zone.description']: type|pluck: 'field_size'|sum,
            total: total,
            last: last
          }"></ng-container>
        </ng-container>
        <tr class="active">
          <th>{{ 'GLOBAL.TOTAL'|translate }}</th>
          <td class="text-right">
            {{ total|number: '1.0-1': currentLang }}
            <span class="currency">ha.</span>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-template>

<ng-template #fieldRow
let-label="label"
let-partial="partial"
let-total="total"
let-last="last">
  <tr class="tr-align-center"
  *ngIf="label?.length > 1"
  [class.last]="last">
    <td>{{ label }}</td>
    <td class="text-right">
      {{ partial|number: '1.0-1': currentLang }}
      <span class="currency">ha.</span>
    </td>
    <td class="small text-right text-muted">{{ partial/total|percent: '0.0': currentLang }}</td>
  </tr>
</ng-template>

<ng-template #modal>
  <!-- modal -->
  <div class="modal-body">
    <p class="text-center text-muted">{{ 'FINTECH.FIELDS_TABLE.FILE_WARNING'|translate }}</p>
    <table class="table no-margin">
      <tbody>
        <tr class="tr-align-center">
          <th>{{ 'FINTECH.FIELDS_TABLE.FIELDS'|translate }}</th>
          <td class="bigger">{{ readedFields.length }}</td>
        </tr>
        <tr class="tr-align-center">
          <th>{{ 'FINTECH.FIELDS_TABLE.HECTARES'|translate }}</th>
          <td class="bigger">{{ readedFields|pluck:'field_size'|sum|number: '1.0-1': currentLang }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" [disabled]="processing" type="button" class="btn btn-warning btn-block" data-dismiss="modal">
      <span class="material-symbols-rounded">warning</span>
      {{ (options.table ? 'FINTECH.FIELDS_TABLE.FILE_WARNING_BUTTON' : 'GLOBAL.ACCEPT')|translate }}
    </button>
  </div>
</ng-template>

<ng-template #showSources>
  <ng-container *ngIf="sourceFiles?.length > 0">
    <p>
      <span class="material-symbols-rounded text-muted">attach_file</span>
      <a *ngFor="let fileId of sourceFiles; let i = index"
      [class.disabled]="disabled"
      (click)="preview(fileId)">
        <ng-container *ngIf="i === 0">{{ 'IMPORTER.SOURCE_FILE'|translate }}</ng-container>
        {{ sourceFiles.length > 1 ? ' [' + (i+1) + ']' : '' }}
      </a>
    </p>
    
    <!-- <p *ngIf="!sourceDiff && sourceParsed"
    class="text-success small">No se identifican diferencias entre el planteo productivo declarado y el obtenido del archivo proporcionado.</p> -->
  </ng-container>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'GLOBAL.NO_DATA'|translate }}
  </div>
</ng-template>