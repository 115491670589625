<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button
      type="button"
      class="close"
      [disabled]="processing"
      (click)="closeModal()"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ "LINKER.TITLE" | translate }}</h4>
  </div>
  <div class="modal-body">
    <p
      class="text-muted"
      [innerHtml]="'LINKER.DESCRIPTION_CONTRACT' | translate"
    ></p>
    <div class="row" *ngIf="contract.product">
      <!-- Actors -->
      <div class="col-sm-4">
        <table-detail [header]="'GLOBAL.PRODUCT' | translate">
          <h4>{{ contract.product.name }}</h4>
        </table-detail>
      </div>
      <div class="col-sm-4">
        <company-table
          [header]="'ORDER_SUMMARY.SELLER' | translate"
          header-class="sell"
          [activity]="false"
          [companies]="[contract.seller]"
        ></company-table>
      </div>
      <div class="col-sm-4">
        <company-table
          [header]="'ORDER_SUMMARY.BUYER' | translate"
          header-class="buy"
          [activity]="false"
          [companies]="[contract.buyer]"
        ></company-table>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <ng-container
          *ngTemplateOutlet="
            contractsTable;
            context: {
              contracts: [contract]
            }
          "
        ></ng-container>
        <div class="row">
          <connector [linked]="true"></connector>
        </div>

        <ng-container *ngIf="selectedContract">
          <h5>
            {{ "LINKER.SELECTED_CONTRACT" | translate }}
            <button
              type="button"
              class="btn btn-link btn-xs"
              (click)="selectedContract = undefined"
              [disabled]="processing"
              *ngIf="selectedContract"
            >
              {{ "GLOBAL.CLEAR_SELECTION" | translate }}
            </button>
          </h5>
          <ng-container
            *ngTemplateOutlet="
              contractsTable;
              context: {
                contracts: [selectedContract]
              }
            "
          ></ng-container>
        </ng-container>
        <ng-container *ngIf="!selectedContract">
          <h5>{{ "LINKER.CONTRACTS" | translate }}</h5>
          <ng-container *ngIf="availableMirrorContracts; else loading">
            <ng-container
              *ngIf="availableMirrorContracts.length; else noContract"
            >
              <p
                class="text-muted"
                [innerHtml]="'LINKER.SELECT_MIRROR_CONTRACT' | translate"
              ></p>
              <ng-container
                *ngTemplateOutlet="
                  contractsTable;
                  context: {
                    contracts: availableMirrorContracts
                  }
                "
              ></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      (click)="closeModal()"
      type="button"
      class="btn btn-link"
      [disabled]="processing"
      data-dismiss="modal"
    >
      {{ "GLOBAL.CANCEL" | translate }}
    </button>
    <button
      type="button"
      (click)="linkSelected()"
      class="btn btn-agree"
      [disabled]="processing || !selectedContract"
    >
      {{ "LINKER.LINK_CONTRACTS" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #contractsTable let-contracts="contracts">
  <div class="horizontal-scroller">
    <table
      *ngIf="contracts?.length > 0"
      class="table table-condensed"
      sortable
      defaultSortBy="-date"
      #tableSortable="tableSortable"
    >
      <thead>
        <tr class="tr-align-center">
          <th class="sap" sortBy="date">{{ "GLOBAL.DATE" | translate }}</th>
          <th class="sap" sortBy="reference">
            {{ "GLOBAL.CONTRACT" | translate }}
          </th>
          <th sortBy="delivery.locations[0].name">
            {{ "GLOBAL.DELIVERY_PLACES" | translate }}
          </th>
          <th class="dlb drb" sortBy="quantity.value">
            <abbr
              title
              tooltip="{{ 'GLOBAL.QUANTITY' | translate }}"
              [adaptivePosition]="false"
              >{{ "GLOBAL.QUANTITY_ABBR" | translate }}</abbr
            >
          </th>
          <th class="sap text-center" sortBy="contract.delivery.date_from">
            {{ "GLOBAL.PERIOD" | translate }}
          </th>
          <th class="sap" sortBy="price.value">
            {{ "GLOBAL.PRICE" | translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1"
              >/{{ company.market.quantity_units[0].abbrev }}</ng-container
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr-align-center"
          [class.clickable]="
            !selectedContract && contract.id !== contracts[0].id
          "
          [class.selected]="selectedContract?.id === c.id"
          (click)="selectedContract = c"
          *ngFor="let c of contracts | orderBy : tableSortable.sortBy"
        >
          <td class="sap micro text-muted">
            <date-md [date]="c.date"></date-md>
          </td>
          <td class="small sap">
            <samp>{{ c.reference }}</samp>
          </td>
          <td class="small">
            {{ c.delivery.locations | locationString }}
          </td>
          <td class="text-right dlb drb">
            <quantity-control
              [quantity]="c.quantity"
              [compact]="true"
            ></quantity-control>
          </td>
          <td class="sap">
            <date-range
              [from]="c.delivery.date_from"
              [to]="c.delivery.date_to"
            ></date-range>
          </td>
          <td class="sap">
            <div class="price-box booked-bg">
              <div
                class="type"
                [class.buy]="c.buyer.id === company.id"
                [class.sell]="c.seller.id === company.id"
              >
                {{
                  (c.buyer.id === company.id
                    ? "COMPANY_TRADED_VIEW.BOUGHT"
                    : c.seller.id === company.id
                    ? "COMPANY_TRADED_VIEW.SOLD"
                    : ""
                  ) | translate
                }}
              </div>
              <div class="value">
                <price-display
                  [price]="c.price"
                  [market]="company.market"
                  [withoutYear]="true"
                ></price-display>
              </div>
            </div>
          </td>
          <!-- <ng-container *ngIf="!environment.production">
            <td class="text-right dlb" *ngIf="environment.modules.unloads">
              {{ (c.quantity.value * 0.7)|number:undefined:currentLang }}
              <span class="currency">{{ c.quantity.unit.abbrev }}.</span>
              &nbsp;<progress-circle [progress]="0.7" size="13"></progress-circle>
            </td>
            <td class="text-right drb" *ngIf="environment.modules.invoices">
              <price-display [price]="{
                type: 'flat',
                unit: {
                  name: 'AR$'
                },
                value: 12345678
              }"></price-display>
            </td>
          </ng-container> -->
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noContract>
  <div
    class="text-danger"
    [innerHtml]="'LINKER.NO_CONTRACTS' | translate"
  ></div>
</ng-template>
