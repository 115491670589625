import { Type } from "class-transformer";

import { Negotiation } from "../company/modules/commercial/models/negotiation.model";
import { Order } from "../company/modules/commercial/models/order.model";
import { Company } from "./company.model";

/**
 * A Barter is an industry practice, whereby a farmer pays for services or
 * supplies (eg, fertilizers, equipment, seeds, etc.) with his
 * [[Product|produce]] (payment in kind).
 *
 * The management of a Barter can be outsourced to a third-party (such as a
 * broker), who is responsible not only for agreeing the conditions with the
 * farmer but also for finding a buyer for those [[Product|products]].
 *
 * All Barters are children of a [[BarterProposal|Barter proposal]] and are
 * containers of one or multiple [[Order|Orders]].
 *
 * ### Related UI components:
 * - [[ViewBarterComponent]]
 * - [[CreateBarterComponent]]
 */
export class Barter {

  /** Agree's internal unique identifier. */
  readonly id: number;
  /**
   * [[Company]] responsible for the Barter management. It may be the input
   * supplier itself or a third party..
   */
  recipient: Company;

  /**
   * ### Possible values
   * | id | name   |
   * |---:|--------|
   * |  1 | Open   |
   * |  2 | Closed |
   */
  status: {
    id: number;
    name: string;
  }

  /**
   * All the [[Order|Orders]] contained in this Barter. There is an [[Order]]
   * for each [[Product|product]]/terms (eg, an [[Order]] for soybeans with
   * delivery in July and another for soybeans with delivery in December).
   *
   * A farmer may use part of one [[Product|product]] and part of another as
   * payment.
   */
  @Type(() => Order)
  orders: Order[];

  /**
   * All the [[Negotiation|Negotiations]] of the Barter's active
   * [[Order|Orders]] share the same status. Which is considered the status of
   * the Barter.
   *
   * For possible values check: [[Negotiation.status]]
   */
  get negotiation_status(): { id: number, name?: string } {
    let firstNego: Negotiation,
      status = {
        id: 2
      };

    if (this.orders.length && this.orders[0].negotiations.length) {
      firstNego = this.orders[0].negotiations[0];
      status = firstNego.status;
    } else if (this.status.id === 2) {
      status.id = 6;
    }

    return status;
  }
}
