<ng-template #createInvoice>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'INVOICES.CREATE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="create-invoice-form" #createInvoiceForm="ngForm" (ngSubmit)="createInvoiceForm.valid && create()">
      <div class="row">
        <!-- Number -->
        <div class="col-md-4">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.INVOICE_NUMBER'|translate }}</label>
            <input type="text" name="invoice-number" class="form-control"
            placeholder="{{ invoiceNumberMask }}"
            [(ngModel)]="invoice.reference" required
            [disabled]="processing"
            [modalFocus]="true">
            <error-list></error-list>
          </div>
        </div>
        <!-- Date -->
        <div class="col-md-4">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.ISSUED'|translate }}</label>
            <div class="input-group">
              <div class="input-group-addon">
                <i class="material-symbols-rounded">date_range</i>
              </div>
              <input type="text" name="date" class="form-control" bsDatepicker
              placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" required
              [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
              [(ngModel)]="invoice.date" [disabled]="processing" readonly>
            </div>
            <error-list></error-list>
          </div>
        </div>
        <!-- Due Date -->
        <div class="col-md-4">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.DUE_DATE'|translate }}
              <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <div class="input-group">
              <div class="input-group-addon">
                <i class="material-symbols-rounded">date_range</i>
              </div>
              <input type="text" name="due-date" class="form-control" bsDatepicker
                placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}"
                [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                [(ngModel)]="invoice.due_date" [disabled]="processing" readonly>
              <button type="button"
              class='delete-date close' *ngIf="invoice.due_date" (click)="invoice.due_date = undefined">&times;</button>
            </div>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- SELECT:ISSUED_BY -->
        <div class="col-md-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.ISSUED_BY'|translate }}</label>
            <selectize *ngIf="!invoice.company" required [maxItems]="1"
            [searchURL]="companyService.getCompanies(company.id)"
            [(ngModel)]="invoice.company" name="issued-by"
            placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}" [disabled]="processing"></selectize>
            <error-list></error-list>
            <div *ngIf="invoice.company" class="editable-pill">
              <button [disabled]="processing" type="button" class="close" (click)="invoice.company = null"><span
                  aria-hidden="true">&times;</span></button>
              <company-info [company]="invoice.company"></company-info>
            </div>
          </div>
        </div>
        <!-- SELECT:ISSUED_TO -->
        <div class="col-md-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'INVOICES.ISSUED_TO'|translate }}</label>
            <selectize *ngIf="!invoice.recipient" required [maxItems]="1"
            [searchURL]="companyService.getCompanies(company.id, {
              excluded_ids: [company.id]
            })"
            [(ngModel)]="invoice.recipient" name="recipient" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
            [disabled]="processing"></selectize>
            <error-list></error-list>
            <div *ngIf="invoice.recipient" class="editable-pill">
              <button [disabled]="processing" type="button" class="close" (click)="invoice.recipient = null"><span
                  aria-hidden="true">&times;</span></button>
              <company-info [company]="invoice.recipient"></company-info>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'TRUCKS.ACTORS.INTERMEDIARY'|translate }}</label>
            <selectize *ngIf="!invoice.broker" [maxItems]="1"
            [searchURL]="companyService.getCompanies(company.id)"
            [(ngModel)]="invoice.broker" name="broker" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
            [disabled]="processing"></selectize>
            <error-list></error-list>
            <div *ngIf="invoice.broker" class="editable-pill">
              <button [disabled]="processing" type="button" class="close" (click)="invoice.broker = null"><span
                  aria-hidden="true">&times;</span></button>
              <company-info [company]="invoice.broker"></company-info>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'INVOICES.CONCEPT'|translate }} <small class="text-muted">({{
                'GLOBAL.OPTIONAL'|translate }})</small></label>
            <input [disabled]="processing" [(ngModel)]="invoice.concept.type" maxlength="64" autocomplete="off"
              type="text" name="concept" class="form-control">
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="invoice.unit">
        <!-- Neto -->
        <div class="col-sm-4">
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'INVOICES.NET'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <div class="input-group">
              <div class="input-group-addon">
                {{ invoice.unit.name }}
                <!-- <i class="material-symbols-rounded">date_range</i> -->
              </div>
              <input type="number" class="form-control"
              name="net" [(ngModel)]="invoice.net"
              (ngModelChange)="calculateBalance()"
              placeholder="0,00"
              step="0.01"
              [min]="0"
              [disabled]="processing">
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            </div>
            <error-list></error-list>
          </div>
        </div>
        <!-- Impuestos -->
        <div class="col-sm-4">
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'FINTECH.TAXES'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <div class="input-group">
              <div class="input-group-addon">
                {{ invoice.unit.name }}
                <!-- <i class="material-symbols-rounded">date_range</i> -->
              </div>
              <input type="number" class="form-control"
              name="taxes" [(ngModel)]="invoice.taxes"
              (ngModelChange)="calculateBalance()"
              placeholder="0,00"
              step="0.01"
              [min]="0"
              [disabled]="processing">
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            </div>
            <error-list></error-list>
          </div>
        </div>
        <!-- Balance -->
        <div class="col-sm-4">
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'GLOBAL.TOTAL'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <div class="input-group">
              <div class="input-group-btn">
                <div dropdown agSelect name="unit" class="btn-group"
                  [class.single-unit]="currencies?.length <= 1"
                  [(ngModel)]="invoice.unit">
                  <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                    data-toggle="dropdown"
                    [class.disabled]="processing"
                    aria-haspopup="true" aria-expanded="false">
                    {{ invoice.unit?.name }}
                    <span class="caret"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let c of currencies" [agOption]="c"><a>{{ c.name }}</a></li>
                  </ul>
                </div><!-- /btn-group -->
              </div>
              <input type="number" class="form-control"
              name="balance" [(ngModel)]="invoice.balance"
              placeholder="0,00"
              step="0.01"
              [min]="0"
              [disabled]="processing">
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            </div>
            <error-list></error-list>
          </div>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="errorMessage">
      <div class="col-xs-12">
        <alert type="danger" [dismissible]="true" (onClosed)="errorMessage = undefined">
          <b>{{ 'IMPORTER.ERROR'|translate }}</b> {{ errorMessage }}
        </alert>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="create-invoice-form" class="btn btn-agree" [disabled]="processing">{{
      'GLOBAL.SAVE'|translate }}</button>
  </div>
</ng-template>

<ng-template #invoiceModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ 'GLOBAL.INVOICE_NUMBER'|translate }}: <samp>{{ invoice?.reference| mask: '0000-00000000' }}</samp>
      <ag-invoice-actions
      [company]="company"
      [invoice]="invoice"
      container=".modal-header"></ag-invoice-actions>
    </h4>
    <div *ngIf="invoice">
      <span *ngIf="invoice.isSettled" class="ag-label label-success"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> {{ 'INVOICES.COVERED'|translate }}</span>
      <span *ngFor="let label of invoice.labels"
      class="ag-label" [style.backgroundColor]="label.color">{{ label.name }}</span>
    </div>
  </div>
  <div class="modal-body" id="invoiceDrop">
    <div class="loading" *ngIf="!invoice">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <ng-container *ngIf="invoice">
      <div class="row">
        <div class="col-xs-7">
          <company-table
          *ngIf="invoice.company"
          [header]="'GLOBAL.ISSUED_BY'|translate"
          [activity]="false"
          [companies]="[invoice.company]"></company-table>
          <company-table
          *ngIf="invoice.recipient"
          [header]="'INVOICES.ISSUED_TO'|translate"
          [activity]="false"
          [companies]="[invoice.recipient]"></company-table>
          <company-table
          *ngIf="invoice.broker"
          [header]="'TRUCKS.ACTORS.INTERMEDIARY'|translate"
          [activity]="false"
          [companies]="[invoice.broker]"></company-table>
        </div>
        <div class="col-xs-5">
          <table class="table table-condensed table-borderless">
            <tbody>
              <tr class="tr-align-center">
                <th class="text-right small hidden-xs text-muted">{{ 'GLOBAL.ISSUED'|translate }}:</th>
                <td class="sap">
                  <div class="visible-xs small text-muted"><b>{{ 'GLOBAL.ISSUED'|translate }}:</b></div>
                  <div><date-md [date]="invoice.date"></date-md></div>
                </td>
              </tr>
              <tr class="tr-align-center">
                <th class="text-right small hidden-xs text-muted">{{ 'GLOBAL.DUE_DATE'|translate }}:</th>
                <td class="sap">
                  <div class="visible-xs small text-muted"><b>{{ 'GLOBAL.DUE_DATE'|translate }}:</b></div>
                  <div><date-md [date]="invoice.due_date" [style]="true"></date-md></div>
                </td>
              </tr>
              <tr class="tr-align-center" *ngIf="invoice.concept">
                <th class="text-right small hidden-xs text-muted">{{ 'GLOBAL.TYPE'|translate }}:</th>
                <td class="sap">
                  <div class="visible-xs small text-muted"><b>{{ 'GLOBAL.TYPE'|translate }}:</b></div>
                  <div>{{ invoice.concept.type }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="invoice.items && invoice.items.length > 0">
        <div class="col-xs-12">
          <div class="horizontal-scroller">
            <table class="table table-condensed table-hover">
              <thead>
                <tr>
                  <th class="sap hidden-xs"><abbr title="{{ 'INVOICES.CONCEPT'|translate }}">{{ 'INVOICES.CONCEPT_ABBR'|translate }}</abbr></th>
                  <th>{{ 'INVOICES.DESCRIPTION'|translate }}</th>
                  <th class="sap"></th>
                  <th class="sap text-right">{{ 'GLOBAL.PRICE'|translate }}</th>
                  <th class="sap hidden-xs text-right"><abbr title="{{ 'GLOBAL.PERCENTAGE'|translate }}">{{ 'INVOICES.PERCENTAGE_ABBR'|translate }}</abbr></th>
                  <th class="sap text-right">{{ 'INVOICES.NET'|translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of invoice.items" class="tr-align-center">
                  <td class="sap hidden-xs small text-muted"><samp [title]="item.concept.type">{{ item.concept.id }}</samp></td>
                  <td>{{ item.description }}</td>
                  <td class="sap text-right micro">
                    <span *ngIf="item.quantity && item.quantity.value > 0">{{ item.quantity.value|number:'1.3-3':currentLang }} <small class="text-muted">{{ item.quantity.unit.abbrev }}</small></span>
                  </td>
                  <td class="sap text-right small"><price [unit]="invoice.unit.name" [value]="item.price"></price></td>
                  <td class="sap hidden-xs text-right micro">
                    <span *ngIf="item.percentage >= 0">{{ item.percentage|number:'1.2-2':currentLang }}<small class="text-muted">%</small></span>
                  </td>
                  <td class="sap text-right"><price [unit]="invoice.unit.name" [value]="item.net"></price></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="hidden-xs hidden-sm col-md-4"></div>
        <div class="col-xs-6 col-md-4">
          <table class="table table-detail table-condensed table-borderless">
            <tbody>
              <tr class="tr-align-center" *ngIf="invoice.quantity">
                <th class="text-right small text-muted">{{ 'GLOBAL.QUANTITY'|translate }}:</th>
                <td class="sap text-right">
                  <span *ngIf="invoice.quantity && invoice.quantity.value > 0">{{ invoice.quantity.value|number:'1.3-3':currentLang }} <small class="text-muted">{{ invoice.quantity.unit.abbrev }}</small></span>
                </td>
              </tr>
              <tr class="tr-align-center" *ngIf="invoice.taxed">
                <th class="text-right small text-muted">{{ 'INVOICES.TAXED'|translate }}:</th>
                <td class="sap text-right"><price [unit]="invoice.unit.name" [value]="invoice.taxed"></price></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xs-6 col-md-4">
          <table class="table table-detail table-condensed table-borderless">
            <tbody>
              <tr class="tr-align-center" *ngIf="invoice.net">
                <th class="text-right small text-muted">{{ 'INVOICES.NET'|translate }}:</th>
                <td class="sap text-right"><price [unit]="invoice.unit.name" [value]="invoice.net"></price></td>
              </tr>
              <tr class="tr-align-center" *ngIf="invoice.tax || invoice.taxes">
                <th class="text-right small text-muted">
                  IVA<span *ngIf="invoice.tax"> ({{ invoice.tax }}%)</span>:
                </th>
                <td class="sap text-right"><price *ngIf="invoice.taxes" [unit]="invoice.unit.name" [value]="invoice.taxes"></price></td>
              </tr>
              <tr class="tr-align-center grayed">
                <th class="text-right small">{{ 'GLOBAL.TOTAL'|translate }}:</th>
                <td class="sap text-right"><price [unit]="invoice.unit.name" [value]="invoice.balance"></price></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8"
        *ngIf="invoice.observations">
          <table-detail [header]="'GLOBAL.OBSERVATIONS'|translate">{{ invoice.observations }}</table-detail>
        </div>
        <div class="col-sm-4">
          <table-detail [header]="'GLOBAL.PAYMENT_METHOD'|translate"
          *ngIf="invoice.payment_method">{{ invoice.payment_method }}</table-detail>
          <table-detail [header]="'GLOBAL.CROP_YEAR'|translate"
          *ngIf="invoice.crop">{{ invoice.crop }}</table-detail>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <attachments
          entity="invoice"
          [entity-id]="invoice.id"
          [company]="company"
          drop-area="#invoiceDrop"></attachments>  
        </div>
      </div>
      <div class="row"
      *ngIf="barter_proposals?.length">
        <div class="col-xs-12">
          <h4>{{ 'COMPANY.BARTERS'|translate }}</h4>

          <div class="horizontal-scroller">
            <table class="table table-condensed" [sortable]="barter_proposals" defaultSortBy="-updated_at" #tableSortable="tableSortable">
              <!-- <caption></caption> -->
              <thead>
                <tr class="tr-align-center">
                  <th class="sap hidden-xs"></th>
                  <th class="sap" sortBy="updated_at">
                    <span class="hidden-xs">{{ 'GLOBAL.DATE'|translate }}</span>
                    <i class="material-symbols-rounded visible-xs">date_range</i>
                  </th>
                  <th>{{ 'GLOBAL.INVOICE_NUMBER'|translate }}</th>
                  <th class="hidden-xs sap">{{ 'GLOBAL.DUE_DATE'|translate }}</th>
                  <th class="sap text-right" sortBy="invoicesBalance.value">{{ 'BARTERS.AMOUNT'|translate }}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let barter_proposal of tableSortable.collection">
                  <!-- Order row -->
                  <tr class="tr-align-center order-row" [class.tr-danger]="barter_proposal.status.id === 2">
                    <td class="sap hidden-xs">
                      <i class="material-symbols-rounded text-danger" tooltip="{{ barter_proposal.status.name }}" data-placement="right"
                        *ngIf="barter_proposal.status.id === 2">highlight_off</i>
                    </td>
                    <td class="sap">
                      <ag-timestamp [date]="barter_proposal.updated_at"></ag-timestamp>
                    </td>
                    <td class="small">
                      <div *ngFor="let bpinvoice of barter_proposal.invoices">
                        <samp [class.highlighted-text]="invoice.reference === bpinvoice.invoice.reference">{{ bpinvoice.invoice.reference| mask: '0000-00000000' }}</samp>
                        <span *ngIf="!bpinvoice.total">&nbsp;
                          <span class="glyphicon glyphicon-exclamation-sign text-warning" tooltip="{{ 'BARTERS.PARTIAL'|translate }}"
                            [adaptivePosition]="false" aria-hidden="true"></span>&nbsp;
                          <span class="text-muted hidden-xs">
                            <price [unit]="bpinvoice.amount.unit.name"
                              [value]="bpinvoice.amount.value"></price>    
                          </span>
                        </span>
                      </div>
                    </td>
                    <td class="hidden-xs small sap">
                      <div *ngFor="let bpinvoice of barter_proposal.invoices">
                        <date-md *ngIf="bpinvoice.invoice.due_date; else none" [date]="bpinvoice.invoice.due_date"
                          [style]="true"></date-md>
                      </div>
                    </td>
                    <td class="sap text-right">
                      <price [unit]="barter_proposal.invoicesBalance.unit.name"
                        [value]="barter_proposal.invoicesBalance.value"></price>
                      <!-- <div class="visible-xs visible-sm small" [class.text-danger]="today.getTime() >= barter_proposal.invoice.due_date.getTime()">{{ 'BARTERS.DUE_ABBR'|translate }}:{{ barter_proposal.invoice.due_date|date: ('GLOBAL.DATE_FORMAT.SM'|translate) }}</div>
                        <div *ngIf="barter_proposal.invoice.id" class="visible-xs visible-sm small text-muted"><samp title="{{ 'GLOBAL.INVOICE_NUMBER'|translate }}" class="truncate">{{ barter_proposal.invoice.id| mask: '0000-00000000' }}</samp></div> -->
                    </td>
                  </tr>
                  <tr
                    *ngIf="barter_proposal.barters.length || (barter_proposal.status.id === 1 && barter_proposal.supplier.id === company.id)">
                    <td class="td-empty hidden-xs"></td>
                    <td class="td-empty"></td>
                    <td colspan="3" class="co-td">
                      <div class="co-title text-uppercase micro" *ngIf="barter_proposal.barters.length"
                        [innerHtml]="'BARTERS.NUMBER_PROPOSALS'|translate:{quantity:barter_proposal.barters.length}"></div>
                      <div class="tree">
                        <div class="co-container" *ngFor="let barter of barter_proposal.barters">
                          <div class="co-logo hidden-xs">
                            <company-logo [company]="barter.recipient"></company-logo>
                          </div>
                          <div class="co-link truncate">
                            <a *ngIf="barter.id; else noId"
                            (click)="goToBarter(barter.id)"
                            title="{{ barter.recipient.name }}">
                              <samp class="small">#{{ barter.id }}:</samp>
                              <b [class.highlighted-text]="company.id === barter.recipient.id">{{ barter.recipient.name }}</b>
                            </a>
                            <ng-template #noId><b>{{ barter.recipient.name }}</b></ng-template>
                          </div>
                          <div class="co-status text-uppercase"
                          (click)="goToBarter(barter.id)">
                            <ng-container *ngIf="[1, 2, 19].includes(barter.negotiation_status.id)">
                              <ng-container *ngIf="company.id === barter.recipient.id ||
                                company.id === barter_proposal.customer.id; else thirdParty">
                                <!-- Recipient or Customer -->
                                <div class="truncate status-warning"
                                  *ngIf="(barter.negotiation_status.id === 1 && company.id !== barter.recipient.id) ||
                                  ([2, 19].includes(barter.negotiation_status.id) && company.id === barter.recipient.id); else received">
                                  <span>{{ 'BARTERS.STATUS.RECEIVED'|translate }}</span>
                                </div>
                                <ng-template #received>
                                  <div class="truncate status-info">
                                    <span>{{ 'BARTERS.STATUS.SENT'|translate }}</span>
                                  </div>
                                </ng-template>
                              </ng-container>
                              <ng-template #thirdParty>
                                <div class="truncate status-default">
                                  <span>{{ 'BARTERS.STATUS.IN_PROGRESS'|translate }}</span>
                                </div>
                              </ng-template>
                            </ng-container>
                            <div class="truncate status-danger" *ngIf="barter.negotiation_status.id === 6">
                              <span>{{ 'BARTERS.STATUS.REJECTED'|translate }}</span>
                            </div>
                            <div class="truncate status-success" *ngIf="barter.negotiation_status.id === 7">
                              <span>{{ 'BARTERS.STATUS.ACCEPTED'|translate }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="bartersEnabled &&
      canCreateBarter &&
      !invoice.isSettled &&
      invoice.balance > 0">
        <div class="col-xs-12 small">
          <a [routerLink]="['/company', company.id, 'create-barter', invoice.id]">{{ 'INVOICES.CANCEL_BARTER'|translate }}</a>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>