import { Type } from "class-transformer";
import { Company } from "../../../../models/company.model";

export class RetiroProductor {
  certificado_coe: any
  cuit_remitente_comercial_productor: any
}

export class Place {
  cuit: number
  provincia?: string
  localidad?: string
  planta?: number
}

export class Intervinientes {
  remitente_comercial_venta_primaria?: Company;
  remitente_comercial_venta_secundaria?: Company;
  remitente_comercial_venta_secundaria_2?: Company;
  mercado_a_termino?: Company;
  corredor_venta_primaria?: Company;
  corredor_venta_secundaria?: Company;
  representante_entregador?: Company;
  representante_recibidor?: Company;
}

export class DatosCarga {
  codigo_grano: number;
  peso_bruto: number;
  peso_tara: number;
  cosecha: number;
  peso_bruto_descarga: number;
  peso_tara_descarga: number;
  product?: any;
}

export class Transporte {
  cuit_transportista: number;
  dominio: string;
  fecha_partida: string;
  km_recorrer: number;
  codigo_turno: any;
  cuit_chofer: number;
  tarifa_referencia: number;
  tarifa: number;
  cuit_pagador_flete: number;
  cuit_intermediario_flete: any;
  mercaderia_fumigada: boolean;
}

export class CartaPorteElectronica {
  /** Agree's internal unique identifier. */

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  importer_id: number;
  tipo_carta_porte: number;
  sucursal: number;
  nro_orden: number;
  planta: any;
  /**
   * Numerical code issued by the Argentine state that must be consigned in
   * each Waybill that covers the transfer of grains. It is obtained prior to
   * transportation and must be linked to a valid Waybill.
   *
   * Maximum characters: 12.
   */
  numero_ctg?: number;
  fecha_emision: string;
  estado: string;
  @Type(() => Date)
  fecha_inicio_estado: Date;

  @Type(() => Date)
  fecha_vencimiento: Date;

  observaciones: string;
  @Type(() => Place)
  origen: Place
  corresponde_retiro_productor: boolean;

  @Type(() => RetiroProductor)
  retiro_productor: RetiroProductor;

  @Type(() => Intervinientes)
  intervinientes: Intervinientes;

  @Type(() => DatosCarga)
  datos_carga: DatosCarga;

  @Type(() => Place)
  destino: Place;

  cuit_destinatario: number;

  @Type(() => Transporte)
  transporte: Transporte;

  file_url?: string;

  constructor(data: Partial<CartaPorteElectronica> = {}) {
    Object.assign(this, data);
  }
}

