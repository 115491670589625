<ng-container *ngIf="metadata">
  <div class="flex-center-distributed">
    <h4 class="grow">{{ 'METADATA.TITLE'|translate }}
      <button class="btn btn-sm btn-link"
      type="button"
      (click)="view_mode = (view_mode === 'NORMAL' ? 'TABLE' : 'NORMAL')"
      [disabled]="processing">
        <span class="material-symbols-rounded">table_view</span>
      </button>
    </h4>
    <div class="checkbox" *ngIf="!editable">
      <label class="no-select">
        <input type="checkbox"
        name="{{ UUID }}-important"
        [(ngModel)]="importantOnly"> {{ 'METADATA.IMPORTANT_ONLY'|translate }}
          <span class="material-symbols-rounded text-warning faded">grade</span>
      </label>
    </div>
    <button class="btn btn-link" title="{{ 'GLOBAL.EDIT'|translate }}"
    *ngIf="!readonly"
    type="button"
    (click)="editable = !editable"
    [disabled]="processing"><i
    class="material-symbols-rounded">edit</i></button>
  </div>
  <div class="meta-panel m-b2">
    <ng-container *ngIf="view_mode === 'NORMAL' || editable">
      <form #metaForm="ngForm" (ngSubmit)="save()">
        <div class="flex-row">
          <ng-container *ngFor="let field of metadata|keyvalue: orderOrder">
            <div class="flex-col" *ngIf="field.value.value &&
            field.value.input &&
            (editable || (!importantOnly || field.value.important))">
              <table-detail
              [header-title]="field.value.label"
              [header]="(!importantOnly && field.value.important ? '<span class=\'material-symbols-rounded text-warning faded\'>grade</span> ' : '') + field.value.label">
                <ng-container *ngIf="!editable">
                  <ng-container *ngTemplateOutlet="fieldValue; context: {
                    field: field.value
                  }"></ng-container>
                </ng-container>
  
                <div *ngIf="editable" class="form-group"
                [ngSwitch]="field.value.input.type">
                  <input *ngSwitchCase="'date'"
                  type="text" class="form-control"
                  bsDatepicker
                  name="{{ UUID }}-{{ field.key }}"
                  placeholder="{{ field.value.input.placeholder || ('GLOBAL.DATE_PLACEHOLDER'|translate) }}"
                  [bsConfig]="{ showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY' }"
                  [(ngModel)]="metadata_temp[field.key].value"
                  [disabled]="processing"
                  readonly>
                  <div *ngSwitchDefault
                  [class.input-group]="field.value.addon">
                    <span class="input-group-addon" *ngIf="field.value.addon?.left">{{ field.value.addon.left }}</span>
                    <input class="form-control"
                    name="{{ UUID }}-{{ field.key }}"
                    type="{{ field.value.input.type }}"
                    placeholder="{{ field.value.input.placeholder }}"
                    [attr.min]="field.value.input.min" [attr.max]="field.value.input.max"
                    [step]="field.value.input.step"
                    [maxlength]="field.value.input.maxlength"
                    autocomplete="off"
                    [disabled]="processing"
                    [(ngModel)]="metadata_temp[field.key].value">
                    <span class="input-group-addon" *ngIf="field.value.addon?.right">{{ field.value.addon.right }}</span>
                  </div>
                </div>
              </table-detail>
            </div>
          </ng-container>
        </div>
        <div class="row" *ngIf="editable">
          <div class="col-sm-offset-8 col-sm-4">
            <button
            type="submit" class="btn btn-block btn-default"
            [disabled]="processing || metaForm.pristine">
              {{ 'GLOBAL.SAVE_CHANGES'|translate }}
            </button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="view_mode === 'TABLE' && !editable">
      <table class="no-margin table table-condensed table-striped">
        <tbody>
          <ng-container *ngFor="let field of metadata|keyvalue: orderOrder">
            <tr *ngIf="field.value.value &&
            field.value.input &&
            (!importantOnly || field.value.important)"
            class="tr-align-center">
              <th class="sap">{{ field.value.label }}
                <span *ngIf="!importantOnly && field.value.important"
                class="material-symbols-rounded text-warning faded">grade</span>
              </th>
              <td>
                <ng-container *ngTemplateOutlet="fieldValue; context: {
                  field: field.value
                }"></ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container>

<ng-template #fieldValue let-field="field">
  <span class="currency" *ngIf="field.addon?.left">{{ field.addon.left }}&nbsp;</span>
  <ng-container [ngSwitch]="field.input.type">
    <ng-container *ngSwitchCase="'number'">
      <ng-container *ngIf="field.input.decimalPipe">{{ field.value|number:field.input.decimalPipe }}</ng-container>
      <ng-container *ngIf="field.input.percentPipe">{{ field.value|percent:field.input.percentPipe }}</ng-container>
      <ng-container *ngIf="!field.input.percentPipe && !field.input.decimalPipe">{{ field.value }}</ng-container>
    </ng-container>
    <date-md *ngSwitchCase="'date'" [date]="field.value"></date-md>
    <span *ngSwitchDefault
    [class.small]="field.value.length > 24">{{ field.value }}</span>
  </ng-container>
  <span class="currency" *ngIf="field.addon?.right">&nbsp;{{ field.addon.right }}</span>
</ng-template>