<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((editMode && contract) ? 'IMPORTER.EDITING_CONTRACT' : 'IMPORTER.ADD_CONTRACT')|translate: {id: contract?.reference} }}</h4>
  </div>
  <div class="modal-body">
    <!-- <div class="loading" *ngIf="!contract">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div> -->

    <form id="contract-form" #contractForm="ngForm" (ngSubmit)="contractForm.valid && submit()">
      <div class="row">
        <!-- Date -->
        <div class="col-sm-4">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.DATE'|translate }}</label>
            <div class="input-group">
              <div class="input-group-addon">
                <i class="material-symbols-rounded">date_range</i>
              </div>
              <input type="text" name="date" class="form-control" bsDatepicker
              placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}"
              required
              [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
              [(ngModel)]="contract.date"
              [disabled]="processing"
              readonly>
            </div>
            <error-list></error-list>
          </div>
        </div>
        <!-- Reference -->
        <div class="col-sm-8">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'IMPORTER.CONTRACT_NUMBER'|translate }}</label>
            <input type="text" name="reference" class="form-control"
            required
            pattern="^[a-zA-Z0-9-_]+$"
            [(ngModel)]="contract.reference"
            [disabled]="processing"
            maxlength="36">
            <error-list></error-list>
            <p class="help-block small">{{ 'IMPORTER.CONTRACT_NUMBER_HELP'|translate }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <!-- SELECT:Seller -->
          <div class="form-group" hasError>
            <label class="control-label">{{ 'ORDER_SUMMARY.SELLER'|translate }}</label>
            <selectize
              *ngIf="!contract.seller"
              [class.hidden]="noResultsFor.seller"
              required
              [maxItems]="1"
              [searchURL]="companyService.getCompanies(company.id, {
                all: true,
                sellers: true,
                excluded_ids: [contract.buyer?.id, contract.brokers[0]?.id]
              })"
              [(ngModel)]="contract.seller"
              name="seller"
              placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              (load)="companyTypeLoad('seller', $event)"
              [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="contract.seller" class="editable-pill">
              <button type="button"
              *ngIf="!disableSelectizeFor.seller"
              [disabled]="processing" type="button" class="close"
              (click)="contract.seller = undefined"><span aria-hidden="true">&times;</span></button>
              <company-info [company]="contract.seller"></company-info>
            </div>
            <alert *ngIf="noResultsFor.seller" type="warning"><span [innerHTML]="'IMPORTER.NO_COMPANIES'|translate: {companyId: company.id}"></span></alert>
            <div class="help-block"
            *ngIf="company.activity.seller &&
            contract.seller?.id !== company.id &&
            ![contract.buyer?.id, contract.brokers[0]?.id].includes(company.id)">
              <a class="small" (click)="contract.seller = company"
              [class.disabled]="processing">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <!-- SELECT:Buyer -->
          <div class="form-group" hasError>
            <label class="control-label">{{ 'ORDER_SUMMARY.BUYER'|translate }}</label>
            <selectize
              *ngIf="!contract.buyer"
              [class.hidden]="noResultsFor.buyer"
              required
              [maxItems]="1"
              [searchURL]="companyService.getCompanies(company.id, {
                all: true,
                buyers: true,
                excluded_ids: [contract.seller?.id, contract.brokers[0]?.id]
              })"
              [(ngModel)]="contract.buyer"
              name="buyer"
              placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              (load)="companyTypeLoad('buyer', $event)"
              [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="contract.buyer" class="editable-pill">
              <button type="button"
              *ngIf="!disableSelectizeFor.buyer"
              [disabled]="processing" type="button" class="close"
              (click)="contract.buyer = undefined"><span aria-hidden="true">&times;</span></button>
              <company-info [company]="contract.buyer"></company-info>
            </div>
            <alert *ngIf="noResultsFor.buyer" type="warning"><span [innerHTML]="'IMPORTER.NO_COMPANIES'|translate: {companyId: company.id}"></span></alert>
            <div class="help-block"
            *ngIf="company.activity.buyer &&
            contract.buyer?.id !== company.id &&
            ![contract.seller?.id, contract.brokers[0]?.id].includes(company.id)">
              <a class="small" (click)="contract.buyer = company"
              [class.disabled]="processing">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <!-- SELECT:Broker -->
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'GLOBAL.BROKER'|translate }}
              <small *ngIf="!disableSelectizeFor.broker" class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <selectize
              *ngIf="!contract.brokers[0]"
              [class.hidden]="noResultsFor.broker"
              [maxItems]="1"
              [searchURL]="companyService.getCompanies(company.id, {
                all: true,
                brokers: true,
                excluded_ids: [contract.buyer?.id, contract.seller?.id]
              })"
              [(ngModel)]="contract.brokers[0]"
              name="broker"
              placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
              (load)="companyTypeLoad('broker', $event)"
              [disabled]="processing">
            </selectize>
            <error-list></error-list>
            <div *ngIf="contract.brokers[0]" class="editable-pill">
              <button type="button"
              *ngIf="!disableSelectizeFor.broker"
              [disabled]="processing" type="button" class="close"
              (click)="contract.brokers[0] = undefined"><span aria-hidden="true">&times;</span></button>
              <company-info [company]="contract.brokers[0]"></company-info>
            </div>
            <alert *ngIf="noResultsFor.broker" type="warning"><span [innerHTML]="'IMPORTER.NO_COMPANIES'|translate: {companyId: company.id}"></span></alert>
            <div class="help-block"
            *ngIf="company.activity.broker &&
            contract.brokers[0]?.id !== company.id &&
            ![contract.buyer?.id, contract.seller?.id].includes(company.id)">
              <a class="small" (click)="contract.brokers[0] = company"
              [class.disabled]="processing">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
            </div>
          </div>
        </div>
        
      </div>
      <div class="row">
        <!-- Product -->
        <div class="col-sm-8">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.PRODUCT'|translate }}</label>
            <select class="form-control" name="product"
            [(ngModel)]="contract.product" [compareWith]="compareId"
            (ngModelChange)="changeProduct()"
            [disabled]="processing" required>
              <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT_PRODUCT'|translate }}</option>
              <option *ngFor="let prod of products" [ngValue]="prod">{{ prod.name }}</option>
            </select>
            <error-list></error-list>
            <a class="small"
            (click)="hubSpotService.open()"
            [class.disabled]="processing">{{ 'ORDER_FORM.PRODUCT.NOT_LISTED'|translate }}</a>
          </div>
        </div>
        <!-- Crop -->
        <div class="col-sm-4">
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'GLOBAL.CROP_YEAR'|translate }}
              <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <select class="form-control" name="harvest"
            [(ngModel)]="contract.crop"
            [disabled]="processing">
              <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'GLOBAL.CROP_YEAR_PLACEHOLDER'|translate }}</option>
              <option *ngFor="let crop of cropList" [ngValue]="crop">{{ crop }}</option>
            </select>
            <error-list></error-list>
          </div>
        </div>
      </div>

      <div id="collapseBasic" [collapse]="!contract.product" [isAnimated]="true">
        <div class="row">
          <!-- Price -->
          <div class="col-sm-7">
            <div ngModelGroup="priceGroup" #price="ngModelGroup" hasError>
              <div class="form-group">
                <label class="control-label">{{ 'GLOBAL.PRICE'|translate }}</label>
                <price-form
                [types]="company.market.configuration.order.business_detail.price.types"
                [price]="contract.price"
                [currencies]="currencies"
                [min]="0.01"
                [units]="contract.product?.quantity_units"
                [disabled]="processing"
                placeholder="0.00"></price-form>
              </div>
            </div>
          </div>
  
          <!-- Quantity -->
          <div class="col-sm-5">
            <div class="form-group" hasError>
              <label class="control-label">{{ 'GLOBAL.QUANTITY'|translate }}</label>
              <div class="input-group">
                <input type="number" placeholder="0" step="1" class="form-control" name="quantity"
                [disabled]="processing"
                [(ngModel)]="contract.quantity.value"
                required [min]="1"
                [max]="9999999">
                <div dropdown agSelect name="quantityUnit" class="input-group-btn" [(ngModel)]="contract.quantity.unit"
                [isDisabled]="processing"
                [class.single-unit]="contract.product?.quantity_units?.length <= 1">
                  <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                    {{ contract.quantity?.unit.name_plural || contract.quantity?.unit.abbrev || contract.quantity?.unit.name }}
                    <span class="caret"></span>
                  </button>
                  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let u of contract.product?.quantity_units" [agOption]="u"><a>{{ u.name_plural }}</a></li>
                  </ul>
                </div>
              </div><!-- /btn-group -->    
              <error-list></error-list>
            </div>
          </div>
        </div>
  
        <div class="row">
          <!-- Delivery place -->
          <div class="col-sm-6">
            <div class="form-group" hasError>
              <label class="control-label">{{ 'ORDER_FORM.DELIVERY_LOCATION.LABEL'|translate }}</label>
              <div *ngIf="company.market.configuration.geolocation?.enabled">
                <google-places name="geolocations"
                [disabled]="processing"
                [(ngModel)]="contract.delivery.geolocations[0]" required></google-places>
              </div>
              <div *ngIf="company.market.configuration.location?.enabled">
                <ag-location-picker name="localidad"
                [(ngModel)]="contract.delivery.locations[0]"
                [market]="company.market"
                required
                [disabled]="processing"
                title="{{ 'ORDER_FORM.DELIVERY_LOCATION.TITLE'|translate }}"></ag-location-picker>
              </div>
              <error-list></error-list>
            </div>
          </div>
  
          <!-- Delivery period -->
          <div class="col-sm-6">
            <div class="form-group" hasError>
              <label class="control-label">{{ company.market.configuration.order.business_detail.delivery.range.label }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="material-symbols-rounded">date_range</i>
                </div>
                <input type="text" name="deliveryDate" class="form-control" placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}"
                required
                bsDaterangepicker
                container="#contract-form"
                [disabled]="processing"
                [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, rangeInputFormat: 'DD/MM/YYYY'}"
                [(ngModel)]="contract.delivery.range" readonly>
              </div>
              <error-list></error-list>
              <a class="small" (click)="setDeliveryRange(30)"
              [class.disabled]="processing">{{ company.market.configuration.market_summary.available.label }}</a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="contract-form" class="btn btn-agree" [disabled]="!contract || processing">
      {{ (editMode ? 'GLOBAL.EDIT' : 'IMPORTER.ADD_CONTRACT')|translate }}
    </button>
  </div>
</ng-template>