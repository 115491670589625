import { Component, Input, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Product } from '../../../models/product.model';
import { qualityToString } from '../../pipes/quality.pipe';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => QualityInputComponent),
  multi: true
};

@Component({
  selector: 'quality-input',
  templateUrl: './quality-input.component.html',
  styleUrls: ['./quality-input.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class QualityInputComponent implements ControlValueAccessor {

  @ViewChild('quality', { static: true }) private readonly template: TemplateRef<any>;

  @Input() public product: Product;
  @Input() public disabled: boolean;

  public modalRef: BsModalRef;
  public description: string;
  public selection: any;

  private innerValue: any;

  get value(): any {
    return this.innerValue;
  };

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_) => void = () => { };

  constructor(
    private modalService: BsModalService
  ) { }

  set value(v) {
    if (v !== this.innerValue) {
      this.innerValue = v;

      this.updateDescription();
      this.onChangeCallback(v);
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.updateSelection();
      this.updateDescription();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  updateDescription(): void {
    let v = this.value;

    this.description = qualityToString(this.value, this.product);
  }

  updateSelection(): void {
    this.selection = {};
    if (this.value) {
      for (let i in this.value) {
        this.selection[i] = this.value[i];
      }
    }
  };

  showModal(): void {
    if (!this.disabled) {
      this.updateSelection();
      this.modalRef = this.modalService.show(this.template, {});
    }
  }

  accept(): void {
    this.value = this.selection;
    this.modalRef.hide();
  }

  /** 
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }
}
