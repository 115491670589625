
<ng-template #linkModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'LINKER.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-muted" [innerHtml]="'LINKER.DESCRIPTION_BATCH_CONTRACT'|translate"></p>

    <ng-container *ngIf="batch.id">
      <div class="row">
        <div class="col-xs-12">
          <batch-details
          [company]="company"
          [show-address]="false"
          [batch]="batch"></batch-details>
        </div>
      </div>
  
      <div class="row">
        <connector [linked]="contract"></connector>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-xs-12">
        <!-- Selected contract -->
        <ng-container *ngIf="contract">
          <h5>{{ 'LINKER.SELECTED_CONTRACT'|translate }}
            <button class="btn btn-link btn-xs" (click)="contract = undefined"
            [disabled]="processing">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</button>
          </h5>
          <div class="horizontal-scroller">
            <table class="table table-condensed">
              <thead>
                <tr class="tr-align-center">
                  <th class="sap">{{ 'GLOBAL.DATE'|translate }}</th>
                  <th class="sap">{{ 'GLOBAL.CONTRACT'|translate }}</th>
                  <th >{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                  <th class="dlb drb">
                    <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                  </th>
                  <th class="sap text-center">{{ 'GLOBAL.PERIOD'|translate }}</th>
                  <th class="sap text-center">{{ 'GLOBAL.PRICE'|translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tr-align-center">
                  <td class="sap micro text-muted">
                    <date-md [date]="contract.date"></date-md>
                  </td>
                  <td class="small sap"><samp>{{ contract.reference }}</samp></td>
                  <td class="small">
                    {{ contract.delivery.locations |locationString }}
                  </td>
                  <td class="text-right dlb drb">
                    <quantity-control [quantity]="contract.quantity" [compact]="true"></quantity-control>
                  </td>
                  <td class="sap">
                    <date-range
                    [from]="contract.delivery.date_from"
                    [to]="contract.delivery.date_to"></date-range>
                  </td>
                  <td class="sap">
                    <div class="price-box booked-bg">
                      <div class="type"
                        [class.buy]="contract.buyer.id === company.id"
                        [class.sell]="contract.seller.id === company.id">{{
                          (contract.buyer.id === company.id ? 'COMPANY_TRADED_VIEW.BOUGHT' : (contract.seller.id === company.id ? 'COMPANY_TRADED_VIEW.SOLD' : ''))|translate
                      }}</div>
                      <div class="value">
                        <price-display [price]="contract.price" [market]="company.market" [withoutYear]="true"></price-display>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <!-- Table of contracts -->
        <div [class.hide]="contract">
          <!-- Filters -->
            <div class="flex flex-j-between">
              <h5>{{ 'LINKER.CONTRACTS'|translate }}</h5>
              <div class="filters">
                <!-- Validity -->
                <label
                (click)="applyFilter('validity', 'on_date', true)"
                class="btn btn-default"
                [class.active]="filters['validity']">
                  <span class="material-symbols-rounded text-muted">{{filters['validity'] ?
                    'filter_alt_off' : 'filter_alt'}}</span>
                  {{'FILTERS.VALIDITY'|translate}}
                </label>
                <!-- Company filter -->
                <label *ngIf="batch.company"
                (click)="applyFilter('company', (filters['filters[seller.name]'] || filters['filters[buyer.name]']) ? '' : ('contains:' + batch.company.name), true)"
                class="btn btn-default"
                [class.active]="filters['filters[seller.name]'] || filters['filters[buyer.name]']">
                  <span class="material-symbols-rounded text-muted">{{filters['filters[seller.name]'] || filters['filters[buyer.name]'] ?
                    'filter_alt_off' : 'filter_alt'}}</span>
                  {{batch.company.name}}
                </label>
                <!-- Search by contract reference -->
                <div class="form-group">
                  <div class="input-group">
                    <input #referenceSearch (keyup.enter)="applyFilter('filters[reference]', 'contains:'+referenceSearch.value)"
                      type="text" autocomplete="off" name="contract.reference" maxlength="128" class="form-control"
                      placeholder="Buscar contrato" aria-label="Buscar">
                    <span class="input-group-btn">
                      <button class="btn btn-default" (click)="applyFilter('filters[reference]','contains:'+referenceSearch.value)" type="button"><i class="material-symbols-rounded">search</i></button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <ng-container *ngIf="refreshedTable">
              <contracts-table
              (onSelectedContract)="selectedContract($event)"
              [contractSelected]="contract"
              [selectable]="true"
              [filters]="filters"
              [company]="company"
              defaultSortBy="-delivery.date_from"
              [hideColumns]="hideColumns.concat(hideBuyerColumn)"
              (load)="tableLoaded($event)"></contracts-table>
            </ng-container>
          </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="linkSelected()" class="btn btn-agree" [disabled]="processing || !contract">{{ 'LINKER.LINK_BATCH_CONTRACT'|translate }}</button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
