<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ (edit ? 'BARTERS.EDIT_PRODUCT' : 'BARTERS.ADD_PRODUCT')|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="loading || !order">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
    
    <form id="order-form" #orderForm="ngForm" (ngSubmit)="orderForm.valid && done()">
      <ng-container *ngIf="!loading && order">
        <div *ngIf="!order.operation_type">operation_type: undefined</div>
        <div *ngIf="!order.business_detail.delivery.delivery_type">business_detail.delivery.delivery_type: undefined</div>
      
        <div class="row"><!-- Product -->
          <div class="col-md-6">
            <!-- <p class="strong">Detalle del producto</p> -->
            <div class="form-group" hasError>
              <label class="control-label">
                {{ 'GLOBAL.PRODUCT'|translate }}
              </label>
              <select class="form-control" name="product"
                [(ngModel)]="order.product"
                [compareWith]="compareId"
                (ngModelChange)="changeProduct()"
                [disabled]="processing"
                #product="ngModel" required>
                <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT_PRODUCT'|translate }}</option>
                <option *ngFor="let prod of products" [ngValue]="prod">{{ prod.name }}</option>
              </select>
              <error-list></error-list>
              <a class="small"
              (click)="hubSpotService.open()">{{ 'ORDER_FORM.PRODUCT.NOT_LISTED'|translate }}</a>
            </div>
          </div>
          <div class="col-md-6" id="order_images" *ngIf="order.product && company.market.configuration.order.allow_media"><!-- Media -->
            <div class="form-group" hasError hasErrorOn='always'>
              <label class="control-label">{{ 'ORDER_FORM.IMAGES.LABEL'|translate }}</label>
              <file-input
              drop-area="#order_images"
              name="images[]" 
              inputId="images" 
              [(ngModel)]="order.new_media" 
              [files]="order.media"
              [max-size]="5242880" 
              accept="image/*"
              [max-files]="20"
              #images="ngModel"
              [disabled]="processing"
              (delete)="deleteImage($event)"
              [deletedFile]="deletedFile"></file-input>
              <error-list></error-list>
              <p class="help-block">{{ 'ORDER_FORM.IMAGES.HELP'|translate }}</p>
            </div>
          </div>
        </div>
        <ng-container *ngIf="order.product">
          <tabset>
            <tab heading="{{ 'BARTERS.ADD_MANUAL'|translate }}" id="manualTab"
            (selectTab)="setMode('manual')">
              <div class="row" *ngIf="!fullmode">
                <div class="col-xs-12">
                  <alert type="info"><span [innerHtml]="'BARTERS.ADD_PRODUCT_HELP'|translate"></span></alert>
                </div>
              </div>
              <div class="row"><!-- Quality specs -->
                <div class="col-sm-4" *ngFor="let attribute of order.product.attributes.quality|pairs|orderBy:'1.order'">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ attribute[1].name }}</label>
                      <textarea class="form-control"
                      *ngIf="attribute[1].element === 'textarea'"
                      name="quality_{{ attribute[0] }}"
                      maxlength="512"
                      [(ngModel)]="order.product_detail.quality[attribute[0]]"
                      [disabled]="processing"
                      rows="6"></textarea>
                      <select class="form-control"
                        *ngIf="attribute[1].element !== 'textarea'"
                        name="quality_{{ attribute[0] }}"
                        [(ngModel)]="order.product_detail.quality[attribute[0]]"
                        [disabled]="processing"
                        [compareWith]="compareId"
                        #quality="ngModel">
                        <option *ngIf="attribute[1].empty" [ngValue]="attribute[1].empty">
                          {{ attribute[1].empty.name }}
                        </option>
                        <option *ngFor="let option of attribute[1].values | orderBy: (attribute[1].empty ? '' : 'name') ; let first = first" [ngValue]="option" [selected]="first">
                          {{ option.name }}
                        </option>
                      </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="checkbox flex-center-distributed">
                    <div>
                      <input type="checkbox" class="ios"
                      name="own_production" id="own_production"
                      [(ngModel)]="order.own_production">
                    </div>
                    <label for="own_production" class="grow">{{ 'ORDER.OWN_PRODUCED'|translate }}</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="checkbox flex-center-distributed">
                    <div>
                      <input type="checkbox" class="ios"
                      name="sustainable" id="sustainable"
                      [(ngModel)]="order.business_detail.sustainable">
                    </div>
                    <label for="sustainable" class="grow">{{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</label>
                  </div>
                </div>  
                <div class="col-sm-6" *ngIf="order.product.attributes.harvest.enabled"><!-- Crop -->
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.CROP_YEAR'|translate }}
                      <small *ngIf="!fullmode" class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <select class="form-control" name="harvest"
                      [(ngModel)]="order.business_detail.harvest"
                      #cropSelect="ngModel"
                      [required]="fullmode"
                      [disabled]="processing">
                      <option [ngValue]="null" [selected]="true" [disabled]="true">{{ 'GLOBAL.CROP_YEAR_PLACEHOLDER'|translate }}</option>
                      <option *ngFor="let crop of cropList" [ngValue]="crop">{{ crop }}</option>
                    </select>
                    <error-list></error-list>
                  </div>
                </div>
              </div>
              <ag-product-quality-info [order]="order" [proposal]="order" key="type">
              </ag-product-quality-info>
              <div class="row"><!-- Price and Quantity -->
                <div class="col-sm-6"><!-- Price -->
                  <div ngModelGroup="priceGroup" #price="ngModelGroup" hasError>
                    <div class="form-group" hasError>
                      <label class="control-label flex-center-distributed">
                        <span class="grow">
                          {{ 'GLOBAL.PRICE'|translate }}
                          <small *ngIf="!fullmode" class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                        </span>
                        <on-off-switch
                        class="micro"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="order.negotiability.business_detail.price"></on-off-switch>
                      </label>
                      <price-form
                      [types]="company.market.configuration.order.business_detail.price.types"
                      [price]="order.business_detail.price"
                      [required]="fullmode"
                      [currencies]="currencies"
                      [min]="0.01"
                      [units]="order.product.quantity_units"
                      placeholder="0.00"></price-form>
                      <error-list></error-list>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6"><!-- Quantity -->
                  <div class="form-group" hasError>
                    <label class="control-label">
                      {{ order.product?.attributes.order_quantity_label[order.operation_type] || order.product?.attributes.order_quantity_label || 'GLOBAL.QUANTITY'|translate }}
                      <small *ngIf="!fullmode" class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <div class="input-group">
                      <div dropdown agSelect name="quantityType" [(ngModel)]="order.business_detail.quantity.type" class="input-group-btn" *ngIf="order.operation_type !== 'venta' && quantityTypes.length > 1">
                        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{ order.business_detail.quantity.type?.name }}
                          <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu">
                          <li *ngFor="let q of quantityTypes" [agOption]="q"><a>{{ q.name }}</a></li>
                        </ul>
                      </div><!-- /btn-group -->
                      <input type="number" placeholder="0" step="1" class="form-control" name="quantity" [(ngModel)]="order.business_detail.quantity.value"
                      [required]="fullmode"
                      number [min]="1"
                      [max]="9999999">
                      <div dropdown agSelect name="quantityUnit" class="input-group-btn" [(ngModel)]="order.business_detail.quantity.unit"
                        [class.single-unit]="order.product?.quantity_units?.length <= 1">
                        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          {{ order.business_detail.quantity?.unit.name_plural || order.business_detail.quantity?.unit.abbrev || order.business_detail.quantity?.unit.name }}
                          <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                          <li *ngFor="let u of order.product?.quantity_units" [agOption]="u"><a>{{ u.name_plural }}</a></li>
                        </ul>
                      </div>
                    </div><!-- /input-group -->
                    <ag-volume-calculator #volumeCalculator
                    [price]="order.business_detail.price.type === 'flat' ? order.business_detail.price.value : undefined"
                    [amount]="amount"
                    [(business-detail)]="order.business_detail"></ag-volume-calculator>

                    <a class="small"
                    (click)="volumeCalculator.show()">{{ 'VOLUME.CALCULATE'|translate }}</a>
                    <error-list></error-list>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="fullmode">
                <div class="row" *ngIf="company.market.configuration.geolocation?.enabled"><!-- Delivery place -->
                <div class="col-xs-12">
                  <div class="form-group" hasError>
                    <label class="control-label">
                      {{ 'ORDER_FORM.DELIVERY_LOCATION.LABEL'|translate }}
                    </label>
                    <div>
                      <google-places name="geolocations" [(ngModel)]="order.business_detail.delivery.geolocations" #geolocations="ngModel" required></google-places>
                    </div>
                    <error-list></error-list>
                  </div>
                </div>
                </div>
                <div class="row" *ngIf="company.market.configuration.location?.enabled"><!-- Delivery place -->
                  <div class="col-xs-12">
                    <div class="form-group" hasError>
                      <label class="control-label">
                        {{ 'ORDER_FORM.DELIVERY_LOCATION.LABEL'|translate }}
                      </label>
                      <div>
                        <ag-location-picker #locationPicker="agLocationPicker" name="localidad"
                        [(ngModel)]="order.business_detail.delivery.locations"
                        [market]="company.market"
                        [arrayMinlength]="1" type="multiple"
                        title="{{ 'ORDER_FORM.DELIVERY_LOCATION.TITLE'|translate }}"
                        #localidad="ngModel"></ag-location-picker>
                      </div>
                      <error-list></error-list>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col-sm-6"><!-- Delivery period -->
                  <div class="form-group" hasError>
                    <label class="control-label">{{ company.market.configuration.order.business_detail.delivery.range.label }}
                      <small *ngIf="!fullmode" class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <div class="input-group">
                      <div class="input-group-addon">
                        <i class="material-symbols-rounded">date_range</i>
                      </div>
                      <input type="text" name="deliveryDate" class="form-control" placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}"
                      [required]="fullmode"
                      bsDaterangepicker
                      container="#order-form"
                      [minDate]="today_start"
                      [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, rangeInputFormat: 'DD/MM/YYYY'}"
                      [(ngModel)]="order.business_detail.delivery.range" readonly>
                    </div>
                    <error-list></error-list>
                    <a class="small" (click)="setDeliveryRange(30)">{{ company.market.configuration.market_summary.available.label }}</a>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="company.market.configuration.location.enabled && order.operation_type === 'venta'"><!-- Origin -->
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.ORIGIN'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <ng-template #locationTemplate let-item="item">
                      {{ item.name }} {{ item.levels[2]? '(' + item.levels[2].name + ')' : '' }} - {{ item.levels[1].name }}
                    </ng-template>
                    <search-input name="origin"
                      [(ngModel)]="order.business_detail.origin"
                      #origin="ngModel"
                      placeholder="{{ 'LOCATION_PICKER.PLACEHOLDER'|translate }}"
                      [search]="locationService.locationSolver(company.market.id)"
                      groupBy="levels.1.name" [itemTemplate]="locationTemplate" type="object">
                    </search-input>
                    <error-list></error-list>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="company.market.configuration.geolocation.enabled && order.operation_type === 'venta'"><!-- Origin -->
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.ORIGIN'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <google-places name="origin" [(ngModel)]="order.business_detail.geo_origin" #origin="ngModel" maxItems="1"></google-places>
                    <error-list></error-list>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!excludeFields.includes('paymentCondition')"><!-- Payment conditions -->
                <div class="col-sm-6">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.PAYMENT_CONDITION'|translate }}
                      <small *ngIf="!fullmode" class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <select class="form-control" name="paymentCondition"
                      [(ngModel)]="order.payment_detail.payment_condition"
                      [required]="fullmode"
                      [compareWith]="compareId"
                      (ngModelChange)="changePaymentCondition()"
                      #paymentCondition="ngModel">
                      <option [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'PAYMENT_CONDITION_INPUT.SELECT_TERMS'|translate }}</option>
                      <option *ngFor="let pc of payment_conditions" [ngValue]="pc">{{ pc.name }}</option>
                    </select>
                    <error-list></error-list>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="order.payment_detail.payment_condition?.slug === 'other'">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'ORDER_FORM.ALTERNATIVE_CONDITION.LABEL'|translate }}</label>
                    <input type="text" name="paymentOther" class="form-control"
                    maxlength="256"
                    [(ngModel)]="order.payment_detail.other"
                    required
                    #paymentOther="ngModel">
                    <error-list></error-list>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="order.payment_detail.payment_condition?.slug === 'certain-date'">
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'GLOBAL.DATE'|translate }}</label>
                    <div class="input-group">
                      <div class="input-group-addon">
                        <i class="material-symbols-rounded">date_range</i>
                      </div>
                      <input type="text" name="paymentDate" class="form-control" bsDatepicker
                        placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}"
                        required
                        [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
                        [minDate]="minPaymentDate"
                        [(ngModel)]="order.payment_detail.date"
                        #paymentDate="ngModel" readonly>
                    </div>
                    <error-list></error-list>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="!excludeFields.includes('commission')"><!-- Commission -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ 'GLOBAL.COMMISSION'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <div class="input-group">
                      <input type="number" class="form-control" name="commission"
                      [min]="0" [max]="100" step="0.01"
                      [(ngModel)]="order.commission" placeholder="0.00">
                      <span class="input-group-addon">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="chambers.length > 0"><!-- Chambers -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label">{{ 'ORDER_FORM.ARBITRATION_CHAMBER.LABEL'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <select class="form-control" name="chamber"
                    [(ngModel)]="order.arbitration_chamber"
                    [compareWith]="compareId">
                      <option [ngValue]="undefined" [selected]="true">{{ 'ORDER_FORM.ARBITRATION_CHAMBER.PLACEHOLDER'|translate }}</option>
                      <option *ngFor="let chamber of chambers" [ngValue]="chamber">{{ chamber.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="company.market.configuration.order.observations?.enabled"><!-- Observations -->
                <div class="col-xs-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
                      <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                    </label>
                    <textarea class="form-control" rows="6" name="obs"
                    maxlength="1024"
                    [(ngModel)]="order.general_observations"></textarea>
                    <span class="small help-block" [innerHtml]="'ORDER_FORM.OBSERVATIONS.HELP'|translate"></span>
                  </div>
                </div>
              </div>
            </tab>
            <tab heading="{{ 'GLOBAL.MARKET'|translate }}" id="marketTab"
            *ngIf="!fullmode"
            (selectTab)="setMode('market')">
              <div class="loading" *ngIf="!ordersByProduct">
                <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
              </div>
              <ng-container *ngIf="ordersByProduct?.length">
                <p class="text-muted">{{ 'BARTERS.REPLICATE'|translate }}</p>
                <ng-container *ngTemplateOutlet="ordersTable; context: {
                  orders: ordersByProduct,
                  market: company.market
                }"></ng-container>
              </ng-container>
              <div class="no-data" *ngIf="ordersByProduct?.length === 0">
                <i class="material-symbols-rounded">not_interested</i>
                {{ 'ORDER.NO_RECORDS'|translate }}
              </div>  
            </tab>
          </tabset>
        </ng-container>
      </ng-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="order-form" class="btn btn-agree" [disabled]="processing || loading" data-dismiss="modal">{{ (edit ? 'GLOBAL.EDIT' : 'GLOBAL.ADD')|translate }}</button>
  </div>
</ng-template>

<ng-template #ordersTable let-orders="orders" let-market="market">
  <div class="horizontal-scroller"
  *ngIf="orders?.length > 0">
    <table class="table table-condensed table-hover" sortable defaultSortBy="companyName" #tableSortable="tableSortable">
      <thead>
        <tr class="tr-align-center">
          <th sortBy="companyName">{{ 'GLOBAL.COMPANY'|translate }}</th>
          <th class="hidden-xs">{{ 'GLOBAL.QUALITY'|translate }}</th>
          <th class="hidden-xs" sortBy="locationString"
            *ngIf="market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="hidden-xs" sortBy="locationString"
            *ngIf="market.configuration.geolocation.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="hidden-xs" sortBy="portString"
            *ngIf="market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
          <th class="sap hidden-xs" sortBy="deliveryType"
            *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</th>
          <th class="sap text-center" sortBy="business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="business_detail.price.value">{{ 'GLOBAL.PRICE'|translate }}</th>
        </tr>
      </thead>
      <tbody *ngFor="let group of orders; last as isLastGroup">
        <!-- Order row -->
        <tr class="tr-align-center clickable"
        *ngFor="let order of group.values | orderBy: tableSortable.sortBy;"
        (click)="applyOrder(order)">
          <td>
            <!-- If no disclosure replace a[href] with this span -->
            <span *ngIf="!order.id" class="not-available truncate" title="{{ 'WORKING_ORDERS.UNAUTHORIZED'|translate }}">***</span>
            <span *ngIf="order.id" class="truncate" title="{{ order.companyName }}">
              <b [class.highlighted-text]="company?.id === order.company.id">{{ order.companyName }}</b>
            </span>

            <div class="visible-xs small"><b>{{ order.product_detail.quality|quality:group.key }}</b></div>
            <div class="visible-xs location-small">
              <span *ngIf="market.configuration.location.enabled" class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
              <span *ngIf="market.configuration.geolocation.enabled" class="clamp-2">{{ order.business_detail.delivery.geolocations |locationString:true:'name' }}</span>
              <span *ngIf="market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(order) }}</span>
              <span *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                ({{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }})
              </span>
            </div>
          </td>
          <td class="hidden-xs small">{{ order.product_detail.quality|quality:group.key }}</td>
          <td class="hidden-xs small"
            *ngIf="market.configuration.location.enabled">
            <span class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
          </td>
          <td class="hidden-xs small"
            *ngIf="market.configuration.geolocation.enabled">
            <span class="clamp-2">{{ order.business_detail.delivery.geolocations |locationString:true:'name' }}</span>
          </td>
          <td class="hidden-xs small"
            *ngIf="market.configuration.order.business_detail.port.enabled">
            {{ order.product.attributes.country?.name }} / {{ order.business_detail.port?.name }}
          </td>
          <td class="sap hidden-xs"
            *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
            {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
          </td>
          <td class="sap">
            <date-range [showSpot]="true" [from]="order.business_detail.delivery.date_from"
              [to]="order.business_detail.delivery.date_to"></date-range>
          </td>
          <td class="sap text-right">
            <price-display [price]="order.business_detail.price" [withoutYear]="true"></price-display>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>