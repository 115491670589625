import { Product } from "./product.model";

/**
 * A SeedingZone represents a zone where indicated by the user when filing for a loan.
 */
export class SeedingZone {
  readonly id?: string;
  geo_zone: GeoZone;
  field_type: 'OWNED' | 'LEASED' = 'OWNED';
  // Lot size in hectares.
  field_size: number;
  seeding_type: Product;
  zone?: {
    description: string,
    name: string
  };

  extras?: { [key: string]: any };

  constructor(data: Partial<SeedingZone> = {}) {
    this.geo_zone = new GeoZone(undefined, undefined);
    this.seeding_type = new Product();

    Object.assign(this, data);
  }
}

// Geographic zone with latitude and longitude
export class GeoZone {
  latitude: number;
  longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
