import { Type } from "class-transformer";
import { Company } from "../../../../models/company.model";
import { Price } from "../../../../models/price.model";
import { Quantity } from "../../../../models/quantity.model";
import { Contract } from "../../contracts/models/contract.model";

export class FixationRequestStatus {
  constructor(data: Partial<FixationRequestStatus> = {}) {
    Object.assign(this, data);
  }
  public id: number;
  public name: string;
  public description: string;
}

export class FixationRequest {

  constructor(data: Partial<FixationRequest> = {}) {
    Object.assign(this, data);
  }

  readonly id: number;

  @Type(() => Price)
  price?: Price;
  //price_fixed?:  number;

  @Type(() => Quantity)
  quantity?: Quantity;

  //quantity_fixed?: number;

  @Type(() => Contract)
  contract: Contract;

  @Type(() => Date)
  date?: Date;

  @Type(() => Date)
  created_at?: Date;

  /**
   * [[Company]] that request the fixation.
   */
  @Type(() => Company)
  company: Company;

  /**
   * [[Company]] that receive the fixation.
   */
  @Type(() => Company)
  recipient: Company;

  /**
   * Additional instructions or comments for the request.
   */
  observations?: string;

  @Type(() => FixationRequestStatus)
  status: FixationRequestStatus;

  public isWaitingResponse(user: Company): boolean {
    const isRequestCreator = user.id === this.company.id;
    const isWaitingCompany = this.status.name === 'waiting_company';
    const isAble = isRequestCreator && isWaitingCompany;
    return isAble;
  }
}
