<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FINTECH.ADDITIONALS.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <table class="table">
      <tbody>
        <tr>
          <th>{{ 'BARTERS.AMOUNT'|translate }}</th>
          <td class="text-right">
            <price [unit]="application.approved.unit.name" [value]="application.approved.value"></price>
          </td>
        </tr>
        <tr *ngFor="let additional of additionals">
          <td>{{ additional.concept }}</td>
          <td class="text-right">
            <price *ngIf="additional.value != undefined; else none" [unit]="application.approved.unit.name" [value]="additional.value"></price>
          </td>
        </tr>
        <tr class="active">
          <th>{{ 'GLOBAL.TOTAL'|translate }}</th>
          <td class="text-right">
            <price [unit]="application.approved.unit.name" [value]="total"></price>
          </td>
        </tr>
      </tbody>
    </table>

    <p class="small text-muted" [innerHtml]="'FINTECH.ADDITIONALS.HELP'|translate"></p>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>