<ng-template #templateModal>
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" [disabled]="processing || disabled"  aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ 'TEMPLATE_NAME_PICKER.TITLE'|translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ 'TEMPLATE_NAME_PICKER.DESCRIPTION'|translate }}</p>
    <form id="template-form" #templateNameForm="ngForm"
    (ngSubmit)="templateNameForm.valid && submit()">
      <div class="form-group">
        <input name="title" [(ngModel)]="title"
        maxlength="128"
        type="text" class="form-control" required
        [modalFocus]="true">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing || disabled" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="template-form" class="btn btn-agree" [disabled]="processing || disabled || templateNameForm.invalid" >
      {{ 'TEMPLATE_NAME_PICKER.CONFIRM_BUTTON'|translate }}
    </button>
  </div>
</ng-template>