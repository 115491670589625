<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FINTECH.CREDIT_LINES'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="lines-form" #linesForm="ngForm" (ngSubmit)="linesForm.valid && submit()">
      <div class="form-group">
        <label class="control-label">
          {{ 'GLOBAL.COMPANY'|translate }}
        </label>
        <ng-container *ngIf="!companyLine && !searchingCompany; else selectedCpmpany">
          <selectize required [maxItems]="1" [searchURL]="companyService.getCompanies(company.id, {
            all: true
          })" [(ngModel)]="companyLine" name="applicant"
          (ngModelChange)="setCompany(companyLine)"
          placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}" [disabled]="processing"></selectize>
          <!-- <div class="flex-center-distributed spaced small">
            <a (click)="fiscal_id_mode = !fiscal_id_mode">{{ 'COMPANY.NOT_LISTED'|translate }}</a>
          </div> -->
        </ng-container>
        <ng-template #selectedCpmpany>
          <div class="editable-pill" *ngIf="companyLine">
            <button [disabled]="processing" type="button" class="close"
              (click)="reset()"><span aria-hidden="true">&times;</span></button>
            <company-info [company]="companyLine"
            [activity]="false"></company-info>
          </div>
        </ng-template>
      </div>

      <div class="row" *ngIf="fiscalId">
        <div class="col-xs-12">
          <div *ngIf="lines; else loading">
            <table class="table table-condensed table-hover no-margin"
            *ngIf="lines.length; else noData">
              <thead>
                <tr class="tr-align-center">
                  <th>{{ 'GLOBAL.PRODUCT'|translate }}</th>
                  <ng-container *ngIf="!readonly">
                    <th>{{ 'FINTECH.CREDIT_LINE'|translate }}</th>
                    <th>{{ 'GLOBAL.DUE_DATE'|translate }}</th>
                    <th class="sap"></th>
                  </ng-container>
                  <ng-container *ngIf="readonly">
                    <th class="text-right">{{ 'FINTECH.CREDIT_LINE'|translate }}</th>
                    <th class="sap"><abbr title tooltip="{{ 'GLOBAL.DUE_DATE'|translate }}"
                    [adaptivePosition]="false">{{ 'GLOBAL.DUE_DATE_ABBR'|translate }}</abbr></th>
                  </ng-container>
                </tr>
              </thead>
              <tbody *ngIf="lines.length > 0">
                <tr *ngFor="let line of lines; let fisrtLine = first"
                [class.tr-align-center]="readonly">
                  <td class="truncate">
                    <span *ngIf="line.product?.name; else default">{{ line.product.name }}</span>
                  </td>
                  <ng-container *ngIf="!readonly">
                    <td>
                      <!-- <price [unit]="line.amount.unit.name" [value]="line.amount.value"></price> -->
                      <price-form
                      [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
                      [(price)]="line.amount"
                      [currencies]="[funder.currency]"
                      [showQuantityUnit]="false"
                      [min]="0"
                      [max]="fisrtLine ? funder.max_credit_line : (lines[0].amount.value || funder.max_credit_line)"
                      [disabled]="processing"
                      [required]="false"
                      (valueChange)="updateLine(fisrtLine)"
                      [prefix]="line.id"
                      placeholder="0.00"></price-form>
  
                      <ng-container *ngIf="funder.max_credit_line != undefined">
                        <input type="range"
                        [(ngModel)]="line.amount.value"
                        name="amount_{{ line.id }}"
                        class="hidden-xs"
                        [attr.min]="0"
                        (ngModelChange)="updateLine(fisrtLine)"
                        [attr.max]="fisrtLine ? funder.max_credit_line : (lines[0].amount.value || funder.max_credit_line)"
                        step="0.01"
                        [disabled]="processing">
                      </ng-container>
                    </td>
                    <td>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-symbols-rounded">today</i>
                        </span>
                        <input
                        type="text"
                        [disabled]="processing"
                        name="due_date-{{ line.id }}"
                        class="form-control"
                        [(ngModel)]="line.due_date"
                        bsDatepicker
                        container="#lines-form"
                        [minDate]="today"
                        [bsConfig]="{
                          showWeekNumbers: false,
                          isAnimated: true,
                          dateInputFormat: 'DD/MM/YYYY'
                        }"
                        placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER' | translate }}"
                        readonly>
                      </div>
                    </td>
                    <td class="sap">
                      <button type="button"
                      class="btn btn-link"
                      [disabled]="processing"
                      title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
                      (onConfirm)="delete(line)"
                      [confirm-options]="{
                        action: 'GLOBAL.YES_DELETE'|translate
                      }">
                        <span class="material-symbols-rounded">delete</span>
                      </button>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="readonly">
                    <td class="text-right">
                      <price [unit]="line.amount.unit.name" [value]="line.amount.value"></price>
                    </td>
                    <td class="small sap">
                      <date-md *ngIf="line.due_date; else none" [date]="line.due_date" [style]="true"></date-md>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer" *ngIf="!readonly">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="lines-form" class="btn btn-agree"
    [disabled]="processing">{{ 'GLOBAL.SAVE_CHANGES'|translate }}</button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #default>
  <span class="text-warning">{{ 'FINTECH.CREDIT_LINE_MAX'|translate }}</span>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'GLOBAL.NO_DATA'|translate }}
  </div>
</ng-template>