<ng-template #stoptt>
  <table class="tt-details">
    <tbody>
      <tr class="tr-align-center" *ngIf="stop.updated_at">
        <td class="text-left">{{ 'IMPORTER.UPDATED'|translate }}</td>
        <td class="text-right"><ag-timestamp [date]="stop.updated_at"></ag-timestamp></td>
      </tr>
      <tr class="tr-align-center" *ngIf="stop.activated_at">
        <td class="text-left">{{ 'SLOTS.STATUS.STOP.2'|translate }}</td>
        <td class="text-right"><ag-timestamp [date]="stop.activated_at"></ag-timestamp></td>
      </tr>
      <tr class="tr-align-center" *ngIf="stop.arrived_at">
        <td class="text-left">{{ 'SLOTS.STATUS.STOP.5'|translate }}</td>
        <td class="text-right"><ag-timestamp [date]="stop.arrived_at"></ag-timestamp></td>
      </tr>
      <tr class="tr-align-center" *ngIf="stop.unloaded_at">
        <td class="text-left">{{ 'SLOTS.STATUS.STOP.3'|translate }}</td>
        <td class="text-right"><ag-timestamp [date]="stop.unloaded_at"></ag-timestamp></td>
      </tr>
    </tbody>
  </table>
</ng-template>

<span *ngIf="stop; else none"
class="label"
[class.label-danger]="stop.status.id === 1"
[class.label-warning]="stop.status.id === 2"
[class.label-success]="stop.status.id === 3"
[class.label-info]="stop.status.id === 5"
[tooltip]="stop.updated_at ||
stop.activated_at ||
stop.arrived_at ||
stop.unloaded_at ? stoptt : undefined" [delay]="500">
  {{ stop.status.name }}
</span>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>