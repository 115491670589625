<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ applicationClone.product.line_group || applicationClone.product.name }}
      <small *ngIf="applicationClone.product.line_group">{{ applicationClone.product.name }}</small>
    </h4>
    <!-- <samp class="micro text-muted">{{ applicationClone.id }}</samp> -->
  </div>
  <form (ngSubmit)="productForm.valid && submit()" #productForm="ngForm">
    <div class="modal-body">
      <div class="row" *ngIf="unit">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label">
              {{ 'FINTECH.APPROVED_LOC'|translate }}
              <ng-container *ngIf="company.id === application.product.funder.company.id">
                <ag-funder-line-modal
                #lineModal
                [readonly]="true"
                [funder]="application.product.funder"
                [company]="company"></ag-funder-line-modal>
                
                &nbsp;<a tooltip="{{ 'FINTECH.CREDIT_LINES'|translate }}"
                (click)="lineModal.open(application.applicant.fiscal_id.value)"
                class="small hidden-print">
                  <span class="material-symbols-rounded">paid</span>
                </a>
              </ng-container>
            </label>
            <price-form
            [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
            [price]="applicationClone.approved"
            [currencies]="[unit]"
            [showQuantityUnit]="false"
            [disabled]="processing"
            placeholder="0.00"></price-form>

            <ng-container *ngIf="applicationClone.product.amount_min != undefined &&
            applicationClone.product.amount_max != undefined">
              <input type="range"
              [(ngModel)]="applicationClone.approved.value"
              name="amount_{{ applicationClone.id }}"
              class="hidden-xs"
              [attr.min]="applicationClone.product.amount_min"
              [attr.max]="applicationClone.product.amount_max"
              step="0.01"
              [disabled]="processing">
            </ng-container>

            <p class="help-block" *ngIf="applicationClone.requested">
              {{ (applicationClone.product.amount_label || 'FINTECH.AMOUNT_REQUESTED')|translate }}:
              <price [unit]="unit.name" [value]="applicationClone.requested.value"></price>
            </p>
          </div>
        </div>
      </div>

      <ng-container *ngIf="application.product.rate != undefined || application.product.tax_rate != undefined">
        <p>{{ 'FINTECH.APPLICATION_PRODUCT'|translate }}</p>
        <div class="row">
          <div class="col-sm-6" *ngIf="application.product.rate != undefined">
            <div class="form-group">
              <label class="control-label">{{ (applicationClone.product.rate_label || 'FINTECH.INTEREST_RATE')|translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="0.00" name="rate"
                [min]="-100" [max]="300"
                step="0.01"
                [disabled]="processing"
                [(ngModel)]="editables.rate">
                <span class="input-group-addon">%</span>
              </div>
              <input type="range" [(ngModel)]="editables.rate" name="rate_range"
              [disabled]="processing"
              [attr.min]="-100" [attr.max]="300" step="0.01">
            </div>
          </div>
          <div class="col-sm-6" *ngIf="application.product.tax_rate != undefined">
            <div class="form-group">
              <label class="control-label">{{ 'FINTECH.TAXES'|translate }}</label>
              <div class="input-group">
                <input type="number" class="form-control" placeholder="0.00" name="tax_rate"
                [min]="0" [max]="100"
                step="0.01"
                [disabled]="processing"
                [(ngModel)]="editables.tax_rate">
                <span class="input-group-addon">%</span>
              </div>
              <input type="range" [(ngModel)]="editables.tax_rate" name="tax_rate_range"
              [disabled]="processing"
              [attr.min]="0" [attr.max]="100" step="0.01">
            </div>
          </div>
        </div>
        <p class="small text-muted">{{ 'FINTECH.APPLICATION_PRODUCT_HELP'|translate }}</p>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing"
      data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
      <button type="submit" class="btn btn-agree"
      [disabled]="processing || productForm.pristine">{{ 'GLOBAL.SAVE_CHANGES'|translate}}</button>
    </div>
  </form>
</ng-template>