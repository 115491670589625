import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CurrentDateService } from '../../../../../services/current-date.service';
import { Order } from '../../models/order.model';

@Component({
  selector: 'preview-order',
  templateUrl: './preview-order.component.html',
  styleUrls: ['./preview-order.component.scss']
})
export class PreviewOrderComponent implements OnInit {

  @Input() public order: Order;
  @Input() public editMode: boolean = false;
  /** The language currently used. */
  @Input() public currentLang: string;

  @Output() public readonly accept = new EventEmitter();
  @Output() public readonly dismiss = new EventEmitter();

  public publishing: boolean;
  public today: Date;

  constructor(
    private currentDate: CurrentDateService
  ) {
    this.today = this.currentDate.get();
  }

  ngOnInit(): void {
    this.publishing = false;
  }

  public isAuction(): boolean {
    return (this.order.type && [4, 5].indexOf(this.order.type.id) !== -1);
  }

  public publish(): void {
    this.publishing = true;
    this.accept.emit()
  }
}
