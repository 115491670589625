<ag-template-name-picker 
title="{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }} - {{order.product.name}} ({{ order.product_detail.quality|quality:order.product }}) x {{order.business_detail.quantity.value}}{{order.business_detail.quantity.unit.abbrev}}"
(accept)="publish()"
[(order)]="order"
[disabled]="publishing"
#templateNamePicker>
</ag-template-name-picker>

<div class="ag-container order-view">
  <div class="ag-col-primary">
    <div [class.container]="!isAuction()" [class.container-fluid]="isAuction()">
      <div class="content-box">
        <!-- <div class="watermark"><span>Vista previa</span></div> -->
        <div class="status">
          <div class="alert status-info" role="alert">
            <span [innerHtml]="'ORDER_PREVIEW.HELP'|translate"></span>
          </div>
        </div>
        <p class="small text-muted text-right">
          <span *ngIf="!order.expiration_datetime; else expiration" class="text-danger">
            {{ 'ORDER_PREVIEW.WARNING'|translate:{offset:order.company.market?.offset} }}
          </span>
          <ng-template #expiration>
            {{ 'ORDER_PREVIEW.EXPIRATION'|translate:{
              day:order.expiration_datetime|date:'EEEE':undefined:currentLang,
              date:order.expiration_datetime|date:'dd',
              month:order.expiration_datetime|date:'MMMM':undefined:currentLang,
              year:order.expiration_datetime|date:'y',
              offset:order.company.market?.offset
            } }}
          </ng-template>

          <label class="icon-input btn-link">
            <i class="material-symbols-rounded">edit</i>
            <input type="text" name="expirationDate"
            bsDatepicker
            [bsConfig]="{showWeekNumbers: false, adaptivePosition: true, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
            [minDate]="today"
            [maxDate]="order.business_detail.delivery?.range[1]"
            [(ngModel)]="order.expiration_datetime">
          </label>
        </p>
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <h3>
              <span class="label text-capitalize" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</span>
              {{ order.product.name }}
            </h3>
          </div>
          <div class="ag-header-actions">
            <div class="btn-toolbar">
              <div class="btn-group">
                <button type="button"
                title="{{ 'ORDER_PREVIEW.BACK.TITLE'|translate }}" class="btn btn-link" (click)="dismiss.emit()" [disabled]="publishing">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">arrow_back</i>
                  {{ 'ORDER_PREVIEW.BACK.LABEL_0'|translate }}
                  <span class="hidden-xs">{{ 'ORDER_PREVIEW.BACK.LABEL_1'|translate }}</span>...
                </button>
              </div>

              <div class="btn-group" dropdown *ngIf="!editMode">
                <button type="button"
                class="btn btn-agree"
                (click)="publish()"
                [disabled]="publishing">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
                  {{ (order.broker_company ? 'GLOBAL.SEND': 'ORDER_PREVIEW.PUBLISH.LABEL')|translate }}
                </button>

                <button
                  [disabled]="publishing"
                  id="publish-toggle" 
                  type="button" 
                  class="btn btn-agree dropdown-toggle" 
                  dropdownToggle 
                  data-toggle="dropdown" 
                  aria-haspopup="true" 
                  aria-expanded="false">
                    <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu with-icons dropdown-menu-right">
                  <li>
                    <a
                    title="{{ 'TEMPLATE_NAME_PICKER.SAVE_AS_TEMPLATE_BUTTON'|translate }}" 
                    (click)="templateNamePicker.show()">
                      <i class="material-symbols-rounded hidden-xs hidden-sm">description</i>
                      <span class="hidden-xs hidden-sm">{{ 'TEMPLATE_NAME_PICKER.SAVE_AS_TEMPLATE_BUTTON'|translate }}</span>
                      <span class="visible-xs visible-sm">{{ 'TEMPLATE_NAME_PICKER.SAVE_AS_TEMPLATE_BUTTON_ABBR'|translate }}</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="btn-group">
                <button type="button"
                *ngIf="editMode"
                title="{{ 'GLOBAL.SAVE_CHANGES'|translate }}" class="btn btn-agree"
                [disabled]="publishing"
                (onConfirm)="publish()"
                [confirm-options]="{
                  title: 'ORDER_PREVIEW.EDIT_CONFIRM.TITLE'|translate,
                  body: 'ORDER_PREVIEW.EDIT_CONFIRM.BODY'|translate,
                  action: 'ORDER_PREVIEW.EDIT_CONFIRM.CONFIRM_BUTTON'|translate
                }">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
                  {{ 'GLOBAL.SAVE_CHANGES'|translate }}...
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row"
        *ngIf="order?.business_detail.sustainable">
          <div class="col-xs-12">
            <span
            class="ag-label label-success"><span class="glyphicon glyphicon-leaf" aria-hidden="true"></span> {{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</span>
          </div>
        </div>

        <counterparties [order]="order"></counterparties>

        <proposal-detail
        [proposal]="order"
        [order]="order"
        [previousContract]="order.product.contract_clauses ? order.product.contract_clauses : order.product_clauses "
        [negotiableMode]="'editable'"
        [disableAllSwitches]="publishing"></proposal-detail>
      </div>
    </div>
  </div>
  <div class="ag-col-secondary" *ngIf="isAuction()">
    <div class="auction sticky">
      <div class="auction-container">
        <div class="small text-muted">{{ 'AUCTION_VIEW.MODULE.TIME_TO_START'|translate }}</div>
        <div>
          <ag-countdown
            (onZero)="dismiss.emit()"
            [end]="order.auction.date_from">
          </ag-countdown>
          <span class="text-muted">&nbsp;({{order.auction.date_from| date:'MMMM d, HH:mm':undefined:currentLang | titlecase }})</span>
        </div>
      </div>
    </div>
  </div>
</div>