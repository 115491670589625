import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderType'
})
export class OrderTypePipe implements PipeTransform {

  transform(orderId: number, ...args: unknown[]): string {
    /**
     * |  1 | Order                  | order                  |
     * |  2 | Pre-order              | pre_order              |
     * |  3 | Template               | template               |
     * |  4 | Auction                | auction_order          |
     * |  5 | Auction Pre-order      | auction_pre_order      |
     * |  6 | Order Barter Proposal  | order_barter_proposal  |
     * |  7 | Order Barter           | order_barter           |
     * |  8 | Certificate of Deposit | certificate_of_deposit |
     */

    if (orderId === 3) return 'template';
    else if (orderId === 4) return 'auction';
    else if (orderId === 7) return 'barter';
    else return 'order';
  }
}
