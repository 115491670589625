import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MapsAPILoader } from '@ng-maps/core';
import { TranslateService } from '@ngx-translate/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Company } from '../../../models/company.model';
import { FileManagerFile } from '../../../models/file-manager-file.model';
import { Product } from '../../../models/product.model';
import { GeoZone, SeedingZone } from '../../../models/seeding-zone.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { MarketService } from '../../../services/market.service';
import { FilePreviewComponent } from '../../../ui/components/file-preview/file-preview.component';
import { checkAccept } from '../../../utilities/file';
import { ProductionPlan } from '../../models/production-plan.model';
import { FileManagerService } from '../../services/file-manager.service';
import { ProductionPlansService } from '../../services/production-plans.service';

declare var google: any;
declare var pdfjsLib: any;

/**
 * This component shows an interactive map that allows adding multiple fields
 * and specifying their characteristics.
 */
@Component({
  selector: 'field-data-selector[company]',
  templateUrl: './field-data-selector.component.html',
  styleUrls: ['./field-data-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FieldDataSelectorComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FieldDataSelectorComponent),
    multi: true,
  }]
})
export class FieldDataSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @ViewChild('mapElement', { static: true }) private readonly mapElement: ElementRef;
  /** Default files drop area. */
  @ViewChild('defaultDrop', { static: true }) private readonly defaultDrop: ElementRef;
  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;
  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  /** Selector to set the files drop area. */
  @Input('drop-area') public dropArea: string;
  @Input() public company: Company;
  @Input() public owner: Company;
  @Input() public help: string;
  @Input() public label?: string;
  /* Enables adding fields only by uploading files. */
  @Input('file-mode') public fileMode: 'AUTO' | 'ONLY' | 'NO';
  @Input('fiscal-id') public fiscalId: string;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  @Input() public set disabled(value: boolean) {
    this._disabled = value;
  };

  public get disabled(): boolean {
    return this._disabled || this.processing;
  }
  @Input() public readonly: boolean = false;
  @Input() public extras: any[];
  @Input() public hide: string[];
  /** Accepted products IDs. */
  @Input('accepted-products') private acceptedProducts: number[];
  @Input('source-files') public sourceFiles: string[] = [];
  @Input('source-parsed') public sourceParsed: SeedingZone[];
  @Input() public set options(value: any) {
    if (value) Object.assign(this._options, value);
  };
  public get options(): any {
    return this._options;
  }
  @Input() public set center(value: any) {
    if (value) {
      this._center = value;
    }
  }
  @Input() public set zoom(value: any) {
    if (typeof value === 'number') {
      this._zoom = value;
    }
  }

  @Output() readonly sourcesUploaded = new EventEmitter<string[]>();
  @Output() readonly sourcesParsed = new EventEmitter<SeedingZone[]>();

  /**
   * Specify what file types the user can pick from the file input dialog box.
   *
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  public accept: string = ".pdf";
  /** The language currently used. */
  public currentLang: string;
  /** Field types. */
  public fieldTypes: string[] = [
    'OWNED',
    'LEASED'
  ];
  public isDragging: boolean;
  public newZone: SeedingZone = new SeedingZone();
  public previous: ProductionPlan[];
  public products: Product[];
  public readedFields: SeedingZone[];
  public selectedPreviousIndex: number;
  public sourceDiff: SeedingZoneDiff[];

  private UUID: string;
  private _options: any = {
    import: false,
    affidavit: true,
    hover: true,
    map: true,
    max_files: 10,
    table: true
  };
  private _center: any = { lat: -35.2884, lng: -65.1988 }; // Argentina
  private _zoom: number = 5; // Default value
  private _disabled: boolean;
  private dropEl: HTMLElement;
  private dropLg: HTMLElement;
  private map;
  private markers: any[];
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  private processing: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private marketService: MarketService,
    private modalService: BsModalService,
    private productionPlansService: ProductionPlansService,
    private fileManagerService: FileManagerService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.UUID = 'map-' + uuidv4();
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.marketService.watchProducts().subscribe(products => {
      if (products) {
        this.products = this.acceptedProducts?.length > 0 ? products.filter(p => {
          // Accepted product ids
          return this.acceptedProducts.includes(p.id);
        }) : products;
      }
    }));
    this.initMap();
  }

  private initMap(): void {
    this.mapsAPILoader.load().then(() => {
      if (this.options.map) {
        this.map = new google.maps.Map(this.mapElement.nativeElement, {
          fullscreenControl: false,
          // mapTypeControl: false,
          streetViewControl: false,
          center: this._center,
          clickableIcons: false,
          maxZoom: 16,
          minZoom: 3,
          mapId: this.UUID
        });

        this.map.setZoom(this._zoom);

        // Add field on click
        this.map.addListener('click', (mapsMouseEvent) => {
          if (!this.readonly &&
            (this.fileMode != 'ONLY' || this.sourceFiles.length > 0)) this.addField(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng());
        });
      }

      setTimeout(() => {
        this.sourceDiff = this.parseDiff();
        this.render();
        this.initFileDrop();
        this.loadPrevious();
      });
    });
  }

  private parseDiff(): SeedingZoneDiff[] {
    if (this.sourceParsed?.length > 0 &&
      this.sourceParsed[0].id) {
      let diff: SeedingZoneDiff[] = [];
      let existsDiff: boolean = false;

      this.value.forEach(field => {
        const diffField = new SeedingZoneDiff(field);
        const parsedField = this.sourceParsed.find(parsed => parsed.id === field.id);
        if (parsedField) {
          // Check editions
          for (const key in field) {
            const value = JSON.stringify(field[key]);
            if (value !== JSON.stringify(parsedField[key])) {
              // Key changed
              diffField.diff.edited[key] = parsedField[key];
              existsDiff = true;
            }
          }
        } else {
          // Is new
          diffField.diff.new = true;
          existsDiff = true;
        }

        diff.push(diffField);
      });

      this.sourceParsed.forEach(field => {
        const submittedField = this.value.find(submitted => submitted.id === field.id);
        if (!submittedField) {
          // Deleted
          const diffField = new SeedingZoneDiff(field);
          diffField.diff.deleted = true;
          diff.push(diffField);
          existsDiff = true;
        }
      });

      return existsDiff ? diff : undefined;
    }
  }

  public render(value: SeedingZone[] = this.value): void {
    if (value?.length > 0 && this.options.map) {
      this.updateMarkers(value);
      this.map.setCenter(this.getLatLngCenter(value));
    }
  }

  public centerOn(geoZone: GeoZone): void {
    if (this.options.map && typeof geoZone?.latitude === 'number' && typeof geoZone?.longitude === 'number') {
      this.map.panTo({
        lat: geoZone.latitude,
        lng: geoZone.longitude
      });
    }
  }

  /**
   * @return array with the center latitude longtitude pairs in
   *   degrees.
   */
  private getLatLngCenter(value: SeedingZone[] = this.value): { lat: number, lng: number } {
    const rad2degr = (rad: number) => { return rad * 180 / Math.PI; };
    const degr2rad = (degr: number) => { return degr * Math.PI / 180; };

    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    value.forEach(zone => {
      const lat = degr2rad(zone.geo_zone.latitude);
      const lng = degr2rad(zone.geo_zone.longitude);
      // sum of cartesian coordinates
      sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    });

    const avgX = sumX / value.length;
    const avgY = sumY / value.length;
    const avgZ = sumZ / value.length;

    // convert average x, y, z coordinate to latitude and longtitude
    const lng = Math.atan2(avgY, avgX);
    const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    const lat = Math.atan2(avgZ, hyp);

    return ({ lat: rad2degr(lat), lng: rad2degr(lng) });
  }

  /** Adds a new Farmer's [[SeedingZone]]. */
  public addField(lat: number = this._center.lat, lng: number = this._center.lng, from?: SeedingZone, diff: boolean = true): SeedingZone {
    if (this.disabled) return;

    lat = from?.geo_zone.latitude || parseFloat(lat.toFixed(4));
    lng = from?.geo_zone.longitude || parseFloat(lng.toFixed(4));

    let nz = new SeedingZone({
      id: uuidv4(),
      geo_zone: new GeoZone(lat, lng),
      // Default values
      field_type: from?.field_type || "OWNED",
      seeding_type: from ? (diff ? this.products[(from.seeding_type.id === this.products[0].id ? 1 : 0)] : from.seeding_type) : this.products[0],
      field_size: from?.field_size || 300
    });

    // Extras
    if (this.extras) {
      nz.extras = {};
      this.extras.forEach(extraField => {
        const fromExtra = from?.extras ? from.extras[extraField.key] : undefined;
        nz.extras[extraField.key] = fromExtra || extraField.default;
      });
    }

    if (!this.value) this.value = [];
    this.value.push(nz);
    this.updateMarkers();

    return nz;
  }

  /** Removes a Farmer's [[SeedingZone]]. */
  public removeField(index: number): void {
    this.value.splice(index, 1);
    this.updateMarkers();
  }

  /** Helper para verificar si un valor es numérico. */
  private isNumber(input: any): boolean {
    return typeof input === 'number';
  };

  public validate(c: FormControl): {
    fieldsData: boolean
  } {
    const validateRange = (num: number, max: number): boolean => {
      return num == Math.min(Math.max(num, max * -1), max);
    };

    let atLeastOneInvalid: boolean;

    this.value?.forEach((sz: SeedingZone, index) => {
      if (!this.isNumber(sz.geo_zone.latitude) ||
        !this.isNumber(sz.geo_zone.longitude) ||
        !this.isNumber(sz.field_size) ||
        !validateRange(sz.geo_zone.latitude, 90) ||
        !validateRange(sz.geo_zone.longitude, 180)) {
        atLeastOneInvalid = true;
      }
    });

    return atLeastOneInvalid ? {
      fieldsData: true
    } : null;
  }

  private updateMarkers(value: SeedingZone[] = this.value): void {
    this.propagateChange(value);

    if (this.options.map) {
      // Helper para validar si un número está dentro del rango permitido
      const validateRange = (num: number, max: number): boolean => num === Math.min(Math.max(num, -max), max);

      // Remueve todos los marcadores actuales del mapa
      this.markers?.forEach(m => m.setMap(null));
      this.markers = [];

      value?.forEach((sz: SeedingZone, index) => {
        const { latitude, longitude } = sz.geo_zone;

        // Validación de datos antes de crear el marcador
        if (this.isNumber(latitude) &&
          this.isNumber(longitude) &&
          this.isNumber(sz.field_size) &&
          validateRange(latitude, 90) &&
          validateRange(longitude, 180)) {

          const fieldTag = this.document.createElement('div');
          fieldTag.className = 'gm-field-tag';
          fieldTag.textContent = `${index + 1}`;

          const marker = new google.maps.marker.AdvancedMarkerElement({
            position: { lat: latitude, lng: longitude },
            map: this.map,
            title: `${index + 1}`,
            gmpDraggable: !this.readonly,
            content: fieldTag
          });

          marker.addListener('dragend', e => {
            const { position } = marker;

            sz.geo_zone.latitude = parseFloat(position.lat.toFixed(4));
            sz.geo_zone.longitude = parseFloat(position.lng.toFixed(4));
          });
          this.markers.push(marker);
        }
      });
    }
  }

  private initFileDrop(): void {
    // Setup drag and drop
    let da = this.defaultDrop.nativeElement;

    if (this.dropArea) {
      const db = this.document.querySelector(this.dropArea);
      if (db) da = db;
    }

    this.setupDrop(da);
  }

  private setupDrop(el: HTMLElement, lg?: HTMLElement): void {
    this.dropEl = el;
    this.dropLg = lg;

    el.addEventListener("dragenter", this.dragger, false);
    el.addEventListener("dragover", this.dragger, false);
    el.addEventListener("drop", this.drop, false);
    el.addEventListener("dragleave", this.removeDrag, false);
    el.addEventListener("dragend", this.removeDrag, false);
  }

  private killDrop(el: HTMLElement): void {
    // Removes all event listeners
    el.removeEventListener("dragenter", this.dragger);
    el.removeEventListener("dragover", this.dragger);
    el.removeEventListener("drop", this.drop);
    el.removeEventListener("dragleave", this.removeDrag);
    el.removeEventListener("dragend", this.removeDrag);
  }

  /**
   * Delays the setting of the variable to avoid multiple unnecessary triggers.
   */
  private _draggingSet: boolean;
  private _draggingTimeout: number;
  private setDragging(value: boolean): void {
    if (this._draggingSet !== value) {
      this._draggingSet = value;
      if (this._draggingTimeout) clearTimeout(this._draggingTimeout);
      if (this.isDragging !== this._draggingSet) this._draggingTimeout = window.setTimeout(() => {
        this.isDragging = this._draggingSet;
      }, 100);
    }
  }

  private dragger = (e: Event) => {
    if (!this.disabled && !this.readonly) {
      this.setDragging(true);
      e.stopPropagation();
      e.preventDefault();
      if (this.dropLg) this.dropLg.className = 'is-dragging';
    }
  };

  private drop = (e: DragEvent) => {
    if (!this.disabled && !this.readonly) {
      this.dragger(e);

      // Triggered by drop
      // Prevent to drag more files when the max files number is reached
      if (checkAccept(e.dataTransfer.files, this.accept)) this.parseFiles(e.dataTransfer.files);
      // }
      this.removeDrag();
    }
  };

  private removeDrag = (e?: DragEvent) => {
    this.setDragging(false);
    if (this.dropLg) this.dropLg.className = '';
  }

  public parseFiles(files: FileList): void {
    if (files && files.length > 0) {
      this.loadScript('//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js', () => {
        pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
        this.readedFields = [];

        this.parsePDF(files);
      });
    }
  }

  private uploadSources(files: File[]): void {
    if (files.length) {
      this.subscriptions.push(this.fileManagerService.upload(this.company.id, 'PRODUCTION_PLAN', files, this.label).subscribe(response => {
        this.sourceFiles = this.sourceFiles.concat(response.map(file => file.id));
        this.sourcesUploaded.emit(this.sourceFiles);
        this.share(response);
      }));
    }
  }

  private share(files: FileManagerFile[]): void {
    if (this.owner && this.owner.id !== this.company.id) {
      files.forEach(file => {
        this.fileManagerService.share(this.company.id, file, [this.owner.id]).subscribe();
      });
    }
  }

  public preview(fileId: string, name?: string): void {
    this.processing = true;
    const observable = this.fileManagerService.getFile(this.company.id, fileId);

    this.subscriptions.push(observable.subscribe(response => {
      this.filePreviewer.preview(observable, response.filename, response.size, response.url);
      this.processing = false;
    }));
  }

  private parsePDF(files: FileList): void {
    for (let index = 0; index < files.length; index++) {
      const file: File = files[index];
      const extension: string = file.name.toLowerCase().split('.').pop();

      if (extension === 'pdf') {
        /* wire up file reader */
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          const ab: ArrayBuffer = e.target.result;

          const loadingTask = pdfjsLib.getDocument(ab);
          loadingTask.promise.then(pdf => {
            // you can now use *pdf* here
            this.checkForKnownPDF(pdf, file);
          });
        };
        reader.readAsArrayBuffer(file);
      }
    }
  }

  private loadScript(src: string, callback?: Function): void {
    const UUID = 'script-' + src.replace(/\W/g, '');

    if (this.document.getElementById(UUID)) {
      if (callback) callback();
    } else {
      this.processing = true;

      let scriptElement = this.document.createElement('script');

      scriptElement.addEventListener('load', () => {
        this.processing = false;
        if (callback) callback();
      });

      scriptElement.id = UUID;
      scriptElement.type = "text/javascript";
      scriptElement.src = src;
      this.document.getElementsByTagName("head")[0].appendChild(scriptElement);
    }
  }

  /**
   * SISA (Beta)
   */
  private checkForKnownPDF(pdf, file: File): void {
    const numPages = pdf.numPages;
    let documentContent: PDFContent[] = [];

    for (let currentPage = 1; currentPage <= numPages; currentPage++) {
      pdf.getPage(currentPage).then(page => {
        const view = page.view;

        page.getTextContent().then((textContent: TextContent) => {
          documentContent.push({
            textContent: textContent,
            view: view,
            lines: []
          });
          if (documentContent.length === numPages) {
            // If multiple formats should be supported, this is the place to evaluate which one applies
            this.parseIP(documentContent, file);
          }
        });
      });
    }
  }

  private _addresses: { [address: string]: BehaviorSubject<any> } = {};
  private _parsedFilesKeys: string[] = [];

  private parseIP(documentContent: PDFContent[], file: File): void {
    let centerTimer;
    const fileKey = file.name + '___' + file.lastModified;

    // const products = ['Soja', 'Maíz', 'Girasol', 'Sorgo', 'Trigo', 'Maní', 'Poroto Mung'];
    const products = ['Soja', 'Maíz', 'Girasol', 'Sorgo', 'Trigo', 'Maní'];
    const provinces = [
      'CIUDAD AUTONOMA DE BUENOS AIRES',
      'BUENOS AIRES',
      'CATAMARCA',
      'CORDOBA',
      'CORRIENTES',
      'ENTRE RIOS',
      'JUJUY',
      'MENDOZA',
      'LA RIOJA',
      'SALTA',
      'SAN JUAN',
      'SAN LUIS',
      'SANTA FE',
      'SANTIAGO DEL ESTERO',
      'TUCUMAN',
      'CHACO',
      'CHUBUT',
      'FORMOSA',
      'MISIONES',
      'NEUQUEN',
      'LA PAMPA',
      'RIO NEGRO',
      'SANTA CRUZ',
      'TIERRA DEL FUEGO'
    ];
    const ignore = [
      'INMUEBLES',
      'Las Malvinas son argentinas',
      'LOCALIDAD',
      'ORGÁNICO',
      'PROVINCIA',
      'TIPO DE GRANO',
      'VARIEDAD',
      'IPRO',
      'No',
      'DON MARIO',
      'NS ', 'EC ', 'MA-'
    ];

    const displayResult = (sz: SeedingZone) => {
      this.readedFields.push(instanceToInstance(sz));
      this.sourcesParsed.emit(this.readedFields);
      if (!this.modalRef) this.openModal(this.modal);

      if (!this._parsedFilesKeys.includes(fileKey)) {
        this._parsedFilesKeys.push(fileKey);
        this.uploadSources([file]);
      }

      if (centerTimer !== undefined) clearTimeout(centerTimer);
      centerTimer = setTimeout(() => {
        if (this.options.map) this.map.panTo(this.getLatLngCenter());
      }, 250);
    };
    const roundToResolution = (x: number, resolution: number = 12) => {
      return Math.round(x / resolution) * resolution;
    };
    const findBest = (results: any[], address: string) => {
      if (results[0]) {
        // By default, the best result is the first
        let bestResult = results[0];

        if (results.length > 1) {
          // If there is more than one result we will try to determine if there is a better one
          const splittedAddress = address.toUpperCase().split(', ');
          // We are trying to identify a province at the address
          const province: string = provinces.find(p => {
            return splittedAddress.find(s => s.includes(p));
          }).normalize("NFD").replace(/[\u0300-\u036f]/g, "");

          if (province) {
            for (let indexResult = 0; indexResult < results.length; indexResult++) {
              const result = results[indexResult];
              const address_components = result.address_components;
              const province_component = address_components[address_components.length - 2].short_name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

              if (province_component.includes(province) && !result.types.includes('route')) {
                bestResult = result;
                break;
              }
            }
          }
        }

        return bestResult;
      }
    };
    const findProduct = (line: string[]) => {
      for (let index = 0; index < line.length; index++) {
        const product = this.products.find(p => p.name === line[index]);
        if (product) return product;
      }
    };
    const geocodeField = (sz: SeedingZone, address: string) => {
      if (this._addresses[address]) {
        this._addresses[address].subscribe(response => {
          if (response) {
            // Get from cache
            const bestResult = findBest(response.results, address);

            displayResult(this.addField(bestResult.geometry.location.lat(), bestResult.geometry.location.lng(), sz, false));
          }
        });
      } else {
        this._addresses[address] = new BehaviorSubject(null);
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          address: address,
          componentRestrictions: {
            country: 'AR'
          },
          region: 'ar'
        }).then((response) => {
          this._addresses[address].next(response);
          if (response.results[0]) {
            // Fetched
            const bestResult = findBest(response.results, address);

            displayResult(this.addField(bestResult.geometry.location.lat(), bestResult.geometry.location.lng(), sz, false));
          } else {
            // No results found
          }
          // this.processing = false;
        }).catch((e) => {
          // "Geocoder failed due to: " + e
          // this.processing = false;
        });
      }
    };
    const declareField = (product: Product, size: number, type: 'LEASED' | 'OWNED', address: string, lat?: number, lng?: number) => {
      const sz = new SeedingZone({
        seeding_type: product,
        field_size: size,
        field_type: type
      });

      if (lat && lng) {
        displayResult(this.addField(lat, lng, sz, false));
      } else {
        if (address?.length > 1) {
          geocodeField(sz, address);
        }
      }
    };
    const parseHa = (str: string): number => {
      // If str has more than one comma is not valid Hectares
      return (str.match(/,/g) || []).length > 1 ? 0 : parseInt(str.replace(',', ''));
    };
    const ConvertDMSToDD = (degrees: string, minutes: string, seconds: string, direction: string): number => {
      let dd = parseInt(degrees) + (parseInt(minutes) / 60) + (parseInt(seconds) / (60 * 60));

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    }

    let fiscalIdExists: boolean = this.fiscalId == undefined;
    let errors: string[] = [];

    documentContent.forEach(page => {
      const textItems: TextItem[] = page.textContent.items;

      // Concatenate the string of the item to the final string
      textItems.forEach(item => {
        item.str = item.str.trim();

        if (item.str !== '' &&
          !ignore.includes(item.str)) {

          const y = Math.ceil(page.view[3]) - roundToResolution(item.transform[5], 16);

          page.lines[y] = [...(page.lines[y] || []), { str: item.str, x: roundToResolution(item.transform[4]) }];
        }
      });

      page.lines = page.lines.filter(l => {
        const str = l.map(i => i.str).join('_');

        if (str.includes('no posee superficie sembrada')) errors.push(str); // Search for IP without crops
        if (!fiscalIdExists && str.includes(this.fiscalId)) fiscalIdExists = true; // Search for Applicant fiscal id

        return products.some(v => str.includes(v)) && // Line must include one of the mapped products
          l.length > 2; // Line must have more than 3 elements
      });

      // Sort text fields by X coordinate
      page.lines.map(l => {
        l.sort((a, b) => {
          return a.x > b.x ? 1 : -1;
        });
      });
    });

    if (fiscalIdExists && errors.length == 0) {
      // Applicant fiscal id must be present in the document
      documentContent.forEach(page => {
        if (page.lines.length > 0) {
          page.lines.forEach(field => {
            const seeding_type = findProduct(field.map(i => i.str));

            if (seeding_type) {
              // Check for GPS coords
              // "32°31'33'',63°59'50''"
              let lat: number, lng: number;
              if (field.at(-1).str.includes('°')) {
                const coords = field.pop().str.split(',');
                const latDMS = (coords[0] + ' S').split(/[^\d\w]+/);
                const lngDMS = (coords[1] + ' W').split(/[^\d\w]+/);

                lat = ConvertDMSToDD.apply(this, latDMS);
                lng = ConvertDMSToDD.apply(this, lngDMS);
              }

              const owned = parseHa(field.at(-2).str);
              const leased = parseHa(field.at(-1).str);
              const addressArray: string[] = field.filter(f => {
                return f.str.length > 3 &&
                  /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/.test(f.str);
              }).slice(1).flatMap(x => x.str);

              if (!provinces.includes(addressArray[0])) addressArray.shift();

              const province = addressArray.shift();
              const address = addressArray.reverse().join(' ') + ', ' + province;

              if (owned > 0) {
                declareField(seeding_type, owned, 'OWNED', address, lat, lng);
              }
              if (leased > 0) {
                declareField(seeding_type, leased, 'LEASED', address, lat, lng);
              }
            }
          });
        }
      });
    }

    if (!fiscalIdExists) {
      this.toastrService.warning(this.translateService.instant('FINTECH.FIELDS_TABLE.FILE_FISCALID_NOT_FOUND'));
    } else if (errors.length > 0) {
      this.toastrService.warning(this.translateService.instant('IMPORTER.ERROR') + ': ' + errors.join(', '));
    }
  }

  private loadPrevious(): void {
    if (!this.readonly &&
      this.fileMode != 'ONLY' &&
      this.company?.id && this.owner?.id) {
      // Do not block the ui when loading previous data
      // this.processing = true;

      this.subscriptions.push(this.productionPlansService.get(this.company.id, {
        'filters[companyId]': this.owner.id,
        order_by: '-createdAt'
      }).subscribe({
        next: response => {
          this.previous = response.body;
          // this.processing = false;
          if (this.previous?.length > 0) {
            this.setPrevious(0);
          }
        },
        error: error => {
          // Non fatal error
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  public setPrevious(index?: number): void {
    if (index != undefined) this.selectedPreviousIndex = index;

    this.setPP(this.previous[this.selectedPreviousIndex]);
  }

  public setPP(pp: ProductionPlan): void {
    this.value = undefined;
    pp.seedingZones.forEach(sz => {
      this.addField(sz.geo_zone.latitude, sz.geo_zone.longitude, sz, false);
    });

    if (this.options.map) this.map.setCenter(this.getLatLngCenter());
  }

  public unsetPrevious(): void {
    this.selectedPreviousIndex = undefined;
    this.value = undefined;
    this.updateMarkers();
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareExtras(a, b): boolean {
    return (!a && !b) || (a && b && (a.id ? a.id === b.id : a === b));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c, ignoreBackdropClick: true });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  // ngModel
  public value: SeedingZone[];
  private propagateChange = (_: any) => { };

  writeValue(value: SeedingZone[]) {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }

  /** @ignore */
  ngOnDestroy(): void {
    if (this.dropEl) this.killDrop(this.dropEl);
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

class TextContent {
  items: TextItem[];
  styles: any;
}

class TextItem {
  str: string;
  dir: 'ttb' | 'ltr' | 'rtl';
  fontName: string;

  hasEOL: boolean;
  width: number;
  height: number;
  transform: number[];
}
class PDFContent {
  textContent: TextContent;
  view: any;
  lines: any[];
}

class SeedingZoneDiff extends SeedingZone {
  diff: {
    new?: boolean;
    deleted?: boolean;
    edited?: {
      [key: string]: any
    };
  } = {};

  constructor(data: Partial<SeedingZone> = {}) {
    super(data);
    this.diff.edited = {};
  }
}