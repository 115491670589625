<ag-file-preview #filePreviewer></ag-file-preview>

<input type="file" id="{{ UUID }}"
(change)="addFiles($event.target.files)"
[disabled]="disabled"
[multiple]="maxFiles > 1"
[accept]="accept" />

<div #defaultDrop class="no-select">
  <!-- Files selected -->
  <ng-container *ngFor="let file of selected">
    <ng-container *ngTemplateOutlet="pill; context: {
      icon: 'task',
      icon_class: 'text-success',
      file: file
    }"></ng-container>
  </ng-container>

  <!-- Files uploading -->
  <ng-container *ngFor="let file of filesUploading">
    <ng-container *ngTemplateOutlet="pill; context: {
      title: file.name,
      help: 'FILE_INPUT.UPLOADING',
      help_icon: 'cloud_upload',
      help_class: 'text-success'
    }"></ng-container>
  </ng-container>

  <ng-container *ngIf="maxFiles > (selected?.length + (filesUploading ? filesUploading.length : 0))">
    <!-- New file... -->
    <ng-container *ngIf="!isDragging; else dragging">
      <ng-container *ngTemplateOutlet="pill; context: {
        classes: 'file-add',
        icon: 'add_circle_outline',
        title: maxFiles === 1 ? 'FILE_INPUT.SELECT_FILE' : 'FILE_INPUT.SELECT_FILES',
        help: 'FILE_INPUT.DROP_HERE',
        popover: popTemplate
      }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #dragging>
  <!-- Dragging... -->
  <ng-container *ngTemplateOutlet="pill; context: {
    classes: 'file-add',
    icon: 'downloading',
    title: 'MESSENGER.DROP_HERE'
  }"></ng-container>
</ng-template>

<ng-template #popTemplate>
  <ng-container *ngIf="(value?.length || 0) < files.length">
    <p class="small text-muted">{{ 'FILE_INPUT.AVAILABLE'|translate }}</p>
    <div class="row">
      <div class="list-group">
        <ng-container *ngFor="let file of files">
          <a class="list-group-item flex-center-distributed"
          *ngIf="!value || !value.includes(file.id)"
          (click)="add(file)">
            <div class="grow">
              <b>{{ file.name }}{{ file.extension }}</b>
              <div class="text-muted micro"><date-md [date]="file.createdAt"></date-md> &#8212; {{ file.size|filesize }}</div>
            </div>
            <a [class.disabled]="disabled"
            clickStop
            title="{{ 'FILE_INPUT.DELETE_CONFIRM.TITLE'|translate }}"
            (click)="delete(file)">
              <i class="material-symbols-rounded">delete</i>
            </a>
          </a>  
        </ng-container>
      </div>
    </div>
  </ng-container>

  <button type="button"
  class="btn btn-block btn-default"
  (click)="openDialog()"
  [disabled]="disabled">
    <i class="material-symbols-rounded">cloud_upload</i>&nbsp;
    {{ (maxFiles === 1 ? 'FILE_INPUT.UPLOAD_FILE': 'FILE_INPUT.UPLOAD_FILES')|translate }}
  </button>
</ng-template>

<ng-template #pill
let-classes="classes" 
let-icon="icon"
let-icon_class="icon_class"
let-title="title"
let-help="help"
let-help_icon="help_icon"
let-help_class="help_class"
let-popover="popover"
let-file="file">
  <div class="file-pill flex-center-distributed {{ classes }}"
  [class.active]="fileMenu.isOpen"
  [class.faded]="disabled && (popover || file)"
  [class.clickable]="!disabled && (popover || file)"
  (click)="file ? preview(file) : null"
  #fileMenu="bs-popover"
  [popover]="(!disabled && popover) ? popTemplate : null"
  [outsideClick]="true">
    <div class="icon">
      <i *ngIf="icon" class="material-symbols-rounded faded {{ icon_class }}"
      [class.text-muted]="!icon_class">{{ icon }}</i>
      <bouncer *ngIf="!icon" [dark]="true"></bouncer>
    </div>
    <div class="grow">
      <b *ngIf="title">{{ title|translate }}</b>
      <ng-container *ngIf="file">
        <b>{{ file.name }}{{ file.extension }}</b>
        <div class="text-muted micro info"><date-md [date]="file.createdAt"></date-md> &#8212; {{ file.size|filesize }}</div>
        <div class="micro cta text-success"><span class="material-symbols-rounded">search</span> {{ 'FILE_INPUT.PREVIEW'|translate }}</div>
      </ng-container>
      <div *ngIf="help"
      class="micro {{ help_class }}"
      [class.text-muted]="!help_class">
        <span *ngIf="help_icon" class="material-symbols-rounded">{{ help_icon }}</span>
        {{ help|translate }}
      </div>
    </div>
    <button *ngIf="file"
    type="button" class="close" [disabled]="disabled"
    (click)="remove(file.id)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</ng-template>