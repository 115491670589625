<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FIXATIONS.REQUEST_MODAL.TITLE'|translate }}</h4>
  </div>

  <div class="modal-body">
    <div class="loading" *ngIf="loading && !error">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <div class="row actors-cards">
      <div class="col-md-6">
        <label class="control-label">{{ 'ORDER_SUMMARY.BUYER'|translate }}</label>
        <div *ngIf="contract.buyer" class="editable-pill">
          <company-info [company]="contract.buyer"></company-info>
        </div>
      </div>

      <div class="col-md-6">
        <label class="control-label">{{ 'ORDER_SUMMARY.SELLER'|translate }}</label>
        <div *ngIf="contract.seller" class="editable-pill">
          <company-info [company]="contract.seller"></company-info>
        </div>
      </div>
    </div>

    <label class="control-label">{{ 'SLOTS.REQUEST_SLOT_MODAL.RECIPIENT'|translate }}</label>
    <ng-container *ngIf="!fixationReq.recipient && counterparts.length > 1; else showCompany">
      <selectize
        [(ngModel)]="fixationReq.recipient"
        [maxItems]="1"
        [options]="counterparts"
        placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}">
      </selectize>
    </ng-container>

    <ng-template #showCompany>
      <div [class.editable-pill]="counterparts.length >= 1">
        <button *ngIf="counterparts.length > 1"
          type="button" class="close" [disabled]="processing"
          (click)="fixationReq.recipient = undefined"><span
            aria-hidden="true">&times;</span></button>
        <company-info [company]="fixationReq.recipient" [activity]="false"></company-info>
      </div>
    </ng-template>

    <form *ngIf="fixationReq" id="requestFixationForm" #requestFixationForm="ngForm">
      <div class="form-group" hasError>

        <div class="row">
          <div class="col-xs-6">
            <div class="input-item">
              <label class="control-label">{{ 'GLOBAL.PRICE'|translate }}/t</label>
              <price-input
                [(price)]="fixationReq.price"
                [currencies]="currencies"
                placeholder="0.00">
              </price-input>
            </div>

          </div>
          <div class="col-xs-6">
            <div class="input-item">
              <label class="control-label">{{ 'GLOBAL.QUANTITY'|translate }}</label>
              <quantity-input
                [(quantity)]="fixationReq.quantity"
                [company]="company"
                [maxValue]="fixationReq.contract.quantity?.value"
                placeholder="0">
              </quantity-input>
            </div>
          </div>
        </div>

        <div class="row input-item">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
                <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
              </label>
              <textarea class="form-control" name="observations" [disabled]="processing"
                [(ngModel)]="fixationReq.observations" maxlength="250" rows="4"></textarea>
              <p class="help-block small">{{ 'FIXATIONS.REQUEST_MODAL.OBSERVATIONS_HELP'|translate }}</p>
            </div>
          </div>
        </div>

        <alert type="warning" *ngIf="fixationReq.quantity?.value > contract.quantity?.value">
          {{ 'FIXATIONS.REQUEST_MODAL.ALERT_LIMIT_OF_VOLUME'|translate }}
        </alert>

      </div>

    </form>

    <div class="no-data" *ngIf="error">
      <i class="material-symbols-rounded text-danger">error_outline</i>
      <span [innerHtml]="'GLOBAL.DATA_ERROR_PERMISSION'|translate"></span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="button"
    *ngIf="(!fixationReq.quantity.value || contract.quantity?.value >= fixationReq.quantity?.value) && recipientIsSelected"
    class="btn btn-agree"
    [disabled]="processing"
    (click)="submitForm()">
      {{ 'FIXATIONS.REQUEST_MODAL.REQUEST'|translate }}
      <i class="material-symbols-rounded" style="margin-left: 5px;">send</i>
    </button>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
