<!--
  .is-dirty para valores modificados por el usuario

  <table class="table table-detail table-condensed is-dirty" tooltip="Valor actual: Rosario Norte">
-->
<li *ngIf="clause"
  [class.not-negotiable]="negotiableMode !== 'hidden' && negotiableValue"
  [class.incoming]="formMode && direction === 'incoming' && hasChanges && !modelGroup?.dirty || formMode && !isNew && clause.delete && direction === 'incoming'"
  [class.outgoing]="((direction === 'outgoing' && hasChanges) || (modelGroup?.dirty) || isNew) && !createOrder"
  [tooltip]="negotiableMode !== 'hidden' && negotiableValue?
    ('NEGOTIABLE_GROUP.NOT_NEGOTIABLE'|translate) : ''">
  <ng-content></ng-content>

  <div *ngIf="modelGroup?.dirty && !createOrder && !isNew">
    <button type="button" class="btn btn-xs btn-link" (mousedown)="discard(clause)">{{ 'NEGOTIABLE_GROUP.DISCARD'|translate }}</button>
  </div>
</li>

<table class="table table-detail table-condensed" *ngIf="!clause && (!formMode || trackedControls.length || trackedGroups.length)"
  [class.not-negotiable]="negotiableMode !== 'hidden' && negotiableValue"
  [class.has-incoming-changes]="formMode && direction === 'incoming' && hasChanges || contractHasChanged && direction === 'incoming' && showChanges"
  [class.has-outgoing-changes]="formMode && direction === 'outgoing' && hasChanges || contractHasChanged && direction === 'outgoing'  && showChanges"
  [class.is-dirty]="formMode && modelGroup?.dirty || isNew"
  [tooltip]="
    formMode && modelGroup?.pristine && hasChanges?
    displayPrevious('NEGOTIABLE_GROUP.PREVIOUS_VALUE'|translate):
    formMode && modelGroup?.dirty && hasDirtyChanges?
    displayInitial('NEGOTIABLE_GROUP.CURRENT_VALUE'|translate):
    negotiableMode !== 'hidden' && negotiableValue?
    ('NEGOTIABLE_GROUP.NOT_NEGOTIABLE'|translate) : ''"
>
  <thead>
    <tr>
      <th>
        <ng-content select=".negotiable-label"></ng-content>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div *ngIf="formMode">
          <ng-content select=".negotiable-form"></ng-content>
        </div>
        <div *ngIf="!formMode">
          <ng-content select=".negotiable-static"></ng-content>
        </div>
        <ng-content select=".negotiable-always"></ng-content>
      </td>
    </tr>
    <tr *ngIf="formMode" class="mini-tr" [class.visible]="formMode && modelGroup?.dirty"><!-- Use .visible class to show -->
      <td>
        <button type="button" class="btn btn-xs btn-link" (mousedown)="discard()">{{ 'NEGOTIABLE_GROUP.DISCARD'|translate }}</button>
      </td>
    </tr>
  </tbody>
</table>

