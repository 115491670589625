import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Assignee } from '../../../models/assignee.model';
import { Company } from '../../../models/company.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { TeamService } from '../../../services/team.service';
import { AssigneesService } from '../../services/assignees.service';

@Component({
  selector: 'ag-assignees-manager',
  templateUrl: './assignee-manager.component.html',
  styleUrls: ['./assignee-manager.component.css']
})
export class AssigneeManagerComponent implements OnInit, OnDestroy {

  @ViewChild('assigneeManager', { static: true }) private readonly assigneeManager: TemplateRef<any>;

  /**
   * Entity type. Eg: negotiation, invoice, etc.
   * 
   * Either this attribute or [[external_id|external-id]] is required.
   */
  @Input() public entity: string;
  /**
   * Entity unique identifier.
   * 
   * This attribute in combination with the [[entity|entity type]] is the
   * unique reference to the virtual file folder.
   * 
   * This attribute is required.
   */
  @Input('entity-id') public entity_id: string | number;
  /**
   * External entity unique identifier.
   * 
   * If this attribute is set the [[entity|entity type]] will be "external".
   * 
   * Either this attribute or [[entity|entity type]] is required.
   */
  @Input('external-id') public external_id: number;
  @Input() public company: Company;
  @Input() public editable: boolean = true;

  public availableAssignees: Assignee[];
  public assignees: Assignee[];
  public assigneesByRole: {
    [roleId: number]: Assignee[]
  };
  public currentUserIncluded: boolean;
  public enabled: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** Current [[User]]. */
  public user: User;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private loginService: LoginService,
    private modalService: BsModalService,
    private teamService: TeamService,
    private assigneesService: AssigneesService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    this.loadData();
  }

  private mapAssignee(assignee: Assignee): void {
    if (assignee.userId === this.user.id) this.currentUserIncluded = true;
    if (!this.assigneesByRole[assignee.roleId]) this.assigneesByRole[assignee.roleId] = [];
    this.assigneesByRole[assignee.roleId].push(assignee);
  }

  private loadData(): void {
    if (this.company && this.entity && this.entity_id) {
      this.enabled = true;
      this.processing = true;
      this.currentUserIncluded = false;
      this.assigneesByRole = {};

      this.subscriptions.push(this.assigneesService.get(this.company.id, this.entity, this.entity_id).subscribe({
        next: assignees => {
          assignees.forEach(assignee => { this.mapAssignee(assignee); });

          this.assignees = assignees;

          this.processing = false;
        },
        error: error => {
          // Non fatal error
          this.dataDogLoggerService.warn(error.message, error.error);
          this.enabled = false;
          this.processing = false;
        }
      }));
    }
  }

  private loadAccounts(): void {
    if (!this.availableAssignees) {
      this.processing = true;
      this.subscriptions.push(this.teamService.get(this.company.id).subscribe(members => {
        this.availableAssignees = [];
        members.forEach(member => {
          if (member.user) this.availableAssignees.push(new Assignee({
            name: member.name,
            email: member.email,
            accountId: member.id,
            userId: member.user.id
          }));
        });

        this.processing = false;
      }));
    }
  }

  public onChange(assignee: Assignee, roleId?: number, added: boolean = false): void {
    if (added) {
      let tempAssignee = instanceToInstance(assignee);
      tempAssignee.roleId = roleId;

      this.subscriptions.push(this.assigneesService.create(this.company.id, this.entity, this.entity_id, tempAssignee).subscribe(assigneeId => {
        assignee.id = assigneeId;
      }));
    } else {
      this.subscriptions.push(this.assigneesService.delete(assignee).subscribe(response => {
        this.assignees = this.assignees.filter(a => {
          return a.id !== assignee.id;
        });

        const findMe = this.assignees.find(a => a.userId === this.user.id);

        this.currentUserIncluded = Boolean(findMe);
      }));
    }
  }

  public show(): boolean {
    if (!this.assigneesByRole || !this.editable) return false;
    this.loadAccounts();
    this.openModal(this.assigneeManager);
  }

  public addMe(): void {
    this.processing = true;

    const account = this.user.accounts.find(account => account.company.id === this.company.id);
    const assignee = new Assignee({
      name: this.user.name + ' ' + this.user.last_name,
      email: this.user.email,
      accountId: account.id,
      userId: this.user.id,
      roleId: 3
    });

    this.subscriptions.push(this.assigneesService.create(this.company.id, this.entity, this.entity_id, assignee).subscribe(assigneeId => {
      assignee.id = assigneeId;

      this.mapAssignee(assignee);

      if (!this.assignees) this.assignees = [];
      this.assignees.push(assignee);

      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
      this.availableAssignees = undefined;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
