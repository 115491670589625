import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Slot } from '../../../../../models/slot.model';
import { SlotHistory } from '../../../../../models/slots-batch.model';
import { SlotService } from '../../../../../services/slot.service';

@Component({
  selector: 'ag-slot-history',
  templateUrl: './slot-history.component.html',
  styleUrls: ['./slot-history.component.css']
})
export class SlotHistoryComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;
  /** It specifies that the component should be disabled. */
  @Input() public disabled: boolean;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public slotHistory: any[];
  public slot: Slot;

  private cache: { [id: number]: SlotHistory[] };
  private subscriptions: Subscription[] = [];
  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private slotService: SlotService
  ) { }

  /** Opens [[Slot]] history modal. */
  public show(slot: Slot): void {
    if (slot && slot.id) {
      this.slot = slot;

      this.openModal(this.modal);

      if (this.cache?.[slot.id]) this.slotHistory = this.cache[slot.id];
      else this.loadData();
    }
  }

  public loadData(): void {
    this.processing = true;
    this.slotHistory = undefined;
    this.subscriptions.push(this.slotService.getHistory(this.company.id, this.slot.id).subscribe(slotHistory => {
      this.cache = { ...(this.cache || {}), [this.slot.id]: slotHistory };
      this.slotHistory = slotHistory;
      this.processing = false;
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.selected = undefined;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
