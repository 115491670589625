<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'TRUCKS.WAYBILL'|translate }}: <samp>{{ waybill?.waybill_number }}</samp></h4>
  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="!waybill && !error">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <ng-container *ngIf="waybill">
      <div class="row">
        <div class="col-sm-4">
          <table-detail [header]="'SLOTS.DETAILS_MODAL.CTG_ABBR'|translate">
            <samp *ngIf="waybill.ctg; else none">{{ waybill.ctg }}</samp>
          </table-detail>
        </div>
        <div class="col-xs-6 col-sm-4">
          <table-detail [header]="'SLOTS.RENSPA'|translate">
            <samp *ngIf="waybill.renspa; else none">{{ waybill.renspa }}</samp>
          </table-detail>
        </div>
        <div class="col-xs-6 col-sm-4">
          <table-detail [header]="'SLOTS.LOADING_DATE'|translate">
            <date-md [date]="waybill.date"></date-md>
          </table-detail>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <h5 class="grayed">{{ 'SLOTS.ACTORS'|translate }}</h5>
        </div>
      </div>
      <div class="row">
        <!-- Actors -->
        <ng-container *ngFor="let actor of waybill.actors| keyvalue: originalOrder">
          <div class="col-sm-6 col-md-4" *ngIf="actor.value">
            <company-table [header]="'TRUCKS.ACTORS.'+(actor.key).toUpperCase()|translate" [activity]="false"
              [companies]="[actor.value]"></company-table>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <h5 class="grayed">{{ 'SLOTS.GRAINS'|translate }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <table-detail [header]="'GLOBAL.PRODUCT'|translate">
            <b *ngIf="waybill.product?.name; else none">{{ waybill.product.name }}</b>
          </table-detail>
        </div>
        <div class="cols-xs-6 col-sm-4">
          <table-detail [header]="'GLOBAL.TYPE'|translate">
            <ng-container *ngIf="waybill.product_type; else none">{{ waybill.product_type }}</ng-container>
          </table-detail>
        </div>
        <div class="cols-xs-6 col-sm-4">
          <table-detail [header]="'GLOBAL.CROP_YEAR'|translate">
            <ng-container *ngIf="waybill.crop; else none">{{ waybill.crop }}</ng-container>
          </table-detail>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <table-detail [header]="'SLOTS.LOAD'|translate">
            <ng-container *ngIf="waybill.cargo; else none">
              {{ ([
                'SLOTS.QUALITY_DECLARATION',
                'SLOTS.CONFORMING',
                'SLOTS.CONDITIONAL'
                ][waybill.cargo - 1])|translate }}
            </ng-container>
          </table-detail>
        </div>
        <div class="cols-xs-6 col-sm-4">
          <table-detail [header]="'GLOBAL.CONTRACT'|translate">
            <ng-container *ngIf="waybill.contract_reference; else none">{{ waybill.contract_reference }}</ng-container>
          </table-detail>
        </div>
      </div>
      <div class="row" *ngIf="waybill.observations">
        <div class="col-xs-12">
          <table-detail [header]="'GLOBAL.OBSERVATIONS'|translate">
            {{ waybill.observations }}
          </table-detail>
        </div>
      </div>

      <ng-container *ngIf="waybill.origin">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'SLOTS.ORIGIN'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <table-detail [header]="'SLOTS.ESTABLISHMENT'|translate">
              <b *ngIf="waybill.origin.terminal; else none">{{ waybill.origin.terminal }}</b>
            </table-detail>
          </div>
          <div class="cols-xs-6 col-sm-4">
            <table-detail [header]="'SLOTS.ADD_MODAL.ADDRESS'|translate">
              <ng-container *ngIf="waybill.origin.address?.street; else none">{{ waybill.origin.address.street }}</ng-container>
            </table-detail>
          </div>
          <div class="cols-xs-6 col-sm-4">
            <table-detail [header]="'SLOTS.LOCALITY'|translate">
              <ng-container *ngIf="waybill.origin.location?.name; else none">{{ waybill.origin.location.name }}</ng-container>
            </table-detail>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="waybill.destination">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'SLOTS.ACCEPT_MODAL.DESTINATION'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <table-detail [header]="'SLOTS.ADD_MODAL.ADDRESS'|translate">
              <ng-container *ngIf="waybill.destination.address?.street; else none">{{ waybill.destination.address.street }}
                <span *ngIf="waybill.destination.terminal"> ({{ waybill.destination.terminal }})</span></ng-container>
            </table-detail>
          </div>
          <div class="cols-xs-6 col-sm-4">
            <table-detail [header]="'SLOTS.LOCALITY'|translate">
              <ng-container *ngIf="waybill.destination.location?.name; else none">{{ waybill.destination.location.name }}</ng-container>
            </table-detail>
          </div>
          <div class="cols-xs-6 col-sm-4">
            <table-detail [header]="'SLOTS.PROVINCE'|translate">
              <ng-container *ngIf="waybill.destination.location?.levels; else none">{{ waybill.destination.location.levels[2].name }}</ng-container>
            </table-detail>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <!-- Weight -->
        <div class="col-xs-6">
          <table class="table table-detail table-condensed">
            <thead>
              <tr>
                <th colspan="2">{{ 'TRUCKS.ORIGIN_WEIGHT'|translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-align-center">
                <th class="text-right small">{{ 'TRUCKS.GROSS'|translate }}:</th>
                <td>{{ (waybill.origin.weight?.gross || 0)|number:'1.0-0' }} <small
                    class="text-muted">Kgs.</small></td>
              </tr>
              <tr class="tr-align-center">
                <th class="text-right small">{{ 'TRUCKS.TARE'|translate }}:</th>
                <td>{{ (waybill.origin.weight?.tare || 0)|number:'1.0-0' }} <small
                    class="text-muted">Kgs.</small></td>
              </tr>
              <tr class="tr-align-center">
                <th class="text-right small">{{ 'TRUCKS.NET'|translate }}:</th>
                <td>{{ (waybill.origin.weight?.net || 0)|number:'1.0-0' }} <small
                    class="text-muted">Kgs.</small></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-xs-6">
          <table class="table table-detail table-condensed">
            <thead>
              <tr>
                <th colspan="2">{{ 'TRUCKS.DESTINATION_WEIGHT'|translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr-align-center">
                <th class="text-right small">{{ 'TRUCKS.GROSS'|translate }}:</th>
                <td>{{ (waybill.destination.weight?.gross || 0)|number:'1.0-0' }} <small
                    class="text-muted">Kgs.</small></td>
              </tr>
              <tr class="tr-align-center">
                <th class="text-right small">{{ 'TRUCKS.TARE'|translate }}:</th>
                <td>{{ (waybill.destination.weight?.tare || 0)|number:'1.0-0' }} <small
                    class="text-muted">Kgs.</small></td>
              </tr>
              <tr class="tr-align-center">
                <th class="text-right small">{{ 'TRUCKS.NET'|translate }}:</th>
                <td>{{ (waybill.destination.weight?.net || 0)|number:'1.0-0' }} <small
                    class="text-muted">Kgs.</small></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <!-- Arrival/Observations -->
        <div class="col-sm-6 col-md-4">
          <table-detail [header]="'TRUCKS.UNLOADED'|translate">
            <b><date-md [date]="waybill.destination.date"></date-md></b>
          </table-detail>

          <h5 class="grayed">{{ 'SLOTS.VEHICLE_DATA'|translate }}</h5>

          <table class="table table-detail table-condensed">
            <!-- <thead>
              <tr>
                <th colspan="2">{{ 'TRUCKS.TRANSPORT'|translate }}</th>
              </tr>
            </thead> -->
            <tbody>
              <tr class="tr-align-center" *ngIf="waybill.vehicle.payer">
                <th class="small">{{ 'SLOTS.FREIGHT_PAYER'|translate }}:</th>
                <td>{{ waybill.vehicle.payer }}</td>
              </tr>
              <tr class="tr-align-center">
                <th class="small">{{ 'TRUCKS.TRUCK'|translate }}:</th>
                <td class="text-uppercase"><samp
                    *ngIf="waybill.vehicle.license; else none">{{ waybill.vehicle.license }}</samp></td>
              </tr>
              <tr class="tr-align-center">
                <th class="small">{{ 'TRUCKS.TRAILER'|translate }}:</th>
                <td class="text-uppercase"><samp
                    *ngIf="waybill.vehicle.trailer; else none">{{ waybill.vehicle.trailer }}</samp></td>
              </tr>
              <tr class="tr-align-center">
                <th class="small">{{ 'TRUCKS.TO_COVER'|translate }}:</th>
                <td><span *ngIf="waybill.vehicle.km; else none">{{ waybill.vehicle.km|number:'1.0-0' }} <small
                      class="text-muted">Km.</small></span></td>
              </tr>
              <tr class="tr-align-center" *ngIf="waybill.freight?.reference">
                <th class="small">{{ 'TRUCKS.FEE_REFERENCE'|translate }}:</th>
                <td><small class="text-muted">AR$</small> {{ waybill.freight.reference|number:'1.0-2' }}</td>
              </tr>
              <tr class="tr-align-center" *ngIf="waybill.freight?.payed">
                <th class="small">{{ 'SLOTS.FREIGHT_PAYED'|translate }}:</th>
                <td>{{ waybill.freight.payed }}</td>
              </tr>
              <tr class="tr-align-center" *ngIf="waybill.freight?.pending">
                <th class="small">{{ 'SLOTS.FREIGHT_TO_PAY'|translate }}:</th>
                <td>{{ waybill.freight.pending }}</td>
              </tr>
              <tr class="tr-align-center" *ngIf="waybill.freight?.fee">
                <th class="small">{{ 'TRUCKS.FEE'|translate }}:</th>
                <td><small class="text-muted">AR$</small> {{ waybill.freight.fee|number:'1.0-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6 col-md-8">
          <table-detail [header]="'GLOBAL.OBSERVATIONS'|translate">
            <span *ngIf="waybill.destination.observations; else none">{{ waybill.destination.observations }}</span>
          </table-detail>
          <table class="table table-detail table-condensed">
            <thead>
              <tr>
                <th colspan="3">{{ 'TRUCKS.WASTAGE'|translate }}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let wastage of waybill.wastage| keyvalue: originalOrder">
                <tr class="tr-align-center" *ngIf="wastage.key !== 'balance' &&
                  wastage.key !== 'total' &&
                  (wastage.value.net || wastage.value.percentage)">
                  <th class="small">{{ 'TRUCKS.WASTAGE_ITEMS.'+(wastage.key).toUpperCase()|translate }}:</th>
                  <td class="text-right micro">
                    <ng-container *ngIf="wastage.value.percentage">
                      {{ wastage.value.percentage|number:'1.2-2' }}<small class="text-muted">%</small>
                    </ng-container>
                  </td>
                  <td class="text-right text-danger">
                    <span *ngIf="wastage.value.net; else none">{{ -wastage.value.net|number:'1.0-0' }}
                      <small class="currency">Kgs.</small></span>
                  </td>
                </tr>
              </ng-container>
              <tr class="tr-align-center">
                <th class="small">{{ 'TRUCKS.WASTAGE_ITEMS.TOTAL'|translate }}:</th>
                <td></td>
                <td class="text-right text-danger">
                  <b>{{ (-waybill.wastage?.total.net || 0)|number:'1.0-0' }} <small
                      class="currency">Kgs.</small></b></td>
              </tr>
              <tr class="tr-align-center grayed">
                <th class="small">{{ 'TRUCKS.APPLIED'|translate }}:</th>
                <td></td>
                <td class="text-right">{{ (waybill.wastage?.balance.net || 0)|number:'1.0-0' }} <small
                    class="currency">Kgs.</small></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <!-- FAS-3027 -->
      <div *ngIf="waybill?.id && company" class="row">
        <div class="col-xs-12">
          <ag-related-envelopes
          entity="waybill"
          [entity-id]="waybill.id"
          [disabled]="processing"
          [company]="company"></ag-related-envelopes>
        </div>
      </div>
    </ng-container>

    <div class="no-data" *ngIf="error">
      <i class="material-symbols-rounded text-danger">error_outline</i>
      <span [innerHtml]="'GLOBAL.DATA_ERROR_PERMISSION'|translate"></span>
    </div>
  </div>
  <div class="modal-footer" *ngIf="this.waybillFiles?.length">
    <button type="button"
    class="btn btn-default" (click)="getFile()" [disabled]="processing">
      <i class="material-symbols-rounded">save_alt</i> {{ 'SLOTS.DOWNLOAD_PDF'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>