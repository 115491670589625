<p class="flex-center-distributed">
  <span class="grow">{{ 'SIGNATURES.SIGNERS_DESCRIPTION'|translate }}</span>
  <button type="button"
  class="btn btn-agree" (click)="signersModals.add(signers?.length == 0)" [disabled]="!user?.mail_verified"><i
    class="material-symbols-rounded hidden-xs hidden-sm">add</i> {{ 'SIGNATURES.ADD_SIGNER'|translate }}</button>
</p>
<ng-container *ngIf="signers; else loading">
  <div *ngIf="signers.length > 0; else noData" class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table class="table no-margin" [sortable]="signers" defaultSortBy="email" #tableSigners="tableSortable">
        <!-- <caption></caption> -->
        <thead>
          <tr>
            <th class="hidden-xs" sortBy="name">{{ 'TEAM_VIEW.NAME_LASTNAME'|translate }}</th>
            <th sortBy="email">{{ 'TEAM_VIEW.EMAIL'|translate }}</th>
            <th sortBy="identity_number">{{ company.market?.configuration?.company.id_card ? company.market.configuration.company.id_card.label : ('TEAM_VIEW.IDENTITY_NUMER'|translate) }}</th>
            <th sortBy="phone">{{ 'REGISTER_COMPANY.TELEPHONE'|translate }}</th>
            <th sortBy="fiscal_id.value">{{ company.market?.configuration?.company.fiscal_id ?
              company.market.configuration.company.fiscal_id.label : (company.address.country?.fiscalIds[0]?.label ?
              company.address.country.fiscalIds[0].label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION' |translate) }}</th>
            <th class="sap"></th>
            <th class="sap"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let signer of tableSigners.collection">
            <td class="hidden-xs">
              <b>{{ signer.name }} {{ signer.last_name }}</b>
            </td>
            <td>
              <div class="visible-xs"><b>{{ signer.name }} {{ signer.last_name }}</b></div>
              <span [class.highlighted-text]="user.email === signer.email">{{ signer.email }}</span>
            </td>
            <td>
              <samp class="text-muted truncate small" *ngIf="signer.identity_number">{{ signer.identity_number }}</samp>
            </td>
            <td>
              <samp class="text-muted truncate small" *ngIf="signer.phone">{{ signer.phone }}</samp>
            </td>
            <td>
              <samp class="text-muted truncate small" *ngIf="signer.fiscal_id">{{ signer.fiscal_id.mask ?
                (signer.fiscal_id.value| mask: signer.fiscal_id.mask) : signer.fiscal_id.value }}</samp>
            </td>
            <td class="sap">
              <a (click)="getSignerFile(signer)" *ngIf="signer.id && signer.file" [class.disabled]="processing"
                title="{{ 'SIGNATURES.SIGNER_AUTHORIZATION'|translate }}">
                <i class="material-symbols-rounded">save_alt</i>
                <span class="hidden-xs">&nbsp;{{ 'SIGNATURES.SIGNER_AUTHORIZATION_VIEW'|translate }}</span>
              </a>
            </td>
            <td class="sap">
              <a title="{{ 'TEAM_VIEW.DELETE_CONFIRM.MODAL_TITLE'|translate }}" (onConfirm)="delete(signer)"
                [confirm-options]="{
                title: 'TEAM_VIEW.DELETE_CONFIRM.TITLE'|translate,
                body: 'TEAM_VIEW.DELETE_CONFIRM.BODY'|translate,
                action: 'GLOBAL.YES_REMOVE'|translate
              }" [class.disabled]="processing">
                <i class="material-symbols-rounded">delete</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'SIGNATURES.NO_SIGNERS'|translate }}
  </div>
</ng-template>

<ag-signers-modals #signersModals (submitted)="refresh()" [company]="company"></ag-signers-modals>

<ag-file-preview #filePreviewer></ag-file-preview>
