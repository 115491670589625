import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { FilePreviewComponent } from '../../../../../ui/components/file-preview/file-preview.component';
import { SubscriptionManager } from '../../../../../utilities/subscription-manager';
import { CartaPorteElectronica } from '../../models/carta-porte-electronica.model';
import { CartaPorteElectronicaService } from '../../services/carta-porte-electronica.service';

@Component({
  selector: 'cpe-modal',
  templateUrl: './cpe-modal.component.html',
  styleUrls: ['./cpe-modal.component.scss']
})
export class CpeModalComponent extends SubscriptionManager implements OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;
  @ViewChild('modalTemplate', { static: true }) private readonly modalTemplate: TemplateRef<any>;

  @Input() public company: Company;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public cpe: CartaPorteElectronica;
  public error: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService,
    private cpeService: CartaPorteElectronicaService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    super();
  }

  public show(cpe: CartaPorteElectronica | number): void {
    this.openModal(this.modalTemplate, 'modal-lg');
    if (typeof cpe === 'number')
      this.loadCpe(cpe);
    else
      this.setCpe(cpe);
  }

  private loadCpe(ctg: number): void {
    this.subscriptions.push(this.cpeService.getById(this.company.id, ctg).subscribe({
      next: cpe => {
        this.setCpe(cpe);
      },
      error: error => {
        this.error = true;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  private setCpe(cartaPorteElectronica: CartaPorteElectronica): void {
    this.cpe = cartaPorteElectronica;
  }

  public preview(cpeItem: CartaPorteElectronica): void {
    // After fix the extension of the file in the api service, get the real file name from file download
    const fileNameProvisional = cpeItem.numero_ctg + ".pdf"
    this.filePreviewer.preview(this.cpeService.getFile(this.company.id, cpeItem.numero_ctg), fileNameProvisional);
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.modalRef = null;
      this.cpe = undefined;
      this.error = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /**
   * Compare function for
   * [[https://angular.io/api/common/KeyValuePipe|keyvalue]] pipe to preserve
   * original array order.
   */
  public originalOrder(): number {
    return 0;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe all subscription with SubscriptionManager
    super.ngOnDestroy();
    this.closeModal();
  }
}
