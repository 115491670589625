import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Slot } from '../../../../../models/slot.model';
import { SlotsBatch } from '../../../../../models/slots-batch.model';
import { SlotService } from '../../../../../services/slot.service';

@Component({
  selector: 'ag-view-batch-modal',
  templateUrl: './view-batch-modal.component.html',
  styleUrls: ['./view-batch-modal.component.scss']
})
export class ViewBatchModalComponent implements OnDestroy {

  @ViewChild('slotsDetailModal', { static: true }) private readonly slotsDetailModal: TemplateRef<any>;

  @Input() public company: Company;

  public selectedBatch: SlotsBatch;
  public selectedSlots: Slot[];
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private slotService: SlotService
  ) { }

  public showBatch(batch: SlotsBatch | number): void {
    this.openModal(this.slotsDetailModal, 'modal-lg')

    // Batch object
    if (batch instanceof SlotsBatch) {
      this.selectedBatch = batch;
      this.selectedSlots = batch.slots
    }

    // Batch id
    if (typeof batch === 'number') {
      this.subscriptions.push(
        this.slotService.watch(this.company.id, batch).subscribe(batch => {
          if (!batch) return;
          
          this.selectedBatch = batch;
          this.selectedSlots = batch.slots
          this.processing = false;
        })
      )
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });
    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;

      // Reset all values
      this.selectedBatch = null;
      this.selectedSlots = null;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
    this.selectedSlots = null;
    this.selectedBatch = null;
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
