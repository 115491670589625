import { Component, Input } from '@angular/core';

import { Order } from '../../modules/commercial/models/order.model';
import { Proposal } from '../../modules/commercial/models/proposal.model';

@Component({
  selector: 'ag-product-quality-info',
  templateUrl: './product-quality-info.component.html',
  styleUrls: ['./product-quality-info.component.css']
})
export class ProductQualityInfoComponent {

  @Input() public order: Order;
  @Input() public proposal: Proposal;
  @Input() public key: string;

  constructor() { }

}
