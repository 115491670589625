<ag-invoices-modals #invoicesModals
(created)="created($event)"
[company]="company"></ag-invoices-modals>

<div class="container">
  <div class="content-box" *ngIf="company">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ 'BARTERS.NEW_PROPOSAL'|translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <div class="btn-toolbar">
          <div class="btn-group">
            <button type="button"
            class="btn btn-link" [routerLink]="['../barters']" [queryParams]="{
              status_barter_proposals: 1,
              order_by: '-updated_at'
            }" [disabled]="processing">{{ 'GLOBAL.CANCEL'|translate }}</button>
          </div>
          <div class="btn-group">
            <button type="submit" form="create-barter" class="btn btn-agree" [disabled]="processing || !step02.validateFormStep">
              <i class="material-symbols-rounded hidden-xs hidden-sm">done</i>
              {{ (recipients?.length ? 'BARTERS.CREATE_AND_SEND' : 'BARTERS.CREATE')|translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <form id="create-barter" #form="ngForm" (ngSubmit)="form.valid && create()">
      <div class="row step"
      [validateFormStep]="barter_proposal.supplier && barter_proposal.customer"
      #step01="validateFormStep"
      [nextStep]="step02">
        <div class="col-xs-12">
          <div class="line"></div>
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row">
              <div class="col-md-4">
                <!-- SELECT:INSUMERA -->
                <div class="form-group" hasError>
                  <label class="control-label">{{ 'GLOBAL.SUPPLIER'|translate }}</label>
                  <selectize
                    *ngIf="!barter_proposal.supplier"
                    [class.hidden]="noResultsFor.supplier"
                    required
                    [maxItems]="1"
                    [searchURL]="companyService.getCompanies(company.id, {
                      excluded_activities: [1],
                      excluded_ids: [barter_proposal.distributor?.id, barter_proposal.customer?.id]
                    })"
                    [(ngModel)]="barter_proposal.supplier"
                    name="suppliers"
                    placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                    (load)="companyTypeLoad('supplier', $event)"
                    [disabled]="processing">
                  </selectize>
                  <div *ngIf="barter_proposal.supplier" class="editable-pill">
                    <button
                      *ngIf="!disableSelectizeFor.supplier"
                      [disabled]="processing" type="button" class="close" (click)="reset('supplier')"><span aria-hidden="true">&times;</span></button>
                    <company-info [company]="barter_proposal.supplier"></company-info>
                  </div>
                  <alert *ngIf="noResultsFor.supplier" type="warning"><span [innerHTML]="'BARTERS.NO_SUPPLIER'|translate:{companyId:company.id}"></span></alert>
                </div>
              </div>
              <div class="col-md-4">
                <!-- SELECT:DISTRIBUIDOR -->
                <div class="form-group" hasError>
                  <label class="control-label">
                    {{ 'GLOBAL.DISTRIBUTOR'|translate }}
                    <small *ngIf="!disableSelectizeFor.distributor" class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <selectize
                    *ngIf="!barter_proposal.distributor"
                    [class.hidden]="noResultsFor.distributor"
                    [maxItems]="1"
                    [searchURL]="companyService.getCompanies(company.id, {
                      excluded_activities: [1],
                      excluded_ids: [barter_proposal.supplier?.id, barter_proposal.customer?.id]
                    })"
                    [(ngModel)]="barter_proposal.distributor"
                    name="distributors"
                    placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                    (load)="companyTypeLoad('distributor', $event, false)"
                    [disabled]="processing">
                  </selectize>
                  <div *ngIf="barter_proposal.distributor" class="editable-pill">
                    <button
                      *ngIf="!disableSelectizeFor.distributor"
                      [disabled]="processing" type="button" class="close" (click)="reset('distributor')"><span aria-hidden="true">&times;</span></button>
                    <company-info [company]="barter_proposal.distributor"></company-info>
                  </div>
                  <alert *ngIf="noResultsFor.distributor" type="warning"><span [innerHTML]="'BARTERS.NO_DISTRIBUTOR'|translate:{companyId:company.id}"></span></alert>
                </div>
              </div>
              <div class="col-md-4">
              	<!-- SELECT:CLIENTS -->
                <div class="form-group" hasError>
                  <label class="control-label">{{ 'GLOBAL.CUSTOMER'|translate }}</label>
                  <selectize
                    *ngIf="!barter_proposal.customer"
                    [class.hidden]="noResultsFor.customer"
                    required
                    [maxItems]="1"
                    [searchURL]="companyService.getCompanies(company.id, {
                      excluded_ids: [barter_proposal.supplier?.id, barter_proposal.distributor?.id]
                    })"
                    [(ngModel)]="barter_proposal.customer"
                    name="clients"
                    placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                    (load)="companyTypeLoad('customer', $event)"
                    [disabled]="processing">
                  </selectize>
                  <error-list></error-list>
                  <div *ngIf="barter_proposal.customer" class="editable-pill">
                    <button
                      *ngIf="!disableSelectizeFor.customer"
                      [disabled]="processing" type="button" class="close" (click)="reset('customer')"><span aria-hidden="true">&times;</span></button>
                    <company-info [company]="barter_proposal.customer"></company-info>
                  </div>
                  <alert *ngIf="noResultsFor.customer" type="warning"><span [innerHTML]="'BARTERS.NO_CUSTOMER'|translate:{companyId:company.id}"></span></alert>
                </div>
              </div>
            </div>
            <ng-container *ngIf="!noResultsFor.supplier && !noResultsFor.customer && barter_proposal.customer">
              <div class="row">
                <div class="col-xs-12">
                	<!-- TEXT:INVOICE -->
                  <div class="form-group" hasError>
                    <label class="control-label">{{ 'INVOICES.TITLE'|translate }} <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small></label>
                    <p class="text-muted" [innerHtml]="'BARTERS.INVOICE_NUMBER_HELP'|translate"></p>
                    <selectize
                    [maxItems]="10"
                    *ngIf="!refreshSelectize"
                    [searchURL]="invoiceService.searchByNumber(company.id, barter_proposal.customer.name)"
                    type="invoice"
                    labelField="reference"
                    searchField="reference"
                    [(ngModel)]="nextInvoice"
                    (ngModelChange)="parseInvoices($event)"
                    name="invoice-search"
                    placeholder="0000-00000000"
                    [disabled]="processing">
                    </selectize>
                    <error-list></error-list>
                    <a class="small"
                    (click)="invoicesModals.open({
                      recipient: barter_proposal.customer
                    })"
                    [class.disabled]="processing">{{ 'INVOICES.NOT_LISTED'|translate }}</a>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="barter_proposal.invoices?.length > 0">
                <div class="col-xs-12">
                  <div class="horizontal-scroller">
                    <table class="table table-condensed table-hover">
                      <thead>
                        <tr class="tr-align-center">
                          <th class="sap">{{ 'GLOBAL.ISSUED'|translate }}</th>
                          <th class="sap">{{ 'GLOBAL.INVOICE_NUMBER'|translate }}</th>
                          <th class="hidden-xs">{{ 'GLOBAL.TYPE'|translate }}</th>
                          <!-- <th class="hidden-xs hidden-sm">{{ 'GLOBAL.ISSUED_BY'|translate }}</th>
                          <th class="hidden-xs hidden-sm">{{ 'INVOICES.ISSUED_TO'|translate }}</th> -->
                          <th class="hidden-xs sap"><abbr title tooltip="{{ 'GLOBAL.DUE_DATE'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.DUE_DATE_ABBR'|translate }}</abbr></th>
                          <th class="sap text-center">{{ 'GLOBAL.TOTAL'|translate }}</th>
                          <th class="sap"></th>
                          <th class="sap text-center">{{ 'DASHBOARDS.PENDING'|translate }}</th>
                          <th class="dlb text-center">{{ 'BARTERS.TO_BE_SETTLED'|translate }}</th>
                          <th class="sap"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="tr-align-center" *ngFor="let bpinvoice of barter_proposal.invoices; let i = index">
                          <td class="sap">
                            <ag-timestamp [date]="bpinvoice.invoice.date" [showTime]="false"></ag-timestamp>
                          </td>
                          <td class="sap small"><samp><a (click)="invoicesModals.show(bpinvoice.invoice)">{{ bpinvoice.invoice.reference| mask: '0000-00000000' }}</a></samp></td>
                          <td class="hidden-xs small text-muted">
                            <ng-container *ngIf="bpinvoice.invoice.concept?.type; else none">{{ bpinvoice.invoice.concept.type }}</ng-container>
                          </td>
                          <!-- <td class="hidden-xs hidden-sm actor"><company-info [company]="bpinvoice.invoice.company" [activity]="false"></company-info></td>
                          <td class="hidden-xs hidden-sm actor"><company-info [company]="bpinvoice.invoice.recipient" [activity]="false"></company-info></td> -->
                          <td class="hidden-xs sap micro text-muted">
                            <date-md [date]="bpinvoice.invoice.due_date" [style]="true"></date-md>
                          </td>
                          <td class="sap text-right"><price [unit]="bpinvoice.invoice.unit.name" [value]="bpinvoice.invoice.balance"></price></td>
                          <td class="sap">
                            <progress-circle
                            *ngIf="bpinvoice.invoice.settled"
                            label="{{ 'INVOICES.COVERAGE'|translate }}"
                            [progress]="bpinvoice.invoice.settled / bpinvoice.invoice.balance"></progress-circle>            
                          </td>
                          <td class="sap text-right text-muted"><price *ngIf="!bpinvoice.invoice.isSettled; else none" [unit]="bpinvoice.invoice.unit.name" [value]="bpinvoice.invoice.balance - (bpinvoice.invoice.settled || 0)"></price></td>
                          <td class="dlb price">
                            <price-form
                            prefix="{{ i }}_"
                            [types]="[{id: 'flat', label: 'Fijo', enabled: true}]"
                            [price]="bpinvoice.amount"
                            [currencies]="[bpinvoice.invoice.unit]"
                            [showQuantityUnit]="false"
                            [min]="0.01"
                            [max]="bpinvoice.invoice.balance - (bpinvoice.invoice.settled || 0)"
                            [disabled]="processing"
                            placeholder="0.00"></price-form>
                          </td>
                          <td class="sap">
                            <span class="glyphicon glyphicon-exclamation-sign text-warning"
                            [class.invisible]="!bpinvoice.invoice.balance ||
                            bpinvoice.amount.value === bpinvoice.invoice.balance"
                            tooltip="{{ 'BARTERS.PARTIAL'|translate }}"
                            aria-hidden="true"></span>
                          </td>
                        </tr>
                        <tr class="tr-align-center" *ngIf="barter_proposal.invoices?.length > 1 &&
                        barter_proposal.invoicesBalance.value > 0">
                          <td colspan="2" class="hidden-xs"></td>
                          <!-- <td colspan="2" class="hidden-xs hidden-sm"></td> -->
                          <td class="text-right text-uppercase micro" colspan="5"><b>{{ 'INVOICES.BALANCE'|translate }}</b></td>
                          <td class="dlb price" colspan="2"><price [unit]="barter_proposal.invoicesBalance.unit.name" [value]="barter_proposal.invoicesBalance.value"></price></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row step"
      [validateFormStep]="step01.validateFormStep &&
      (barter_proposal.scope !== 'offline' || (barter_proposal.orders.length > 0 && recipients.length > 0))"
      #step02="validateFormStep">
        <div class="col-xs-12">
          <!-- <div class="line"></div> -->
          <span class="step-indicator">
            <span></span>
            <i class="material-symbols-rounded">done</i>
          </span>
          <div class="container-fluid" style="padding-left: 0px;">
            <div class="row" *ngIf="!company.activity.broker">
              <div class="col-sm-9">
                <!-- SELECT:RECIPIENTS -->
                <div class="form-group" hasError>
                  <label class="control-label">{{ 'BARTERS.MANAGERS'|translate }}
                    <small class="text-muted"
                    *ngIf="barter_proposal.scope !== 'offline'">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <ng-container *ngIf="recipients && (recipients[0]?.id === company.id || barter_proposal.scope === 'offline'); else selectManagers">
                    <div class="editable-pill">
                      <button
                      *ngIf="!exisitngNegotiations"
                      [disabled]="processing" type="button" class="close"
                      (click)="recipients = undefined"><span aria-hidden="true">&times;</span></button>
                      <company-info [company]="recipients[0]"></company-info>
                    </div>
                    <p *ngIf="recipients[0].id === company.id"
                    class="help-block small" [innerHtml]="'BARTERS.SELF_MANAGED_HELP'|translate: {name: recipients[0].name}"></p>
                  </ng-container>
                  <ng-template #selectManagers>
                    <selectize
                    *ngIf="brokers"
                    [options]="brokers"
                    [(ngModel)]="recipients"
                    name="brokers"
                    placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"
                    [disabled]="processing"></selectize>
                    <a class="small" (click)="recipients = [company]"
                    *ngIf="barter_proposal.supplier?.id === company.id"
                    [class.disabled]="processing">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
                  </ng-template>
                  <error-list></error-list>
                </div>
              </div>
              <div class="col-sm-3"
              *ngIf="!exisitngNegotiations">
                <div class="checkbox flex-center-distributed">
                  <div>
                    <input type="checkbox" class="ios"
                    name="scope_offline" id="offline_chk"
                    [disabled]="processing"
                    [checked]="barter_proposal.scope === 'offline'"
                    (click)="offline($event.target.checked)">
                  </div>
                  <label for="offline_chk" class="grow">{{ 'BARTERS.OFFLINE'|translate }}</label>
                </div>
                <p *ngIf="barter_proposal.scope === 'offline'" class="help-block" [innerHtml]="'ORDER_FORM.SCOPE.OFFLINE_HELP'|translate"></p>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label class="control-label">{{ 'COMMERCIAL_MANAGER.TABLE.PRODUCTS'|translate }}
                    <small class="text-muted"
                    *ngIf="barter_proposal.scope !== 'offline'">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
                  </label>
                  <p *ngIf="barter_proposal.orders.length === 0" class="text-muted">{{ 'BARTERS.OPTIONAL_PROPOSALS'|translate }}</p>
                  <div *ngFor="let order of barter_proposal.orders; let i = index" class="panel panel-default">
                    <div class="panel-heading">
                      <button [disabled]="processing" type="button" class="close"
                      (onConfirm)="removeOrder(i)"
                      [confirm-options]="{
                        title: 'BARTERS.REMOVE_MODAL.TITLE'|translate,
                        body: 'BARTERS.REMOVE_MODAL.BODY'|translate,
                        action: 'GLOBAL.YES_REMOVE'|translate
                      }"><span aria-hidden="true">&times;</span></button>
                      <b>{{ order.product.name }}</b>
                      <samp *ngIf="order.negotiations?.length" class="small text-muted">&nbsp;<a [routerLink]="['/company', company.id, 'counter-order', order.negotiations[0].id]">#{{ order.negotiations[0].id }}</a></samp>
                      &nbsp;<a [class.disabled]="processing" class="small"
                      (click)="editProduct(i)"
                      *ngIf="!order.id">
                        <i class="material-symbols-rounded">edit</i>
                      </a>
                    </div>
                    <div class="panel-body">
                      <proposal-detail
                      [proposal]="order"
                      [order]="order"
                      [editable]="false"
                      [formMode]="false"
                      [previousContract]="order.product_clauses"
                      [hideUndefined]="true"
                      [condensed]="true"></proposal-detail>
                    </div>
                  </div>
                  <button type="button" class="btn btn-agree" [disabled]="processing"
                  (click)="addProduct()"
                  *ngIf="!exisitngNegotiations">
                    <i class="material-symbols-rounded">add</i>
                    {{ 'BARTERS.ADD_PRODUCT'|translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<create-order-modal #createOrder="createOrder"
*ngIf="barter_proposal"
[(order)]="new_order"
[edit]="editingOrder !== undefined"
[excludeFields]="['paymentCondition']"
[amount]="barter_proposal.invoicesBalance"
[ordersFrom]="recipients"
[fullmode]="barter_proposal.scope === 'offline'"
(success)="saveOrder()"></create-order-modal>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>