import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';
import { buildFilters } from '../../utilities/filters';
import { ProductionPlan } from '../models/production-plan.model';

@Injectable({
  providedIn: 'root'
})
export class ProductionPlansService {

  private baseUrl: string = '/:apiBase/companies/:companyId/productive-plans';
  private planById: string = this.baseUrl + '/:id';
  private sharePlan: string = this.planById + '/share';
  private importUrl: string = this.baseUrl + '/import';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: ProductionPlan[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrl
      .replace(':companyId', companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<{
      productivePlans: ProductionPlan[]
    }>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      return { body: plainToInstance(ProductionPlan, response.body.data.productivePlans), headers: response.headers };
    }));
  }

  public create(companyId: number, productionPlan: ProductionPlan): Observable<ProductionPlan> {
    const url = this.baseUrl
      .replace(":companyId", companyId + '');

    return this.http.post<ProductionPlan>(url, productionPlan).pipe(map(response => {
      return plainToInstance(ProductionPlan, response);
    }));
  }

  public share(companyId: number, productionPlanId: string, companyIds: number[]): Observable<any> {
    const url = this.sharePlan
      .replace(":companyId", companyId + '')
      .replace(":id", productionPlanId);

    return this.http.post<any>(url, {
      shareWith: companyIds
    });
  }

  public delete(companyId: number, productionPlanId: string): Observable<any> {
    const url = this.planById
      .replace(":companyId", companyId + '')
      .replace(":id", productionPlanId);

    return this.http.delete<any>(url);
  }

  public import(companyId: number, fiscalIds: string[], source: number): Observable<{
    fiscalId: string;
    productionPlan: ProductionPlan;
  }[]> {
    let url = this.importUrl
      .replace(":companyId", companyId + '')
      + '?fiscalIds=' + fiscalIds.join(',')
      + '&source=' + source;

    const stream = this.http.get<JSend<{
      productionPlans: {
        fiscalId: string;
        productionPlan: ProductionPlan;
      }[];
    }>>(url);

    return stream.pipe(map(response => {
      if (response.data.productionPlans?.length > 0) response.data.productionPlans.forEach(pp => {
        pp.productionPlan = plainToInstance(ProductionPlan, pp.productionPlan);
      });
      return response.data.productionPlans;
    }));
  }
}
