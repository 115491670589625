import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Company } from '../../../../../models/company.model';
import { SlotsBatch } from '../../../../../models/slots-batch.model';
import { Negotiation } from '../../../commercial/models/negotiation.model';
import { Contract } from '../../../contracts/models/contract.model';
import { LinkToContractComponent } from './link-to-contract/link-to-contract.component';
import { LinkToNegotiationComponent } from './link-to-negotiation/link-to-negotiation.component';

/**
 * SlotsLinker is a component intended to assist the [[User]] to link and/or
 * unlink [[SlotsBatch|Batches]] with [[Negotiation|Negotiations]].
 *
 * ## Usage
 * ``` html
 * <ag-slots-linker #slotsLinker
 * [entity]="entity"
 * [company]="company"></ag-slots-linker>
 * ```
 *
 * Then:
 * ``` javascript
 * slotsLinker.linkBatch(batch); // Opens the linker modal
 * slotsLinker.unlinkBatch(batch); // Unlinks the specifie
 * ```
 *
 * ### Related UI components:
 * - [[ClosedNegotiationsTableComponent]]
 * - [[ContractsTableComponent]]
 */
@Component({
  selector: 'ag-slots-linker',
  templateUrl: './slots-linker.component.html',
  styleUrls: ['./slots-linker.component.scss']
})
export class SlotsLinkerComponent {

  @ViewChild('linkToNegotiationModal', { static: true }) public readonly linkToNegotiationModal: LinkToNegotiationComponent;
  @ViewChild('linkToContractModal', { static: true }) public readonly linkToContractModal: LinkToContractComponent;

  /**
   * [[Company]] that makes the link.
   */
  @Input() public company: Company;
  @Input() public entity: 'contract' | 'negotiation' = 'negotiation';

  @Output() readonly linked = new EventEmitter<Contract | Negotiation>();

  constructor() { }

  public linkBatch(batch: SlotsBatch, type?: 'venta' | 'compra'): void {
    const switchObj = {
      negotiation: this.linkToNegotiationModal,
      contract: this.linkToContractModal,
    };

    switchObj[this.entity].linkBatch(batch, type);
  }

  public unlinkBatch(batch: SlotsBatch): void {
    const switchObj = {
      negotiation: this.linkToNegotiationModal,
      contract: this.linkToContractModal,
    };

    switchObj[this.entity].unlinkBatch(batch);
  }

  public linkedListener(entityObj: Contract | Negotiation): void {
    this.linked.emit(entityObj);
  }
}
