import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { Negotiation } from '../../modules/commercial/models/negotiation.model';
import { Order, OrderCreation } from '../../modules/commercial/models/order.model';
import { OrderService } from '../../modules/commercial/services/order.service';

/**
 * Shows the Counterparties of an [[Order]] or [[Negotiation]].
 *
 * ## Usage
 * ``` html
 * <counterparties
 * [negotiation]="negotiation"
 * [company]="company"></counterparties>
 * ```
 *
 * ### Related UI components:
 * - [[CounterOrderComponent]]
 * - [[PreviewOrderComponent]]
 * - [[ViewOrderComponent]]
 */
@Component({
  selector: 'counterparties',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnDestroy {

  @ViewChild('discloseModal', { static: true }) private readonly discloseModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() set order(order: OrderCreation) {
    if (order) {
      const from_broker = order.company.activity.broker;
      const represented = order.represented || undefined;
      const counterpart_is_broker = order.private_companies[0]?.activity.broker;
      const isPrivateOrder = order.scope === 'privado';

      if (order.operation_type === 'compra') {
        if (!isPrivateOrder) {
          this.sellers = counterpart_is_broker ? order.represented_counterpart : order.private_companies;
          this.seller_broker = counterpart_is_broker ? order.private_companies[0] : undefined;
        }
        this.buyers = from_broker ? represented : [order.company];
        this.buyer_broker = from_broker ? order.company : undefined;

      } else {
        if (!isPrivateOrder) {
          this.buyers = counterpart_is_broker ? order.represented_counterpart : order.private_companies;
          this.buyer_broker = counterpart_is_broker ? order.private_companies[0] : undefined;
        }
        this.sellers = from_broker ? represented : [order.company];
        this.seller_broker = from_broker ? order.company : undefined;
      }

      this._order = order;
    }
  }
  get order(): OrderCreation {
    return this._order;
  }
  @Input() set negotiation(negotiation: Negotiation) {
    if (negotiation) {
      const buy_order = negotiation.order.operation_type === 'compra',
        buyers_from = buy_order ? negotiation.order : negotiation,
        sellers_from = buy_order ? negotiation : negotiation.order;

      this.buyers = buyers_from.company.activity.broker ? buyers_from.represented : [buyers_from.company];
      this.buyer_broker = buyers_from.company.activity.broker ? buyers_from.company : undefined;
      this.sellers = sellers_from.company.activity.broker ? sellers_from.represented : [sellers_from.company];
      this.seller_broker = sellers_from.company.activity.broker ? sellers_from.company : undefined;

      this.booked = negotiation.status.id === 7; // Booked

      this._negotiation = negotiation;
    }
  }
  get negotiation(): Negotiation {
    return this._negotiation;
  }

  @Output() public readonly disclose = new EventEmitter();

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** Indicates if the [[Negotiation]] is booked. */
  public booked: boolean;
  public buyers: Company[];
  public buyer_broker: Company;
  public seller_broker: Company;
  public sellers: Company[];
  /** Temporal variable for the disclosure modal. */
  public represented: Company[];
  /** Meta data for the modal presentation. */
  public modalData: {
    order?: boolean,
    buyers?: boolean
  } = {};

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private _negotiation: Negotiation;
  private _order: OrderCreation;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private modalService: BsModalService,
    private orderService: OrderService,
    /** @ignore */
    public companyService: CompanyService
  ) { }

  private ownerOf(entity: Order | Negotiation): boolean {
    return entity && entity.company.id === this.company.id;
  }

  /** Opens the disclose dialog modal. */
  public discloseDialog(): void {
    // Reserved to Brokers
    if (this.company.activity.broker) {
      this.represented = undefined;

      const order = this.order || this.negotiation.order,
        order_buy = order.operation_type === 'compra',
        orderOwner = this.ownerOf(order),
        negotiationOwner = this.ownerOf(this.negotiation);

      if (orderOwner) {
        // I'm the owner of the Order
        // Editing the Order
        if (order.represented && order.represented.length) this.represented = instanceToInstance(order.represented);
        this.modalData.order = true;
      } else if (negotiationOwner) {
        // I'm the owner of the Negotiation
        // Editing the Negotiation
        if (this.negotiation.represented && this.negotiation.represented.length) this.represented = instanceToInstance(this.negotiation.represented);
        this.modalData.order = false;
      }

      this.modalData.buyers = (this.modalData.order && order_buy) || (!this.modalData.order && !order_buy);

      this.openModal(this.discloseModal);
    }
  }

  /** Submits the [[OrderSummaryComponent.represented|represented]]. */
  public updateRepresented(): void {
    this.processing = true;
    if (this.modalData.order) {
      const order = this.order || this.negotiation.order;
      this.subscriptions.push(this.orderService.orderRepesented(this.company.id, order.id, this.represented).subscribe(response => {
        this.closeModal();
      }));
    } else {
      this.subscriptions.push(this.orderService.negotiationRepesented(this.company.id, this.negotiation.id, this.represented).subscribe(response => {
        this.closeModal();
      }));
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
