import { PrimitiveUnit } from "./unit.model";

export class CompanyLocation {
  id: number;
  name: string;
  
  location: PrimitiveUnit;
  partido: PrimitiveUnit;
  provincia: PrimitiveUnit;
  zone: PrimitiveUnit;

  get zoneName(): string {
    return this.zone?.name || ' ';
  }

  get locationName(): string {
    // TODO: Translate
    return this.location ? this.location.name + ', ' + this.partido.name + ' (' + this.provincia.name + ')' : 'Opción Comprador';
  }
}
