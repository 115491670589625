<ng-template #RepublishMassively>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close" [disabled]="loading || republishing">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.MODAL_TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="condition-form" #conditionForm="ngForm" (ngSubmit)="conditionForm.valid && republish()">
      <div class="row">
        <div class="col-xs-12">
          <div class="flex-center-distributed">
            <p *ngIf="hasDates" class="grow">
              {{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.MODAL_DESC_PRE'|translate }}
              <select class="input-sm" (change)="onChangeDate($event)" [disabled]="loading || republishing">
                <option *ngFor="let date of dates">{{ date }}</option>
              </select>
              {{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.MODAL_DESC_POST'|translate }}
            </p>
            <p *ngIf="ordersByProduct?.length">
              <button type="button" title="{{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.EDIT_PRICES'|translate }}" class="btn btn-default btn-sm" (click)="toogleEditPrices()" [disabled]="loading || republishing">
                <i class="material-symbols-rounded">monetization_on</i> {{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.EDIT_PRICES'|translate }}
              </button>
            </p>
          </div>

          <div class="loading" *ngIf="loading">
            <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
          </div>
          <!-- No data -->

          <div class="no-data" *ngIf="!loading && ordersByProduct?.length === 0">
            <i class="material-symbols-rounded">not_interested</i>
            {{ 'MY_OPERATIONS_VIEW.NO_RECORDS'|translate }}
          </div>

          <div *ngIf="!loading" class="horizontal-scroller">
            <table class="table table-condensed" sortable defaultSortBy="-priority" #tableSortable="tableSortable"
            *ngIf="ordersByProduct?.length > 0"
            AgMultiselection
            [(ngModel)]="selection"
            name="selection">
              <!-- <caption></caption> -->
              <thead>
                <tr class="tr-align-center">
                  <th class="sap">
                    <input type="checkbox" AgSelectall
                    id="select-all-toggle"
                    [disabled]="loading || republishing"
                    class="no-margin"
                    tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
                    container="body" [adaptivePosition]="false">
                  </th>
                  <th scope="col" id="ico"></th>
                  <th scope="col" id="loc" sortBy="locationString" *ngIf="company?.market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                  <th scope="col" id="port" class="hidden-xs" sortBy="portString" *ngIf="company?.market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
                  <th scope="col" id="qua" class="hidden-xs" sortBy="quality_string">{{ 'GLOBAL.QUALITY'|translate }}</th>
                  <th scope="col" id="d_type" class="hidden-xs" sortBy="deliveryType" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">{{ 'GLOBAL.DELIVERY_TYPE'|translate }}</th>
                  <th class="sap hidden-xs hidden-sm" sortBy="hasObservations">
                    <abbr title tooltip="{{ 'GLOBAL.OBSERVATIONS'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate }}</abbr>
                  </th>
                  <th scope="col" id="ton" class="dlb drb" sortBy="business_detail.quantity.valueString">
                    <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                  </th>
                  <th class="sap text-center" sortBy="business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
                  <th class="sap" sortBy="business_detail.price.value">{{ 'GLOBAL.PRICE'|translate }}</th>
                </tr>
              </thead>
              <tbody *ngFor="let group of ordersByProduct">
                <!-- Product sub-header -->
                <tr class="product-header">
                  <th scope="colgroup" id="product_{{ group.key.id }}" class="text-uppercase" [colSpan]="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists ? '9' : '8'">{{
                    group.key.name }}</th>
                </tr>
                <!-- Order row -->
                <ng-template ngFor let-order [ngForOf]="group.values|orderBy:tableSortable.sortBy">
                  <tr class="order-row tr-align-center">
                    <td class="sap" headers="product_id order_id_{{ order.id }} chk">
                      <input [AgCheckbox]="order" type="checkbox" id="order_id_{{ order.id }}_check" [disabled]="loading || republishing"
                      class="no-margin">
                    </td>
                    <td headers="product_{{ group.key.id }} order_id_{{ order.id }} ico">
                      <scope-icon [entity]="order"></scope-icon>
                      <i tooltip="{{ order.general_observations }}" data-placement="right" class="hidden-md hidden-lg material-symbols-rounded"
                        *ngIf="order.general_observations">comment</i>
                    </td>
                    <th headers="product_{{ group.key.id }} order_id_{{ order.id }} loc" class="small"
                    *ngIf="company?.market.configuration.location.enabled">
                      <span class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
                    </th>
                    <th headers="product_{{ group.key.id }} order_id_{{ order.id }} port" class="small"
                    *ngIf="company?.market.configuration.order.business_detail.port.enabled">
                      {{ countryAndPort(order) }}
                    </th>
                    <td headers="product_{{ group.key.id }} order_id_{{ order.id }} qua" class="hidden-xs small">
                      {{ order.quality_string }}
                    </td>
                    <td headers="product_{{ group.key.id }} order_id_{{ order.id }} d_type" class="hidden-xs" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                      {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
                    </td>
                    <td class="sap hidden-xs hidden-sm">
                      <i tooltip="{{ order.general_observations }}" class="material-symbols-rounded" *ngIf="order.hasObservations">comment</i>
                    </td>
                    <td headers="product_{{ group.key.id }} order_id_{{ order.id }} ton" class="text-right">
                      <quantity-control [quantity]="order.business_detail.quantity"></quantity-control>
                    </td>
                    <td class="sap" [class.adjusted]="order.business_detail.delivery.date_modified">
                      <div class="adjust-indicator" [tooltip]="order.business_detail.delivery.date_modified ? ('MY_OPERATIONS_VIEW.BULK_REPUBLISH.TOOLTIP_DATE'|translate ) : null">
                        <date-range
                        [styles]="false"
                        [from]="order.business_detail.delivery.date_from"
                        [to]="order.business_detail.delivery.date_to"></date-range>
                        <i class="material-symbols-rounded">error_outline</i>
                      </div>
                    </td>
                    <td class="sap">
                      <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                        <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                        <div class="value">
                          <price-display [price]="order.business_detail.price" [market]="company?.market" [withoutYear]="true" [editable]="editPrices && !republishing"></price-display>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" data-dismiss="modal" [disabled]="loading || republishing">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="condition-form" class="btn btn-agree" [disabled]="!(selection.length > 0) || loading || republishing">
      {{ (republishing ? 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.PUBLISHING' : 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.ACCEPT_BUTTON')|translate }}
    </button>
  </div>
</ng-template>