<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing || disabled" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SIGNATURES.HISTORY'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12">
        <div class="flex-center-distributed m-b1">
          <code>{{ slot.reference }}</code>
          <div class="grow">
            <ag-stop-status [stop]="slot.stop"></ag-stop-status>
          </div>
          <button type="button" class="btn btn-link" [disabled]="processing || disabled" (click)="loadData()"><span
            class="material-symbols-rounded">refresh</span></button>    
        </div>

        <table *ngIf="slotHistory; else loadingIndicator"
        class="table table-condensed table-hover no-margin" sortable defaultSortBy="date"
          #tableSortable="tableSortable">
          <thead>
            <tr>
              <th class="sap" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
              <th sortBy="company.name">{{ 'GLOBAL.COMPANY'|translate }}</th>
              <th sortBy="user.name">{{ 'TRACK_RECORD.USER_AND_ACTION'|translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let step of slotHistory|orderBy: tableSortable.sortBy; let i = index">
              <td class="sap">
                <ag-timestamp [date]="step.date"></ag-timestamp>
              </td>
              <td>
                <company-info [company]="step.company"></company-info>
              </td>
              <td>
                <b *ngIf="step.user" [class.highlighted-text]="user?.id === step.user.id" title="{{ step.user.email }}">{{
                  step.user.name }} {{ step.user.last_name}}</b>&nbsp;
                <span
                  [innerHtml]="('SLOTS.ACTIONS.' + step.action)|translate:{company: step.company_reference?.name}"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingIndicator>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>