import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, combineLatest } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { Price } from '../../../../../models/price.model';
import { FintechApplication } from '../../models/fintech-application.model';
import { FintechProduct } from '../../models/fintech-product.model';
import { FintechApplicationsService } from '../../services/fintech-applications.service';

@Component({
  selector: 'ag-application-product',
  templateUrl: './application-product.component.html',
  styleUrls: ['./application-product.component.scss']
})
export class FintechApplicationProductComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public application: FintechApplication;
  @Input() public company: Company;

  public applicationClone: FintechApplication;
  public editables: Partial<FintechProduct> = {
    rate: undefined,
    tax_rate: undefined
  };
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public unit: Currency;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private fintechApplicationsService: FintechApplicationsService,
    private modalService: BsModalService
  ) { }

  public edit(): void {
    this.applicationClone = instanceToInstance(this.application);

    this.unit = this.applicationClone.product.currencies[0];

    if (!this.applicationClone.approved) {
      this.applicationClone.approved = new Price({
        unit: this.unit
      });
    }

    for (const key in this.editables) {
      const original = this.applicationClone.product[key];
      if (original !== undefined) {
        this.editables[key] = instanceToInstance(original);
      }
    }

    this.openModal(this.modal);
  }

  public submit(): void {
    const observables: Observable<any>[] = [];

    // Application DIFF
    if (this.applicationClone.approved.value !== this.application.approved?.value) {
      observables.push(this.fintechApplicationsService.edit(this.company.id, this.applicationClone));
    }

    // Product DIFF
    const diff: Partial<FintechProduct> = {};
    let found_diff: boolean;

    for (let i = 0, keys = Object.keys(this.editables); i < keys.length; i++) {
      const field = keys[i];
      const element = this.editables[field];
      if (this.applicationClone.product[field] !== element) {
        // Post only differences
        found_diff = true;
        diff[field] = element;
      };
    }

    if (found_diff) observables.push(this.fintechApplicationsService.editProduct(this.company.id, this.applicationClone.id, this.editables));

    if (observables.length > 0) {
      this.processing = true;

      this.subscriptions.push(combineLatest(observables).subscribe(response => {
        this.closeModal();
        this.processing = false;
      }));
    } else this.closeModal();
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
