<ag-file-preview #filePreviewer></ag-file-preview>

<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SLOTS.DETAILS_MODAL.CTG_ABBR'|translate }}: {{ cpe?.numero_ctg }}</h4>

  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="!cpe && !error">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <ng-container *ngIf="cpe">
      <div class="row">
        <div class="col-sm-4">
          <table-detail [header]="'TRUCKS.WAYBILL'|translate">
            <samp>{{ cpe.nro_orden | lpad: 13: '0' | mask: '00000-00000000' }}</samp>
          </table-detail>

        </div>
        <div class="col-xs-6 col-sm-4">
          <table-detail [header]="'GLOBAL.DATE'|translate">
            <date-md [date]="cpe.fecha_emision"></date-md>
          </table-detail>

        </div>
        <div class="col-xs-6 col-sm-4">
          <table-detail [header]="'CPE.ACTORS.TITULAR_CARTA_DE_PORTE'|translate">
            <b *ngIf="cpe.origen?.cuit; else none">{{ cpe.origen.cuit| mask:'00-00000000-0' }}</b>
          </table-detail>
        </div>
      </div>

      <!-- Actors / Intervinientes -->
      <ng-container *ngIf="cpe.intervinientes">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'SLOTS.ACTORS'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <ng-container *ngFor="let interviniente of cpe.intervinientes| keyvalue: originalOrder">
            <div class="col-sm-6 col-md-4" *ngIf="interviniente.value">
              <company-table [header]="'TRUCKS.CPE_ACTORS.'+(interviniente.key).toUpperCase()|translate" [activity]="false"
                [companies]="[interviniente.value]"></company-table>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- GRANO / ESPECIE -->
      <ng-container *ngIf="cpe.datos_carga">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'SLOTS.GRAINS'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8" style="padding-left: 0px;">
            <div class="col-xs-6 col-sm-6">
              <table-detail [header]="'GLOBAL.PRODUCT'|translate">
                <b *ngIf="cpe.product; else none">{{ cpe.product.name }}</b>
              </table-detail>
            </div>
            <div class="col-xs-6 col-sm-6">
              <table-detail [header]="'GLOBAL.TYPE'|translate">
                <ng-container *ngIf="cpe.product_type; else none">{{ cpe.product_type }}</ng-container>
              </table-detail>
            </div>
            <div class="col-xs-12 col-sm-12">
              <table-detail [header]="'GLOBAL.OBSERVATIONS'|translate">
                <ng-container *ngIf="cpe.observaciones; else none">{{ cpe.observaciones }}</ng-container>
              </table-detail>
            </div>
          </div>

          <!-- Weight -->
          <div class="col-xs-12 col-sm-4">
            <table class="table table-detail table-condensed">
              <thead>
                <tr>
                  <th colspan="2">{{ 'TRUCKS.ORIGIN_WEIGHT'|translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-left small">{{ 'TRUCKS.GROSS'|translate }}:</th>
                  <td>{{ (cpe.datos_carga.peso_bruto || 0)|number:'1.0-0' }} <small class="text-muted">Kgs.</small></td>
                </tr>
                <tr>
                  <th class="text-left small">{{ 'TRUCKS.TARE'|translate }}:</th>
                  <td>{{ (cpe.datos_carga.peso_tara || 0)|number:'1.0-0' }} <small class="text-muted">Kgs.</small></td>
                </tr>
                <tr>
                  <th class="text-left small">{{ 'TRUCKS.NET'|translate }}:</th>
                  <td>{{ (cpe.datos_carga.peso_bruto - cpe.datos_carga.peso_tara || 0)|number:'1.0-0' }} <small class="text-muted">Kgs.</small></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </ng-container>

      <!-- PROCEDENCIA -->
      <ng-container *ngIf="cpe.origen">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'GLOBAL.ORIGIN'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'CPE.ORIGIN.PLANTA'|translate">
              <b *ngIf="cpe.origen.planta; else none">{{ cpe.origen.planta }}</b>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'SLOTS.PROVINCE'|translate">
              <b *ngIf="cpe.origen.provincia; else none">{{ cpe.origen.provincia }}</b>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'SLOTS.LOCALITY'|translate">
              <b *ngIf="cpe.origen.localidad; else none">{{ cpe.origen.localidad }}</b>
            </table-detail>
          </div>

        </div>
      </ng-container>

      <!-- DESTINO DE LA MERCADERÍA -->
      <ng-container *ngIf="cpe.destino">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'TRUCKS.CPE_ACTORS.DESTINO'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-3">
            <table-detail [header]="'CUIT'">
              <b *ngIf="cpe.destino.cuit; else none">{{ cpe.destino.cuit| mask:'00-00000000-0' }}</b>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-3">
            <table-detail [header]="'CPE.ORIGIN.PLANTA'|translate">
              <b *ngIf="cpe.destino.planta; else none">{{ cpe.destino.planta }}</b>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-3">
            <table-detail [header]="'SLOTS.PROVINCE'|translate">
              <b *ngIf="cpe.destino.provincia; else none">{{ cpe.destino.provincia }}</b>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-3">
            <table-detail [header]="'SLOTS.LOCALITY'|translate">
              <b *ngIf="cpe.destino.localidad; else none">{{ cpe.destino.localidad }}</b>
            </table-detail>
          </div>

        </div>
      </ng-container>

      <!--  DATOS DEL TRANSPORTE -->
      <ng-container *ngIf="cpe.transporte">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'CPE.TRANSPORTE.TITLE'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'CPE.TRANSPORTE.DOMINIO'|translate">
              <b *ngIf="cpe.transporte.dominio; else none">{{ cpe.transporte.dominio }}</b>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'CPE.TRANSPORTE.FECHA_PARTIDA'|translate">
              <date-md [date]="cpe.transporte.fecha_partida"></date-md>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'CPE.TRANSPORTE.KMS_RECORRER'|translate">
              <ng-container *ngIf="cpe.transporte.km_recorrer; else none">
                {{ (cpe.transporte.km_recorrer || 0) | number:'1.0-0' }} <small class="text-muted">Kms.</small>
              </ng-container>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'CPE.TRANSPORTE.TARIFA_REF'|translate">
              <ng-container *ngIf="cpe.transporte.tarifa_referencia; else none">{{ cpe.transporte.tarifa_referencia }}
              </ng-container>
            </table-detail>
          </div>
          <div class="col-xs-6 col-sm-4">
            <table-detail [header]="'CPE.TRANSPORTE.TARIFA'|translate">
              <ng-container *ngIf="cpe.transporte.tarifa; else none">{{ cpe.transporte.tarifa }}</ng-container>
            </table-detail>
          </div>
        </div>
      </ng-container>

      <!-- DESCARGA -->
      <ng-container *ngIf="cpe.datos_carga">
        <div class="row">
          <div class="col-xs-12">
            <h5 class="grayed">{{ 'TRACKER.EVENTS.UNLOAD'|translate }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8" style="padding-left: 0px;">

            <div class="col-xs-6 col-sm-6">
              <table-detail [header]="'SLOTS.ARRIVAL_DATE'|translate">
                <date-md [date]="cpe.fecha_emision"></date-md>
              </table-detail>

            </div>
            <div class="col-xs-6 col-sm-6">
              <table-detail [header]="'TRUCKS.UNLOADED'|translate">
                <ag-timestamp [date]="cpe.fecha_inicio_estado"></ag-timestamp>
              </table-detail>
            </div>
            <div class="col-xs-6 col-sm-6">
              <table-detail [header]="'SLOTS.PROVINCE'|translate">
                <b *ngIf="cpe.destino.provincia; else none">{{ cpe.destino.provincia }}</b>
              </table-detail>
            </div>
            <div class="col-xs-6 col-sm-6">
              <table-detail [header]="'SLOTS.LOCALITY'|translate">
                <b *ngIf="cpe.destino.localidad; else none">{{ cpe.destino.localidad }}</b>
              </table-detail>
            </div>
          </div>

          <!-- Weight -->
          <div class="col-xs-12 col-sm-4">
            <table class="table table-detail table-condensed">
              <thead>
                <tr>
                  <th colspan="2">{{ 'TRUCKS.ORIGIN_WEIGHT'|translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-left small">{{ 'TRUCKS.GROSS'|translate }}:</th>
                  <td>{{ (cpe.datos_carga.peso_bruto_descarga || 0)|number:'1.0-0' }} <small class="text-muted">Kgs.</small></td>
                </tr>
                <tr>
                  <th class="text-left small">{{ 'TRUCKS.TARE'|translate }}:</th>
                  <td>{{ (cpe.datos_carga.peso_tara_descarga || 0)|number:'1.0-0' }} <small class="text-muted">Kgs.</small></td>
                </tr>
                <tr>
                  <th class="text-left small">{{ 'TRUCKS.NET'|translate }}:</th>
                  <td>{{ (cpe.datos_carga.peso_bruto_descarga - cpe.datos_carga.peso_tara_descarga || 0)|number:'1.0-0' }} <small class="text-muted">Kgs.</small></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </ng-container>

    </ng-container>

    <div class="no-data" *ngIf="error">
      <i class="material-symbols-rounded text-danger">error_outline</i>
      <span [innerHtml]="'GLOBAL.DATA_ERROR_PERMISSION'|translate"></span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
    class="btn btn-default" (click)="preview(cpe)" [disabled]="processing">
      <i class="material-symbols-rounded">picture_as_pdf</i> {{ 'ORDER_FORM.PREVIEW'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
