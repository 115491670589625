import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MemoryStorageService {

  public data: any = {};

  constructor() { }
}
