
<contract-modal #contractModal
[company]="company"></contract-modal>

<ng-template #linkModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'LINKER.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-muted" [innerHtml]="'LINKER.DESCRIPTION_BATCH'|translate"></p>

    <ng-container *ngIf="batch.id">
      <div class="row">
        <div class="col-xs-12">
          <batch-details
          [company]="company"
          [show-address]="false"
          [batch]="batch"></batch-details>
        </div>
      </div>
  
      <div class="row">
        <connector [linked]="negotiation"></connector>
      </div>
    </ng-container>

    <div class="row">
      <div class="col-xs-12">
        <ng-container *ngIf="negotiation">
          <h5>{{ 'LINKER.SELECTED_TRADE'|translate }}
            <!-- &nbsp;<small>(<a [class.disabled]="processing" (click)="negotiation = undefined">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>)</small> -->
            <button class="btn btn-link btn-xs" (click)="negotiation = undefined"
            [disabled]="processing">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</button>
          </h5>
          <ng-container *ngTemplateOutlet="negotiationsTable;context:{
            negotiations: [negotiation]
          }"></ng-container>
        </ng-container>
        <ng-container *ngIf="!negotiation">
          <h5>{{ 'LINKER.TRADES'|translate }}</h5>
          <ng-container *ngIf="availableNegotiations; else loading">
            <ng-container *ngIf="availableNegotiations.length; else noNegotiations">
              <p class="text-muted" [innerHtml]="'LINKER.SELECT_TRADE_BATCH'|translate: {counterpart: batch.company.name}"></p>
              <ng-container *ngTemplateOutlet="negotiationsTable;context:{
                negotiations: availableNegotiations
              }"></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="linkSelected()" class="btn btn-agree" [disabled]="processing || !negotiation">{{ 'LINKER.LINK_BATCH'|translate }}</button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noNegotiations>
  <div class="text-danger" [innerHtml]="'LINKER.NO_TRADES'|translate"></div>
</ng-template>

<ng-template #negotiationsTable let-negotiations="negotiations">
  <div class="horizontal-scroller">
    <table *ngIf="negotiations?.length > 0" class="table table-condensed" sortable defaultSortBy="-booking_date" #tableSortable="tableSortable">
      <thead>
        <tr class="tr-align-center">
          <th class="sap" sortBy="booking_date">{{ 'GLOBAL.DATE'|translate }}</th>
          <th class="sap" sortBy="id">{{ 'GLOBAL.TRADE'|translate }}</th>
          <th sortBy="counterpartiesOf(company.id)[0].name">{{ 'GLOBAL.COUNTERPART'|translate }}</th>
          <!-- FAS-2875 -->
          <th>{{ 'GLOBAL.BROKER'|translate }}</th>
          <th sortBy="delivery.locations[0].name"
            *ngIf="company.market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <!-- FAS-2875 -->
          <th class="sap" sortBy="contract_reference">{{ 'GLOBAL.CONTRACT'|translate }}</th>
          <th class="sap" sortBy="data_external.reference.id">
            <abbr title tooltip="{{ 'GLOBAL.EXTERNAL_REFERENCE'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.EXTERNAL_REFERENCE_ABBR'|translate }}</abbr>
          </th>
          <th class="sap dlb drb" sortBy="quantity.value">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="contract.delivery.date_from">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="price.value">
            {{ 'GLOBAL.PRICE'|translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1">/{{ company.market.quantity_units[0].abbrev }}</ng-container>
          </th>
          <!-- FAS-2875 -->
          <th class="sap">{{ 'COMPANY.SLOTS'|translate }}</th>
          <th class="sap dlb text-center">
            <abbr tooltip="{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}" [adaptivePosition]="false"><span class="material-symbols-rounded">local_shipping</span></abbr>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="tr-align-center"
        [class.clickable]="!negotiation"
        [class.selected]="negotiation?.id === n.id"
        (click)="!negotiation ? negotiation = n : null"
        *ngFor="let n of negotiations|orderBy: tableSortable.sortBy;">
          <td class="sap micro">
            <ag-timestamp [date]="n.booking_date"></ag-timestamp>
          </td>
          <td class="small sap"><samp>#{{ n.id }}</samp></td>
          <td class="actor">
            <ng-container *ngTemplateOutlet="companiesNames; context: {companies: n.counterpartiesOf(company.id)[0]?.activity?.broker ? [] : n.counterpartiesOf(company.id)}"></ng-container>
          </td>
          <!-- FAS-2875 -->
          <td class="actor">
            <ng-container *ngTemplateOutlet="companiesNames; context: {companies: n.brokers ? n.brokers : []}"></ng-container>
          </td>
          <td class="small"
          *ngIf="company.market.configuration.location.enabled">
            <span class="clamp-2">{{ n.proposal.business_detail.delivery.locations |locationString }}</span>
          </td>
          <!-- FAS-2875 -->
          <td class="small sap hidden-xs hidden-sm text-right">
            <ng-container *ngIf="n.contracts?.length">
              <div *ngFor="let c of n.contracts">
                <samp><a (click)="contractModal.show(c.id)">{{ c.reference}}</a></samp>
              </div>
            </ng-container>
          </td>
          <td class="sap small">
            <samp *ngIf="n.data_external?.reference?.id">{{ n.data_external.reference.id }}</samp>
          </td>
          <td class="text-right sap dlb drb">
            <quantity-control [quantity]="n.proposal.business_detail.quantity" [compact]="true"></quantity-control>
          </td>
          <td class="sap">
            <date-range
            [from]="n.proposal.business_detail.delivery.date_from"
            [to]="n.proposal.business_detail.delivery.date_to"></date-range>
          </td>
          <td class="sap">
            <div class="price-box booked-bg">
              <div class="type"
              [class.buy]="n.buyers_id.includes(company.id)"
              [class.sell]="n.sellers_id.includes(company.id)">{{
                (n.buyers_id.includes(company.id) ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
              }}</div>
              <div class="value">
                <price-display [price]="n.proposal.business_detail.price" [market]="company.market" [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
          <!-- FAS-2875 -->
          <td class="sap text-right small">
            <ag-slots-details
            [batches]="n.batches"></ag-slots-details>
          </td>
          <td class="sap dlb">
            <progress-circle
            label="{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}"
            [progress]="(n.summary?.applied_volume && n.contracts[0].quantity) ? (n.summary.applied_volume.value / n.contracts[0].quantity.value) : 0"></progress-circle>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #companiesNames let-companies="companies">
  <b *ngIf="companies.length; else none"
  class="truncate"
  title="{{ companies|listFormatter }}">
    <span [class.highlighted-text]="companies[0].id === company.id">{{ companies[0].name }}</span>
    <small *ngIf="companies.length > 1">&nbsp;(+{{ companies.length-1 }})</small>
  </b>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>