import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Order } from '../../modules/commercial/models/order.model';

@Component({
  selector: 'ag-template-name-picker',
  exportAs: 'agTemplateNamePicker',
  templateUrl: './template-name-picker.component.html',
  styleUrls: ['./template-name-picker.component.css']
})
export class TemplateNamePickerComponent implements OnDestroy {

  @ViewChild('templateModal', { static: true }) private readonly templateModal: TemplateRef<any>;

  @Input() public order: Order;
  @Input() public disabled: boolean;
  @Input() public title: string;

  @Output() public readonly accept = new EventEmitter();

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService
  ) { }

  public show(): void {
    this.openModal(this.templateModal);
  }

  public submit(): void {
    this.order.template = {
      title: this.title
    };
    this.order.type = { id: 3, name: '' };

    this.closeModal();
    this.accept.emit();
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();
  }
}
