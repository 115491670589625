import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../../../models/jsend.model';
import { PusherMessage } from '../../../../models/pusher-message.model';
import { PusherService } from '../../../../services/pusher.service';
import { buildFilters } from '../../../../utilities/filters';
import { simpleHash } from '../../../../utilities/json-tools';
import { FintechApplication } from '../models/fintech-application.model';
import { FintechProduct } from '../models/fintech-product.model';
import { WorkflowDataValue } from '../models/work-flow-data-field.model';
import { WorkflowPossibility } from '../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class FintechApplicationsService {

  private baseUrl: string = '/:apiBase/fintech';
  private companyUrl: string = this.baseUrl + '/companies/:companyId/applications';
  private applicationByIdPath: string = this.companyUrl + '/:applicationId';
  private reportsPath: string = this.companyUrl + '/reports';
  private applicationProduct: string = this.applicationByIdPath + '/products';
  private applicationMoveto: string = this.applicationByIdPath + '/move-to';
  private applicationWithdraw: string = this.applicationByIdPath + '/withdraw';
  private applicationForm: string = this.applicationByIdPath + '/forms';
  private applicationDocsPreview: string = this.applicationByIdPath + '/preview';
  private applicationExportUrl: string = '/:apiApplicationsExport/application/file';
  private applicationChild: string = this.applicationByIdPath + '/child';

  private _collectionSubjects: { [eventKey: string]: BehaviorSubject<{ body: FintechApplication[], headers: HttpHeaders }> } = {};
  private _itemSubjects: { [applicationId: number]: BehaviorSubject<FintechApplication> } = {};
  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>;

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private get(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: FintechApplication[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.companyUrl
      .replace(":companyId", companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<JSend<FintechApplication[]>>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      this.parseApplicationData(response.body.data);
      return { body: plainToInstance(FintechApplication, response.body.data), headers: response.headers };
    }));
  }

  /**
   * Returns all the [[FintechApplication|Applications]] requested by the
   * specified [[Company]].
   */
  public watch(companyId: number, filters?: any, paginated: boolean = true): Observable<{ body: FintechApplication[], headers: HttpHeaders }> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.get(companyId, filters, paginated)
      },
      {
        channel: `company_${companyId}`,
        event: 'fintech-application'
      });
  }

  public create(companyId: number, applications: FintechApplication[]): Observable<FintechApplication[]> {
    const url = this.companyUrl
      .replace(":companyId", companyId + '');
    const stream = this.http.post<JSend<FintechApplication[]>>(url, applications);

    return stream.pipe(map(response => {
      return plainToInstance(FintechApplication, response.data);
    }));
  }

  public edit(companyId: number, application: FintechApplication): Observable<FintechApplication> {
    const url = this.applicationByIdPath
      .replace(":companyId", companyId + '')
      .replace(":applicationId", application.id + '');

    const stream = this.http.put<any>(url, application);

    return stream.pipe(map(response => {
      return plainToInstance(FintechApplication, response.data);
    }));
  }

  private getApplication(companyId: number, applicationId: string): Observable<FintechApplication> {
    const url = this.applicationByIdPath
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    const stream = this.http.get<JSend<FintechApplication>>(url);

    return stream.pipe(map(response => {
      this.parseApplicationData(response.data);
      return plainToInstance(FintechApplication, response.data);
    }));
  }

  /**
   * Returns all the [[FintechApplication|Applications]] requested by the
   * specified [[Company]].
   */
  public watchApplication(companyId: number, applicationId: string): Observable<FintechApplication> {
    return this.pusherService.subjectManager(
      {
        collection: this._itemSubjects,
        key: applicationId,
        getData: () => this.getApplication(companyId, applicationId)
      },
      {
        channel: `company_${companyId}`,
        event: 'fintech-application',
        condition: (event: PusherMessage) => !event.data || (event.data.id === applicationId)
      });
  }

  public editProduct(companyId: number, applicationId: string, fields: Partial<FintechProduct>, reason?: string): Observable<any> {
    const url = this.applicationProduct
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    return this.http.post<any>(url, {
      diff: fields,
      reason: reason
    });
  }

  public goto(companyId: number, applicationId: string, possibility: WorkflowPossibility, reason?: string): Observable<FintechApplication> {
    const url = this.applicationMoveto
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    const stream = this.http.post<JSend<FintechApplication>>(url, {
      possibility: possibility,
      reason: reason
    });

    return stream.pipe(map(response => {
      this.parseApplicationData(response.data);
      return plainToInstance(FintechApplication, response.data);
    }));
  }

  public withdraw(companyId: number, applicationId: string, reason?: string): Observable<FintechApplication> {
    const url = this.applicationWithdraw
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    const stream = this.http.post<JSend<FintechApplication>>(url, {
      reason: reason
    });

    return stream.pipe(map(response => {
      this.parseApplicationData(response.data);
      return plainToInstance(FintechApplication, response.data);
    }));
  }

  public save(companyId: number, applicationId: string, data: {
    [dataFieldKey: string]: WorkflowDataValue;
  }): Observable<any> {
    const url = this.applicationForm
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    return this.http.post<JSend<any>>(url, data);
  }

  public update(companyId: number, applicationId: string, data: {
    [dataFieldKey: string]: WorkflowDataValue;
  }): Observable<any> {
    const url = this.applicationForm
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    return this.http.put<JSend<any>>(url, data);
  }

  /**
   * Parses application data, so far just converting ISO8601 date strings to Date objects.
   * 
   * @param {FintechApplication | FintechApplication[]} application - The application or array of applications to parse.
   */
  private parseApplicationData(application: FintechApplication | FintechApplication[]): void {
    if (Array.isArray(application)) {
      application.forEach(element => {
        this.parseApplicationData(element);
      });
    } else {
      if (application.data) {
        const ISO8601 = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

        for (const key in application.data) {
          const field = application.data[key];

          if (field) {
            if (ISO8601.test(field.value)) {
              field.value = new Date(field.value);
            } else {
              switch (field.type) {
                // Eventually, we could add support to multiple data types
                case 'DATE_RANGE':
                  field.value = [new Date(field.value[0]), new Date(field.value[1])];
                  break;
              }
            }
          }
        }
      }
    }
  }

  public report(companyId: number, filters: {
    from_date?: Date;
    to_date?: Date;
    funder_id?: string;
    application_ids?: string[]
  }): Observable<any> {
    const url = this.reportsPath
      .replace(":companyId", companyId + '');

    return this.http.post<JSend<any>>(url, {
      filters: filters
    });
  }

  public docsPreview(companyId: number, applicationId: string, params: any): Observable<any> {
    const url = this.applicationDocsPreview
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    const stream = this.http.post<JSend<any>>(url, {
      params: params
    });

    return stream.pipe(map(response => {
      if (response.status === 'success') return response.data;
      else throw new Error(String(response.data));
    }));
  }

  public export(companyId: number, applications: FintechApplication[]): Observable<void> {
    const url = this.applicationExportUrl;

    const applicationsSimplified = applications.map(({ id, created_at, applicant, supplier, company, requested, qualification_id }) => ({
      id,
      qualification_id,
      date: created_at,
      applicant,
      supplier,
      company,
      requested
    }));

    return this.http.post<void>(url, { company_id: companyId, applications: applicationsSimplified });
  }

  /** Create child applications. */
  public child(companyId: number, applicationId: string, reason?: string): Observable<FintechApplication> {
    const url = this.applicationChild
      .replace(":companyId", companyId + '')
      .replace(":applicationId", applicationId + '');

    const stream = this.http.post<JSend<FintechApplication>>(url, {
      reason: reason
    });

    return stream.pipe(map(response => {
      this.parseApplicationData(response.data);
      return plainToInstance(FintechApplication, response.data);
    }));
  }
}
