<div class="row">
  <ng-container *ngIf="negotiation ||
  buyer_broker || buyers">
    <div [class.col-sm-3]="buyer_broker" [class.col-sm-6]="!buyer_broker">
      <company-table
      [header]="'ORDER_SUMMARY.BUYER'|translate"
      header-class="buy"
      [companies]="buyers"></company-table>
      <a class="disclose hidden-print"
      (click)="discloseDialog()"
      [class.disabled]="processing"
      *ngIf="booked &&
      ![7].includes(negotiation.order.type.id) &&
      (buyer_broker?.id === company?.id)">{{ 'ORDER_SUMMARY.DISCLOSE'|translate }}</a>
    </div>
    <!-- Con broker buyer -->
    <div *ngIf="buyer_broker" class="col-sm-3">
      <company-table
      [header]="'ORDER_SUMMARY.BROKER_BUYER'|translate"
      header-class="buy"
      [companies]="[buyer_broker]"></company-table>
    </div>
  </ng-container>
  <!-- Con broker seller -->
  <ng-container *ngIf="negotiation ||
  seller_broker || sellers">
    <div *ngIf="seller_broker" class="col-sm-3">
      <company-table
      [header]="'ORDER_SUMMARY.BROKER_SELLER'|translate"
      header-class="sell"
      [companies]="[seller_broker]"></company-table>
    </div>
    <div [class.col-sm-3]="seller_broker" [class.col-sm-6]="!seller_broker">
      <company-table
      [header]="'ORDER_SUMMARY.SELLER'|translate"
      header-class="sell"
      [companies]="sellers"></company-table>
      <a class="disclose hidden-print"
      (click)="discloseDialog()"
      [class.disabled]="processing"
      *ngIf="booked &&
      ![7].includes(negotiation.order.type.id) &&
      (seller_broker?.id === company?.id)">{{ 'ORDER_SUMMARY.DISCLOSE'|translate }}</a>
    </div>
  </ng-container>
</div>

<ng-template #discloseModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ (modalData.buyers ? 'ORDER_SUMMARY.DISCLOSURE_BUYERS' : 'ORDER_SUMMARY.DISCLOSURE_SELLERS')|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row"><!-- Recipients -->
      <div class="col-xs-12">
        <div class="form-group">
          <label class="control-label">{{ 'GLOBAL.COUNTERPARTS'|translate }}</label>
          <selectize 
          [searchURL]="companyService.getCompanies(company.id, {
            buyers: modalData.buyers ? true : null,
            sellers: modalData.buyers ? null : true,
            all: true,
            brokers: false,
            excluded_ids: [company.id]
          })"
          #companiesInput="ngModel"
          name="represented"
          [disabled]="processing"
          [(ngModel)]="represented"
          placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"></selectize>
          <div class="help-block small">{{ 'ORDER_SUMMARY.DISCLOSE_HELP'|translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing"
      data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="updateRepresented()" type="button" class="btn btn-agree"
      [disabled]="processing || !represented || companiesInput.control.pristine">{{ 'ORDER_SUMMARY.DISCLOSE_CONFIRM'|translate }}</button>
  </div>
</ng-template>