import { Component, Input } from '@angular/core';

import { Stop } from '../../../../../models/slot.model';

@Component({
  selector: 'ag-stop-status',
  templateUrl: './stop-status.component.html',
  styleUrls: ['./stop-status.component.scss']
})
export class StopStatusComponent {

  @Input() public stop: Stop;

}
