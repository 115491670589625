<ag-file-preview #filePreviewer></ag-file-preview>

<contract-modal #contractModal [company]="company"></contract-modal>

<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
</div>

<ng-container *ngIf="!loading && liquidaciones">
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table
        class="table table-condensed"
        sortable
        #tableSortable="tableSortable"
        [variableInUrl]="!predefinedFilters"
        AgMultiselection
        [(ngModel)]="selected"
      >
        <thead>
          <tr class="tr-align-center">
            <th
              class="sap micro"
              *ngIf="enableSelection && liquidaciones?.length > 0"
            >
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>
            <th class="sap" sortBy="date" *ngIf="!hideColumns.includes('date')">
              <span class="hidden-xs">{{ "GLOBAL.DATE" | translate }}</span>
              <i class="material-symbols-rounded visible-xs">date_range</i>
            </th>
            <th class="sap" sortBy="coe" *ngIf="!hideColumns.includes('coe')">
              <span>C.O.E.</span>
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[coe]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
              class="sap"
              sortBy="contract.reference"
              *ngIf="!hideColumns.includes('contract_reference')"
            >
              {{ "GLOBAL.CONTRACT" | translate }}
              <column-filter
                *ngIf="!predefinedFilters"
                type="string"
                alignment="left"
                [filters]="filters"
                key="filters[contract.reference]"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th
              sortBy="product.name"
              *ngIf="!hideColumns.includes('product_name')"
            >
              {{ "GLOBAL.PRODUCT" | translate }}
            </th>
            <th class="sap hidden-xs"></th>
            <th sortBy="tipo.descripcion" *ngIf="!hideColumns.includes('tipo')">
              {{ "GLOBAL.TYPE" | translate }}
            </th>
            <th sortBy="buyer.name" *ngIf="!hideColumns.includes('buyer')">
              {{ "ORDER_SUMMARY.BUYER" | translate }}
            </th>
            <th sortBy="seller.name" *ngIf="!hideColumns.includes('seller')">
              {{ "ORDER_SUMMARY.SELLER" | translate }}
            </th>
            <th
              class="hidden-xs"
              sortBy="broker.name"
              *ngIf="!hideColumns.includes('broker')"
            >
              {{ "GLOBAL.BROKER" | translate }}
            </th>
            <th
              class="hidden-xs hidden-sm hidden-md"
              sortBy="liquidacion.pesoNetoEnTn"
              *ngIf="!hideColumns.includes('volume')"
            >
              {{ "FUTURES.VOLUME" | translate }}
            </th>
            <th
              class="sap text-right dlb"
              sortBy="total"
              *ngIf="!hideColumns.includes('total')"
            >
              {{ "GLOBAL.TOTAL" | translate }}
            </th>
            <th
              class="hidden-xs hidden-sm sap text-center"
              *ngIf="!hideColumns.includes('source')"
            >
              <abbr
                tooltip="{{ 'GLOBAL.SOURCE' | translate }}"
                container="body"
                [adaptivePosition]="false"
              >
                <span class="material-symbols-rounded">input</span>
              </abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let liquidacion of liquidaciones | orderBy : tableSortable.sortBy
            "
          >
            <tr class="tr-align-center">
              <td class="sap micro" rowspan="2" *ngIf="enableSelection">
                <input
                  [AgCheckbox]="liquidacion"
                  type="checkbox"
                  class="show-onhover no-margin"
                />
              </td>
              <td class="sap small" *ngIf="!hideColumns.includes('date')">
                <date-md
                  *ngIf="liquidacion.date; else none"
                  [date]="liquidacion.date"
                  [showTime]="false"
                ></date-md>
              </td>
              <td class="sap small" *ngIf="!hideColumns.includes('coe')">
                <samp
                  ><a (click)="preview(liquidacion)">{{
                    liquidacion.coe
                  }}</a></samp
                >
              </td>
              <td
                class="sap dlb small"
                *ngIf="!hideColumns.includes('contract_reference')"
              >
                <samp *ngIf="liquidacion.contract?.reference; else none"
                  ><a (click)="contractModal.show(liquidacion.contract.id)">{{
                    liquidacion.contract.reference
                  }}</a></samp
                >
              </td>
              <td *ngIf="!hideColumns.includes('product_name')">
                <ng-container *ngIf="liquidacion.product; else none">{{
                  liquidacion.product.name
                }}</ng-container>
              </td>
              <td class="sap hidden-xs micro text-warning">
                <ng-container
                  *ngIf="liquidacion.ajuste || liquidacion.ajusteUnificado"
                  >{{ "LIQUIDACIONES.TIPO.3" | translate }}</ng-container
                >
              </td>
              <td
                class="text-muted"
                rowspan="2"
                *ngIf="!hideColumns.includes('tipo')"
              >
                <ng-container *ngIf="liquidacion.tipo; else none">{{
                  "LIQUIDACIONES.TIPO." + liquidacion.tipo.id | translate
                }}</ng-container>
              </td>
              <td rowspan="2" *ngIf="!hideColumns.includes('buyer')">
                <company-info
                  *ngIf="liquidacion.buyer; else none"
                  [company]="liquidacion.buyer"
                  [activity]="false"
                >
                </company-info>
              </td>
              <td rowspan="2" *ngIf="!hideColumns.includes('seller')">
                <company-info
                  *ngIf="liquidacion.seller; else none"
                  [company]="liquidacion.seller"
                  [activity]="false"
                >
                </company-info>
              </td>
              <td
                class="hidden-xs"
                rowspan="2"
                *ngIf="!hideColumns.includes('broker')"
              >
                <company-info
                  *ngIf="liquidacion.broker; else none"
                  [company]="liquidacion.broker"
                  [activity]="false"
                >
                </company-info>
              </td>
              <td
                class="hidden-xs hidden-sm hidden-md sap text-right small"
                rowspan="2"
                *ngIf="!hideColumns.includes('volume')"
              >
                <ng-container *ngIf="liquidacion.liquidacion?.pesoNetoEnTn">
                  {{ liquidacion.liquidacion.pesoNetoEnTn | number : "1.3-3" }}
                  <span class="currency">t.</span>
                </ng-container>
                <ng-container *ngIf="liquidacion.autorizacion?.totalPesoNeto">
                  {{
                    liquidacion.autorizacion.totalPesoNeto / 1000
                      | number : "1.3-3"
                  }}
                  <span class="currency">t.</span>
                </ng-container>
              </td>
              <td
                class="sap text-right dlb"
                rowspan="2"
                *ngIf="!hideColumns.includes('total')"
              >
                <price [unit]="'AR$'" [value]="liquidacion.total"></price>
              </td>
              <td
                *ngIf="!hideColumns.includes('source')"
                class="hidden-xs hidden-sm sap"
                rowspan="2"
              >
                <span
                  *ngIf="liquidacion.import_channel_id; let source"
                  class="micro text-muted"
                  >{{ "IMPORTER.SOURCE." + source | translate }}</span
                >
              </td>
            </tr>
            <tr class="tr-align-center">
              <td
                [attr.colspan]="
                  4 -
                  hiddenColumnsCount([
                    'date',
                    'reference',
                    'contract_reference',
                    'product_name'
                  ])
                "
                *ngIf="
                  !hideColumns.includes('labels') && liquidacion.labels?.length
                "
                class="td-empty no-vertical-padding"
              >
                <span
                  *ngFor="let label of liquidacion.labels"
                  class="ag-label"
                  [style.backgroundColor]="label.color"
                  >{{ label.name }}</span
                >
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="no-data" *ngIf="liquidaciones && liquidaciones.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ "GLOBAL.NO_DATA" | translate }}
    </div>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
