import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../../../models/jsend.model';
import { buildFilters } from '../../../../utilities/filters';
import { CartaPorteElectronica } from '../models/carta-porte-electronica.model';

/** [[CartaPorteElectronicaService]] service. */
@Injectable({
  providedIn: 'root'
})
export class CartaPorteElectronicaService {

  private baseUrl: string = '/:apiBase/companies/:companyId/afip/cpe';

  private cpeById: string = this.baseUrl + '/:ctg';
  private cpeFiles: string = this.cpeById + '/file';

  /** Maps only those parameters that don't match in the API call. */
  private readonly queryMap: Record<string, string>; // = {
  // 'past_range': 'filters[range]'
  // };

  /** @ignore */
  constructor(
    private http: HttpClient
  ) { }

  /** Gets all the [[CartaPorteElectronica]] paginated */
  public get(companyId: number, filters?: any, paginated?: boolean): Observable<{ body: CartaPorteElectronica[], headers: HttpHeaders }> {
    if (paginated && !filters?.page) filters = { ...filters, page: 1 };

    let url = this.baseUrl.replace(':companyId', companyId + '');
    url = buildFilters(url, filters, this.queryMap);

    const stream = this.http.get<any>(url, { observe: 'response' });

    return stream.pipe(map(response => {
      let cpes: CartaPorteElectronica[];
      cpes = response.body.data.cartasDePorteElectronica;
      return { body: plainToInstance(CartaPorteElectronica, cpes), headers: response.headers };
    }));
  }

  /** Search and return the [[CartaPorteElectronica]] according to ID. */
  public getById(companyId: number, ctgNumber: number): Observable<CartaPorteElectronica> {
    const url = this.cpeById.replace(':companyId', companyId + '').replace(':ctg', ctgNumber + '');

    const stream = this.http.get<JSend<{ cartaDePorteElectronica: CartaPorteElectronica }>>(url).pipe(map(response => {
      let cpeClass = plainToInstance(CartaPorteElectronica, response.data.cartaDePorteElectronica);
      return cpeClass;
    }));

    return stream;
  }

  /** Gets the [[CartaPorteElectronica]] file according to Carta de Porte Electronica CTG number. */
  public getFile(companyId: number, ctgNumber: number): Observable<any> {
    const url = this.cpeFiles
      .replace(':companyId', companyId + '')
      .replace(':ctg', ctgNumber + '');

    const stream = this.http.get<JSend<{ url: string }>>(url).pipe(map(result => {
      return {
        url: result.data.url
      }
    }));

    return stream;
  }
}
