import { Component, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { GeoSelection } from '../../../models/geo-selection.model';
import { Zone } from '../../../models/zone.model';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { CreateOrderComponent } from '../../modules/commercial/components/create-order/create-order.component';
import { Market } from '../../modules/commercial/models/market.model';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LocationPickerComponent),
  multi: true
};

@Component({
  selector: 'ag-location-picker',
  exportAs: 'agLocationPicker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class LocationPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild('locationPickerModal', { static: true }) private readonly locationPickerModal: TemplateRef<any>;
  // @ViewChild('locationForm') private readonly form: NgForm;

  @Input() public title: string;
  @Input() public type: string;
  @Input() public hidden: boolean;
  @Input() public disabled: boolean;
  @Input() public market: Market;

  private innerValue: any;
  private subscriptions: Subscription[] = [];

  public company: Company;
  public hasZones: boolean;
  public modalRef: BsModalRef;
  public namedZones: Zone[];
  public selection: GeoSelection = new GeoSelection(null, null);
  public selectionType = 'zone';

  constructor(
    @Optional() public createOrder: CreateOrderComponent,
    public locationService: LocationService,
    private modalService: BsModalService,
    private companyService: CompanyService
  ) { }

  get value(): any {
    return this.innerValue;
  };

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_) => void = () => { };

  set value(v) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.company = company;

      if (company.market.configuration.location.zone.enabled) {
        this.hasZones = true;
      }

      if (this.hasZones) {
        this.subscriptions.push(this.locationService.getZones().subscribe(zones => {
          this.namedZones = zones.filter(zone => {
            return !zone.other;
          });
        }));
      }
    }));

    this.subscriptions.push(this.modalService.onShow.subscribe((reason: string) => {
      this.selection = new GeoSelection(null, null);
      // this.form.resetForm();
    }));
  }

  public setType(value: string): void {
    this.selectionType = value;

    if (value === 'zone') {
      this.selection.location = null;
    } else if (value === 'location') {
      this.selection.zone = null;
      // this.searchInput.focus();
    }
  }

  public show(): void {
    this.openModal(this.locationPickerModal);
  }

  public submit(): void {
    if (this.type === 'multiple') {
      if (!this.value) {
        this.value = [];
      }
      let duplicate = this.value.find(item => {
        return this.selection && this.selection.location && item.location ?
          this.selection.location.id === item.location.id :
          this.selection && this.selection.zone && item.zone ?
            this.selection.zone.id === item.zone.id :
            false;
      });
      if (!duplicate) {
        this.value.push(this.selection);
        this.onChangeCallback(this.value);
      }
    } else {
      this.value = this.selection;
    }
    this.closeModal();
  }

  public remove(item): void {
    if (this.disabled) return;

    if (this.createOrder && this.createOrder.order.scope === 'privado')
      this.createOrder.cleanPrivateCompanies();

    if (this.type === 'multiple') {
      this.value = this.value.filter(function (i) {
        return i !== item;
      });
    } else {
      this.value = null;
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    // this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
    //   this.modalSub.unsubscribe();
    //   this.modalRef = undefined;
    //   // Reset all values
    //   this.processing = false;
    // });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
