<input type="text" class="form-control" [ngModel]="description" (focus)="showModal()" [disabled]="disabled">

<ng-template #quality>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'QUALITY_INPUT.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="quality-form" #qualityForm="ngForm" (ngSubmit)="qualityForm.valid && accept()">
      <div class="row">
        <div class="col-sm-6" *ngFor="let attribute of product.attributes.quality|pairs|orderBy:'1.order'">
          <div class="form-group" hasError>
            <label class="control-label">{{ attribute[1].name }}</label>
            <textarea *ngIf="attribute[1].element === 'textarea'; else qualitySelect"
            class="form-control"
            maxlength="512"
            [(ngModel)]="selection[attribute[0]]"
            name="q_{{ attribute[0] }}"></textarea>

            <ng-template #qualitySelect>
              <select class="form-control" name="q_{{ attribute[0] }}" [(ngModel)]="selection[attribute[0]]" [compareWith]="compareId">
                <option *ngIf="attribute[1].empty" [ngValue]="attribute[1].empty">
                  {{ attribute[1].empty.name }}
                </option>
                <option *ngFor="let option of attribute[1].values; let first = first" [ngValue]="option" [selected]="first">
                  {{ option.name }}
                </option>
              </select>
            </ng-template>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="modalRef.hide()" type="button" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="quality-form" class="btn btn-agree">
      {{ 'QUALITY_INPUT.ACTION'|translate }}
    </button>
  </div>
</ng-template>
