import { Type } from "class-transformer";

import { Contract } from "../company/modules/contracts/models/contract.model";
import { Company } from "./company.model";
import { Product } from "./product.model";

class LGTotalesUnificados {
  importeNeto: number;
  importeOtrasRetenciones: number;
  iva105: number;
  iva21: number;
  ivaDeducciones: number;
  ivaRG4310_18: number;
  pagoSCondicion: number;
  pagoSegunCondicion: number;
  retencionesGanancias: number;
  retencionesIVA: number;
  subtotalCredDeb: number;
  subtotalDebCred: number;
  subtotalGeneral: number;
  totalBaseDeducciones: number;
}

class LGAjuste {
  coeAjustado: number;
  /** Valor alfanumérico de un total de 200 caracteres (máximo). */
  conceptoIva0?: string;
  importeAjustar0?: number;
  /** Valor alfanumérico de un total de 200 caracteres (máximo). */
  conceptoIva10?: string;
  importeAjustar10?: number;
  /** Valor alfanumérico de un total de 200 caracteres (máximo). */
  conceptoIva21?: string;
  importeAjustar21?: number;

  /**
   * | Tipo | Descripción                                            |
   * |-----:|--------------------------------------------------------|
   * |    7 | Liquidación Primaria - Ajuste por COE - Débito         |
   * |    8 | Liquidación Primaria - Ajuste por COE - Crédito        |
   * |    9 | Liquidación Primaria - Ajuste por contrato - Débito    |
   * |   10 | Liquidación Primaria - Ajuste por contrato - Crédito   |
   * |   11 | Liquidación Secundaria - Ajuste por COE - Débito       |
   * |   12 | Liquidación Secundaria - Ajuste por COE - Crédito      |
   * |   13 | Liquidación Secundaria - Ajuste por contrato - Débito  |
   * |   14 | Liquidación Secundaria - Ajuste por contrato - Crédito |
   */
  tipoAjuste: 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;
  datosAdicionales?: string;

  /**
   * Estado de la liquidación.
   *
   * | Tipo | Descripción |
   * |-----:|-------------|
   * | "AC" | Activa      |
   * | "AN" | Anulada     |
   */
  estado?: "AC" | "AN";

  /** Detalle de las percepciones ingresadas. */
  percepciones?: null;

  totalesUnificados: LGTotalesUnificados;
}

class LGRetencion {
  retencion: {
    // Posibles valores?
    codigoConcepto: string;
    detalleAclaratorio: string;
    baseCalculo: number;
    alicuota: number;
  };
  importeRetencion: number;
}

class LGAutorizacion {
  ptoEmision: number;
  nroOrden: number;

  // Posibles valores?
  codTipoOperacion: string;

  // Posibles valores?
  codTipoAjuste: string;

  // Posibles valores?
  nroOpComercial: number;

  @Type(() => Date)
  fechaLiquidacion: Date;

  precioOperacion: number;
  subTotal: number;
  importeIva: number;
  operacionConIva: number;
  totalPesoNeto: number;

  // Type?
  deducciones: null;

  totalDeduccion: number;
  retenciones: LGRetencion[];
  totalRetencion: number;
  totalRetencionAfip: number;
  totalOtrasRetenciones: number;
  totalNetoAPagar: number;
  totalIvaRg4310_18: number;
  totalPagoSegunCondicion: number;
  coe: number;
  coeAjustado: number;

  // Posibles valores?
  estado: string;

  codLocalidadOperacion: number;

  codProvinciaOperacion: number;

  nroContrato: number;
  datosAdicionales: string;

  // Posibles valores?
  tipo: number;
}

class LGPercepcion {
  /** Número de Código de Trazabilidad de Grano. */
  detalleAclaratoria: string;
  /** Número de carta de porte. */
  BaseCalculo: number;
  Alicuota: number;
}

class LGDeduccionAjuste {
  /**
   * Número de operación comercial.
   *
   * Valor entero de un total de 10 dígitos. Valor mínimo permitido (inclusivo) 0 Valor máximo permitido (inclusivo) 9999999999.
   */
  nroOpComercial?: number;

  @Type(() => Date)
  fechaLiquidacion?: Date;

  // Type?
  precioOperacion?: null;

  // Type?
  subTotal: null;

  // Type?
  importeIva: null;

  // Type?
  operacionConIva: null;

  // Type?
  totalPesoNeto: null;

  // Type?
  importe: null;

  // Type?
  concepto: null;

  // Type?
  alicuota: null;

  // Type?
  ivaCalculado: null;

  // Type?
  totalDeduccion: null;

  // Type?
  totalRetencion: null;

  // Type?
  totalRetencionAfip: null;

  // Type?
  totalOtrasRetenciones: null;

  // Type?
  totalNetoAPagar: null;

  // Type?
  totalIvaRg4310_18: null;

  // Type?
  totalPagoSegunCondicion: null;

  // Type?
  deducciones: null;

  // Type?
  retenciones: null;
}

class LGDeduccion {

  // Type?
  codigoConcepto: null;

  // Type?
  detalleAclaratorio: null;

  // Type?
  detalleAclaratoria: null;

  // Type?
  diasAlmacenaje: null;

  // Type?
  precioPKGdiario: null;

  // Type?
  comisionGastosAdm: null;
  baseCalculo: number;
  alicuotaIva: number;

  // Type?
  alicuotaIVA: null;

  @Type(() => LGDeduccionAjuste)
  ajusteCredito: LGDeduccionAjuste;

  @Type(() => LGDeduccionAjuste)
  ajusteDebito: LGDeduccionAjuste;
}

class LGCertificado {
  tipoCertificadoDeposito: string;
  nroCertificadoDeposito: number;
  pesoNeto: number;
  codLocalidadProcedencia: number;
  codProvProcedencia: number;
  campania: number;

  @Type(() => Date)
  fechaCierre: Date;
}

class LGLiquidacion {
  /** Punto de emisión de la solicitud. */
  ptoEmision: number;
  /** Nº de orden correspondiente al envío de la liquidación. */
  nroOrden: number;

  // Comprador
  cuitComprador: number;
  nroActComprador: number;
  nroIngBrutoComprador: number;

  /**
   * Código del Tipo de Operación.
   *
   * | Tipo | Descripción            |
   * |-----:|------------------------|
   * | "01" | Compra Venta de granos |
   * | "02" | Consignación de granos |
   */
  codTipoOperacion: "01" | "02";

  /** Código del tipo de ajuste. Solo se incluye cuando se trata de un Ajuste. */
  codTipoAjuste: string;

  /** Nº de Operador Comercial – Se devolverá 0 en todos los casos. */
  nroOpComercial?: 0;

  /** Representa si se auto liquida. */
  esLiquidacionPropia?: "S" | "N";

  /**
   * Identifica si la liquidación representa un canje.
   *
   * | Tipo | Descripción |
   * |-----:|-------------|
   * |  "P" | Parcial     |
   * |  "T" | Total       |
   * |  "N" | No es canje |
   */
  esCanje?: "P" | "T" | "N";

  /** Código de puerto. */
  codPuerto: number;

  /** Descripción de puerto, se utiliza cuando se ingresó en código de puerto “otro puerto”. */
  desPuertoLocalidad?: string;

  /** Código de grano. */
  codGrano: number;

  cuitVendedor: number;
  nroIngBrutoVendedor: number;

  /** Actúa corredor. */
  actuaCorredor?: "S" | "N";

  /** Liquida corredor. */
  liquidaCorredor: "S" | "N";

  cuitCorredor?: number;
  nroIngBrutoCorredor?: number;

  /**
   * Comisión del corredor.
   *
   * Si liquida Comprador es la comisión por parte del comprador.
   * Si liquida Corredor es la comisión por parte del comprador y el vendedor.
   * Valores posibles desde 0 a 99.99 inclusive.
   */
  comisionCorredor?: number;

  /** Fecha precio operación. */
  @Type(() => Date)
  fechaPrecioOperacion: Date;

  /** Precio referencia tonelada. */
  precioRefTn: number;

  /** Precio de operación. */
  precioOperacion: number;

  // Posibles valores?
  codGradoRef: string;

  // Posibles valores?
  codGradoEnt: string;

  valGradoEnt: number;
  factorEnt: number;
  precioFleteTn: number;
  contProteico: number;

  /** Alicuota IVA operación. */
  alicIvaOperacion?: number;

  /**
   * Campaña Principal.
   *
   * Valor entero comprendido entre 3 y 4 dígitos. Valor mínimo permitido (inclusivo) 203 Valor máximo permitido (inclusivo) 9999.
   */
  campaniaPPal: number;

  /**
   * Identificador de Campaña.
   *
   * Valor entero comprendido entre 3 y 4 dígitos. Valor mínimo permitido (inclusivo) 203 Valor máximo permitido (inclusivo) 9999.
   */
  campania: number;

  /** Localidad donde se emite el documento. */
  codLocalidadProcedencia: number;

  /** Provincia donde se emite el documento. */
  codProvProcedencia: number;
  datosAdicionales?: string;

  /** Certificado vinculado con la liquidación. */
  @Type(() => LGCertificado)
  certificados?: LGCertificado[];

  /** Datos de factura papel. */
  facturaPapel?: {
    nroCAI: number;
    nroFacturaPapel: number;
    fechaFactura: Date;
    /**
     * | Tipo | Descripción                                             |
     * |-----:|---------------------------------------------------------|
     * |  "1" | Factura A                                               |
     * | "34" | Cbtes. A del Anexo I, Apartado A, inc.f, R.G.Nro. 1415. |
     * | "39" | Cbtes. A que cumplan con R.G.Nro. 1415.                 |
     * | "51" | Factura M                                               |
     * | "63" | Liquidación A                                           |
     */
    tipoComprobante: "1" | "34" | "39" | "51" | "63";
  };

  @Type(() => LGDeduccion)
  deducciones: LGDeduccion[];

  percepciones: LGPercepcion[];

  estado?: string;

  /** Parámetros opcionales con el fin de ser utilizado en otras versiones. */
  opcionales?: {
    codigo: string;
    descripcion: string;
  }[];

  // Posibles valores?
  codProvinciaOperacion: number;

  // Posibles valores?
  codLocalidadOperacion: number;

  localidadEmision: string;
  alicuotaIvaOperacion: number;
  precioOperacionTn: number;
  precioReferenciaTn: number;

  otraLocalidad?: string;
  pesoNetoEnTn: number;
  nroActVendedor: number;
  nroContrato?: number;

  // No encontrada en la documentacion
  // nrototalPercepcionesontrato: null;

  // No encontrada en la documentacion
  // Type?
  totalDeducciones: null;

  // Type?
  // No encontrada en la documentacion
  descripcionPuertoLocalidad: null;

  private _totalPercepciones: number;
  get totalPercepciones(): number {
    if (this._totalPercepciones == undefined) {
      this._totalPercepciones = 0;
      if (Array.isArray(this.percepciones)) this.percepciones.forEach(p => {
        this._totalPercepciones += (p.Alicuota / 100) * p.BaseCalculo;
      });
    }

    return this._totalPercepciones;
  }
}

class LGAjusteUnificado {
  @Type(() => LGDeduccionAjuste)
  ajusteCredito: LGDeduccionAjuste;

  @Type(() => LGDeduccionAjuste)
  ajusteDebito: LGDeduccionAjuste;

  codLocalidadOperacion: number;

  codProvinciaOperacion: number;

  codTipoOperacion: string;

  coe: number;
  coeAjustado: number;

  /**
   * Estado de la liquidación.
   *
   * | Tipo | Descripción |
   * |-----:|-------------|
   * | "AC" | Activa      |
   * | "AN" | Anulada     |
   */
  estado?: "AC" | "AN";

  nroContrato: number;
  /** Punto de emisión de la solicitud. */
  ptoEmision: number;
  /** Nº de orden correspondiente al envío de la liquidación. */
  nroOrden: number;

  totalesUnificados: LGTotalesUnificados;
}

/** AFIP original model. */
class LiquidacionGranos {
  @Type(() => LGAjuste)
  readonly ajuste?: LGAjuste;

  @Type(() => LGAutorizacion)
  readonly autorizacion?: LGAutorizacion;

  @Type(() => LGLiquidacion)
  readonly liquidacion?: LGLiquidacion;

  @Type(() => LGAjusteUnificado)
  readonly ajusteUnificado: LGAjusteUnificado;
}

export class AFIPLiquidacion extends LiquidacionGranos {
  /** Agree's internal unique identifier. */
  readonly id: string;

  @Type(() => Date)
  readonly created_at: Date;

  @Type(() => Date)
  readonly updated_at: Date;

  /**
   * | Tipo | Descripción |
   * |-----:|-------------|
   * |    1 | Primaria    |
   * |    2 | Secundaria  |
   */
  readonly tipo: {
    id: 1 | 2;
    description: string;
  };

  /**
   * Contribuyente identificado como comprador.
   * Mapeado de: cuitComprador
   */
  @Type(() => Company)
  readonly buyer: Company;

  /**
   * Contribuyente identificado como vendedor.
   * Mapeado de: cuitVendedor
   */
  @Type(() => Company)
  readonly seller: Company;

  /**
   * Mapeado de: cuitCorredor
   */
  @Type(() => Company)
  readonly broker?: Company;

  /**
   * Grano.
   * Mapeado de: codGrano
   */
  @Type(() => Product)
  readonly product: Product;

  readonly contract?: Contract;

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  get coe(): number {
    return this.autorizacion ? this.autorizacion.coe : this.ajusteUnificado.coe;
  }

  get date(): Date | null {

    if (this.ajusteUnificado?.ajusteCredito?.fechaLiquidacion)
      return this.ajusteUnificado.ajusteCredito.fechaLiquidacion;

    if (this.ajusteUnificado?.ajusteDebito?.fechaLiquidacion)
      return this.ajusteUnificado.ajusteCredito.fechaLiquidacion;

    if (this.autorizacion?.fechaLiquidacion)
      return this.autorizacion.fechaLiquidacion;

    return null;
  }

  get total(): number {
    if (this.ajusteUnificado) {
      return this.ajusteUnificado.totalesUnificados.importeNeto;
    } else if (this.ajuste) {
      return this.ajuste.totalesUnificados.importeNeto;
    } else if (this.autorizacion) {
      let total = this.autorizacion.operacionConIva - this.autorizacion.totalDeduccion;
      if (this.liquidacion) total += this.liquidacion.totalPercepciones;
      return total;
    }
  }
}
