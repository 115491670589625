import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Company } from '../../../../../models/company.model';
import { Quantity } from '../../../../../models/quantity.model';
import { CompanyService } from '../../../../../services/company.service';
import { CurrentDateService } from '../../../../../services/current-date.service';
import { startOfDay } from '../../../../../utilities/date';
import { SlotsRequest } from '../../../../models/slots-request.model';
import { SlotRequestService } from '../../../../services/slot-request.service';
import { Negotiation } from '../../../commercial/models/negotiation.model';

@Component({
  selector: 'slots-batch-request-form',
  templateUrl: './slots-batch-request-form.component.html',
  styleUrls: ['./slots-batch-request-form.component.scss']
})
export class SlotsBatchRequestFormComponent implements OnInit {

  @Input() public company: Company;
  @Input() public negotiation: Negotiation

  @Output() readonly close: EventEmitter<void> = new EventEmitter<void>();

  /** @ignore */
  public today: Date;
  public processing: boolean = false;
  public formArray: UntypedFormArray = new UntypedFormArray([]);
  public observations: string = '';
  public selectedRecipient: Company;
  public maxCuposToRequest: number;
  public slotsAvailable: number;
  public totalSlotsRequest: number;
  public someControlIsEmpty: boolean;
  public totalRequestedSlotsInNegotiation: number;
  public buyers: Company[];
  public errorMessage?: string = null;

  get arrayCantCupos(): number[] {
    return Array.from({ length: this.maxCuposToRequest + 1 }, (_, i) => i);
  }

  get appliedVolume(): Quantity {
    if (this.negotiation.summary)
      return this.negotiation.summary.applied_volume;
    return new Quantity({ value: 0 });
  }

  constructor(
    private slotRequestService: SlotRequestService,
    private currentDate: CurrentDateService,
    public companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.today = startOfDay(this.currentDate.get());

    this.addCupo();
    this.subscribeToFormChanges();
    this.selectedRecipient = this.negotiation.buyer;
    this.buyers = this.getBuyers();

    this.maxCuposToRequest = this.assignableSlotsToRequest();
    this.totalRequestedSlotsInNegotiation = this.negotiation.slot_request.reduce((accum, curr) => accum + curr.quantity, 0)
  }

  private subscribeToFormChanges(): void {
    this.slotsAvailable = this.assignableSlotsQty();

    this.formArray.valueChanges.subscribe(value => {
      this.totalSlotsRequest = this.sumTotalSlotsRequest();
      this.slotsAvailable = this.assignableSlotsQty();
      this.errorMessage = null;
      this.checkSomeControlIsEmpty();

    });
  }

  public getBuyers(): Company[] {
    // for test ddl => let buyers = [this.negotiation.buyer].concat(this.negotiation.seller_represented|| [])
    return [this.negotiation.buyer].concat(this.negotiation.buyer_represented || []);
  }

  private assignableSlotsQty(): number {
    if (this.negotiation) {
      const totalAssignableSlots = this.assignableSlotsToRequest();
      return totalAssignableSlots - this.totalSlotsRequest;
    }
    return 0;
  }

  private assignableSlotsToRequest(): number {
    const pendingVolumeToApply = this.calculatePendingVolumeToApply();
    return Math.ceil(pendingVolumeToApply / 30) + 1;
  }

  private calculatePendingVolumeToApply(): number {
    const negotiationVolume = this.negotiation?.proposal?.business_detail?.quantity?.value || 0;
    const appliedVolume = this.appliedVolume?.value || 0;
    return negotiationVolume - appliedVolume;
  }

  private sumTotalSlotsRequest(): number {
    return this.formArray.value.reduce((acc, slot) => acc + parseInt(slot.cuposQty, 10), 0);
  }

  /** FormArray Methods */

  public removeCupo(index: number): void {
    // Solo permite eliminar si hay más de un elemento en el array
    if (this.formArray.length > 1) {
      this.formArray.removeAt(index);
    }
  }

  public clear(): void {
    this.formArray.clear();
  }

  public addCupo(): void {
    const group = new UntypedFormGroup({
      cuposQty: new UntypedFormControl(0),
      date: new UntypedFormControl(new Date())
    });

    this.formArray.push(group);
    this.checkSomeControlIsEmpty();
  }

  private checkSomeControlIsEmpty(): void {
    this.someControlIsEmpty = this.formArray.value.some(slot => slot.cuposQty === 0);
  }

  private formToClass(formArray: UntypedFormArray): SlotsRequest[] {
    const slotsInForm = formArray.value;
    const slotsRequests: SlotsRequest[] = [];
    slotsInForm.forEach(request => {
      if (request.cuposQty === 0) return;

      const slotRequested = new SlotsRequest({
        quantity: request.cuposQty,
        date: request.date,
        observations: this.observations,
        company: this.company,
        recipient: this.selectedRecipient,
        negotiation: this.negotiation,
      }
      );

      slotsRequests.push(slotRequested);
    });
    return slotsRequests;
  }

  public submit(): void {
    const slotsRequested = this.formToClass(this.formArray)
    this.processing = true;
    this.slotRequestService.create(this.company.id, slotsRequested).subscribe((response) => {
      this.processing = false;
      this.close.emit()
    },
      ({ error }) => {
        this.errorMessage = error.message?.ext_invalid_action || error.info?.message;
        this.processing = false;
      });
  }
}