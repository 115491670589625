import { Type } from "class-transformer";
import { Price } from "../../../../models/price.model";
import { FintechProduct } from "./fintech-product.model";

export class CreditLine {
  readonly id?: string;

  fiscal_id: string;
  amount: Price;

  @Type(() => Date)
  readonly created_at?: Date;

  @Type(() => Date)
  due_date?: Date;

  @Type(() => FintechProduct)
  product?: Partial<FintechProduct>;

  constructor(data: Partial<CreditLine> = {}) {
    Object.assign(this, data);
  }
}
