import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { Quantity } from '../../../../../models/quantity.model';
import { FilterSetComponent } from '../../../../../ui/components/filter-set/filter-set.component';
import { removeString } from '../../../../../utilities/array';
import { TableFilters } from '../../../../../utilities/table-filters';
import { Unload } from '../../models/unload.model';
import { UnloadService } from '../../services/unload.service';

/**
 * Table of unloaded trucks.
 */
@Component({
  selector: 'trucks-table',
  templateUrl: './trucks-table.component.html',
  styleUrls: ['./trucks-table.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TrucksTableComponent),
    multi: true
  }]
})
export class TrucksTableComponent extends TableFilters implements OnInit, ControlValueAccessor, OnDestroy {

  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  @Input() public selectedFilters: FilterSetComponent;
  /** Whether the selection checkboxes are shown or not. */
  @Input() public enableSelection: boolean;

  @Output('load') readonly onLoad = new EventEmitter();

  private weight: any;

  /** The language currently used. */
  public currentLang: string;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public predefinedFilters: boolean;
  public trucks: Unload[];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private unloadService: UnloadService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    const { configuration } = this.company.market;
    if (configuration.imported_data.collections &&
      configuration.imported_data.collections.includes('unloads')) {
      if (this.filters) {
        this.predefinedFilters = true;
        this.loadData();
      } else {
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }
  }

  private loadData(): void {
    this.loading = true;
    this.selected = [];

    if (!this.predefinedFilters) {
      if (this.route.parent.snapshot.queryParams['product_id']) this.hideColumns.push('product');
      else this.hideColumns = removeString(this.hideColumns, 'product');
    }

    this.subscriptions.push(this.unloadService.get(this.company.id, this.filters).subscribe(response => {
      this.dataLoaded(response.body, parsePagination(response.headers));
    }));
  }

  private dataLoaded(data: Unload[], pagination: Pagination): void {
    this.weight = {
      net: 0,
      tare: 0,
      gross: 0,
      applied: new Quantity()
    };

    if (data.length && data[0].quantity_applied) {
      this.weight.applied.type = { slug: 'fixed' };//data[0].quantity_applied.type;
      this.weight.applied.unit = data[0].quantity_applied.unit;
      this.weight.applied.value = 0;
    }

    data.forEach(truck => {
      if (truck.quantity_applied) this.weight.applied.value += truck.quantity_applied.value;

      if (truck.waybill && truck.waybill.destination) {
        // Summarized weight on destination
        this.weight.net += truck.waybill.destination.weight.net;
        this.weight.tare += truck.waybill.destination.weight.tare;
        this.weight.gross += truck.waybill.destination.weight.gross;
      }
    });

    this.trucks = data;
    this.loading = false;

    this.onLoad.emit({
      weight: this.weight,
      data: this.trucks,
      pagination: pagination
    });
  }

  // ngModel
  private _value: Unload[] = [];
  public get selected(): Unload[] { return this._value }
  public set selected(v: Unload[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
