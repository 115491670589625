import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, forkJoin } from 'rxjs';

import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company, companyIs } from '../../../../../models/company.model';
import { Currency } from '../../../../../models/currency.model';
import { CompanyService } from '../../../../../services/company.service';
import { MarketService } from '../../../../../services/market.service';
import { Contract } from '../../models/contract.model';
import { ContractService } from '../../services/contract.service';

@Component({
  selector: 'ag-contract-actions',
  templateUrl: './contract-actions.component.html',
  styleUrls: ['./contract-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractActionsComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('toTradeModal', { static: true }) private readonly toTradeModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public container: string = "body";
  @Input() public right: boolean;
  @Input() public contract: Contract;

  @Output() readonly done = new EventEmitter();

  public actors: {
    [role: string]: Company;
  };
  public availableActors: {
    [role: string]: Company[];
  };
  public companyNotFound: boolean;
  public currencies: Currency[];
  public noBrokers: boolean;
  public user: User;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public isFarmer: boolean;
  public broker: boolean;
  public buyer: boolean;
  public seller: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    /** @ignore */
    public companyService: CompanyService,
    private contractService: ContractService,
    private modalService: BsModalService,
    private marketService: MarketService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.loadCurrencies();

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.company && this.contract) {
      this.isFarmer = companyIs(this.company, 'FARMER');
      
      this.buyer = this.contract.buyer.id === this.company.id;
      this.broker = Boolean(this.contract.getBroker(this.company.id));
      this.seller = this.contract.seller.id === this.company.id;
    }
  }

  private loadCurrencies(): void {
    if (this.currencies?.length) return;
    this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
      if (currencies) {
        this.currencies = currencies;
      }
    }));
  }

  public delete(): void {
    this.subscriptions.push(this.contractService.delete(this.company.id, this.contract.id).subscribe(response => {
      this.done.emit();
    }));
  }

  public toTrade(): void {
    this.actors = {
      seller: this.contract.seller,
      buyer: this.contract.buyer,
      broker_seller: undefined,
      broker_buyer: undefined
    };

    if (this.contract.brokers) {
      this.actors[this.seller ? 'broker_buyer' : 'broker_seller'] = this.contract.brokers[0];
      if (this.contract.brokers[1]) {
        this.actors[this.seller ? 'broker_seller' : 'broker_buyer'] = this.contract.brokers[1];
      }
    }

    this.loadCounterpartsOptions();
    this.openModal(this.toTradeModal);
  }

  private loadCounterpartsOptions(): void {
    this.availableActors = {};
    this.processing = true;

    this.subscriptions.push(forkJoin([
      this.companyService.getCompaniesByFiscalValue(this.company.id, this.company.market.country_id, this.contract.buyer.fiscal_id.value),
      this.companyService.getCompaniesByFiscalValue(this.company.id, this.company.market.country_id, this.contract.seller.fiscal_id.value)
    ]).subscribe(values => {
      this.availableActors = {
        buyers: values[0],
        sellers: values[1]
      };

      this.processing = false;
    }));
  }

  public brokersLoaded(e): void {
    this.noBrokers = this.checkSelectizeEmptyResponse(e) === 0;
  }

  private checkSelectizeEmptyResponse(e): number {
    if (e.query === "") {
      // With an empty query
      if (e.results.length === 1) {
        // Only one result
        return 1;
      } else if (e.results.length === 0) {
        // No results
        return 0;
      }
    }
    return -1;
  }

  public submit(): void {
    let found: boolean = false;
    for (const role in this.actors) {
      const company = this.actors[role];
      if (company && company.id === this.company.id) found = true;
    }

    this.companyNotFound = !found;

    if (found) {
      this.subscriptions.push(this.contractService.toTrade(this.company.id, this.contract.id, this.actors).subscribe(response => {
        this.closeModal();
      }));
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
