import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../../../models/company.model';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { Waybill } from '../../models/waybill.model';
import { WaybillService } from '../../services/waybill.service';

@Component({
  selector: 'waybill-modal',
  templateUrl: './waybill-modal.component.html',
  styleUrls: ['./waybill-modal.component.scss']
})
export class WaybillModalComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) public readonly modalTemplate: TemplateRef<any>;

  @Input() public company: Company;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public waybill: Waybill;
  public waybillFiles: any[];
  public error: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private waybillService: WaybillService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  public show(waybill: Waybill | number): void {
    this.openModal(this.modalTemplate, 'modal-lg');

    if (typeof waybill === 'number') this.loadWaybill(waybill);
    else this.setWaybill(waybill);
  }

  private loadWaybill(id: number): void {
    this.subscriptions.push(this.waybillService.getById(this.company.id, id).subscribe({
      next: waybill => {
        this.setWaybill(waybill);
      },
      error: error => {
        this.error = true;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  private setWaybill(waybill: Waybill): void {
    this.waybill = waybill;
    this.getFiles();
  }

  private getFiles(): void {
    this.waybillFiles = undefined;
    this.subscriptions.push(this.waybillService.getFiles(this.company.id, this.waybill.id).subscribe(response => {
      this.waybillFiles = response.data;
    }));
  }

  public getFile(): void {
    this.processing = true;

    const last_file = this.waybillFiles[this.waybillFiles.length - 1];
    // Safari blocks any call to window.open() which is made inside an async call.
    const windowReference = window.open();
    this.subscriptions.push(this.waybillService.getFile(this.company.id, this.waybill.id, last_file.id).subscribe({
      next: response => {
        if (response.url) {
          windowReference.location = response.url;
        } else {
          windowReference.close();
        }
        this.processing = false;
      },
      error: error => {
        windowReference.close();
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.modalRef = null;
      this.waybill = undefined;
      this.error = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /**
   * Compare function for
   * [[https://angular.io/api/common/KeyValuePipe|keyvalue]] pipe to preserve
   * original array order.
   */
  public originalOrder(): number {
    return 0;
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
