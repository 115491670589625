import { Type } from "class-transformer";

import { WorkFlowDataField } from "../../../../company/modules/fintech/models/work-flow-data-field.model";

export class FintechForm {
  /** Agree's internal unique identifier. */
  readonly id: string;
  name: string;
  description: string;

  @Type(() => WorkFlowDataField)
  spec: WorkFlowDataField[];

  params?: { [key: string]: WorkFlowDataField[] };

  constructor(data: Partial<FintechForm> = {}) {
    Object.assign(this, data);
  }
}