import { Type } from "class-transformer";
import { Waybill } from "./waybill.model";
import { Contract } from "../../contracts/models/contract.model";
import { Label } from "../../../../models/label.model";
import { Price } from "../../../../models/price.model";
import { Quantity } from "../../../../models/quantity.model";

/**
 * An Unload links a [[Waybill]] with one ore more [[Contract|Contracts]]. It
 * also specifies the [[Unload.quantity_applied|quantity applied]], once
 * discounts have been applied for non-compliance (for example, it was not the
 * specified [[Product]] quality).
 *
 * Optionally, it can include the [[Unload.price|price]] fixed for this
 * [[Unload.quantity_applied|quantity]].
 *
 * ### Related UI components:
 * - [[TrucksTableComponent]]
 * @deprecated
 */
export class Unload {
  /** Agree's internal unique identifier. */
  readonly id: number; // Internal ID

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  /**
   * Actual unload date. It should match the one on the [[Waybill]].
   */
  @Type(() => Date)
  date: Date; // Fecha descarga de la carta de Porte - ENTREGAS > Fecha

  /**
   * All [[Contract|Contracts]] to which this cargo applies.
   */
  contracts: Contract[];
  /**
   * If there is any non-compliance in the delivery of the [[Product]],
   * discounts may be applied. The quantity applied establishes how much of
   * this charge is considered to apply to [[Unload.contracts|Contracts]] after
   * said discounts.
   */
  quantity_applied: Quantity;
  price?: Price;

  @Type(() => Waybill)
  waybill: Waybill;

  /**
   * Potential inconsistencies in imported data.
   */
  warnings?: string[];

  private _contract_reference: string;

  /**
   * Returns the first [[Contract]] reference for sorting purposes.
   */
  get contract_reference(): string {
    if (!this._contract_reference) { // Performs only once
      let _contracts: string[] = [];

      this.contracts.forEach(contract => {
        _contracts.push(contract.reference);
      });

      this._contract_reference = _contracts.length ? _contracts.sort()[0] : '';
    }
    return this._contract_reference;
  }

  /** For labeleable entities. */
  get entity(): string { return 'unload'; }
  readonly labels?: Label[];
}
